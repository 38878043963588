import { Outlet } from "react-router-dom";
import { Pages } from "../../pages/index";
import { useFastContext } from "../../providers/FastContractContext/FastContractContext";
import { useFastSymbol } from "../../providers/FastSymbolProvider";
import { useTopContracts } from "../../providers/TopContractsProvider";

// Different permissions.
export const enum Persona {
  Member = "FAST Member",
  Governor = "FAST Governor",
  Issuer = "Issuer Member",
  MemberOrIssuer = "FAST Member | Issuer Member",
  MemberOrGovernor = "FAST Member | FAST Governor",
  GovernorOrIssuerMember = "FAST Governor | Issuer Member",
  MemberOrGovernorOrIssuerMember = "FAST Member | FAST Governor | Issuer Member",
}
// The `as` property specifies the angle required to view this page.
interface Props {
  readonly requires: Persona;
}

// This component is in charge of screening unauthorized access to any given `/fasts/:symbol`
// sub-route.
export const Guard = ({ requires }: Props) => {
  const { symbol } = useFastSymbol();
  const { isIssuerMember } = useTopContracts();
  const { membership } = useFastContext();
  const { isMember, isGovernor } = membership;

  const authorized =
    // Requires membership, user has it.
    (requires == Persona.Member && isMember) ||
    // Requires governorship, user has it.
    (requires == Persona.Governor && isGovernor) ||
    // Requires issuer membership, user has it.
    (requires == Persona.Issuer && isIssuerMember) ||
    // Requires membership or issuer membership, user has it.
    (requires == Persona.MemberOrIssuer && (isMember || isIssuerMember)) ||
    // Requires that the user is either a member or a governor, and that's the case.
    (requires == Persona.MemberOrGovernor && (isMember || isGovernor)) ||
    // Requires that the user is either a governor or an issuer, and that's the case.
    (requires == Persona.GovernorOrIssuerMember && (isIssuerMember || isGovernor)) ||
    // Requires that the user is either a member, a governor or an issuer member, and that's the case.
    (requires == Persona.MemberOrGovernorOrIssuerMember && (isMember || isGovernor || isIssuerMember));

  return authorized ? (
    <Outlet />
  ) : (
    <Pages.Errors.Forbidden symbol={symbol} reason={`Requires FAST "${requires}" privilege.`} whiteLayout />
  );
};

export const Member = () => <Guard requires={Persona.Member} />;
export const Governor = () => <Guard requires={Persona.Governor} />;
export const Issuer = () => <Guard requires={Persona.Issuer} />;
export const MemberOrIssuer = () => <Guard requires={Persona.MemberOrIssuer} />;
export const MemberOrGovernor = () => <Guard requires={Persona.MemberOrGovernor} />;
export const GovernorOrIssuerMember = () => <Guard requires={Persona.GovernorOrIssuerMember} />;
export const MemberOrGovernorOrIssuerMember = () => <Guard requires={Persona.MemberOrGovernorOrIssuerMember} />;
