import { Button, Modal } from "antd";
import { PropsWithModalState } from "../../../hooks/useModalState";

interface Props {
  readonly description: string;
  readonly vote: string;
  readonly onVote: () => void;
}

export const VoteModal = ({ description, state: { isVisible, close }, vote, onVote }: PropsWithModalState<Props>) => {
  const handleAction = () => {
    onVote();
    close();
  };

  return (
    <Modal className="Vote-Modal" closable={false} footer={null} maskClosable={false} open={isVisible} onCancel={close}>
      <h2>Confirm your vote please</h2>
      <h4>
        REMEMBER!
        <br />
        &bull; You can not undo this action
        <br />
        &bull; All users just have one vote
      </h4>
      <div className="Vote-Info">
        <p className="Bold">Vote</p>
        <div className="Vote">{vote}</div>
        <p className="Bold">Comment</p>
        <p>{description}</p>
      </div>
      <div className="ButtonPairWrapper">
        <Button onClick={close}>Cancel</Button>
        <Button onClick={handleAction}>Send Vote</Button>
      </div>
    </Modal>
  );
};
