import { DistributionContract } from "../../types/contracts";
import { EthAddress } from "../../types/ethers";
import { ResolvedIdentity } from "../AddressResolver.tsx/AddressResolverProvider";
import { ERC20Info } from "../ERC20Fetcher";

export enum Phase {
  Funding = 0,
  FeeSetup = 1,
  BeneficiariesSetup = 2,
  Withdrawal = 3,
  Terminated = 4,
}

export const formatPhase = (phase: Phase | number) => {
  switch (phase) {
    case Phase.Funding:
      return "Funding";
    case Phase.FeeSetup:
      return "Requested";
    case Phase.BeneficiariesSetup:
      return "Sent";
    case Phase.Withdrawal:
      return "Distributed";
    case Phase.Terminated:
      return "Aborted";
    default:
      throw Error(`Unknown Distribution phase ${phase}.`);
  }
};

export const enforceNumericPhase = (phase: number): Phase => {
  if (phase < Phase.Funding || phase > Phase.Terminated) throw new Error(`Invalid phase: ${phase}`);
  return phase;
};

export interface Params {
  readonly distributor: EthAddress;
  readonly issuer: EthAddress;
  readonly fast: EthAddress;
  readonly token: EthAddress;
  readonly blockLatch: bigint;
  readonly total: bigint;
  readonly ref: string;
  // Augmented at runtime.
  readonly distributorFullName?: Promise<ResolvedIdentity>;
}

// TODO: We probably want this type to be a union of the different
// phases, so that we only query data minimalistically.
export interface Info {
  readonly address: EthAddress;
  readonly contract: DistributionContract;
  readonly version: number;
  readonly params: Params;
  readonly erc20Info: ERC20Info;
  readonly phase: Phase;
  readonly fee: bigint;
  readonly available: bigint;
  readonly creationBlock: bigint;
  readonly beneficiaryCount: bigint;
}

export interface DistributionContextState {
  readonly info: Info;
  readonly isReceivedWithdrawal: boolean;
}
