import { Link } from "react-router-dom";
import { ResolvedIdentity } from "../../providers/AddressResolver.tsx/AddressResolverProvider";
import { EthAddress, isEthAddress } from "../../types/ethers";
import { ResolvableAddress } from "../ResolvableAddress";

interface Props {
  readonly ethAddress: EthAddress | string;
  readonly explorer: string;
  readonly fullName: Promise<ResolvedIdentity> | string;
  readonly linkedIn?: string | null;
}

export const UserColumn = ({ ethAddress, explorer, fullName, linkedIn }: Props) => (
  <div className="UserColumn">
    {isEthAddress(ethAddress) ? (
      <>
        {linkedIn && (
          <Link to={linkedIn} target="_blank">
            <i className="icon-linkedin" />
          </Link>
        )}
        <ResolvableAddress address={ethAddress} fullName={fullName} explorer={explorer} withCopyButton={false} />
      </>
    ) : (
      <>{fullName}</>
    )}
  </div>
);
