import { Button, Popover } from "antd";
import { useCallback, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { FastsDropdownOption } from "./FastsDropdown/Option";
import { TokenSphereOption } from "./FastsDropdown/TokenSphereOption";
import { useFastsList } from "../providers/FastsListProvider";
import { Paths } from "../routing";

export const FastsDropdown = () => {
  const { symbol } = useParams();
  const detailedFastsList = useFastsList();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const getOption = useMemo(() => {
    if (!symbol) return <TokenSphereOption />;

    const name = detailedFastsList.find((fast) => fast.symbol === symbol)?.name;
    if (!name) return <TokenSphereOption />;

    return <FastsDropdownOption name={name} symbol={symbol} />;
  }, [symbol, detailedFastsList]);

  const handleCloseDropdown = useCallback(() => setIsDropdownOpen(false), []);

  return (
    <div className="FastsDropdown">
      {detailedFastsList.length ? (
        <Popover
          autoAdjustOverflow
          overlayClassName="FastsDropdown-Popover"
          placement="bottomLeft"
          trigger="click"
          showArrow={false}
          open={isDropdownOpen}
          onOpenChange={setIsDropdownOpen}
          content={
            <div className="Content">
              {detailedFastsList.map(({ name, symbol: fastSymbol }) => (
                <Link
                  key={fastSymbol}
                  className={`Option ${fastSymbol === symbol ? "Active" : ""}`}
                  to={Paths.Fasts.One.About.overview.replace(":symbol", fastSymbol)}
                  onClick={handleCloseDropdown}>
                  <strong>{fastSymbol}</strong>
                  <span>{name}</span>
                </Link>
              ))}
              <div className="Divider" />
              <Link className={`DefaultOption ${symbol ? "" : "Active"}`} to={Paths.root} onClick={handleCloseDropdown}>
                <strong>TokenSphere</strong>
                <span>Community</span>
              </Link>
            </div>
          }>
          <Button className="FastsDropdown-Button">
            {getOption}
            <i className="icon-arrow-down" />
          </Button>
        </Popover>
      ) : (
        <TokenSphereOption />
      )}
    </div>
  );
};
