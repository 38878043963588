import { writeContract } from "@wagmi/core";
import { Button } from "antd";
import { useEffect, useMemo, useState } from "react";
import { distributionABI } from "../../../../__generated__/contracts";
import { ResolvableAddress } from "../../../../components/ResolvableAddress";
import { useErrorHelpers } from "../../../../hooks/useErrorHelpers";
import {
  ResolvedIdentity,
  useAddressesResolver,
} from "../../../../providers/AddressResolver.tsx/AddressResolverProvider";
import { useChain } from "../../../../providers/ChainClient";
import { Phase } from "../../../../providers/DistributionProvider/types";
import { useFastContext } from "../../../../providers/FastContractContext/FastContractContext";
import { useTopContracts } from "../../../../providers/TopContractsProvider";
import { EthAddress } from "../../../../types/ethers";
import { SimpleToast } from "../../../../types/toasts";

interface Props {
  readonly address: EthAddress;
  readonly available: bigint;
  readonly distributor: EthAddress;
  readonly explorer: string;
  readonly phase: Phase;
  readonly setIsLoading: (isLoading: boolean) => void;
}

export const ActionColumn = ({ address, available, distributor, explorer, phase, setIsLoading }: Props) => {
  const { signerAddress } = useChain();
  const { isIssuerMember } = useTopContracts();
  const {
    membership: { balance },
  } = useFastContext();
  const { resolveAddresses } = useAddressesResolver();
  const { handleError } = useErrorHelpers();
  const [distributorName, setDistributorName] = useState<Promise<ResolvedIdentity> | EthAddress>();

  // Let's resolve distributor name, because resolveAddresses is Promise.
  useEffect(() => {
    const resolving = resolveAddresses([distributor]);
    setDistributorName(resolving[distributor]);
  }, [distributor, resolveAddresses]);

  const getRole = useMemo(() => {
    if (distributor === signerAddress && balance > 0n) return <p>My role: Distributor I Beneficiary</p>;
    else if (distributor === signerAddress) return <p>My role: Distributor</p>;
    else if (balance > 0n) return <p>My role: Beneficiary</p>;
    else return;
  }, [balance, distributor, signerAddress]);

  const onTerminate = () => {
    setIsLoading(true);
    SimpleToast.success("Distribution Termination.");

    writeContract({
      abi: distributionABI,
      address,
      functionName: "terminate",
    })
      .then(() => SimpleToast.success("Distribution was terminated."))
      .catch((err) => {
        setIsLoading(false);
        handleError("Distribution Termination Error.", err);
      });
  };

  const onDistribute = () => {
    setIsLoading(true);
    SimpleToast.success("Distribution.");

    writeContract({
      abi: distributionABI,
      address,
      functionName: "advanceToWithdrawal",
    })
      .then(() => SimpleToast.success("Distribution was completed."))
      .catch((err) => {
        setIsLoading(false);
        handleError("Distribution Error.", err);
      });
  };

  return (
    <div className="ActionColumn">
      <div>
        Distributor:&nbsp;
        <ResolvableAddress
          address={distributor}
          fullName={distributorName}
          explorer={explorer}
          withCopyButton={false}
        />
      </div>
      {getRole}
      {isIssuerMember && (
        <div className="ButtonPairWrapper">
          {phase !== Phase.Terminated && !(phase === Phase.BeneficiariesSetup && available > 0n) && (
            <Button onClick={onTerminate}>Abort</Button>
          )}
          {phase === Phase.BeneficiariesSetup && available <= 0n && <Button onClick={onDistribute}>Distribute</Button>}
        </div>
      )}
    </div>
  );
};
