import { ApolloQueryResult } from "@apollo/client";
import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { Proposal, ProposalVote } from "./types";
import { ProposalQuery, useProposalQuery } from "../../__generated__/graphql";
import { Loader } from "../../components/uiElements/Loader";
import { useFastDetails } from "../../pages/fast/Top";
import { Pages } from "../../pages/index";
import { useAppContext } from "../APIClient";
import { createGenericContext } from "../utils";

interface ContextState {
  readonly info: Proposal;
  readonly updateProposal: () => Promise<ApolloQueryResult<ProposalQuery>>;
}

const [useProposal, Provider] = createGenericContext<ContextState>("ProposalContext");

interface Props {
  readonly address: string;
}

export const ProposalProvider = ({ address, children }: PropsWithChildren<Props>) => {
  const { sessionData } = useAppContext();
  const { fastDetails } = useFastDetails();

  // We need to know this proposal has been voted or not.
  const isVotedProposal = useMemo(() => {
    if (fastDetails?.proposals?.edges) {
       const proposal = fastDetails.proposals.edges.find((p) => p?.node?.id === address)?.node; 
       return proposal?.state === "CLOSED" || proposal?.hasVoted;
    }
  }, [address, fastDetails?.proposals?.edges]);

  const {
    error,
    loading,
    data,
    refetch: updateProposal,
  } = useProposalQuery({
    variables: { proposalId: address, identityId: sessionData?.identity.id ?? "", hasVoted: isVotedProposal },
  });
  const [info, setInfo] = useState<Proposal>();

  useEffect(() => {
    if (!data) return;

    const proposal = data.proposal;
    if (!proposal || proposal.__typename !== "Proposal") throw new Error("Unexpected server response");

    let votes = null;
    if (proposal.votes?.edges) {
      votes = proposal.votes.edges.reduce<ReadonlyArray<ProposalVote>>((acc, vote) => {
        const v = vote?.node;

        if (!v) return acc;

        return [
          ...acc,
          {
            choice: v.choice,
            id: v.id,
            comment: v.comment,
            insertedAt: v.insertedAt,
          },
        ];
      }, []);
    }

    setInfo({ ...proposal, votes });
  }, [data]);

  if (error) return <Pages.Errors.Error reason={`Failed to invoke Proposal ${address} contract.`} whiteLayout />;
  else if (loading) return <Loader label="Loading Proposal..." whiteLayout />;
  else if (!info) return null;

  return <Provider value={{ info, updateProposal }}>{children}</Provider>;
};

export { useProposal };
