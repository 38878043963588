import { Image } from "antd";
import { useSearchParams } from "react-router-dom";
import { Application } from "./Onboarding/Application";
import { Issuance } from "./Onboarding/Issuance";
import { TokenSphereWhite } from "../assets";

export const Onboarding = () => {
  const [searchParams] = useSearchParams();
  const onboardId = searchParams.get("id");

  return (
    <section className="Onboarding">
      <h2 className="Onboarding-Title">
        <Image alt="TokenSpheree" src={TokenSphereWhite} preview={false} />
        <span className="Opaque">Platform</span>
      </h2>

      <div className="Onboarding-Body">{onboardId ? <Application onboardId={onboardId} /> : <Issuance />}</div>
    </section>
  );
};
