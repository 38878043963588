import { Phase } from "../../../../providers/DistributionProvider/types";

interface Props {
  readonly phase: Phase;
  readonly withdrawn: boolean;
}

export const Status = ({ phase, withdrawn }: Props) => (
  <>
    <div className={`Status ${withdrawn ? "Correct" : phase === Phase.Terminated ? "Bad" : "Available"}`} />
    {withdrawn ? "Withdrawn" : phase === Phase.Terminated ? "Not withdrawn" : "Available to withdraw"}
  </>
);
