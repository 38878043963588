import { Button } from "antd";
import { useCallback } from "react";
import { useAccount, useSwitchNetwork } from "wagmi";
import { SupportedChain } from "../../helpers/chain";

export const SwitchNetworkButton = () => {
  const { switchNetwork } = useSwitchNetwork();
  const { address, connector } = useAccount();

  const onClick = useCallback(() => {
    if (!connector || !address || !switchNetwork) return;
    switchNetwork(Number(SupportedChain.id));
  }, [address, connector, switchNetwork]);

  return (
    <div className="SwitchNetwork">
      {connector ? (
        <>
          <Button onClick={onClick}>Switch Network</Button>
          <p>{address || "UNKNOWN ADDRESS"}</p>
          <p>Connected to {connector.name}</p>
        </>
      ) : (
        <p>No Wallet Connection Detected</p>
      )}
    </div>
  );
};
