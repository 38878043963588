import Form, { IChangeEvent } from "@rjsf/core";
import { RegistryFieldsType, RegistryWidgetsType } from "@rjsf/utils";
import { customizeValidator } from "@rjsf/validator-ajv8";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Stages, initialFormData } from "./Edit/types";
import { AddButton, RemoveButton } from "./Edit/Widgets/ButtonTemplates";
import { CustomSelect } from "./Edit/Widgets/CustomSelect";
import { CustomTextArea } from "./Edit/Widgets/CustomTextArea";
import { EnumMultiSelect } from "./Edit/Widgets/MultiSelect";
import { TagsEditor } from "./Edit/Widgets/TagsEditor";
import { Schema, SchemaType } from "../../__generated__/FastForm.module.v1";
import {
  AmendFastInput,
  FullFastDetailsFragment,
  useAmendFastMutation,
  useFastDetailsLazyQuery,
} from "../../__generated__/graphql";
import { withAccepts } from "../../components/DocumentUploader";
import { isFastDetails } from "../../graphql/types/JsonScalar";
import { useErrorHelpers } from "../../hooks/useErrorHelpers";
import * as FastDetailsSchema from "../../JsonSchemas/FastForm.schema.v1";
import uiSchema from "../../JsonSchemas/FastForm.uiSchema.v1.json";
import { useAppContext } from "../../providers/APIClient";
import { useFastContext } from "../../providers/FastContractContext/FastContractContext";
import { useFastSymbol } from "../../providers/FastSymbolProvider";
import { Paths } from "../../routing";
import { SimpleToast } from "../../types/toasts";

const fieldsRegistry: RegistryFieldsType<FastDetailsSchema.FastForm, SchemaType> = {
  LogoCustomField: withAccepts<FastDetailsSchema.FastForm, SchemaType>("image/*"),
  DocumentCustomField: withAccepts<FastDetailsSchema.FastForm, SchemaType>("application/pdf"),
  ImageCustomField: withAccepts<FastDetailsSchema.FastForm, SchemaType>("image/*"),
};

const widgetsRegistry: RegistryWidgetsType<FastDetailsSchema.FastForm, SchemaType> = {
  TagsEditor: TagsEditor<FastDetailsSchema.FastForm, SchemaType>,
  TextareaWidget: CustomTextArea<FastDetailsSchema.FastForm, SchemaType>,
  SelectWidget: CustomSelect<FastDetailsSchema.FastForm, SchemaType>,
  StageMultiSelect: EnumMultiSelect<FastDetailsSchema.FastForm, SchemaType>(Stages),
};

const templates = {
  ButtonTemplates: {
    AddButton: AddButton<FastDetailsSchema.FastForm, SchemaType>,
    RemoveButton: RemoveButton<FastDetailsSchema.FastForm, SchemaType>,
  },
};

// TODO: Split the component into two smaller components:
// - One in charge of loading the fast details and making sure that all needed data is present.
// - Another one in charge of rendering the form and submitting the changes given guaranteed data.
export const Edit = () => {
  // We really just need the symbol for the FAST currently in scope.
  const { symbol } = useFastSymbol();
  const { membership } = useFastContext();
  // This immediately loads the FAST details from the GraphQL endpoint.
  const { isMember } = membership;
  const { handleError } = useErrorHelpers();
  const [query] = useFastDetailsLazyQuery();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<FastDetailsSchema.FastForm>(initialFormData);
  const [fastDetails, setFastDetails] = useState<FullFastDetailsFragment>();
  const [isLoading, setIsLoading] = useState(true);

  const { sessionData } = useAppContext();

  const [amendFast] = useAmendFastMutation();

  const onFastUpdated = useCallback(
    (f: FullFastDetailsFragment | undefined | null) => {
      if (f) setFastDetails(f);
      const data = f?.form?.data;
      if (isFastDetails(data)) setFormData(data);
    },
    [setFastDetails],
  );

  useEffect(() => {
    if (!sessionData) return;

    setIsLoading(true);
    query({ variables: { symbol, isMember, identityId: sessionData.identity.id } })
      .then((res) => onFastUpdated(res.data?.fasts?.edges?.at(0)?.node))
      .catch((err) => handleError("Loading Fast Details", err))
      .finally(() => setIsLoading(false));
  }, [symbol, isMember, query, onFastUpdated, handleError, sessionData]);

  // When the form is submitted...
  const schemaVersion = fastDetails?.form?.schemaVersion || "1";
  const fastId = fastDetails?.id;
  const onSubmit = useCallback(
    (data: IChangeEvent<FastDetailsSchema.FastForm, SchemaType>) => {
      // Fast details not loaded yet, or form has errors...
      if (!sessionData || !fastId || data.errors.length) return;
      setIsLoading(true);
      const input: AmendFastInput = {
        fastId,
        schemaVersion,
        data: JSON.stringify(data.formData),
      };
      amendFast({ variables: { input, isMember, identityId: sessionData.identity.id } })
        .then((res) => {
          SimpleToast.success("FAST details updated successfully.");
          onFastUpdated(res.data?.amendFast?.fast);
        })
        .catch((err) => handleError("Amending FAST Details", err))
        .finally(() => setIsLoading(false));
    },
    [fastId, schemaVersion, amendFast, isMember, onFastUpdated, handleError, sessionData],
  );

  // An AJV validator typed to our FormData input structure.
  const validator = useMemo(() => customizeValidator<FastDetailsSchema.FastForm, SchemaType>(), []);
  // Render the form.
  return (
    <section className="FastEdit">
      <div className="FastEdit-TitleBlock">
        <h1>Edit</h1>
        <button
          className="Button-Secondary"
          onClick={() => navigate(Paths.Fasts.One.About.overview.replace(":symbol", symbol))}>
          Cancel
        </button>
      </div>

      <Form<FastDetailsSchema.FastForm, SchemaType>
        validator={validator}
        noHtml5Validate
        formData={formData}
        schema={Schema}
        uiSchema={uiSchema}
        fields={fieldsRegistry}
        templates={templates}
        widgets={widgetsRegistry}
        onSubmit={onSubmit}
        showErrorList="bottom"
        disabled={isLoading}
      />
    </section>
  );
};
