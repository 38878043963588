import { Button, Modal } from "antd";
import { useState } from "react";

interface Props {
  readonly content: JSX.Element;
  readonly title: string;
}

export const TipModal = ({ content, title }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal((prev) => !prev);

  return (
    <>
      <Button className="TipModal-Button" onClick={toggleModal}>
        Read more
      </Button>
      <Modal className="ModalWrapper TipModal" footer={null} open={showModal} width={555} onCancel={toggleModal}>
        <i className="icon-info-circle" />
        <h4>{title}</h4>
        <div className="Content">{content}</div>
      </Modal>
    </>
  );
};
