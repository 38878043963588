import { Layout, Spin } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FastCard } from "../components/FastCard";
import { OpacityTransition } from "../components/layout/OpacityTransition";
import { NetworkName } from "../components/NetworkName";
import { BigNumberFormatter } from "../hooks/useBigNumberFormatter";
import { RawFastDetails } from "../providers/FastContractContext/types";
import { useTopContracts } from "../providers/TopContractsProvider";
import { Paths } from "../routing";

type State = ReadonlyArray<Readonly<RawFastDetails>>;

export const Groups = () => {
  const { issuer } = useTopContracts();
  const { group } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState<State>([]);

  if (!group) throw new Error("Impossible state - no group slug.");

  const fetchPaginateDetailedFasts = useCallback(() => {
    // Mark the section as being loaded.
    setIsLoading(true);
    // Load FASTS - upon success populate the state.
    return issuer.read
      .paginateDetailedFastsInGroup([group, 0n, 5000n])
      .then(([paginatedFastsData]) =>
        setState(paginatedFastsData.map(({ addr, ...rest }) => ({ ...rest, address: addr }))),
      )
      .finally(() => setIsLoading(false));
  }, [group, issuer, setIsLoading, setState]);

  // Loads the list of FASTs...
  useEffect(() => {
    void fetchPaginateDetailedFasts();
  }, [fetchPaginateDetailedFasts]);

  return (
    <OpacityTransition>
      <Layout className="FastsLayout">
        <NetworkName group={group} className="FastsLayout-Header" />

        <div className="FastsLayout-Body">
          <h1>{group} FAST Portfolio</h1>
          <p>These are all the FAST on your network.</p>
          {isLoading ? (
            <Spin size="large" spinning={isLoading} />
          ) : (
            <div className="FastsLayout-Body-Fasts">
              {state.map(({ symbol, name, decimals, memberCount, hasFixedSupply, totalSupply }) => {
                const readableTotalSupply = new BigNumberFormatter(decimals).format(totalSupply, 2);
                return (
                  <FastCard
                    key={symbol}
                    hasFixedSupply={hasFixedSupply}
                    memberCount={memberCount}
                    name={name}
                    path={Paths.Fasts.one.replace(":symbol", symbol)}
                    readableTotalSupply={readableTotalSupply}
                    symbol={symbol}
                  />
                );
              })}
            </div>
          )}
        </div>
      </Layout>
    </OpacityTransition>
  );
};
