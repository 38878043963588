import { Transfer } from "./Wallet/Transfer";
import { TransferHistory } from "./Wallet/TransferHistory";
import { useFastContext } from "../../providers/FastContractContext/FastContractContext";

export const Wallet = () => {
  const {
    details: { address, formatter },
    details,
    membership,
  } = useFastContext();

  return (
    <section className="FastTab Wallet">
      <Transfer fastAddress={address} details={details} membership={membership} />
      <TransferHistory formatter={formatter} />
    </section>
  );
};
