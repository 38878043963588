import { HTMLMotionProps, MotionProps, motion } from "framer-motion";
import { PropsWithChildren } from "react";

const initialProp = { opacity: 0 };
const animateProp = { opacity: 1 };
const exitProp = { opacity: 0 };
const defaultTransition = {
  delay: 0.25,
  duration: 0.2,
};

export const OpacityTransition = ({
  children,
  className,
  transition,
}: PropsWithChildren<MotionProps & HTMLMotionProps<"div">>) => (
  <motion.div
    className={className}
    initial={initialProp}
    animate={animateProp}
    exit={exitProp}
    transition={transition ? transition : defaultTransition}>
    {children}
  </motion.div>
);
