import { writeContract } from "@wagmi/core";
import { Button, Form, Input, Modal } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useCallback, useState } from "react";
import { fastABI } from "../../../__generated__/contracts";
import { useErrorHelpers } from "../../../hooks/useErrorHelpers";
import { PropsWithModalState } from "../../../hooks/useModalState";
import { useFastContext } from "../../../providers/FastContractContext/FastContractContext";
import { EthAddress } from "../../../types/ethers";

interface NewMemberForm {
  readonly ethAddress: EthAddress;
}

export const AddAddressModal = ({ state: { isVisible, close } }: PropsWithModalState) => {
  const {
    details: { address: fastAddress },
  } = useFastContext();
  const { trackTransaction } = useErrorHelpers();
  const [form] = useForm<NewMemberForm>();
  const [isLoading, setIsLoading] = useState(false);

  const onAddAddress = useCallback(
    ({ ethAddress }: NewMemberForm) => {
      // Define a promiseFromFilter function based on the filter input to add the member or governor
      const tx = writeContract({
        abi: fastABI,
        address: fastAddress,
        functionName: "addGovernor",
        args: [ethAddress],
      });
      // Define an onSuccess function that returns a success message with the added address
      const onSuccess = () => `Address ${ethAddress} added successfully.`;
      // Define a customSetIsLoading function that resets the fields in the form, hides the add address modal, and sets the isLoading state variable
      const loader = (value: boolean) => {
        setIsLoading(value);
        if (isLoading) return;
        form.resetFields();
        close();
      };
      void trackTransaction(tx, "Coordinator Addition", onSuccess, loader);
    },
    [fastAddress, trackTransaction, form, close, isLoading],
  );

  return (
    <Modal className="Community-AddAddress" open={isVisible} onCancel={close} footer={null}>
      <Form form={form} layout="vertical" requiredMark disabled={isLoading} onFinish={onAddAddress}>
        <h2>Add Coordinator</h2>
        <Form.Item name="ethAddress" label="Coordinator Address" required={true}>
          <Input placeholder="0x..." />
        </Form.Item>
        <div className="ButtonPairWrapper">
          <Button onClick={close}>Cancel</Button>
          <Button htmlType="submit">Add</Button>
        </div>
      </Form>
    </Modal>
  );
};
