import { ColumnsType } from "antd/lib/table";
import { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { PaginatedTable } from "../../../components/tables/PaginatedTable";
import { BigNumberFormatter } from "../../../hooks/useBigNumberFormatter";
import { useChain } from "../../../providers/ChainClient";
import { FASTContract } from "../../../types/contracts";

interface Props {
  readonly contract: FASTContract;
  readonly formatter: BigNumberFormatter;
  readonly symbol: string;
}

interface SupplyProof {
  op: number;
  amount: bigint;
  blockNumber: bigint;
  ref: string;
}

export const History = ({ formatter, contract, symbol }: Props) => {
  const { chain } = useChain();
  const explorer = chain.blockExplorers.default.url;

  const fetchTotal = useCallback(() => contract.read.supplyProofCount().then(Number), [contract]);
  const fetchItems = useCallback(
    (index: number, perPage: number) => {
      return contract.read.paginateSupplyProofs([BigInt(index), BigInt(perPage)]).then(
        ([proofs]): ReadonlyArray<SupplyProof> =>
          proofs.map((proof) => ({
            op: proof.op,
            amount: proof.amount,
            blockNumber: proof.blockNumber,
            ref: proof.ref,
          })),
      );
    },
    [contract],
  );

  const columns: ColumnsType<SupplyProof> = useMemo(
    () => [
      {
        title: "Block",
        render: (_, { blockNumber }) => (
          <Link to={`${explorer}/block/${blockNumber.toString()}`} target="_blank">
            {blockNumber.toString()}
          </Link>
        ),
      },
      {
        title: "Reference",
        render: (_, { ref }) => ref,
      },
      {
        title: "Operation",
        render: (_, { op }) => (op === 0 ? "Mint" : "Burn"),
      },
      {
        title: `Amount (${symbol})`,
        render: (_, { amount }) => formatter.format(amount, 2),
      },
    ],
    [formatter, explorer, symbol],
  );

  return (
    <div className="TableWithTitle">
      <h2>Reserve Supply History</h2>
      <PaginatedTable columns={columns} fetchTotal={fetchTotal} fetchItems={fetchItems} />
    </div>
  );
};
