import { Steps } from "antd";
import { Step } from "./types";
import { assertUnreachable } from "../../../helpers/utils";

const titleForStep = (step: Step) => {
  switch (step) {
    case Step.ClaimOnboard:
      return "Verify Email";
    case Step.YourDetails:
      return "Your Details";
    case Step.ConnectWallet:
      return "Connect Wallet";
    case Step.Documentation:
      return "Documentation";
    case Step.KYCAML:
      return "Verify Identity";
    case Step.Confirmation:
      return "Confirmation";
    default:
      assertUnreachable(step);
  }
};

const Items = [
  { title: titleForStep(Step.ClaimOnboard) },
  { title: titleForStep(Step.YourDetails) },
  { title: titleForStep(Step.ConnectWallet) },
  { title: titleForStep(Step.Documentation) },
  { title: titleForStep(Step.KYCAML) },
  { title: titleForStep(Step.Confirmation) },
];

interface Props {
  readonly step: number;
}

export const Stepper = ({ step }: Props) => (
  <Steps className="Steps" current={step} direction="vertical" items={Items} progressDot size="small" />
);
