import { Navigate, Outlet, Route, Routes, useParams } from "react-router-dom";
import { FastProtectedRoute, ProtectedRoute } from "./guards/index";
import { WalletBadge } from "../components/badges/WalletBadge";
import { isProduction } from "../helpers/utils";
import { Section, SectionProps } from "../hooks/useNavigatorTabs";
import { FastContentWrapper } from "../pages/fast/FastContentWrapper";
import { FastPageWrapper } from "../pages/fast/FastPageWrapper";
import { Top } from "../pages/fast/Top";
import { Groups } from "../pages/Groups";
import { Header } from "../pages/Home/Header";
import { Pages } from "../pages/index";
import { IssuerDashboard } from "../pages/IssuerDashboard/IssuerDashboard";
import { Onboarding } from "../pages/Onboarding";
import { CrowdfundProvider } from "../providers/CrowdfundProvider/CrowdfundProvider";
import { DistributionProvider } from "../providers/DistributionProvider/DistributionProvider";
import { FastContractContext } from "../providers/FastContractContext/FastContractContext";
import { FastsListProvider } from "../providers/FastsListProvider";
import { FastSymbolProvider } from "../providers/FastSymbolProvider";
import { ProposalProvider } from "../providers/ProposalProvider/ProposalProvider";
import { enforceEthAddress } from "../types/ethers";

const HeaderWrapper = () => (
  <FastsListProvider>
    <Header />
    <Outlet />
  </FastsListProvider>
);

// The FastRouteWrapper component helps wrapping required providers for all
// individual FAST sections routes.
const FastRouteWrapper = () => (
  <FastSymbolProvider>
    <FastContractContext>
      <Top>
        <FastPageWrapper>
          <Outlet />
        </FastPageWrapper>
      </Top>
    </FastContractContext>
  </FastSymbolProvider>
);

const DistributionRouteWrapper = () => {
  const { address: rawAddress } = useParams();
  if (!rawAddress) return <>Error</>;
  const address = enforceEthAddress(rawAddress);
  return (
    <DistributionProvider address={address}>
      <Outlet />
    </DistributionProvider>
  );
};

const CrowdfundRouteWrapper = () => {
  const { address: rawAddress } = useParams();
  if (!rawAddress) return <>Error</>;
  const address = enforceEthAddress(rawAddress);
  return (
    <CrowdfundProvider address={address}>
      <Outlet />
    </CrowdfundProvider>
  );
};

const ProposalRouteWrapper = () => {
  const { address } = useParams();
  if (!address) return <>Error</>;

  return (
    <ProposalProvider address={address}>
      <Outlet />
    </ProposalProvider>
  );
};

const IssuerDashboardRouteWrapper = () => (
  <IssuerDashboard>
    <Outlet />
  </IssuerDashboard>
);

const FastContentRouteWrapper = ({ section }: SectionProps) => (
  <FastContentWrapper section={section}>
    <Outlet />
  </FastContentWrapper>
);

export default () => (
  <Routes>
    <Route element={<ProtectedRoute.ConnectedWallet />}>
      <Route path="*" element={<Pages.Errors.NotFound />} />
      <Route path="/" element={<WalletBadge />}>
        <Route path="onboarding" element={<Onboarding />} />
        <Route path="groups" element={<ProtectedRoute.MarketplaceMember />}>
          <Route path=":group" element={<Groups />}></Route>
        </Route>
        <Route element={<ProtectedRoute.AuthenticatedAndIdentified />}>
          <Route element={<HeaderWrapper />}>
            <Route index element={<Pages.Home />} />
            <Route path="fasts">
              <Route index element={<Navigate to="/" />} />
              <Route element={<ProtectedRoute.IssuerMember />}>
                <Route element={<IssuerDashboardRouteWrapper />}>
                  <Route path="new" element="Page for new FAST for an Issuer." />
                  <Route path="membership-requests" element={<Pages.IssuerDashboard.MembershipRequests />} />
                </Route>
              </Route>
              <Route path=":symbol" element={<FastRouteWrapper />}>
                {/* All FAST specific pages are only accessible to members and issuers. */}
                <Route element={<FastProtectedRoute.MemberOrIssuer />}>
                  {/* Default. */}
                  <Route index element={<Navigate to="./about/overview" />} />
                  {/* Sections. */}
                  {/* About routes. */}
                  <Route path="about" element={<FastContentRouteWrapper section={Section.About} />}>
                    <Route index element={<Navigate to="./overview" />} />
                    <Route path="overview" element={<Pages.Fast.About />} />
                    {/* The FAST Edit route can only be seen by a governor. */}
                    <Route element={<FastProtectedRoute.Governor />}>
                      <Route path="edit" element={<Pages.Fast.Edit />} />
                    </Route>
                  </Route>
                  {/* Transfers routes. */}
                  <Route path="transfers" element={<FastContentRouteWrapper section={Section.Transfers} />}>
                    <Route index element={<Navigate to="./my-transfers" />} />
                    <Route element={<FastProtectedRoute.GovernorOrIssuerMember />}>
                      <Route path="network-transfers" element={<Pages.Fast.Transfers />} />
                    </Route>
                    {/* The FAST Management route can only be seen by issuer members. */}
                    <Route element={<FastProtectedRoute.Issuer />}>
                      <Route path="tokens-management" element={<Pages.Fast.Management />} />
                    </Route>
                    <Route element={<FastProtectedRoute.MemberOrGovernor />}>
                      <Route path="my-transfers" element={<Pages.Fast.Wallet />} />
                    </Route>
                  </Route>
                  {/* Community routes. */}
                  <Route path="community" element={<FastContentRouteWrapper section={Section.Community} />}>
                    <Route index element={<Navigate to="./members" />} />
                    <Route path="members" element={<Pages.Fast.CommunityMembers />} />
                    <Route path="coordinators" element={<Pages.Fast.CommunityGovernors />} />
                    <Route element={<FastProtectedRoute.Governor />}>
                      <Route path="applications" element={<Pages.Fast.CommunityApplications />} />
                    </Route>
                  </Route>
                  {/* Manage Funds routes. */}
                  <Route path="manage-funds" element={<FastContentRouteWrapper section={Section.ManageFunds} />}>
                    <Route index element={<Navigate to="./drawdowns" />} />
                    {/* Distributions routes - disabled in production. */}
                    {!isProduction && <Route path="distributions">
                      {/* The list of distributions (index) page is only for issuers and governors. */}
                      <Route index element={<Pages.Fast.Distributions.List />} />
                      {/* The deploying of a distribution can only be seen by a governor. */}
                      <Route element={<FastProtectedRoute.Governor />}>
                        <Route path="new" element={<Pages.Fast.Distributions.New />} />
                      </Route>
                      <Route path=":address" element={<DistributionRouteWrapper />}>
                        <Route index element={<Pages.Fast.Distributions.View />} />
                      </Route>
                    </Route>}
                    {/* Crowdfunds routes. */}
                    <Route path="drawdowns">
                      <Route index element={<Pages.Fast.Crowdfunds.List />} />
                      {/* The creating of a drawdown can only be seen by a governor. */}
                      <Route element={<FastProtectedRoute.Governor />}>
                        <Route path="new" element={<Pages.Fast.Crowdfunds.New />} />
                      </Route>
                      <Route path=":address" element={<CrowdfundRouteWrapper />}>
                        <Route index element={<Pages.Fast.Crowdfunds.View />} />
                      </Route>
                    </Route>
                  </Route>
                </Route>
                {/* Proposals routes. */}
                <Route path="proposals">
                  <Route index element={<Pages.Fast.Proposals.List />} />
                  <Route path="new" element={<Pages.Fast.Proposals.New />} />
                  <Route path=":address" element={<ProposalRouteWrapper />}>
                    <Route index element={<Pages.Fast.Proposals.View />} />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
          {/* Onboarding to a specific FAST. */}
          <Route path="fasts/:symbol/onboarding" element={<Pages.Fast.FastOnboarding />} />
        </Route>
      </Route>
    </Route>
  </Routes>
);
