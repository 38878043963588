import { ApolloQueryResult } from "@apollo/client";
import { Button, Popover, Space } from "antd";
import { useCallback, useState } from "react";
import { MembershipRequestState, MembershipRequestsQuery } from "../../../__generated__/graphql";
import { ChangeApplicationStatusModal } from "../../../components/modals/ChangeApplicationStatusModal";
import { ChangeStatusModalType } from "../../../components/modals/types";
import { stringToShortIsoDate } from "../../../helpers/utils";
import { useModalState } from "../../../hooks/useModalState";

interface Props {
  readonly accepted?: string | null;
  readonly id: string;
  readonly rejected?: string | null;
  readonly status: MembershipRequestState;
  readonly updateMembershipRequests: () => Promise<ApolloQueryResult<MembershipRequestsQuery>>;
}

export const StatusColumn = ({ accepted, id, rejected, status, updateMembershipRequests }: Props) => {
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  const [modalType, setModalType] = useState<ChangeStatusModalType>("Accept");
  const changeApplicationStatusModalState = useModalState(false);

  const handleOpenModal = useCallback(
    (type: ChangeStatusModalType) => () => {
      setModalType(type);
      setIsSubmenuOpen(false);
      changeApplicationStatusModalState.open();
    },
    [changeApplicationStatusModalState],
  );

  const renderStatus = useCallback(() => {
    switch (status) {
      case MembershipRequestState.Issued:
        return (
          <Popover
            overlayClassName="StatusColumn-Submenu"
            showArrow={false}
            autoAdjustOverflow
            placement="rightTop"
            trigger="click"
            open={isSubmenuOpen}
            onOpenChange={setIsSubmenuOpen}
            content={
              <div>
                <Button onClick={handleOpenModal("Reject")}>Reject</Button>
                <ChangeApplicationStatusModal
                  id={id}
                  state={changeApplicationStatusModalState}
                  type={modalType}
                  updateMembershipRequests={updateMembershipRequests}
                />
              </div>
            }>
            <Button className="StatusColumn-SubmenuButton">...</Button>
          </Popover>
        );
      case MembershipRequestState.Amended:
        return (
          <Space>
            <Button onClick={handleOpenModal("Accept")}>Accept</Button>
            <Popover
              overlayClassName="StatusColumn-Submenu"
              showArrow={false}
              autoAdjustOverflow
              placement="rightTop"
              trigger="click"
              open={isSubmenuOpen}
              onOpenChange={setIsSubmenuOpen}
              content={
                <div>
                  <Button onClick={handleOpenModal("Accept")}>Accept</Button>
                  <Button onClick={handleOpenModal("Reject")}>Reject</Button>
                </div>
              }>
              <Button className="StatusColumn-SubmenuButton">...</Button>
            </Popover>
            <ChangeApplicationStatusModal
              id={id}
              state={changeApplicationStatusModalState}
              type={modalType}
              updateMembershipRequests={updateMembershipRequests}
            />
          </Space>
        );
      case MembershipRequestState.Accepted:
        return (
          <>
            <div className="GreenText">Accepted</div>
            {accepted && <div>on {stringToShortIsoDate(accepted)}</div>}
          </>
        );
      case MembershipRequestState.Rejected:
        return (
          <>
            <div className="RedText">Rejected</div>
            {rejected && <div>on {stringToShortIsoDate(rejected)}</div>}
          </>
        );
    }
  }, [
    accepted,
    changeApplicationStatusModalState,
    handleOpenModal,
    id,
    isSubmenuOpen,
    modalType,
    rejected,
    status,
    updateMembershipRequests,
  ]);

  return <div className="StatusColumn">{renderStatus()}</div>;
};
