import toast, { Renderable, ValueOrFunction } from "react-hot-toast";

const baseStyle = {
  borderRadius: 0,
  padding: "8px 16px",
  color: "#676783",
  fontSize: "12px",
  fontWeight: "500",
  lineHeight: "22px",
  maxWidth: "484px",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

export const SimpleToast = {
  success: (message: Renderable) => {
    toast.success(message, {
      duration: 4000,
      position: "top-right",
      style: { ...baseStyle, border: "1px solid #6DBD8F" },
      iconTheme: { primary: "#6DBD8F", secondary: "#FFFAEE" },
    });
  },

  error: (message: Renderable) => {
    toast.error(message, {
      duration: 4000,
      position: "top-right",
      style: { ...baseStyle, border: "1px solid #EE3900" },
      iconTheme: { primary: "#EE3900", secondary: "#FFFAEE" },
    });
  },

  promise: <T>(
    promise: Promise<T>,
    loading: Renderable,
    success: ValueOrFunction<Renderable, T>,
    error: ValueOrFunction<Renderable, T>,
  ) =>
    toast.promise(
      promise,
      { loading, success, error },
      {
        position: "top-right",
        style: baseStyle,
      },
    ),
};
