import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PromiseDisplayer } from "./PromiseDisplayer";
import { CopyPopover } from "./uiElements/CopyPopover";
import { ResolvedIdentity } from "../providers/AddressResolver.tsx/AddressResolverProvider";
import { EthAddress, shortenAddress } from "../types/ethers";

interface AddressDisplayerProps {
  readonly explorer: string;
  readonly address: EthAddress;
  readonly fullName?: Promise<ResolvedIdentity> | string;
  readonly withCopyButton?: boolean;
}

// This component takes a block explorer, and address and either a string or promise to an Identity.
export const ResolvableAddress = ({ explorer, address, fullName, withCopyButton = true }: AddressDisplayerProps) => {
  // Based on the passed props, determine the initial state.
  const [displayable, setDisplayable] = useState<React.ReactNode>(<>...</>);
  // Whenever the address or fullName change, we want to update the displayable.
  useEffect(() => {
    const shortAddress = shortenAddress(address);
    if (!fullName) return;
    // A fullname was passed - use it immediately.
    else if (typeof fullName === "string")
      setDisplayable(<PromiseDisplayer promise={Promise.resolve(fullName)} loading={shortAddress} />);
    // A resolution promise and a stash lookup was provided - resolve fullName.
    else
      setDisplayable(
        <PromiseDisplayer promise={fullName} renderer={(v) => v?.fullName || shortAddress} loading={shortAddress} />,
      );
  }, [address, fullName]);

  return (
    <div className="AddressDisplayer">
      <Link to={`${explorer}/address/${address}`} target="_blank">
        {displayable}
      </Link>
      {withCopyButton && <CopyPopover message={address} />}
    </div>
  );
};
