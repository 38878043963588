import { WidgetProps } from "@rjsf/utils";
import { Input } from "antd";
import { JSONSchema7 } from "json-schema";
import { useCallback } from "react";

export const CustomTextArea = <T, S extends JSONSchema7>(props: WidgetProps<T, S>) => {
  const { placeholder, onChange, disabled } = props;
  props.schema.maxLength;
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => onChange(event.target.value),
    [onChange],
  );

  if (typeof props.value !== "string") {
    throw new Error("CustomTextArea: value is not a string");
  }

  return (
    <Input.TextArea
      disabled={disabled}
      className="FastEdit-Textarea"
      showCount
      maxLength={props.schema.maxLength || 250}
      rows={props.uiSchema?.["ui:rows"] || 5}
      placeholder={placeholder}
      value={props.value}
      onChange={handleChange}
    />
  );
};
