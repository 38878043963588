import { Empty, Image } from "antd";
import { Link } from "react-router-dom";
import { FastMembershipCard } from "./About/Cards/FastMembershipCard";
import { DataBox } from "./About/DataBox";
import { DocumentDownloadButton } from "./About/DocumentDownloadButton";
import { ElaborateInfo } from "./About/ElaborateInfo";
import { TeamMember } from "./About/TeamMember";
import { useFastDetails } from "./Top";
import { FastVisibility } from "../../__generated__/graphql";
import { TipModal } from "../../components/modals/TipModal";
import { isFastDetails } from "../../graphql/types/JsonScalar";
import { useDocumentDetails } from "../../hooks/useDocumentDetails";
import { useFastContext } from "../../providers/FastContractContext/FastContractContext";

const fastVisibilityToString = (value: FastVisibility) => {
  switch (value) {
    case FastVisibility.Private:
      return "Private";
    case FastVisibility.Public:
      return "Public";
    case FastVisibility.Community:
      return "Community";
  }
};

export const About = () => {
  const { membership, details: fastContractDetails } = useFastContext();
  const { name, symbol } = fastContractDetails;
  const { fastDetails } = useFastDetails();

  const detailsFormData = fastDetails?.form?.data;
  const fastDetailsDataValid = isFastDetails(detailsFormData);
  const logoDocument = useDocumentDetails(fastDetailsDataValid ? detailsFormData.logoId : undefined);

  const Card = () => {
    if (!membership.isMember) return <FastMembershipCard symbol={symbol} />;
    return <span></span>;
  };

  return (
    <section className="FastAbout">
      <div className="FastAbout-Top">
        {/* Elaborate Info */}
        {membership.isGovernor && (
          <>
            <div className="FastAbout-ElaborateInfo">
              <i className="icon-info-circle" />
              <i>Educate and Incentivise your FAST network to leverage network effects.</i>
              <TipModal content={<ElaborateInfo />} title="Tips to improve" />
            </div>
            <hr className="FastAbout-Border-Solid" />
          </>
        )}

        {/* Fast About Header Informations */}
        <div className="FastAbout-Info">
          <div className="FastAbout-Index">
            <Image src={logoDocument?.downloadUrl} className="FastAbout-Logo" preview={false} />
            <div className="FastAbout-Info-Name">
              <h1>{name}</h1>
              <h2>{symbol}</h2>
              <p className="FastAbout-CommunityTag">{fastVisibilityToString(fastDetails.visibility)}</p>
            </div>
          </div>
        </div>
      </div>

      {fastDetailsDataValid ? (
        <>
          <div className="FastAbout-Middle">
            <div className="FastAbout-Left">
              <div className="FastAbout-DataBoxes">
                <DataBox
                  description="Minimum ticket size"
                  isLocked={false}
                  value={detailsFormData.minimumTicketSize}
                  unit={detailsFormData.currency}
                />
                <DataBox
                  description="Target Portfolio Size"
                  text={`${detailsFormData.targetPortfolioSize} ${
                    detailsFormData.targetPortfolioSize != 1 ? "Companies" : "Company"
                  }`}
                  isLocked={false}
                />
                <DataBox
                  description="Unrealised token value"
                  value={detailsFormData.unrealisedTokenValue}
                  unit={detailsFormData.currency}
                  isLocked={!membership.isMember}
                />
              </div>
              <div className="FastAbout-Tags">
                <div className="FastAbout-TagsWrapper">
                  <h4>SECTOR{detailsFormData.sectors.length != 1 && "S"}</h4>
                  <div>
                    {detailsFormData.sectors.map((sector, i) => {
                      return <p key={i}>{sector}</p>;
                    })}
                  </div>
                </div>
                <div className="FastAbout-TagsWrapper">
                  <h4>STAGE{detailsFormData.stage.length != 1 && "S"}</h4>
                  <div>
                    {detailsFormData.stage.map((singleStage, i) => {
                      return <p key={i}>{singleStage}</p>;
                    })}
                  </div>
                </div>
              </div>

              <div className="FastAbout-ColumDiection">
                <h4>ABOUT</h4>
                <p>{detailsFormData.investmentThesis}</p>
              </div>

              <div className="FastAbout-TagsWrapper">
                <h4>PORTFOLIO SUPPORT AREAS</h4>
                <div>
                  {detailsFormData.supportProvided.map((supporType, i) => {
                    return <p key={i}>{supporType}</p>;
                  })}
                </div>
              </div>
              <div className="FastAbout-DocumentWrapper">
                <>
                  {detailsFormData.documents.map((document, i) => (
                    <DocumentDownloadButton key={i} document={document} />
                  ))}
                  {detailsFormData.links.map((link, i) => (
                    <Link key={i} to={link.url} target="_blank">
                      <i className="icon-link" />
                      {link.title}
                    </Link>
                  ))}
                </>
              </div>
            </div>

            <Card />
          </div>

          {!!detailsFormData.teamMembers.length && (
            <div className="FastAbout-Divider">
              <hr />
            </div>
          )}

          {!!detailsFormData.teamMembers.length && (
            <div className="FastAbout-Middle">
              <div className="FastAbout-ColumDiection">
                <h4>A GLIMPSE OF THE team</h4>
                <div className="FastAbout-TeamMembers">
                  {detailsFormData.teamMembers.map((teamMember, i) => {
                    return (
                      <TeamMember
                        key={i}
                        imageId={teamMember.imageId}
                        linkedinProfileUrl={teamMember.linkedinProfileUrl}
                        fullName={teamMember.fullName}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="FastAbout-Empty">
          <Empty />
        </div>
      )}
    </section>
  );
};
