import { Button } from "antd";

interface Props {
  readonly decimals: number;
  readonly numberOfDecimals: number;
  readonly title: string;
  readonly changeNumberOfDecimals: () => void;
}

export const CollapsingColumnTitle = ({ decimals, numberOfDecimals, title, changeNumberOfDecimals }: Props) => (
  <div className="CollapsingColumnTitle">
    {title}&nbsp;
    <Button className={numberOfDecimals === decimals ? "Collapsed" : ""} onClick={changeNumberOfDecimals}>
      <i className="icon-arrow-left" />
      <i className="icon-arrow-right" />
    </Button>
  </div>
);
