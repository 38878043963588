import * as Common from "../../../JsonSchemas/common.schema.v1";
import { FastForm } from "../../../JsonSchemas/FastForm.schema.v1";

export const Stages = Object.values(Common.Stage);

// At minimum, our initial form data cannot be typed FormData because it would
// not fullfil some of the constraints.
export const initialFormData: FastForm = {
  id: "FastForm",
  investmentThesis: "",
  supportProvided: [],
  sectors: [],
  stage: [],
  currency: Common.Currency.USD,
  minimumTicketSize: 0,
  targetPortfolioSize: 0,
  unrealisedTokenValue: 0,
  visibility: Common.Visibility.Public,
  links: [],
  documents: [],
  teamMembers: [],
};
