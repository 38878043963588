import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache, createHttpLink, from, split } from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";
import { PropsWithChildren, useMemo, useState } from "react";
import { useChain } from "./ChainClient";
import { SettingsProvider } from "./SettingsProvider/SettingsProvider";
import { createGenericContext } from "./utils";
import { SessionSessionFragment } from "../__generated__/graphql";

const createApolloClient = (apiUrl: string, apiWsUrl: string, data?: SessionSessionFragment) => {
  // We want one cache per credential set.
  const cache = new InMemoryCache();
  // We want one HTTP link per credential set.
  const httpLink = createHttpLink({ uri: apiUrl });

  const wsLink = new GraphQLWsLink(createClient({ url: apiWsUrl }));

  // Authentication is also per credential set.
  const authLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: data ? { ...headers, authorization: `Bearer ${data.bearerToken}` } : headers,
    }));
    return forward(operation);
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === "OperationDefinition" && definition.operation === "subscription";
    },
    wsLink,
    httpLink,
  );

  return new ApolloClient({ cache, link: from([authLink, splitLink]) });
};

interface ContextState {
  readonly sessionData: SessionSessionFragment | undefined;
  readonly setSessionData: (data?: SessionSessionFragment) => void;
}

export const [useAppContext, Provider] = createGenericContext<ContextState>();

export const APIClient = ({ children }: PropsWithChildren) => {
  const { chain } = useChain();
  // Other components will pass us credentials.
  const [sessionData, setSessionData] = useState<SessionSessionFragment>();

  // Whenever the credentials change, create a new client.
  const apolloClient = useMemo(
    () => createApolloClient(chain.apiUrl, chain.apiWsUrl, sessionData),
    [chain.apiUrl, chain.apiWsUrl, sessionData],
  );

  return (
    <Provider value={{ sessionData, setSessionData }}>
      <ApolloProvider client={apolloClient}>
        <SettingsProvider>{children}</SettingsProvider>
      </ApolloProvider>
    </Provider>
  );
};
