import { Outlet, useParams } from "react-router-dom";
import { useAccount } from "wagmi";
import { ConnectWallet } from "../../components/ConnectWallet";
import { Login } from "../../components/Login";
import { Pages } from "../../pages/index";
import { useAppContext } from "../../providers/APIClient";
import { useTopContracts } from "../../providers/TopContractsProvider";

// Different permissions.
export const enum Persona {
  MarketplaceMember = "Marketplace Member",
  IssuerMember = "Issuer Member",
  AuthenticatedAndIdentified = "Authenticated & Identified",
  ConnectedWallet = "It needs to connect to the wallet",
}
// The `as` property specifies the angle required to view this page.
interface Props {
  readonly requires: Persona;
}

// This component is in charge of screening access to people who are not marketplace
// or issuer members.
export const Guard = ({ requires }: Props) => {
  const { isConnected } = useAccount();
  const { isMarketplaceMember, isIssuerMember } = useTopContracts();
  const { sessionData } = useAppContext();
  // Note: we don't want to use `useFastSymbol` here, because this component
  // can be used outside of the main `<FastSymbolProvider>` tree.
  const { symbol } = useParams();

  const authorized =
    // Marketplace membership required, user has it.
    (requires == Persona.MarketplaceMember && isMarketplaceMember) ||
    // Issuer membership required, user has it.
    (requires == Persona.IssuerMember && isIssuerMember) ||
    // Requires that the user is authenticated and has a valid identity.
    (requires == Persona.AuthenticatedAndIdentified && sessionData) ||
    // Requires that the wallet is connected.
    (requires === Persona.ConnectedWallet && isConnected);

  const reason =
    requires === Persona.AuthenticatedAndIdentified
      ? "Please login by clicking on the below button."
      : `Requires "${requires}" privileges.`;

  return authorized ? (
    <Outlet />
  ) : requires === Persona.ConnectedWallet ? (
    <ConnectWallet />
  ) : requires == Persona.AuthenticatedAndIdentified ? (
    <Login />
  ) : (
    <Pages.Errors.Forbidden symbol={symbol} reason={reason} />
  );
};

export const MarketplaceMember = () => <Guard requires={Persona.MarketplaceMember} />;
export const IssuerMember = () => <Guard requires={Persona.IssuerMember} />;
export const AuthenticatedAndIdentified = () => <Guard requires={Persona.AuthenticatedAndIdentified} />;
export const ConnectedWallet = () => <Guard requires={Persona.ConnectedWallet} />;
