import Modal from "antd/lib/modal/Modal";
import { SocialButtons } from "./SocialButtons";
import { CopyPopover } from "../../../components/uiElements/CopyPopover";
import { PropsWithModalState } from "../../../hooks/useModalState";

export const LocationShareModal = ({ state }: PropsWithModalState) => {
  const { isVisible, close } = state;
  const currentLocation = window.location.href;

  return (
    <Modal open={isVisible} centered onCancel={close} footer={null}>
      <div className="FastAbout-ShareModal">
        <h2>Share The Fast profile</h2>
        <p>Share this link on socials</p>
        <div>
          <SocialButtons shareUrl={currentLocation} />
        </div>
        <p>Or Copy Link</p>
        <div className="FastAbout-Copy">
          <i className="icon-link" />
          <span>{currentLocation}</span>
          <CopyPopover message={currentLocation} />
        </div>
      </div>
    </Modal>
  );
};
