import { Spin } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useCallback, useEffect, useMemo, useState } from "react";
import { OnfidoColumn } from "./OnfidoColumn";
import { StatusColumn } from "./StatusColumn";
import { MembershipRequest } from "./types";
import { OnboardIdentityCheckStatus, PageInfo, useMembershipRequestsQuery } from "../../../__generated__/graphql";
import { AppearanceTransition } from "../../../components/layout/AppearanceTransition";
import { AddressColumn } from "../../../components/tables/AddressColumn";
import { CursorPaginatedTable } from "../../../components/tables/CursorPaginatedTable";
import { UserColumn } from "../../../components/tables/UserColumn";
import { stringToShortIsoDate } from "../../../helpers/utils";
import { useChain } from "../../../providers/ChainClient";
import { isEthAddress } from "../../../types/ethers";

type MembershipRequests = ReadonlyArray<Readonly<MembershipRequest>>;

export const Applications = () => {
  const [applications, setApplications] = useState<MembershipRequests>([]);
  const [pageInfo, setPageInfo] = useState<PageInfo>();
  const [pageSize, setPageSize] = useState(10);
  const { chain } = useChain();
  const { error, loading, data, refetch } = useMembershipRequestsQuery({ variables: { first: pageSize } });

  const explorer = chain.blockExplorers.default.url;

  useEffect(() => {
    if (!data) return;
    const membershipRequests = data.membershipRequests?.edges;
    const fetchedPageInfo = data?.membershipRequests?.pageInfo;

    if (!membershipRequests || !fetchedPageInfo) throw new Error("Unexpected server response");

    // Transform the necessary data from the response.
    const convertedApplications = membershipRequests.reduce<ReadonlyArray<MembershipRequest>>(
      (acc, membershipRequest) => {
        const request = membershipRequest?.node;
        if (!request) return acc;

        const ethAddress =
          request.applicant.ethAddress && isEthAddress(request.applicant.ethAddress) && request.applicant.ethAddress;
        return [
          ...acc,
          {
            id: request.id,
            ethAddress: ethAddress || "-",
            fullName: request.applicant.fullName || "N/A",
            acceptedAt: request.acceptedAt,
            issuedAt: request.issuedAt,
            rejectedAt: request.rejectedAt,
            linkedIn: request.applicant?.linkedIn,
            fastSymbol: request.fast.symbol,
            onfidoStatus: request.applicant.identityCheckStatus || OnboardIdentityCheckStatus.Unchecked,
            status: request.state,
          },
        ];
      },
      [],
    );
    setApplications(convertedApplications);
    setPageInfo(fetchedPageInfo);
  }, [data]);

  const updateMembershipRequests = useCallback(() => refetch({ first: pageSize }), [pageSize, refetch]);

  const columns: ColumnsType<MembershipRequest> = useMemo(
    () => [
      {
        title: "Sent on",
        render: (_, { issuedAt }) => stringToShortIsoDate(issuedAt),
      },
      {
        title: "User",
        render: (_, { ethAddress, fullName, linkedIn }) => (
          <UserColumn ethAddress={ethAddress} explorer={explorer} fullName={fullName} linkedIn={linkedIn} />
        ),
      },
      {
        title: "Address",
        render: (_, { ethAddress }) => <AddressColumn ethAddress={ethAddress} />,
      },
      { title: "FAST", render: (_, { fastSymbol }) => fastSymbol },
      {
        title: "Onfido feedback",
        render: (_, { onfidoStatus }) => <OnfidoColumn status={onfidoStatus} />,
      },
      {
        title: "Application Status",
        render: (_, { acceptedAt, id, rejectedAt, status }) => (
          <StatusColumn
            accepted={acceptedAt}
            id={id}
            rejected={rejectedAt}
            status={status}
            updateMembershipRequests={updateMembershipRequests}
          />
        ),
      },
    ],
    [explorer, updateMembershipRequests],
  );

  return (
    <AppearanceTransition className="FastTab Applications">
      <Spin spinning={loading} size="large">
        {pageInfo && (
          <CursorPaginatedTable
            columns={columns}
            data={applications}
            error={error}
            pageInfo={pageInfo}
            pageSize={pageSize}
            refetch={refetch}
            setPageSize={setPageSize}
          />
        )}
      </Spin>
    </AppearanceTransition>
  );
};
