import { Image } from "antd";
import { Link } from "react-router-dom";
import { OnfidoStatusFeedback } from "./types";
import { OnboardIdentityCheckStatus } from "../../../__generated__/graphql";
import { CheckMarkIcon, Question } from "../../../assets";

interface Props {
  readonly link?: string;
  readonly status: OnboardIdentityCheckStatus;
}

export const OnfidoColumn = ({ link, status }: Props) => (
  <div className="OnfidoColumn">
    <Image src={status === OnboardIdentityCheckStatus.Approved ? CheckMarkIcon : Question} preview={false} />
    <span>{OnfidoStatusFeedback[status]}</span>
    {link && <Link to={link}>Onfido</Link>}
  </div>
);
