import { Web3AuthConnector } from "@web3auth/web3auth-wagmi-connector";
import { PropsWithChildren } from "react";
import { WagmiConfig, configureChains, createConfig } from "wagmi";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import { InnerProvider, useInnerChainProvider } from "./ChainClient/InnerProvider";
import { web3AuthOptionsBuilder } from "./ChainClient/web3AuthOptionsBuilder";
import { AlchemyClientId, SupportedChain } from "../helpers/chain";

export const useChain = useInnerChainProvider;

export const ChainClient = ({ children }: PropsWithChildren) => {
  const { chains, publicClient, webSocketPublicClient } = configureChains(
    [SupportedChain],
    [alchemyProvider({ apiKey: AlchemyClientId() }), publicProvider()],
  );

  const chainClient = createConfig({
    autoConnect: true,
    publicClient,
    webSocketPublicClient,
    connectors: [new Web3AuthConnector(web3AuthOptionsBuilder(SupportedChain)), new MetaMaskConnector({ chains })],
  });

  return (
    <WagmiConfig config={chainClient}>
      <InnerProvider>{children}</InnerProvider>
    </WagmiConfig>
  );
};
