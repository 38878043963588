import { Button } from "antd";
import { useState } from "react";
import { ProposalTally, ProposalVote } from "../../../providers/ProposalProvider/types";

interface Props {
  readonly tally: ReadonlyArray<ProposalTally>;
  readonly votes: ReadonlyArray<ProposalVote>;
}

export const VoteResultBlock = ({ tally, votes }: Props) => {
  const [voteChoice, setVoteChoise] = useState("All");
  const [votesDetailsList, setVotesDetailsList] = useState<ReadonlyArray<ProposalVote>>(votes);

  const showVotesDetails = (choice: string) => () => {
    if (!votes) return;

    const newList = votes.filter((vote) => vote.choice === choice);
    setVoteChoise(choice);
    newList.length === 0 ? setVotesDetailsList(votes) : setVotesDetailsList(newList);
  };

  return (
    <div className="Proposal-VoteResultBlock">

      {!!votes.length && <Button disabled={voteChoice === "All"} onClick={showVotesDetails("All")}>
        Show all votes
      </Button>}

      <div className="Results">
        <div className="Votes">
          {tally.map((ballotItem) =>
            ballotItem.count > 0 ? (
              <div
                key={ballotItem.choice}
                className={`Tally-Item ${voteChoice === ballotItem.choice ? "Tally-Item-Active" : ""}`}
                role="button"
                tabIndex={0}
                onClick={showVotesDetails(ballotItem.choice)}>
                <div>{`${ballotItem.count} votes`}</div>
                <div className={`Votes-Item ${ballotItem.choice}`}>{ballotItem.choice}</div>
              </div>
            ) : (
              <div key={ballotItem.choice} className="Tally-Item">
                <div>{`${ballotItem.count} votes`}</div>
                <div className={`Votes-Item ${ballotItem.choice}`}>{ballotItem.choice}</div>
              </div>
            ),
          )}
        </div>

        <div className="VotesDetails">
          {votesDetailsList.map((vote) => (
            <div key={vote.id} className="Vote-Comment">
              <div>
                <div className={`Vote-Avatar ${vote.choice}`}>
                  <span className="icon-user">
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                  </span>
                </div>
                <div className="Vote-Info">
                  <div className={vote.choice}>{vote.choice}</div>
                  <div>{new Date(vote.insertedAt).toLocaleString()}</div>
                </div>
              </div>
              <p>{vote.comment}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
