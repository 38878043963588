import { WriteContractResult, waitForTransaction } from "@wagmi/core";
import { useCallback } from "react";
import { Renderable, ValueOrFunction } from "react-hot-toast";
import { isEthErrWithCode, isEthErrWithData, isViemError, isWagmiError } from "../types/ethers";
import { SimpleToast } from "../types/toasts";

export const errorContent = (title: string, err?: unknown) => {
  err ||= "Unknown Error";
  let content;
  if (typeof err === "string" || typeof err === "number") content = err.toString();
  else if (isWagmiError(err)) content = `${err.cause.shortMessage} ${err.cause.data.errorName}`;
  else if (isViemError(err)) content = `${err.shortMessage} ${err.name}`;
  // Error is straight with data. Cool - we can use it.
  else if (isEthErrWithData(err)) content = err.error.data.message;
  // The error only has a code field...
  else if (isEthErrWithCode(err)) content = err?.code.toString();
  // We have no idea what happened.
  content ||= "Unknown Error";

  // A simple function to copy to the clipboard.
  const copyErrToClipboard = () => {
    console.error({ url: window.location.href, error: err });
    navigator.clipboard
      .writeText(
        // For the second parameter to JSON.stringify function, we pass a function which converts Bigint to a string,
        // because the JSON.stringify function can't serialize Bigint and occurs an error.
        JSON.stringify({ url: window.location.href, error: err }, (_, v: unknown) =>
          typeof v === "bigint" ? v.toString() : v,
        ),
      )
      .catch(console.warn);
  };
  // Toast an error.
  return (
    <span>
      <>
        {title}: <b>{content}</b>&nbsp;
        {err && (
          <a className="outlined-dark" onClick={copyErrToClipboard}>
            Copy
          </a>
        )}
      </>
    </span>
  );
};

const handleError = (title: string, err: unknown) => SimpleToast.error(errorContent(title, err));

export const useErrorHelpers = () => {
  const trackTransaction = useCallback(
    (
      tx: Promise<WriteContractResult>,
      title: string,
      onSuccess: ValueOrFunction<Renderable, unknown>,
      loader?: (isLoading: boolean) => void,
    ) => {
      // Toast it.
      const ret = SimpleToast.promise(
        tx.then(waitForTransaction),
        `Waiting for ${title} transaction...`,
        onSuccess,
        (err: unknown) => errorContent(title, err),
      ).catch((err) => console.error(`Transaction ${title} failed.`, err));
      // Indicate loading if a loader function is provided.
      // Note that loading will only occur while sending the transaction,
      // not while awaiting its mining completion.
      if (loader) {
        loader(true);
        ret.finally(() => loader(false));
      }
      // Return the toast promise.
      return ret;
    },
    [],
  );

  return { trackTransaction, handleError };
};
