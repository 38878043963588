import { useEffect, useMemo } from "react";
import { useEvent } from "./useEvent";

// Classic debounce function.
function debounce<T extends (...args: unknown[]) => unknown>(fn: T, ms: number) {
  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  function debounced(...args: Parameters<T>) {
    if (typeof timeoutId === "number") {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      timeoutId = null;
      fn(...args);
    }, ms);
  }

  debounced.cancel = () => {
    if (typeof timeoutId !== "number") return;
    clearTimeout(timeoutId);
  };

  return debounced;
}

// A simple callback debouncer.
export function useDebounce<Fn extends (...args: unknown[]) => unknown>(fn: Fn, delay: number) {
  const memoizedFn = useEvent(fn);

  const debounceFn = useMemo(() => {
    return debounce((...args: unknown[]) => memoizedFn(...args), delay);
  }, [delay, memoizedFn]);

  useEffect(() => () => debounceFn.cancel(), [debounceFn]);

  return debounceFn;
}
