import { Steps } from "antd";
import { useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { SwitchNetworkButton } from "./buttons/SwitchNetworkButton";
import { Connectors } from "./Connectors";
import { Loader } from "./uiElements/Loader";
import { Logo } from "./uiElements/Logo";
import { AuthenticationStepItems, AuthenticationSteps } from "./utils";
import { assertUnreachable } from "../helpers/utils";
import { Status, UnreadyReason, useUnsafeChain } from "../providers/ChainClient/InnerProvider";
import { Paths } from "../routing";

export const ConnectWallet = () => {
  const chainCtx = useUnsafeChain();
  const { pathname } = useLocation();

  const isNeedStepper = useMemo(() => !pathname.includes(Paths.onboarding), [pathname]);

  const CTAs = useCallback(() => {
    const { status } = chainCtx;
    if (status === Status.Unready) {
      const { reason } = chainCtx;
      switch (reason) {
        case UnreadyReason.Loading:
          return <Loader label="Please wait..." withLogo />;
        case UnreadyReason.AccountDisconnected:
          return (
            <>
              {isNeedStepper && (
                <Steps
                  className="Signin-Stepper"
                  current={AuthenticationSteps.WalletConnection}
                  items={AuthenticationStepItems}
                  progressDot
                  size="small"
                />
              )}
              <Connectors />;
            </>
          );
        case UnreadyReason.InvalidChain:
          return <SwitchNetworkButton />;
        case UnreadyReason.Unknown:
          return (
            <>
              <Connectors />
              <SwitchNetworkButton />
            </>
          );
        default:
          assertUnreachable(reason);
      }
    }
    return <></>;
  }, [chainCtx, isNeedStepper]);

  return (
    <div className="ConnectWalletPage">
      <Logo />
      <CTAs />
    </div>
  );
};
