import { getContract } from "@wagmi/core";
import { useEffect, useState } from "react";
import { zeroAddress } from "viem";
import { BigNumberFormatter } from "./useBigNumberFormatter";
import { fastABI } from "../__generated__/contracts";
import { useChain } from "../providers/ChainClient";
import { FastDetails } from "../providers/FastContractContext/types";
import { useTopContracts } from "../providers/TopContractsProvider";

export const enum Status {
  Loading,
  Error,
  Done,
}

interface Loading {
  readonly status: Status.Loading;
}
interface Error {
  readonly status: Status.Error;
  readonly reason: string;
}
interface Done {
  readonly status: Status.Done;
  readonly details: FastDetails;
}
type State = Loading | Error | Done;

export const useFast = (symbol: string) => {
  const { walletClient } = useChain();
  const { issuer } = useTopContracts();
  const [state, setState] = useState<State>({ status: Status.Loading });

  // Whenever the symbol changes, get the FAST address from the issuer registry.
  useEffect(() => {
    setState({ status: Status.Loading });

    issuer.read
      // Lookup...
      .fastBySymbol([symbol])
      // Success!
      .then((address) => {
        if (address === zeroAddress) throw "Not found";
        const contract = getContract({ abi: fastABI, address, walletClient });
        return Promise.all([contract, contract.read.details()]);
      })
      // Once loaded, populate our state.
      .then(([contract, rawData]) => {
        // Make a formatter.
        const formatter = new BigNumberFormatter(rawData.decimals);
        // Set our successful ready state!
        const { addr: address, ...useful } = rawData;
        setState({ status: Status.Done, details: { ...useful, address, contract, formatter } });
      })
      .catch((e) => {
        setState((s) => {
          if (s.status == Status.Error) return s;
          else {
            console.error(e);
            return { status: Status.Error, reason: "Unable to load FAST details" };
          }
        });
      });
  }, [symbol, issuer.read, walletClient]);

  // Event listening follows. Those are mainly examples and need to be massaged a bit.

  // TODO: Re-enable once the node doesn't blow up?...
  // useFastDetailsChangedEvent({
  //   address,
  //   listener: (log) => {
  //     const eventData = log.at(0)?.args;
  //     if (!eventData) return;
  //     console.log("FAST Details Changed:", eventData);
  //   },
  // });

  // useFastMemberAddedEvent({
  //   address,
  //   listener: (log) => {
  //     const eventData = log.at(0)?.args;
  //     if (!data || !eventData) return;
  //     console.info("Member added.", eventData.member);
  //   },
  // });

  // useFastMemberRemovedEvent({
  //   address,
  //   listener: (log) => {
  //     const eventData = log.at(0)?.args;
  //     if (!data || !eventData) return;
  //     console.info("Member removed.", eventData.member);
  //   },
  // });

  return state;
};
