import { writeContract } from "@wagmi/core";
import { Button, Form, Input } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useCallback, useMemo, useState } from "react";
import { distributionABI } from "../../../../__generated__/contracts";
import { RequiredFieldRule } from "../../../../helpers/utils";
import { useErrorHelpers } from "../../../../hooks/useErrorHelpers";
import { ERC20Info } from "../../../../providers/ERC20Fetcher";
import { EthAddress } from "../../../../types/ethers";
import { SimpleToast } from "../../../../types/toasts";

interface Props {
  readonly address: EthAddress;
  readonly available: bigint;
  readonly erc20Info: ERC20Info;
  readonly total: bigint;
  readonly setIsLoading: (isLoading: boolean) => void;
}

interface SetFeeForm {
  readonly fee: `${number}`;
}

export const FeeColumn = ({ address, available, erc20Info, total, setIsLoading }: Props) => {
  const { handleError } = useErrorHelpers();
  const [form] = useForm<SetFeeForm>();
  const [fee, setFee] = useState<bigint>();

  const finalFee = useMemo(() => erc20Info.formatter.format(fee || 0n, 4), [erc20Info.formatter, fee]);

  const finalFeeLabel = useMemo(
    () => (
      <>
        <p>
          Fee: {finalFee} {erc20Info.symbol}
        </p>
        <p>
          Remaining distribution: {erc20Info.formatter.format(total - (fee || 0n))} {erc20Info.symbol}
        </p>
      </>
    ),
    [erc20Info.formatter, erc20Info.symbol, fee, finalFee, total],
  );

  const calculateFee = useCallback(
    (value: `${number}`) => (erc20Info.formatter.parse(value) * available) / erc20Info.formatter.parse("100"),
    [available, erc20Info.formatter],
  );

  const onFeeChanged: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setFee(calculateFee(e.target.value as `${number}`));
    },
    [calculateFee],
  );

  const onSendDistribution = useCallback(() => {
    if (!fee) return;

    setIsLoading(true);
    SimpleToast.success("Distribution Fee Setup.");

    writeContract({
      abi: distributionABI,
      address,
      functionName: "advanceToBeneficiariesSetup",
      args: [fee],
    })
      .then(() => SimpleToast.success("Fee was set successfully."))
      .catch((err) => {
        setIsLoading(false);
        handleError("Distribution Fee Setup Error.", err);
      });
  }, [address, fee, handleError, setIsLoading]);

  return (
    <div className="FeeColumn">
      <Form form={form} layout="vertical" requiredMark onFinish={onSendDistribution}>
        <Form.Item
          name="fee"
          label="Fee"
          extra={finalFeeLabel}
          rules={[
            ...RequiredFieldRule,
            () => ({
              validator(_, value) {
                if (value <= 99 && value > 0) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("The amount must be less than 99% and more than 0%"));
              },
            }),
          ]}>
          <Input type="number" suffix="%" onChange={onFeeChanged} />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit">
            Set Fee <i className="icon-sign" />
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
