import { OpacityTransition } from "../../components/layout/OpacityTransition";
import { NetworkName } from "../../components/NetworkName";

interface Props {
  readonly symbol?: string;
  readonly reason?: string;
  readonly whiteLayout?: boolean;
}

export const Forbidden = ({ symbol, reason, whiteLayout }: Props) => (
  <OpacityTransition className={`PageNotFound ${whiteLayout ? "WhiteLayout" : ""}`}>
    <NetworkName symbol={symbol} />
    <h3>
      {reason || (
        <>
          Your current wallet address is not authorized to view this page, please choose the appropriate wallet if you
          think you should have access to the page.
        </>
      )}
    </h3>
  </OpacityTransition>
);
