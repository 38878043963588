import { Empty, Table } from "antd";
import { TableLocale } from "antd/lib/table/interface";

interface Props {
  readonly text: string;
}

export const EmptyTable = ({ text }: Props) => {
  const locale: TableLocale = {
    emptyText: (
      <div>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        {text}
      </div>
    ),
  };

  return <Table showHeader={false} locale={locale} />;
};
