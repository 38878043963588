import Form, { IChangeEvent } from "@rjsf/core";
import { RJSFValidationError } from "@rjsf/utils";
import { customizeValidator } from "@rjsf/validator-ajv8";
import { useCallback, useMemo, useState } from "react";
import { AmendOnboardInput, useAmendOnboardMutation } from "../../../../__generated__/graphql";
import { Schema, SchemaType } from "../../../../__generated__/OnboardForm.module.v1";
import { useErrorHelpers } from "../../../../hooks/useErrorHelpers";
import { OnboardForm } from "../../../../JsonSchemas/OnboardForm.schema.v1";
import uiSchema from "../../../../JsonSchemas/OnboardForm.uiSchema.v1.json";
import { SimpleToast } from "../../../../types/toasts";
import { StepProps, initialFormData } from "../types";

// Please use map correctly.
const transformErrors = (errors: ReadonlyArray<Readonly<RJSFValidationError>>) => {
  return errors.map((error) => {
    // We should make sure that this is for the `linkedin` field.
    if (error.property === ".linkedIn" && error.name === "pattern")
      return { ...error, message: "must be a linkedin profile URL such as https://www.linkedin.com/in/john" };
    return error;
  });
};

export const Details = ({ onboard, refreshOnboard }: StepProps) => {
  const [amendOnboardMutation] = useAmendOnboardMutation();
  const { handleError } = useErrorHelpers();
  const [isLoading, setIsLoading] = useState(false);
  const validator = useMemo(() => customizeValidator<OnboardForm, SchemaType>(), []);
  const { id: onboardId, claimToken } = onboard;

  const onSubmit = useCallback(
    (data: IChangeEvent<OnboardForm, SchemaType>) => {
      if (data.errors.length) return;
      setIsLoading(true);

      const input: AmendOnboardInput = {
        onboardId,
        claimToken,
        data: JSON.stringify(data.formData),
        // TODO: Amend passing schema version
        schemaVersion: "1",
      };

      void amendOnboardMutation({ variables: { input } })
        .then((res) => {
          if (!res.data?.amendOnboard) throw new Error("Invalid response from server.");
          else {
            SimpleToast.success("Your details saved successfully");
            refreshOnboard(res.data?.amendOnboard.onboard);
          }
        })
        .catch((err) => handleError("Sending Onboarding User Details", err))
        .finally(() => setIsLoading(false));
    },
    [amendOnboardMutation, handleError, onboardId, claimToken, refreshOnboard],
  );

  return (
    <Form<OnboardForm, SchemaType>
      className="Details Padding"
      disabled={isLoading}
      formData={initialFormData}
      noHtml5Validate
      schema={Schema}
      showErrorList={false}
      uiSchema={uiSchema}
      transformErrors={transformErrors}
      validator={validator}
      onSubmit={onSubmit}
    />
  );
};
