export const ElaborateInfo = () => (
  <>
    <p>
      As a coordinator, educating your members about your organisation, vision, your FAST and incentivising them to
      invite members would help you grow a quality sizeable network. That should translate into a higher value addition
      to your portfolio companies, bigger marketplace for your FAST leading to higher valuations and shorter times to
      liquidity.
    </p>
    <br />
    <p>Here a few general resources to help you educate you network:</p>
    <ul>
      <a
        href="https://a16z.com/2021/12/16/come-for-the-tool-stay-for-the-exchange-bootstrapping-liquidity-in-the-private-markets/"
        target="_blank"
        rel="noreferrer">
        <li>Bootstrapping Liquidity in the Private Markets</li>
      </a>
      <a href="https://www.nfx.com/post/token-investing/" target="_blank" rel="noreferrer">
        <li>The Future of Fundraising? What We Need to Fix About Token Investing</li>
      </a>
      <a
        href="https://kevinmonserrat.medium.com/uk-startups-can-pay-in-sweat-equity-and-earn-90k-in-r-d-tax-relief-30dceb63a7ff/"
        target="_blank"
        rel="noreferrer">
        <li>UK Startups Can Pay In “Sweat Equity” and benefit from R&amp;D Tax Relief</li>
      </a>
      <a
        href="https://kevinmonserrat.medium.com/vcs-as-portfolio-curators-democratizing-access-to-private-markets-d4b8561155ae/"
        target="_blank"
        rel="noreferrer">
        <li>VCs as Portfolio Curators: Democratizing Access to Private Markets</li>
      </a>
    </ul>
  </>
);
