import { PublicClient, WalletClient, getPublicClient } from "@wagmi/core";
import { PropsWithChildren, createContext, useContext, useEffect, useState } from "react";
import { useAccount, useNetwork, useWalletClient } from "wagmi";
import { ConnectWallet } from "../../components/ConnectWallet";
import { ExtendedChain, SupportedChain } from "../../helpers/chain";
import { EthAddress } from "../../types/ethers";
import { MetamaskErrorCode } from "../../types/MetamaskErrorCodes";
import { createGenericContext } from "../utils";

export type ERC20AssetWatchFun = (addr: EthAddress, symbol: string, decimals: number) => void;

export const enum Status {
  Unready,
  Ready,
}
export const enum UnreadyReason {
  Loading = "Loading",
  AccountDisconnected = "AccountDisconnected",
  InvalidChain = "InvalidChain",
  Unknown = "Unknown",
}

// Our unready state - eg the chain is not fully available.
interface UnreadyState {
  readonly status: Status.Unready;
  readonly reason: UnreadyReason;
  readonly chain?: ExtendedChain;
}
// Our ready state - everything seems OK.
interface ReadyState {
  readonly status: Status.Ready;
  readonly chain: ExtendedChain;
  readonly publicClient: PublicClient;
  readonly walletClient: WalletClient;
  readonly signerAddress: EthAddress;
  readonly watchERC20Asset: ERC20AssetWatchFun;
}
export type State = UnreadyState | ReadyState;
type SafeState = Omit<ReadyState, "status">;

// Initially, things are not ready, and they're not loading.
const initialState: UnreadyState = { status: Status.Unready, reason: UnreadyReason.Loading };

export const Context = createContext<State>(initialState);
Context.displayName = "ChainProvider.InnerProvider";

export const InnerProvider = ({ children }: PropsWithChildren) => {
  const network = useNetwork();
  const account = useAccount();
  const signer = useWalletClient();
  const publicClient = getPublicClient();
  const [state, setState] = useState<State>(initialState);
  const [safeState, setSafeState] = useState<SafeState>();

  // Check if we know which chain is supported...
  // We're memoizing the supported chain result, as it's an iterative operation.
  // If it has been computed in the past for any active chain ID, we don't have to
  // do it again.
  const netChainId = network.chain?.id;
  const chain = SupportedChain.id === netChainId ? SupportedChain : undefined;

  const { address: signerAddress, isConnecting, isReconnecting, isConnected } = account;
  const isBusy = isConnecting || isReconnecting;
  const walletClient = signer.data;
  useEffect(() => {
    // Need to connect to wallet.
    if (isBusy) return setState({ status: Status.Unready, reason: UnreadyReason.Loading, chain });
    else if (!isConnected)
      return setState({ status: Status.Unready, reason: UnreadyReason.AccountDisconnected, chain });
    // No valid chain found to connect to.
    else if (!chain) return setState({ status: Status.Unready, reason: UnreadyReason.InvalidChain });
    // Weird. Everything is fine, but some required data is still `undefined` or `null`...
    else if (!signerAddress || !walletClient)
      return setState({ status: Status.Unready, reason: UnreadyReason.Unknown, chain });

    const watchERC20Asset: ERC20AssetWatchFun = (address: EthAddress, symbol: string, decimals: number) => {
      const options = { address, decimals, symbol };
      walletClient
        .request({ method: "wallet_watchAsset", params: { type: "ERC20", options } })
        .catch((err?: Record<string, unknown>) => {
          switch (err?.code) {
            case MetamaskErrorCode.RejectedRequest:
              console.info("Watch operation cancelled by user.");
              return;
            default:
              console.warn("Watch operation failed.", err);
              return;
          }
        });
    };

    // Set our state to a ReadyState containing everything needed.
    const s: ReadyState = {
      status: Status.Ready,
      chain,
      publicClient,
      walletClient,
      signerAddress,
      watchERC20Asset,
    };
    setState(s);
    setSafeState(s);
  }, [
    isBusy, // 'Sometimes, the only changes are busyness.
    isConnected, // Whenever we loose connection to the account, refresh.
    chain, // If the chain we're connected to changes.
    publicClient, // Public client changed.
    walletClient, // Signer changed. Too bad, we have to monitor this, it's an account optional field.
    signerAddress, // Account address we're connected to.
  ]);

  return (
    <Context.Provider value={state}>
      {safeState ? <SafeProvider value={safeState}>{children}</SafeProvider> : <ConnectWallet />}
    </Context.Provider>
  );
};

export const useUnsafeChain = () => useContext(Context);

// -------- //

const [useInnerChainProvider, SafeProvider] = createGenericContext<SafeState>("ChainSafeContext");
export { useInnerChainProvider };
