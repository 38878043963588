// Generated by @wagmi/cli@1.2.0 on 5/15/2024 at 10:48:36 AM
import {
  useContractRead,
  UseContractReadConfig,
  useContractWrite,
  UseContractWriteConfig,
  useContractEvent,
  UseContractEventConfig,
} from 'wagmi';
import { ReadContractResult, WriteContractMode, PrepareWriteContractResult } from 'wagmi/actions';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Fast
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const fastABI = [
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresGovernorsManager',
  },
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresMembersManager',
  },
  { type: 'error', inputs: [{ name: 'who', internalType: 'address', type: 'address' }], name: 'RequiresValidGovernor' },
  { type: 'error', inputs: [{ name: 'who', internalType: 'address', type: 'address' }], name: 'RequiresValidMember' },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'governor', internalType: 'address', type: 'address', indexed: true }],
    name: 'GovernorAdded',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'governor', internalType: 'address', type: 'address', indexed: true }],
    name: 'GovernorRemoved',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'member', internalType: 'address', type: 'address', indexed: true }],
    name: 'MemberAdded',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'member', internalType: 'address', type: 'address', indexed: true }],
    name: 'MemberRemoved',
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'addGovernor',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'addMember',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'a', internalType: 'address', type: 'address' }],
    name: 'flags',
    outputs: [
      {
        name: '',
        internalType: 'struct FastAccessFacet.Flags',
        type: 'tuple',
        components: [
          { name: 'isGovernor', internalType: 'bool', type: 'bool' },
          { name: 'isMember', internalType: 'bool', type: 'bool' },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'governorCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'isGovernor',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'isMember',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'memberCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'index', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateGovernors',
    outputs: [
      { name: '', internalType: 'address[]', type: 'address[]' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'index', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateMembers',
    outputs: [
      { name: '', internalType: 'address[]', type: 'address[]' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'governor', internalType: 'address', type: 'address' }],
    name: 'removeGovernor',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'member', internalType: 'address', type: 'address' }],
    name: 'removeMember',
    outputs: [],
  },
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresAutomatonsManager',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'automaton', internalType: 'address', type: 'address', indexed: true },
      { name: 'privileges', internalType: 'uint32', type: 'uint32', indexed: true },
    ],
    name: 'AutomatonPrivilegesSet',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'automaton', internalType: 'address', type: 'address', indexed: true }],
    name: 'AutomatonRemoved',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'automaton', internalType: 'address', type: 'address' },
      { name: 'privilege', internalType: 'uint32', type: 'uint32' },
    ],
    name: 'automatonCan',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'automatonCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'automaton', internalType: 'address', type: 'address' }],
    name: 'automatonPrivileges',
    outputs: [{ name: '', internalType: 'uint32', type: 'uint32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'candidate', internalType: 'address', type: 'address' }],
    name: 'isAutomaton',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'cursor', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateAutomatons',
    outputs: [
      { name: '', internalType: 'address[]', type: 'address[]' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'candidate', internalType: 'address', type: 'address' }],
    name: 'removeAutomaton',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'candidate', internalType: 'address', type: 'address' },
      { name: 'privileges', internalType: 'uint32', type: 'uint32' },
    ],
    name: 'setAutomatonPrivileges',
    outputs: [],
  },
  {
    type: 'error',
    inputs: [{ name: 'fee', internalType: 'uint32', type: 'uint32' }],
    name: 'InvalidCrowdfundBasisPointsFee',
  },
  {
    type: 'error',
    inputs: [
      { name: 'who', internalType: 'address', type: 'address' },
      { name: 'privilege', internalType: 'uint32', type: 'uint32' },
    ],
    name: 'RequiresPrivilege',
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'token', internalType: 'contract IERC20', type: 'address' },
      { name: 'beneficiary', internalType: 'address', type: 'address' },
      { name: 'ref', internalType: 'string', type: 'string' },
    ],
    name: 'createCrowdfund',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'crowdfundCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'crowdfundsDefaultBasisPointFee',
    outputs: [{ name: '', internalType: 'uint32', type: 'uint32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'index', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateCrowdfunds',
    outputs: [
      { name: '', internalType: 'address[]', type: 'address[]' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'crowdfund', internalType: 'contract Crowdfund', type: 'address' }],
    name: 'removeCrowdfund',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'newBasisPointFee', internalType: 'uint32', type: 'uint32' }],
    name: 'setCrowdfundsDefaultBasisPointFee',
    outputs: [],
  },
  { type: 'error', inputs: [{ name: 'amount', internalType: 'uint256', type: 'uint256' }], name: 'InsufficientFunds' },
  { type: 'error', inputs: [], name: 'TokenContractError' },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'token', internalType: 'contract IERC20', type: 'address' },
      { name: 'total', internalType: 'uint256', type: 'uint256' },
      { name: 'blockLatch', internalType: 'uint256', type: 'uint256' },
      { name: 'ref', internalType: 'string', type: 'string' },
    ],
    name: 'createDistribution',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'distributionCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'index', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateDistributions',
    outputs: [
      { name: '', internalType: 'address[]', type: 'address[]' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'distribution', internalType: 'contract Distribution', type: 'address' }],
    name: 'removeDistribution',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'addr', internalType: 'address', type: 'address' }],
    name: 'detailedPrivileges',
    outputs: [
      {
        name: '',
        internalType: 'struct FastFrontendFacet.PrivilegesDetails',
        type: 'tuple',
        components: [
          { name: 'addr', internalType: 'address', type: 'address' },
          { name: 'balance', internalType: 'uint256', type: 'uint256' },
          { name: 'ethBalance', internalType: 'uint256', type: 'uint256' },
          { name: 'isMember', internalType: 'bool', type: 'bool' },
          { name: 'isGovernor', internalType: 'bool', type: 'bool' },
          { name: 'automatonPrivileges', internalType: 'uint32', type: 'uint32' },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'details',
    outputs: [
      {
        name: '',
        internalType: 'struct FastFrontendFacet.Details',
        type: 'tuple',
        components: [
          { name: 'addr', internalType: 'address', type: 'address' },
          { name: 'name', internalType: 'string', type: 'string' },
          { name: 'symbol', internalType: 'string', type: 'string' },
          { name: 'decimals', internalType: 'uint8', type: 'uint8' },
          { name: 'totalSupply', internalType: 'uint256', type: 'uint256' },
          { name: 'isSemiPublic', internalType: 'bool', type: 'bool' },
          { name: 'hasFixedSupply', internalType: 'bool', type: 'bool' },
          { name: 'transfersDisabled', internalType: 'bool', type: 'bool' },
          { name: 'crowdfundsDefaultBasisPointsFee', internalType: 'uint32', type: 'uint32' },
          { name: 'reserveBalance', internalType: 'uint256', type: 'uint256' },
          { name: 'memberCount', internalType: 'uint256', type: 'uint256' },
          { name: 'governorCount', internalType: 'uint256', type: 'uint256' },
        ],
      },
    ],
  },
  { stateMutability: 'nonpayable', type: 'function', inputs: [], name: 'emitDetailsChanged', outputs: [] },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'index', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateDetailedCrowdfunds',
    outputs: [
      {
        name: '',
        internalType: 'struct Crowdfund.Details[]',
        type: 'tuple[]',
        components: [
          { name: 'addr', internalType: 'address', type: 'address' },
          { name: 'VERSION', internalType: 'uint16', type: 'uint16' },
          {
            name: 'params',
            internalType: 'struct Crowdfund.Params',
            type: 'tuple',
            components: [
              { name: 'owner', internalType: 'address', type: 'address' },
              { name: 'issuer', internalType: 'address', type: 'address' },
              { name: 'fast', internalType: 'address', type: 'address' },
              { name: 'beneficiary', internalType: 'address', type: 'address' },
              { name: 'basisPointsFee', internalType: 'uint32', type: 'uint32' },
              { name: 'token', internalType: 'contract IERC20', type: 'address' },
              { name: 'ref', internalType: 'string', type: 'string' },
            ],
          },
          { name: 'phase', internalType: 'enum Crowdfund.Phase', type: 'uint8' },
          { name: 'creationBlock', internalType: 'uint256', type: 'uint256' },
          { name: 'collected', internalType: 'uint256', type: 'uint256' },
          { name: 'feeAmount', internalType: 'uint256', type: 'uint256' },
          { name: 'pledgerCount', internalType: 'uint256', type: 'uint256' },
        ],
      },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'index', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateDetailedDistributions',
    outputs: [
      {
        name: '',
        internalType: 'struct Distribution.Details[]',
        type: 'tuple[]',
        components: [
          { name: 'addr', internalType: 'address', type: 'address' },
          { name: 'VERSION', internalType: 'uint16', type: 'uint16' },
          {
            name: 'params',
            internalType: 'struct Distribution.Params',
            type: 'tuple',
            components: [
              { name: 'distributor', internalType: 'address', type: 'address' },
              { name: 'issuer', internalType: 'address', type: 'address' },
              { name: 'fast', internalType: 'address', type: 'address' },
              { name: 'token', internalType: 'contract IERC20', type: 'address' },
              { name: 'blockLatch', internalType: 'uint256', type: 'uint256' },
              { name: 'total', internalType: 'uint256', type: 'uint256' },
              { name: 'ref', internalType: 'string', type: 'string' },
            ],
          },
          { name: 'phase', internalType: 'enum Distribution.Phase', type: 'uint8' },
          { name: 'creationBlock', internalType: 'uint256', type: 'uint256' },
          { name: 'fee', internalType: 'uint256', type: 'uint256' },
          { name: 'available', internalType: 'uint256', type: 'uint256' },
          { name: 'beneficiaryCount', internalType: 'uint256', type: 'uint256' },
        ],
      },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'index', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateDetailedGovernors',
    outputs: [
      {
        name: '',
        internalType: 'struct FastFrontendFacet.PrivilegesDetails[]',
        type: 'tuple[]',
        components: [
          { name: 'addr', internalType: 'address', type: 'address' },
          { name: 'balance', internalType: 'uint256', type: 'uint256' },
          { name: 'ethBalance', internalType: 'uint256', type: 'uint256' },
          { name: 'isMember', internalType: 'bool', type: 'bool' },
          { name: 'isGovernor', internalType: 'bool', type: 'bool' },
          { name: 'automatonPrivileges', internalType: 'uint32', type: 'uint32' },
        ],
      },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'index', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateDetailedMembers',
    outputs: [
      {
        name: '',
        internalType: 'struct FastFrontendFacet.PrivilegesDetails[]',
        type: 'tuple[]',
        components: [
          { name: 'addr', internalType: 'address', type: 'address' },
          { name: 'balance', internalType: 'uint256', type: 'uint256' },
          { name: 'ethBalance', internalType: 'uint256', type: 'uint256' },
          { name: 'isMember', internalType: 'bool', type: 'bool' },
          { name: 'isGovernor', internalType: 'bool', type: 'bool' },
          { name: 'automatonPrivileges', internalType: 'uint32', type: 'uint32' },
        ],
      },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
      { name: 'ref', internalType: 'string', type: 'string' },
    ],
    name: 'burnt',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
      { name: 'ref', internalType: 'string', type: 'string' },
    ],
    name: 'minted',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'cursor', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateSupplyProofs',
    outputs: [
      {
        name: '',
        internalType: 'struct LibFastHistory.SupplyProof[]',
        type: 'tuple[]',
        components: [
          { name: 'op', internalType: 'enum LibFastHistory.SupplyOp', type: 'uint8' },
          { name: 'amount', internalType: 'uint256', type: 'uint256' },
          { name: 'blockNumber', internalType: 'uint256', type: 'uint256' },
          { name: 'ref', internalType: 'string', type: 'string' },
        ],
      },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'involvee', internalType: 'address', type: 'address' },
      { name: 'cursor', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateTransferProofIndicesByInvolvee',
    outputs: [
      { name: '', internalType: 'uint256[]', type: 'uint256[]' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'cursor', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateTransferProofs',
    outputs: [
      {
        name: '',
        internalType: 'struct LibFastHistory.TransferProof[]',
        type: 'tuple[]',
        components: [
          { name: 'spender', internalType: 'address', type: 'address' },
          { name: 'from', internalType: 'address', type: 'address' },
          { name: 'to', internalType: 'address', type: 'address' },
          { name: 'amount', internalType: 'uint256', type: 'uint256' },
          { name: 'blockNumber', internalType: 'uint256', type: 'uint256' },
          { name: 'ref', internalType: 'string', type: 'string' },
        ],
      },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'involvee', internalType: 'address', type: 'address' },
      { name: 'cursor', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateTransferProofsByInvolvee',
    outputs: [
      {
        name: '',
        internalType: 'struct LibFastHistory.TransferProof[]',
        type: 'tuple[]',
        components: [
          { name: 'spender', internalType: 'address', type: 'address' },
          { name: 'from', internalType: 'address', type: 'address' },
          { name: 'to', internalType: 'address', type: 'address' },
          { name: 'amount', internalType: 'uint256', type: 'uint256' },
          { name: 'blockNumber', internalType: 'uint256', type: 'uint256' },
          { name: 'ref', internalType: 'string', type: 'string' },
        ],
      },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'supplyProofCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'involvee', internalType: 'address', type: 'address' }],
    name: 'transferProofByInvolveeCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'transferProofCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
      { name: 'ref', internalType: 'string', type: 'string' },
    ],
    name: 'transfered',
    outputs: [],
  },
  { type: 'error', inputs: [], name: 'RequiresContinuousSupply' },
  {
    type: 'error',
    inputs: [{ name: 'a', internalType: 'address', type: 'address' }],
    name: 'RequiresDifferentSenderAndRecipient',
  },
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresMarketplaceActiveMembership',
  },
  {
    type: 'error',
    inputs: [{ name: 'holder', internalType: 'address', type: 'address' }],
    name: 'RequiresPositiveBalance',
  },
  { type: 'error', inputs: [], name: 'RequiresTransfersEnabled' },
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresValidTokenHolder',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'owner', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'member', internalType: 'address', type: 'address' }],
    name: 'beforeRemovingMember',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
      { name: 'ref', internalType: 'string', type: 'string' },
    ],
    name: 'burn',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'decimals',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'disapprove',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'owner', internalType: 'address', type: 'address' }],
    name: 'givenAllowanceCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'holders',
    outputs: [{ name: '', internalType: 'address[]', type: 'address[]' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
      { name: 'ref', internalType: 'string', type: 'string' },
    ],
    name: 'mint',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'index', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateAllowancesByOwner',
    outputs: [
      { name: '', internalType: 'address[]', type: 'address[]' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'index', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateAllowancesBySpender',
    outputs: [
      { name: '', internalType: 'address[]', type: 'address[]' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'performApproval',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'performDisapproval',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      {
        name: 'p',
        internalType: 'struct FastTokenFacet.TransferArgs',
        type: 'tuple',
        components: [
          { name: 'spender', internalType: 'address', type: 'address' },
          { name: 'from', internalType: 'address', type: 'address' },
          { name: 'to', internalType: 'address', type: 'address' },
          { name: 'amount', internalType: 'uint256', type: 'uint256' },
          { name: 'ref', internalType: 'string', type: 'string' },
        ],
      },
    ],
    name: 'performTransfer',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'spender', internalType: 'address', type: 'address' }],
    name: 'receivedAllowanceCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'holder', internalType: 'address', type: 'address' }],
    name: 'retrieveDeadTokens',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'symbol',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
      { name: 'ref', internalType: 'string', type: 'string' },
    ],
    name: 'transferFromWithRef',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
      { name: 'ref', internalType: 'string', type: 'string' },
    ],
    name: 'transferWithRef',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'group',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'hasFixedSupply',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'isSemiPublic',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'issuerAddress',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'marketplaceAddress',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'newGroup', internalType: 'string', type: 'string' }],
    name: 'setGroup',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'flag', internalType: 'bool', type: 'bool' }],
    name: 'setIsSemiPublic',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'flag', internalType: 'bool', type: 'bool' }],
    name: 'setTransfersDisabled',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'transfersDisabled',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: 'ref', internalType: 'string', type: 'string', indexed: false },
      { name: 'who', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'Burnt',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'basisPointFee', internalType: 'uint32', type: 'uint32', indexed: true }],
    name: 'CrowdfundDefaultBasisPointFeeSet',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'crowdfund', internalType: 'contract Crowdfund', type: 'address', indexed: true }],
    name: 'CrowdfundDeployed',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'crowdfund', internalType: 'contract Crowdfund', type: 'address', indexed: true }],
    name: 'CrowdfundRemoved',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'transfersDisabled', internalType: 'bool', type: 'bool', indexed: false },
      { name: 'crowdfundsDefaultBasisPointsFee', internalType: 'uint32', type: 'uint32', indexed: false },
      { name: 'memberCount', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'governorCount', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'totalSupply', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'reserveBalance', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'DetailsChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Disapproval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'distribution', internalType: 'contract Distribution', type: 'address', indexed: true }],
    name: 'DistributionDeployed',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'distribution', internalType: 'contract Distribution', type: 'address', indexed: true }],
    name: 'DistributionRemoved',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'ref', internalType: 'string', type: 'string', indexed: false },
    ],
    name: 'FastTransfer',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: 'ref', internalType: 'string', type: 'string', indexed: false },
      { name: 'who', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'Minted',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Transfer',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: '_diamondCut',
        internalType: 'struct IDiamondCut.FacetCut[]',
        type: 'tuple[]',
        components: [
          { name: 'facetAddress', internalType: 'address', type: 'address' },
          { name: 'action', internalType: 'enum IDiamondCut.FacetCutAction', type: 'uint8' },
          { name: 'functionSelectors', internalType: 'bytes4[]', type: 'bytes4[]' },
        ],
        indexed: false,
      },
      { name: '_init', internalType: 'address', type: 'address', indexed: false },
      { name: '_calldata', internalType: 'bytes', type: 'bytes', indexed: false },
    ],
    name: 'DiamondCut',
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      {
        name: '_diamondCut',
        internalType: 'struct IDiamondCut.FacetCut[]',
        type: 'tuple[]',
        components: [
          { name: 'facetAddress', internalType: 'address', type: 'address' },
          { name: 'action', internalType: 'enum IDiamondCut.FacetCutAction', type: 'uint8' },
          { name: 'functionSelectors', internalType: 'bytes4[]', type: 'bytes4[]' },
        ],
      },
      { name: '_init', internalType: 'address', type: 'address' },
      { name: '_calldata', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'diamondCut',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_functionSelector', internalType: 'bytes4', type: 'bytes4' }],
    name: 'facetAddress',
    outputs: [{ name: 'facetAddress_', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'facetAddresses',
    outputs: [{ name: 'facetAddresses_', internalType: 'address[]', type: 'address[]' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_facet', internalType: 'address', type: 'address' }],
    name: 'facetFunctionSelectors',
    outputs: [{ name: 'facetFunctionSelectors_', internalType: 'bytes4[]', type: 'bytes4[]' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'facets',
    outputs: [
      {
        name: 'facets_',
        internalType: 'struct IDiamondLoupe.Facet[]',
        type: 'tuple[]',
        components: [
          { name: 'facetAddress', internalType: 'address', type: 'address' },
          { name: 'functionSelectors', internalType: 'bytes4[]', type: 'bytes4[]' },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'interfaceId', internalType: 'bytes4', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'previousOwner', internalType: 'address', type: 'address', indexed: true },
      { name: 'newOwner', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'OwnershipTransferred',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'owner',
    outputs: [{ name: 'owner_', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_newOwner', internalType: 'address', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Issuer
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const issuerABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: '_diamondCut',
        internalType: 'struct IDiamondCut.FacetCut[]',
        type: 'tuple[]',
        components: [
          { name: 'facetAddress', internalType: 'address', type: 'address' },
          { name: 'action', internalType: 'enum IDiamondCut.FacetCutAction', type: 'uint8' },
          { name: 'functionSelectors', internalType: 'bytes4[]', type: 'bytes4[]' },
        ],
        indexed: false,
      },
      { name: '_init', internalType: 'address', type: 'address', indexed: false },
      { name: '_calldata', internalType: 'bytes', type: 'bytes', indexed: false },
    ],
    name: 'DiamondCut',
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      {
        name: '_diamondCut',
        internalType: 'struct IDiamondCut.FacetCut[]',
        type: 'tuple[]',
        components: [
          { name: 'facetAddress', internalType: 'address', type: 'address' },
          { name: 'action', internalType: 'enum IDiamondCut.FacetCutAction', type: 'uint8' },
          { name: 'functionSelectors', internalType: 'bytes4[]', type: 'bytes4[]' },
        ],
      },
      { name: '_init', internalType: 'address', type: 'address' },
      { name: '_calldata', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'diamondCut',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_functionSelector', internalType: 'bytes4', type: 'bytes4' }],
    name: 'facetAddress',
    outputs: [{ name: 'facetAddress_', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'facetAddresses',
    outputs: [{ name: 'facetAddresses_', internalType: 'address[]', type: 'address[]' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_facet', internalType: 'address', type: 'address' }],
    name: 'facetFunctionSelectors',
    outputs: [{ name: 'facetFunctionSelectors_', internalType: 'bytes4[]', type: 'bytes4[]' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'facets',
    outputs: [
      {
        name: 'facets_',
        internalType: 'struct IDiamondLoupe.Facet[]',
        type: 'tuple[]',
        components: [
          { name: 'facetAddress', internalType: 'address', type: 'address' },
          { name: 'functionSelectors', internalType: 'bytes4[]', type: 'bytes4[]' },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'interfaceId', internalType: 'bytes4', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'previousOwner', internalType: 'address', type: 'address', indexed: true },
      { name: 'newOwner', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'OwnershipTransferred',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'owner',
    outputs: [{ name: 'owner_', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_newOwner', internalType: 'address', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
  },
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresMembersManager',
  },
  { type: 'error', inputs: [{ name: 'who', internalType: 'address', type: 'address' }], name: 'RequiresValidMember' },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'member', internalType: 'address', type: 'address', indexed: true }],
    name: 'MemberAdded',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'member', internalType: 'address', type: 'address', indexed: true }],
    name: 'MemberRemoved',
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'addMember',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'governor', internalType: 'address', type: 'address' }],
    name: 'governorAddedToFast',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'governor', internalType: 'address', type: 'address' }],
    name: 'governorRemovedFromFast',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'isMember',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'memberCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'governor', internalType: 'address', type: 'address' },
      { name: 'cursor', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateGovernorships',
    outputs: [
      { name: '', internalType: 'address[]', type: 'address[]' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'index', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateMembers',
    outputs: [
      { name: '', internalType: 'address[]', type: 'address[]' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'member', internalType: 'address', type: 'address' }],
    name: 'removeMember',
    outputs: [],
  },
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresAutomatonsManager',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'automaton', internalType: 'address', type: 'address', indexed: true },
      { name: 'privileges', internalType: 'uint32', type: 'uint32', indexed: true },
    ],
    name: 'AutomatonPrivilegesSet',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'automaton', internalType: 'address', type: 'address', indexed: true }],
    name: 'AutomatonRemoved',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'automaton', internalType: 'address', type: 'address' },
      { name: 'privilege', internalType: 'uint32', type: 'uint32' },
    ],
    name: 'automatonCan',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'automatonCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'automaton', internalType: 'address', type: 'address' }],
    name: 'automatonPrivileges',
    outputs: [{ name: '', internalType: 'uint32', type: 'uint32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'candidate', internalType: 'address', type: 'address' }],
    name: 'isAutomaton',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'cursor', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateAutomatons',
    outputs: [
      { name: '', internalType: 'address[]', type: 'address[]' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'candidate', internalType: 'address', type: 'address' }],
    name: 'removeAutomaton',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'candidate', internalType: 'address', type: 'address' },
      { name: 'privileges', internalType: 'uint32', type: 'uint32' },
    ],
    name: 'setAutomatonPrivileges',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'cursor', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateDetailedFasts',
    outputs: [
      {
        name: '',
        internalType: 'struct FastFrontendFacet.Details[]',
        type: 'tuple[]',
        components: [
          { name: 'addr', internalType: 'address', type: 'address' },
          { name: 'name', internalType: 'string', type: 'string' },
          { name: 'symbol', internalType: 'string', type: 'string' },
          { name: 'decimals', internalType: 'uint8', type: 'uint8' },
          { name: 'totalSupply', internalType: 'uint256', type: 'uint256' },
          { name: 'isSemiPublic', internalType: 'bool', type: 'bool' },
          { name: 'hasFixedSupply', internalType: 'bool', type: 'bool' },
          { name: 'transfersDisabled', internalType: 'bool', type: 'bool' },
          { name: 'crowdfundsDefaultBasisPointsFee', internalType: 'uint32', type: 'uint32' },
          { name: 'reserveBalance', internalType: 'uint256', type: 'uint256' },
          { name: 'memberCount', internalType: 'uint256', type: 'uint256' },
          { name: 'governorCount', internalType: 'uint256', type: 'uint256' },
        ],
      },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'group', internalType: 'string', type: 'string' },
      { name: 'cursor', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateDetailedFastsInGroup',
    outputs: [
      {
        name: '',
        internalType: 'struct FastFrontendFacet.Details[]',
        type: 'tuple[]',
        components: [
          { name: 'addr', internalType: 'address', type: 'address' },
          { name: 'name', internalType: 'string', type: 'string' },
          { name: 'symbol', internalType: 'string', type: 'string' },
          { name: 'decimals', internalType: 'uint8', type: 'uint8' },
          { name: 'totalSupply', internalType: 'uint256', type: 'uint256' },
          { name: 'isSemiPublic', internalType: 'bool', type: 'bool' },
          { name: 'hasFixedSupply', internalType: 'bool', type: 'bool' },
          { name: 'transfersDisabled', internalType: 'bool', type: 'bool' },
          { name: 'crowdfundsDefaultBasisPointsFee', internalType: 'uint32', type: 'uint32' },
          { name: 'reserveBalance', internalType: 'uint256', type: 'uint256' },
          { name: 'memberCount', internalType: 'uint256', type: 'uint256' },
          { name: 'governorCount', internalType: 'uint256', type: 'uint256' },
        ],
      },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  { type: 'error', inputs: [], name: 'DuplicateEntry' },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'symbol', internalType: 'string', type: 'string' }],
    name: 'fastBySymbol',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'fastCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'fast', internalType: 'address', type: 'address' }],
    name: 'isFastRegistered',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'cursor', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateFasts',
    outputs: [
      { name: '', internalType: 'address[]', type: 'address[]' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'group', internalType: 'string', type: 'string' },
      { name: 'cursor', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateFastsInGroup',
    outputs: [
      { name: '', internalType: 'address[]', type: 'address[]' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'fast', internalType: 'address', type: 'address' }],
    name: 'registerFast',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'fast', internalType: 'address', type: 'address' },
      { name: 'newGroup', internalType: 'string', type: 'string' },
    ],
    name: 'setFastGroup',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'token', internalType: 'contract IERC20', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
      { name: 'to', internalType: 'address', type: 'address' },
    ],
    name: 'transferERC20Tokens',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'fast', internalType: 'address', type: 'address' }],
    name: 'unregisterFast',
    outputs: [],
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'fast', internalType: 'address', type: 'address', indexed: true },
      { name: 'oldGroup', internalType: 'string', type: 'string', indexed: true },
      { name: 'newGroup', internalType: 'string', type: 'string', indexed: true },
    ],
    name: 'FastGroupChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'fast', internalType: 'address', type: 'address', indexed: true }],
    name: 'FastRegistered',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'fast', internalType: 'address', type: 'address', indexed: true }],
    name: 'FastUnregistered',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'fast', internalType: 'address', type: 'address', indexed: true },
      { name: 'governor', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'GovernorshipAdded',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'fast', internalType: 'address', type: 'address', indexed: true },
      { name: 'governor', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'GovernorshipRemoved',
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Marketplace
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const marketplaceABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: '_diamondCut',
        internalType: 'struct IDiamondCut.FacetCut[]',
        type: 'tuple[]',
        components: [
          { name: 'facetAddress', internalType: 'address', type: 'address' },
          { name: 'action', internalType: 'enum IDiamondCut.FacetCutAction', type: 'uint8' },
          { name: 'functionSelectors', internalType: 'bytes4[]', type: 'bytes4[]' },
        ],
        indexed: false,
      },
      { name: '_init', internalType: 'address', type: 'address', indexed: false },
      { name: '_calldata', internalType: 'bytes', type: 'bytes', indexed: false },
    ],
    name: 'DiamondCut',
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      {
        name: '_diamondCut',
        internalType: 'struct IDiamondCut.FacetCut[]',
        type: 'tuple[]',
        components: [
          { name: 'facetAddress', internalType: 'address', type: 'address' },
          { name: 'action', internalType: 'enum IDiamondCut.FacetCutAction', type: 'uint8' },
          { name: 'functionSelectors', internalType: 'bytes4[]', type: 'bytes4[]' },
        ],
      },
      { name: '_init', internalType: 'address', type: 'address' },
      { name: '_calldata', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'diamondCut',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_functionSelector', internalType: 'bytes4', type: 'bytes4' }],
    name: 'facetAddress',
    outputs: [{ name: 'facetAddress_', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'facetAddresses',
    outputs: [{ name: 'facetAddresses_', internalType: 'address[]', type: 'address[]' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_facet', internalType: 'address', type: 'address' }],
    name: 'facetFunctionSelectors',
    outputs: [{ name: 'facetFunctionSelectors_', internalType: 'bytes4[]', type: 'bytes4[]' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'facets',
    outputs: [
      {
        name: 'facets_',
        internalType: 'struct IDiamondLoupe.Facet[]',
        type: 'tuple[]',
        components: [
          { name: 'facetAddress', internalType: 'address', type: 'address' },
          { name: 'functionSelectors', internalType: 'bytes4[]', type: 'bytes4[]' },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'interfaceId', internalType: 'bytes4', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'previousOwner', internalType: 'address', type: 'address', indexed: true },
      { name: 'newOwner', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'OwnershipTransferred',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'owner',
    outputs: [{ name: 'owner_', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_newOwner', internalType: 'address', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
  },
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresMarketplaceActiveMembership',
  },
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresMarketplaceDeactivatedMember',
  },
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresMarketplaceMembership',
  },
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresMembersManager',
  },
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresNoFastMemberships',
  },
  { type: 'error', inputs: [{ name: 'who', internalType: 'address', type: 'address' }], name: 'RequiresValidMember' },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'member', internalType: 'address', type: 'address', indexed: true }],
    name: 'MemberAdded',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'member', internalType: 'address', type: 'address', indexed: true }],
    name: 'MemberRemoved',
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'member', internalType: 'address', type: 'address' }],
    name: 'activateMember',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'addMember',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'member', internalType: 'address', type: 'address' }],
    name: 'deactivateMember',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'member', internalType: 'address', type: 'address' },
      { name: 'cursor', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'fastMemberships',
    outputs: [
      { name: '', internalType: 'address[]', type: 'address[]' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'candidate', internalType: 'address', type: 'address' }],
    name: 'isActiveMember',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'isMember',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'member', internalType: 'address', type: 'address' }],
    name: 'memberAddedToFast',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'memberCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'member', internalType: 'address', type: 'address' }],
    name: 'memberRemovedFromFast',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'index', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateMembers',
    outputs: [
      { name: '', internalType: 'address[]', type: 'address[]' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'member', internalType: 'address', type: 'address' }],
    name: 'removeMember',
    outputs: [],
  },
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresAutomatonsManager',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'automaton', internalType: 'address', type: 'address', indexed: true },
      { name: 'privileges', internalType: 'uint32', type: 'uint32', indexed: true },
    ],
    name: 'AutomatonPrivilegesSet',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'automaton', internalType: 'address', type: 'address', indexed: true }],
    name: 'AutomatonRemoved',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'automaton', internalType: 'address', type: 'address' },
      { name: 'privilege', internalType: 'uint32', type: 'uint32' },
    ],
    name: 'automatonCan',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'automatonCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'automaton', internalType: 'address', type: 'address' }],
    name: 'automatonPrivileges',
    outputs: [{ name: '', internalType: 'uint32', type: 'uint32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'candidate', internalType: 'address', type: 'address' }],
    name: 'isAutomaton',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'cursor', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateAutomatons',
    outputs: [
      { name: '', internalType: 'address[]', type: 'address[]' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'candidate', internalType: 'address', type: 'address' }],
    name: 'removeAutomaton',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'candidate', internalType: 'address', type: 'address' },
      { name: 'privileges', internalType: 'uint32', type: 'uint32' },
    ],
    name: 'setAutomatonPrivileges',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'account', internalType: 'address', type: 'address' },
      { name: 'balance', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'fastBalanceChanged',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'holdings',
    outputs: [{ name: '', internalType: 'address[]', type: 'address[]' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'issuerAddress',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'member', internalType: 'address', type: 'address', indexed: true }],
    name: 'MemberActivated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'member', internalType: 'address', type: 'address', indexed: true }],
    name: 'MemberDeactivated',
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// AHasAutomatons
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const aHasAutomatonsABI = [
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresAutomatonsManager',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'automaton', internalType: 'address', type: 'address', indexed: true },
      { name: 'privileges', internalType: 'uint32', type: 'uint32', indexed: true },
    ],
    name: 'AutomatonPrivilegesSet',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'automaton', internalType: 'address', type: 'address', indexed: true }],
    name: 'AutomatonRemoved',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'automaton', internalType: 'address', type: 'address' },
      { name: 'privilege', internalType: 'uint32', type: 'uint32' },
    ],
    name: 'automatonCan',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'automatonCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'automaton', internalType: 'address', type: 'address' }],
    name: 'automatonPrivileges',
    outputs: [{ name: '', internalType: 'uint32', type: 'uint32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'candidate', internalType: 'address', type: 'address' }],
    name: 'isAutomaton',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'cursor', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateAutomatons',
    outputs: [
      { name: '', internalType: 'address[]', type: 'address[]' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'candidate', internalType: 'address', type: 'address' }],
    name: 'removeAutomaton',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'candidate', internalType: 'address', type: 'address' },
      { name: 'privileges', internalType: 'uint32', type: 'uint32' },
    ],
    name: 'setAutomatonPrivileges',
    outputs: [],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// AHasGovernors
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const aHasGovernorsABI = [
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresGovernorsManager',
  },
  { type: 'error', inputs: [{ name: 'who', internalType: 'address', type: 'address' }], name: 'RequiresValidGovernor' },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'governor', internalType: 'address', type: 'address', indexed: true }],
    name: 'GovernorAdded',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'governor', internalType: 'address', type: 'address', indexed: true }],
    name: 'GovernorRemoved',
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'addGovernor',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'governorCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'isGovernor',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'index', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateGovernors',
    outputs: [
      { name: '', internalType: 'address[]', type: 'address[]' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'governor', internalType: 'address', type: 'address' }],
    name: 'removeGovernor',
    outputs: [],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// AHasMembers
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const aHasMembersABI = [
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresMembersManager',
  },
  { type: 'error', inputs: [{ name: 'who', internalType: 'address', type: 'address' }], name: 'RequiresValidMember' },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'member', internalType: 'address', type: 'address', indexed: true }],
    name: 'MemberAdded',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'member', internalType: 'address', type: 'address', indexed: true }],
    name: 'MemberRemoved',
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'addMember',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'isMember',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'memberCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'index', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateMembers',
    outputs: [
      { name: '', internalType: 'address[]', type: 'address[]' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'member', internalType: 'address', type: 'address' }],
    name: 'removeMember',
    outputs: [],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ERC20
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const erc20ABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: '_name', internalType: 'string', type: 'string' },
      { name: '_symbol', internalType: 'string', type: 'string' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'account', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'burn',
    outputs: [],
  },
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [],
    name: 'decimals',
    outputs: [{ name: '', internalType: 'uint8', type: 'uint8' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'account', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'mint',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'symbol',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'recipient', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'sender', internalType: 'address', type: 'address' },
      { name: 'recipient', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Crowdfund
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const crowdfundABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      {
        name: 'p',
        internalType: 'struct Crowdfund.Params',
        type: 'tuple',
        components: [
          { name: 'owner', internalType: 'address', type: 'address' },
          { name: 'issuer', internalType: 'address', type: 'address' },
          { name: 'fast', internalType: 'address', type: 'address' },
          { name: 'beneficiary', internalType: 'address', type: 'address' },
          { name: 'basisPointsFee', internalType: 'uint32', type: 'uint32' },
          { name: 'token', internalType: 'contract IERC20', type: 'address' },
          { name: 'ref', internalType: 'string', type: 'string' },
        ],
      },
    ],
  },
  { type: 'error', inputs: [], name: 'DuplicateEntry' },
  { type: 'error', inputs: [{ name: 'param', internalType: 'string', type: 'string' }], name: 'InconsistentParameter' },
  { type: 'error', inputs: [{ name: 'amount', internalType: 'uint256', type: 'uint256' }], name: 'InsufficientFunds' },
  { type: 'error', inputs: [], name: 'InvalidPhase' },
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresFastGovernorship',
  },
  { type: 'error', inputs: [], name: 'RequiresFastMemberCaller' },
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresFastMembership',
  },
  { type: 'error', inputs: [], name: 'RequiresIssuerMemberCaller' },
  { type: 'error', inputs: [], name: 'TokenContractError' },
  { type: 'error', inputs: [{ name: 'who', internalType: 'address', type: 'address' }], name: 'UnknownPledger' },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'phase', internalType: 'enum Crowdfund.Phase', type: 'uint8', indexed: false }],
    name: 'Advance',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'pledger', internalType: 'address', type: 'address', indexed: true },
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Pledge',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'success', internalType: 'bool', type: 'bool', indexed: true }],
    name: 'Terminated',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'VERSION',
    outputs: [{ name: '', internalType: 'uint16', type: 'uint16' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'basisPointsFee', internalType: 'uint32', type: 'uint32' }],
    name: 'advanceToFunding',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'collected',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'creationBlock',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'details',
    outputs: [
      {
        name: '',
        internalType: 'struct Crowdfund.Details',
        type: 'tuple',
        components: [
          { name: 'addr', internalType: 'address', type: 'address' },
          { name: 'VERSION', internalType: 'uint16', type: 'uint16' },
          {
            name: 'params',
            internalType: 'struct Crowdfund.Params',
            type: 'tuple',
            components: [
              { name: 'owner', internalType: 'address', type: 'address' },
              { name: 'issuer', internalType: 'address', type: 'address' },
              { name: 'fast', internalType: 'address', type: 'address' },
              { name: 'beneficiary', internalType: 'address', type: 'address' },
              { name: 'basisPointsFee', internalType: 'uint32', type: 'uint32' },
              { name: 'token', internalType: 'contract IERC20', type: 'address' },
              { name: 'ref', internalType: 'string', type: 'string' },
            ],
          },
          { name: 'phase', internalType: 'enum Crowdfund.Phase', type: 'uint8' },
          { name: 'creationBlock', internalType: 'uint256', type: 'uint256' },
          { name: 'collected', internalType: 'uint256', type: 'uint256' },
          { name: 'feeAmount', internalType: 'uint256', type: 'uint256' },
          { name: 'pledgerCount', internalType: 'uint256', type: 'uint256' },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'feeAmount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'index', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginatePledgers',
    outputs: [
      { name: '', internalType: 'address[]', type: 'address[]' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'paramsStruct',
    outputs: [
      {
        name: '',
        internalType: 'struct Crowdfund.Params',
        type: 'tuple',
        components: [
          { name: 'owner', internalType: 'address', type: 'address' },
          { name: 'issuer', internalType: 'address', type: 'address' },
          { name: 'fast', internalType: 'address', type: 'address' },
          { name: 'beneficiary', internalType: 'address', type: 'address' },
          { name: 'basisPointsFee', internalType: 'uint32', type: 'uint32' },
          { name: 'token', internalType: 'contract IERC20', type: 'address' },
          { name: 'ref', internalType: 'string', type: 'string' },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'phase',
    outputs: [{ name: '', internalType: 'enum Crowdfund.Phase', type: 'uint8' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'amount', internalType: 'uint256', type: 'uint256' }],
    name: 'pledge',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'pledgerCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'pledges',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'pledger', internalType: 'address', type: 'address' }],
    name: 'refund',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'refunded',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'success', internalType: 'bool', type: 'bool' }],
    name: 'terminate',
    outputs: [],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Distribution
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const distributionABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      {
        name: 'p',
        internalType: 'struct Distribution.Params',
        type: 'tuple',
        components: [
          { name: 'distributor', internalType: 'address', type: 'address' },
          { name: 'issuer', internalType: 'address', type: 'address' },
          { name: 'fast', internalType: 'address', type: 'address' },
          { name: 'token', internalType: 'contract IERC20', type: 'address' },
          { name: 'blockLatch', internalType: 'uint256', type: 'uint256' },
          { name: 'total', internalType: 'uint256', type: 'uint256' },
          { name: 'ref', internalType: 'string', type: 'string' },
        ],
      },
    ],
  },
  { type: 'error', inputs: [], name: 'DuplicateEntry' },
  { type: 'error', inputs: [{ name: 'param', internalType: 'string', type: 'string' }], name: 'InconsistentParameter' },
  { type: 'error', inputs: [{ name: 'amount', internalType: 'uint256', type: 'uint256' }], name: 'InsufficientFunds' },
  { type: 'error', inputs: [], name: 'InvalidPhase' },
  { type: 'error', inputs: [{ name: 'amount', internalType: 'uint256', type: 'uint256' }], name: 'Overfunded' },
  { type: 'error', inputs: [], name: 'RequiresFastCaller' },
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresFastMembership',
  },
  { type: 'error', inputs: [], name: 'RequiresManagerCaller' },
  { type: 'error', inputs: [], name: 'TokenContractError' },
  { type: 'error', inputs: [{ name: 'who', internalType: 'address', type: 'address' }], name: 'UnknownBeneficiary' },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'phase', internalType: 'enum Distribution.Phase', type: 'uint8', indexed: false }],
    name: 'Advance',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'beneficiary', internalType: 'address', type: 'address', indexed: true },
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: true },
    ],
    name: 'BeneficiaryAdded',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'beneficiary', internalType: 'address', type: 'address', indexed: true }],
    name: 'BeneficiaryRemoved',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'caller', internalType: 'address', type: 'address', indexed: true },
      { name: 'beneficiary', internalType: 'address', type: 'address', indexed: true },
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Withdrawal',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'VERSION',
    outputs: [{ name: '', internalType: 'uint16', type: 'uint16' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_beneficiaries', internalType: 'address[]', type: 'address[]' },
      { name: '_amounts', internalType: 'uint256[]', type: 'uint256[]' },
    ],
    name: 'addBeneficiaries',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_fee', internalType: 'uint256', type: 'uint256' }],
    name: 'advanceToBeneficiariesSetup',
    outputs: [],
  },
  { stateMutability: 'nonpayable', type: 'function', inputs: [], name: 'advanceToFeeSetup', outputs: [] },
  { stateMutability: 'nonpayable', type: 'function', inputs: [], name: 'advanceToWithdrawal', outputs: [] },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'available',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'beneficiaryCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'creationBlock',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'details',
    outputs: [
      {
        name: '',
        internalType: 'struct Distribution.Details',
        type: 'tuple',
        components: [
          { name: 'addr', internalType: 'address', type: 'address' },
          { name: 'VERSION', internalType: 'uint16', type: 'uint16' },
          {
            name: 'params',
            internalType: 'struct Distribution.Params',
            type: 'tuple',
            components: [
              { name: 'distributor', internalType: 'address', type: 'address' },
              { name: 'issuer', internalType: 'address', type: 'address' },
              { name: 'fast', internalType: 'address', type: 'address' },
              { name: 'token', internalType: 'contract IERC20', type: 'address' },
              { name: 'blockLatch', internalType: 'uint256', type: 'uint256' },
              { name: 'total', internalType: 'uint256', type: 'uint256' },
              { name: 'ref', internalType: 'string', type: 'string' },
            ],
          },
          { name: 'phase', internalType: 'enum Distribution.Phase', type: 'uint8' },
          { name: 'creationBlock', internalType: 'uint256', type: 'uint256' },
          { name: 'fee', internalType: 'uint256', type: 'uint256' },
          { name: 'available', internalType: 'uint256', type: 'uint256' },
          { name: 'beneficiaryCount', internalType: 'uint256', type: 'uint256' },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'fee',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'owings',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'index', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateBeneficiaries',
    outputs: [
      { name: '', internalType: 'address[]', type: 'address[]' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'paramsStruct',
    outputs: [
      {
        name: '',
        internalType: 'struct Distribution.Params',
        type: 'tuple',
        components: [
          { name: 'distributor', internalType: 'address', type: 'address' },
          { name: 'issuer', internalType: 'address', type: 'address' },
          { name: 'fast', internalType: 'address', type: 'address' },
          { name: 'token', internalType: 'contract IERC20', type: 'address' },
          { name: 'blockLatch', internalType: 'uint256', type: 'uint256' },
          { name: 'total', internalType: 'uint256', type: 'uint256' },
          { name: 'ref', internalType: 'string', type: 'string' },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'phase',
    outputs: [{ name: '', internalType: 'enum Distribution.Phase', type: 'uint8' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_beneficiaries', internalType: 'address[]', type: 'address[]' }],
    name: 'removeBeneficiaries',
    outputs: [],
  },
  { stateMutability: 'nonpayable', type: 'function', inputs: [], name: 'terminate', outputs: [] },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'beneficiary', internalType: 'address', type: 'address' }],
    name: 'withdraw',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'withdrawn',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// FastAccessFacet
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const fastAccessFacetABI = [
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresGovernorsManager',
  },
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresMembersManager',
  },
  { type: 'error', inputs: [{ name: 'who', internalType: 'address', type: 'address' }], name: 'RequiresValidGovernor' },
  { type: 'error', inputs: [{ name: 'who', internalType: 'address', type: 'address' }], name: 'RequiresValidMember' },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: 'ref', internalType: 'string', type: 'string', indexed: false },
      { name: 'who', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'Burnt',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'basisPointFee', internalType: 'uint32', type: 'uint32', indexed: true }],
    name: 'CrowdfundDefaultBasisPointFeeSet',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'crowdfund', internalType: 'contract Crowdfund', type: 'address', indexed: true }],
    name: 'CrowdfundDeployed',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'crowdfund', internalType: 'contract Crowdfund', type: 'address', indexed: true }],
    name: 'CrowdfundRemoved',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'transfersDisabled', internalType: 'bool', type: 'bool', indexed: false },
      { name: 'crowdfundsDefaultBasisPointsFee', internalType: 'uint32', type: 'uint32', indexed: false },
      { name: 'memberCount', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'governorCount', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'totalSupply', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'reserveBalance', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'DetailsChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Disapproval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'distribution', internalType: 'contract Distribution', type: 'address', indexed: true }],
    name: 'DistributionDeployed',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'distribution', internalType: 'contract Distribution', type: 'address', indexed: true }],
    name: 'DistributionRemoved',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'ref', internalType: 'string', type: 'string', indexed: false },
    ],
    name: 'FastTransfer',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'governor', internalType: 'address', type: 'address', indexed: true }],
    name: 'GovernorAdded',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'governor', internalType: 'address', type: 'address', indexed: true }],
    name: 'GovernorRemoved',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'member', internalType: 'address', type: 'address', indexed: true }],
    name: 'MemberAdded',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'member', internalType: 'address', type: 'address', indexed: true }],
    name: 'MemberRemoved',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: 'ref', internalType: 'string', type: 'string', indexed: false },
      { name: 'who', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'Minted',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'addGovernor',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'addMember',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'a', internalType: 'address', type: 'address' }],
    name: 'flags',
    outputs: [
      {
        name: '',
        internalType: 'struct FastAccessFacet.Flags',
        type: 'tuple',
        components: [
          { name: 'isGovernor', internalType: 'bool', type: 'bool' },
          { name: 'isMember', internalType: 'bool', type: 'bool' },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'governorCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'isGovernor',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'isMember',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'memberCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'index', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateGovernors',
    outputs: [
      { name: '', internalType: 'address[]', type: 'address[]' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'index', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateMembers',
    outputs: [
      { name: '', internalType: 'address[]', type: 'address[]' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'governor', internalType: 'address', type: 'address' }],
    name: 'removeGovernor',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'member', internalType: 'address', type: 'address' }],
    name: 'removeMember',
    outputs: [],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// FastAutomatonsFacet
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const fastAutomatonsFacetABI = [
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresAutomatonsManager',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'automaton', internalType: 'address', type: 'address', indexed: true },
      { name: 'privileges', internalType: 'uint32', type: 'uint32', indexed: true },
    ],
    name: 'AutomatonPrivilegesSet',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'automaton', internalType: 'address', type: 'address', indexed: true }],
    name: 'AutomatonRemoved',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: 'ref', internalType: 'string', type: 'string', indexed: false },
      { name: 'who', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'Burnt',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'basisPointFee', internalType: 'uint32', type: 'uint32', indexed: true }],
    name: 'CrowdfundDefaultBasisPointFeeSet',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'crowdfund', internalType: 'contract Crowdfund', type: 'address', indexed: true }],
    name: 'CrowdfundDeployed',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'crowdfund', internalType: 'contract Crowdfund', type: 'address', indexed: true }],
    name: 'CrowdfundRemoved',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'transfersDisabled', internalType: 'bool', type: 'bool', indexed: false },
      { name: 'crowdfundsDefaultBasisPointsFee', internalType: 'uint32', type: 'uint32', indexed: false },
      { name: 'memberCount', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'governorCount', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'totalSupply', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'reserveBalance', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'DetailsChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Disapproval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'distribution', internalType: 'contract Distribution', type: 'address', indexed: true }],
    name: 'DistributionDeployed',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'distribution', internalType: 'contract Distribution', type: 'address', indexed: true }],
    name: 'DistributionRemoved',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'ref', internalType: 'string', type: 'string', indexed: false },
    ],
    name: 'FastTransfer',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: 'ref', internalType: 'string', type: 'string', indexed: false },
      { name: 'who', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'Minted',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'automaton', internalType: 'address', type: 'address' },
      { name: 'privilege', internalType: 'uint32', type: 'uint32' },
    ],
    name: 'automatonCan',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'automatonCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'automaton', internalType: 'address', type: 'address' }],
    name: 'automatonPrivileges',
    outputs: [{ name: '', internalType: 'uint32', type: 'uint32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'candidate', internalType: 'address', type: 'address' }],
    name: 'isAutomaton',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'cursor', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateAutomatons',
    outputs: [
      { name: '', internalType: 'address[]', type: 'address[]' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'candidate', internalType: 'address', type: 'address' }],
    name: 'removeAutomaton',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'candidate', internalType: 'address', type: 'address' },
      { name: 'privileges', internalType: 'uint32', type: 'uint32' },
    ],
    name: 'setAutomatonPrivileges',
    outputs: [],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// FastCrowdfundsFacet
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const fastCrowdfundsFacetABI = [
  {
    type: 'error',
    inputs: [{ name: 'fee', internalType: 'uint32', type: 'uint32' }],
    name: 'InvalidCrowdfundBasisPointsFee',
  },
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresFastGovernorship',
  },
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresIssuerMembership',
  },
  {
    type: 'error',
    inputs: [
      { name: 'who', internalType: 'address', type: 'address' },
      { name: 'privilege', internalType: 'uint32', type: 'uint32' },
    ],
    name: 'RequiresPrivilege',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: 'ref', internalType: 'string', type: 'string', indexed: false },
      { name: 'who', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'Burnt',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'basisPointFee', internalType: 'uint32', type: 'uint32', indexed: true }],
    name: 'CrowdfundDefaultBasisPointFeeSet',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'crowdfund', internalType: 'contract Crowdfund', type: 'address', indexed: true }],
    name: 'CrowdfundDeployed',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'crowdfund', internalType: 'contract Crowdfund', type: 'address', indexed: true }],
    name: 'CrowdfundRemoved',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'transfersDisabled', internalType: 'bool', type: 'bool', indexed: false },
      { name: 'crowdfundsDefaultBasisPointsFee', internalType: 'uint32', type: 'uint32', indexed: false },
      { name: 'memberCount', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'governorCount', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'totalSupply', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'reserveBalance', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'DetailsChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Disapproval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'distribution', internalType: 'contract Distribution', type: 'address', indexed: true }],
    name: 'DistributionDeployed',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'distribution', internalType: 'contract Distribution', type: 'address', indexed: true }],
    name: 'DistributionRemoved',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'ref', internalType: 'string', type: 'string', indexed: false },
    ],
    name: 'FastTransfer',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: 'ref', internalType: 'string', type: 'string', indexed: false },
      { name: 'who', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'Minted',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'token', internalType: 'contract IERC20', type: 'address' },
      { name: 'beneficiary', internalType: 'address', type: 'address' },
      { name: 'ref', internalType: 'string', type: 'string' },
    ],
    name: 'createCrowdfund',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'crowdfundCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'crowdfundsDefaultBasisPointFee',
    outputs: [{ name: '', internalType: 'uint32', type: 'uint32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'index', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateCrowdfunds',
    outputs: [
      { name: '', internalType: 'address[]', type: 'address[]' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'crowdfund', internalType: 'contract Crowdfund', type: 'address' }],
    name: 'removeCrowdfund',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'newBasisPointFee', internalType: 'uint32', type: 'uint32' }],
    name: 'setCrowdfundsDefaultBasisPointFee',
    outputs: [],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// FastDistributionsFacet
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const fastDistributionsFacetABI = [
  { type: 'error', inputs: [{ name: 'amount', internalType: 'uint256', type: 'uint256' }], name: 'InsufficientFunds' },
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresFastMembership',
  },
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresIssuerMembership',
  },
  { type: 'error', inputs: [], name: 'TokenContractError' },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: 'ref', internalType: 'string', type: 'string', indexed: false },
      { name: 'who', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'Burnt',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'basisPointFee', internalType: 'uint32', type: 'uint32', indexed: true }],
    name: 'CrowdfundDefaultBasisPointFeeSet',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'crowdfund', internalType: 'contract Crowdfund', type: 'address', indexed: true }],
    name: 'CrowdfundDeployed',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'crowdfund', internalType: 'contract Crowdfund', type: 'address', indexed: true }],
    name: 'CrowdfundRemoved',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'transfersDisabled', internalType: 'bool', type: 'bool', indexed: false },
      { name: 'crowdfundsDefaultBasisPointsFee', internalType: 'uint32', type: 'uint32', indexed: false },
      { name: 'memberCount', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'governorCount', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'totalSupply', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'reserveBalance', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'DetailsChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Disapproval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'distribution', internalType: 'contract Distribution', type: 'address', indexed: true }],
    name: 'DistributionDeployed',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'distribution', internalType: 'contract Distribution', type: 'address', indexed: true }],
    name: 'DistributionRemoved',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'ref', internalType: 'string', type: 'string', indexed: false },
    ],
    name: 'FastTransfer',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: 'ref', internalType: 'string', type: 'string', indexed: false },
      { name: 'who', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'Minted',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'token', internalType: 'contract IERC20', type: 'address' },
      { name: 'total', internalType: 'uint256', type: 'uint256' },
      { name: 'blockLatch', internalType: 'uint256', type: 'uint256' },
      { name: 'ref', internalType: 'string', type: 'string' },
    ],
    name: 'createDistribution',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'distributionCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'index', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateDistributions',
    outputs: [
      { name: '', internalType: 'address[]', type: 'address[]' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'distribution', internalType: 'contract Distribution', type: 'address' }],
    name: 'removeDistribution',
    outputs: [],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// FastFrontendFacet
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const fastFrontendFacetABI = [
  { type: 'error', inputs: [], name: 'InternalMethod' },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: 'ref', internalType: 'string', type: 'string', indexed: false },
      { name: 'who', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'Burnt',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'basisPointFee', internalType: 'uint32', type: 'uint32', indexed: true }],
    name: 'CrowdfundDefaultBasisPointFeeSet',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'crowdfund', internalType: 'contract Crowdfund', type: 'address', indexed: true }],
    name: 'CrowdfundDeployed',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'crowdfund', internalType: 'contract Crowdfund', type: 'address', indexed: true }],
    name: 'CrowdfundRemoved',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'transfersDisabled', internalType: 'bool', type: 'bool', indexed: false },
      { name: 'crowdfundsDefaultBasisPointsFee', internalType: 'uint32', type: 'uint32', indexed: false },
      { name: 'memberCount', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'governorCount', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'totalSupply', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'reserveBalance', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'DetailsChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Disapproval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'distribution', internalType: 'contract Distribution', type: 'address', indexed: true }],
    name: 'DistributionDeployed',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'distribution', internalType: 'contract Distribution', type: 'address', indexed: true }],
    name: 'DistributionRemoved',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'ref', internalType: 'string', type: 'string', indexed: false },
    ],
    name: 'FastTransfer',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: 'ref', internalType: 'string', type: 'string', indexed: false },
      { name: 'who', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'Minted',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'addr', internalType: 'address', type: 'address' }],
    name: 'detailedPrivileges',
    outputs: [
      {
        name: '',
        internalType: 'struct FastFrontendFacet.PrivilegesDetails',
        type: 'tuple',
        components: [
          { name: 'addr', internalType: 'address', type: 'address' },
          { name: 'balance', internalType: 'uint256', type: 'uint256' },
          { name: 'ethBalance', internalType: 'uint256', type: 'uint256' },
          { name: 'isMember', internalType: 'bool', type: 'bool' },
          { name: 'isGovernor', internalType: 'bool', type: 'bool' },
          { name: 'automatonPrivileges', internalType: 'uint32', type: 'uint32' },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'details',
    outputs: [
      {
        name: '',
        internalType: 'struct FastFrontendFacet.Details',
        type: 'tuple',
        components: [
          { name: 'addr', internalType: 'address', type: 'address' },
          { name: 'name', internalType: 'string', type: 'string' },
          { name: 'symbol', internalType: 'string', type: 'string' },
          { name: 'decimals', internalType: 'uint8', type: 'uint8' },
          { name: 'totalSupply', internalType: 'uint256', type: 'uint256' },
          { name: 'isSemiPublic', internalType: 'bool', type: 'bool' },
          { name: 'hasFixedSupply', internalType: 'bool', type: 'bool' },
          { name: 'transfersDisabled', internalType: 'bool', type: 'bool' },
          { name: 'crowdfundsDefaultBasisPointsFee', internalType: 'uint32', type: 'uint32' },
          { name: 'reserveBalance', internalType: 'uint256', type: 'uint256' },
          { name: 'memberCount', internalType: 'uint256', type: 'uint256' },
          { name: 'governorCount', internalType: 'uint256', type: 'uint256' },
        ],
      },
    ],
  },
  { stateMutability: 'nonpayable', type: 'function', inputs: [], name: 'emitDetailsChanged', outputs: [] },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'index', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateDetailedCrowdfunds',
    outputs: [
      {
        name: '',
        internalType: 'struct Crowdfund.Details[]',
        type: 'tuple[]',
        components: [
          { name: 'addr', internalType: 'address', type: 'address' },
          { name: 'VERSION', internalType: 'uint16', type: 'uint16' },
          {
            name: 'params',
            internalType: 'struct Crowdfund.Params',
            type: 'tuple',
            components: [
              { name: 'owner', internalType: 'address', type: 'address' },
              { name: 'issuer', internalType: 'address', type: 'address' },
              { name: 'fast', internalType: 'address', type: 'address' },
              { name: 'beneficiary', internalType: 'address', type: 'address' },
              { name: 'basisPointsFee', internalType: 'uint32', type: 'uint32' },
              { name: 'token', internalType: 'contract IERC20', type: 'address' },
              { name: 'ref', internalType: 'string', type: 'string' },
            ],
          },
          { name: 'phase', internalType: 'enum Crowdfund.Phase', type: 'uint8' },
          { name: 'creationBlock', internalType: 'uint256', type: 'uint256' },
          { name: 'collected', internalType: 'uint256', type: 'uint256' },
          { name: 'feeAmount', internalType: 'uint256', type: 'uint256' },
          { name: 'pledgerCount', internalType: 'uint256', type: 'uint256' },
        ],
      },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'index', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateDetailedDistributions',
    outputs: [
      {
        name: '',
        internalType: 'struct Distribution.Details[]',
        type: 'tuple[]',
        components: [
          { name: 'addr', internalType: 'address', type: 'address' },
          { name: 'VERSION', internalType: 'uint16', type: 'uint16' },
          {
            name: 'params',
            internalType: 'struct Distribution.Params',
            type: 'tuple',
            components: [
              { name: 'distributor', internalType: 'address', type: 'address' },
              { name: 'issuer', internalType: 'address', type: 'address' },
              { name: 'fast', internalType: 'address', type: 'address' },
              { name: 'token', internalType: 'contract IERC20', type: 'address' },
              { name: 'blockLatch', internalType: 'uint256', type: 'uint256' },
              { name: 'total', internalType: 'uint256', type: 'uint256' },
              { name: 'ref', internalType: 'string', type: 'string' },
            ],
          },
          { name: 'phase', internalType: 'enum Distribution.Phase', type: 'uint8' },
          { name: 'creationBlock', internalType: 'uint256', type: 'uint256' },
          { name: 'fee', internalType: 'uint256', type: 'uint256' },
          { name: 'available', internalType: 'uint256', type: 'uint256' },
          { name: 'beneficiaryCount', internalType: 'uint256', type: 'uint256' },
        ],
      },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'index', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateDetailedGovernors',
    outputs: [
      {
        name: '',
        internalType: 'struct FastFrontendFacet.PrivilegesDetails[]',
        type: 'tuple[]',
        components: [
          { name: 'addr', internalType: 'address', type: 'address' },
          { name: 'balance', internalType: 'uint256', type: 'uint256' },
          { name: 'ethBalance', internalType: 'uint256', type: 'uint256' },
          { name: 'isMember', internalType: 'bool', type: 'bool' },
          { name: 'isGovernor', internalType: 'bool', type: 'bool' },
          { name: 'automatonPrivileges', internalType: 'uint32', type: 'uint32' },
        ],
      },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'index', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateDetailedMembers',
    outputs: [
      {
        name: '',
        internalType: 'struct FastFrontendFacet.PrivilegesDetails[]',
        type: 'tuple[]',
        components: [
          { name: 'addr', internalType: 'address', type: 'address' },
          { name: 'balance', internalType: 'uint256', type: 'uint256' },
          { name: 'ethBalance', internalType: 'uint256', type: 'uint256' },
          { name: 'isMember', internalType: 'bool', type: 'bool' },
          { name: 'isGovernor', internalType: 'bool', type: 'bool' },
          { name: 'automatonPrivileges', internalType: 'uint32', type: 'uint32' },
        ],
      },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// FastHistoryFacet
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const fastHistoryFacetABI = [
  { type: 'error', inputs: [], name: 'InternalMethod' },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: 'ref', internalType: 'string', type: 'string', indexed: false },
      { name: 'who', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'Burnt',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'basisPointFee', internalType: 'uint32', type: 'uint32', indexed: true }],
    name: 'CrowdfundDefaultBasisPointFeeSet',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'crowdfund', internalType: 'contract Crowdfund', type: 'address', indexed: true }],
    name: 'CrowdfundDeployed',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'crowdfund', internalType: 'contract Crowdfund', type: 'address', indexed: true }],
    name: 'CrowdfundRemoved',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'transfersDisabled', internalType: 'bool', type: 'bool', indexed: false },
      { name: 'crowdfundsDefaultBasisPointsFee', internalType: 'uint32', type: 'uint32', indexed: false },
      { name: 'memberCount', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'governorCount', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'totalSupply', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'reserveBalance', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'DetailsChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Disapproval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'distribution', internalType: 'contract Distribution', type: 'address', indexed: true }],
    name: 'DistributionDeployed',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'distribution', internalType: 'contract Distribution', type: 'address', indexed: true }],
    name: 'DistributionRemoved',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'ref', internalType: 'string', type: 'string', indexed: false },
    ],
    name: 'FastTransfer',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: 'ref', internalType: 'string', type: 'string', indexed: false },
      { name: 'who', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'Minted',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
      { name: 'ref', internalType: 'string', type: 'string' },
    ],
    name: 'burnt',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
      { name: 'ref', internalType: 'string', type: 'string' },
    ],
    name: 'minted',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'cursor', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateSupplyProofs',
    outputs: [
      {
        name: '',
        internalType: 'struct LibFastHistory.SupplyProof[]',
        type: 'tuple[]',
        components: [
          { name: 'op', internalType: 'enum LibFastHistory.SupplyOp', type: 'uint8' },
          { name: 'amount', internalType: 'uint256', type: 'uint256' },
          { name: 'blockNumber', internalType: 'uint256', type: 'uint256' },
          { name: 'ref', internalType: 'string', type: 'string' },
        ],
      },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'involvee', internalType: 'address', type: 'address' },
      { name: 'cursor', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateTransferProofIndicesByInvolvee',
    outputs: [
      { name: '', internalType: 'uint256[]', type: 'uint256[]' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'cursor', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateTransferProofs',
    outputs: [
      {
        name: '',
        internalType: 'struct LibFastHistory.TransferProof[]',
        type: 'tuple[]',
        components: [
          { name: 'spender', internalType: 'address', type: 'address' },
          { name: 'from', internalType: 'address', type: 'address' },
          { name: 'to', internalType: 'address', type: 'address' },
          { name: 'amount', internalType: 'uint256', type: 'uint256' },
          { name: 'blockNumber', internalType: 'uint256', type: 'uint256' },
          { name: 'ref', internalType: 'string', type: 'string' },
        ],
      },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'involvee', internalType: 'address', type: 'address' },
      { name: 'cursor', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateTransferProofsByInvolvee',
    outputs: [
      {
        name: '',
        internalType: 'struct LibFastHistory.TransferProof[]',
        type: 'tuple[]',
        components: [
          { name: 'spender', internalType: 'address', type: 'address' },
          { name: 'from', internalType: 'address', type: 'address' },
          { name: 'to', internalType: 'address', type: 'address' },
          { name: 'amount', internalType: 'uint256', type: 'uint256' },
          { name: 'blockNumber', internalType: 'uint256', type: 'uint256' },
          { name: 'ref', internalType: 'string', type: 'string' },
        ],
      },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'supplyProofCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'involvee', internalType: 'address', type: 'address' }],
    name: 'transferProofByInvolveeCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'transferProofCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
      { name: 'ref', internalType: 'string', type: 'string' },
    ],
    name: 'transfered',
    outputs: [],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// FastInitFacet
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const fastInitFacetABI = [
  { type: 'error', inputs: [], name: 'AlreadyInitialized' },
  { type: 'error', inputs: [], name: 'InternalMethod' },
  {
    type: 'error',
    inputs: [{ name: 'fee', internalType: 'uint32', type: 'uint32' }],
    name: 'InvalidCrowdfundBasisPointsFee',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: 'ref', internalType: 'string', type: 'string', indexed: false },
      { name: 'who', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'Burnt',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'basisPointFee', internalType: 'uint32', type: 'uint32', indexed: true }],
    name: 'CrowdfundDefaultBasisPointFeeSet',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'crowdfund', internalType: 'contract Crowdfund', type: 'address', indexed: true }],
    name: 'CrowdfundDeployed',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'crowdfund', internalType: 'contract Crowdfund', type: 'address', indexed: true }],
    name: 'CrowdfundRemoved',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'transfersDisabled', internalType: 'bool', type: 'bool', indexed: false },
      { name: 'crowdfundsDefaultBasisPointsFee', internalType: 'uint32', type: 'uint32', indexed: false },
      { name: 'memberCount', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'governorCount', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'totalSupply', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'reserveBalance', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'DetailsChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Disapproval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'distribution', internalType: 'contract Distribution', type: 'address', indexed: true }],
    name: 'DistributionDeployed',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'distribution', internalType: 'contract Distribution', type: 'address', indexed: true }],
    name: 'DistributionRemoved',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'ref', internalType: 'string', type: 'string', indexed: false },
    ],
    name: 'FastTransfer',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'governor', internalType: 'address', type: 'address', indexed: true }],
    name: 'GovernorAdded',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'member', internalType: 'address', type: 'address', indexed: true }],
    name: 'MemberAdded',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: 'ref', internalType: 'string', type: 'string', indexed: false },
      { name: 'who', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'Minted',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      {
        name: 'params',
        internalType: 'struct FastInitFacet.InitializerParams',
        type: 'tuple',
        components: [
          { name: 'issuer', internalType: 'address', type: 'address' },
          { name: 'marketplace', internalType: 'address', type: 'address' },
          { name: 'name', internalType: 'string', type: 'string' },
          { name: 'symbol', internalType: 'string', type: 'string' },
          { name: 'decimals', internalType: 'uint8', type: 'uint8' },
          { name: 'hasFixedSupply', internalType: 'bool', type: 'bool' },
          { name: 'isSemiPublic', internalType: 'bool', type: 'bool' },
          { name: 'crowdfundsDefaultBasisPointsFee', internalType: 'uint32', type: 'uint32' },
        ],
      },
    ],
    name: 'initialize',
    outputs: [],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// FastTokenFacet
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const fastTokenFacetABI = [
  { type: 'error', inputs: [], name: 'InternalMethod' },
  { type: 'error', inputs: [], name: 'RequiresContinuousSupply' },
  {
    type: 'error',
    inputs: [{ name: 'a', internalType: 'address', type: 'address' }],
    name: 'RequiresDifferentSenderAndRecipient',
  },
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresFastMembership',
  },
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresIssuerMembership',
  },
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresMarketplaceActiveMembership',
  },
  {
    type: 'error',
    inputs: [{ name: 'holder', internalType: 'address', type: 'address' }],
    name: 'RequiresPositiveBalance',
  },
  { type: 'error', inputs: [], name: 'RequiresTransfersEnabled' },
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresValidTokenHolder',
  },
  { type: 'error', inputs: [], name: 'UnsupportedOperation' },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: 'ref', internalType: 'string', type: 'string', indexed: false },
      { name: 'who', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'Burnt',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'basisPointFee', internalType: 'uint32', type: 'uint32', indexed: true }],
    name: 'CrowdfundDefaultBasisPointFeeSet',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'crowdfund', internalType: 'contract Crowdfund', type: 'address', indexed: true }],
    name: 'CrowdfundDeployed',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'crowdfund', internalType: 'contract Crowdfund', type: 'address', indexed: true }],
    name: 'CrowdfundRemoved',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'transfersDisabled', internalType: 'bool', type: 'bool', indexed: false },
      { name: 'crowdfundsDefaultBasisPointsFee', internalType: 'uint32', type: 'uint32', indexed: false },
      { name: 'memberCount', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'governorCount', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'totalSupply', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'reserveBalance', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'DetailsChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Disapproval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'distribution', internalType: 'contract Distribution', type: 'address', indexed: true }],
    name: 'DistributionDeployed',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'distribution', internalType: 'contract Distribution', type: 'address', indexed: true }],
    name: 'DistributionRemoved',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'ref', internalType: 'string', type: 'string', indexed: false },
    ],
    name: 'FastTransfer',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: 'ref', internalType: 'string', type: 'string', indexed: false },
      { name: 'who', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'Minted',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'owner', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'member', internalType: 'address', type: 'address' }],
    name: 'beforeRemovingMember',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
      { name: 'ref', internalType: 'string', type: 'string' },
    ],
    name: 'burn',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'decimals',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'disapprove',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'owner', internalType: 'address', type: 'address' }],
    name: 'givenAllowanceCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'holders',
    outputs: [{ name: '', internalType: 'address[]', type: 'address[]' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
      { name: 'ref', internalType: 'string', type: 'string' },
    ],
    name: 'mint',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'index', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateAllowancesByOwner',
    outputs: [
      { name: '', internalType: 'address[]', type: 'address[]' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'index', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateAllowancesBySpender',
    outputs: [
      { name: '', internalType: 'address[]', type: 'address[]' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'performApproval',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'performDisapproval',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      {
        name: 'p',
        internalType: 'struct FastTokenFacet.TransferArgs',
        type: 'tuple',
        components: [
          { name: 'spender', internalType: 'address', type: 'address' },
          { name: 'from', internalType: 'address', type: 'address' },
          { name: 'to', internalType: 'address', type: 'address' },
          { name: 'amount', internalType: 'uint256', type: 'uint256' },
          { name: 'ref', internalType: 'string', type: 'string' },
        ],
      },
    ],
    name: 'performTransfer',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'spender', internalType: 'address', type: 'address' }],
    name: 'receivedAllowanceCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'holder', internalType: 'address', type: 'address' }],
    name: 'retrieveDeadTokens',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'symbol',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
      { name: 'ref', internalType: 'string', type: 'string' },
    ],
    name: 'transferFromWithRef',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
      { name: 'ref', internalType: 'string', type: 'string' },
    ],
    name: 'transferWithRef',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// FastTopFacet
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const fastTopFacetABI = [
  { type: 'error', inputs: [], name: 'InternalMethod' },
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresIssuerMembership',
  },
  { type: 'error', inputs: [], name: 'UnsupportedOperation' },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: 'ref', internalType: 'string', type: 'string', indexed: false },
      { name: 'who', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'Burnt',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'basisPointFee', internalType: 'uint32', type: 'uint32', indexed: true }],
    name: 'CrowdfundDefaultBasisPointFeeSet',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'crowdfund', internalType: 'contract Crowdfund', type: 'address', indexed: true }],
    name: 'CrowdfundDeployed',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'crowdfund', internalType: 'contract Crowdfund', type: 'address', indexed: true }],
    name: 'CrowdfundRemoved',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'transfersDisabled', internalType: 'bool', type: 'bool', indexed: false },
      { name: 'crowdfundsDefaultBasisPointsFee', internalType: 'uint32', type: 'uint32', indexed: false },
      { name: 'memberCount', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'governorCount', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'totalSupply', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'reserveBalance', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'DetailsChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Disapproval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'distribution', internalType: 'contract Distribution', type: 'address', indexed: true }],
    name: 'DistributionDeployed',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'distribution', internalType: 'contract Distribution', type: 'address', indexed: true }],
    name: 'DistributionRemoved',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'ref', internalType: 'string', type: 'string', indexed: false },
    ],
    name: 'FastTransfer',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: 'ref', internalType: 'string', type: 'string', indexed: false },
      { name: 'who', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'Minted',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'group',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'hasFixedSupply',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'isSemiPublic',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'issuerAddress',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'marketplaceAddress',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'newGroup', internalType: 'string', type: 'string' }],
    name: 'setGroup',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'flag', internalType: 'bool', type: 'bool' }],
    name: 'setIsSemiPublic',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'flag', internalType: 'bool', type: 'bool' }],
    name: 'setTransfersDisabled',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'transfersDisabled',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ICustomErrors
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const iCustomErrorsABI = [
  { type: 'error', inputs: [], name: 'AlreadyInitialized' },
  { type: 'error', inputs: [{ name: 'who', internalType: 'address', type: 'address' }], name: 'CannotSelfRemove' },
  { type: 'error', inputs: [], name: 'DuplicateEntry' },
  { type: 'error', inputs: [{ name: 'param', internalType: 'string', type: 'string' }], name: 'InconsistentParameter' },
  { type: 'error', inputs: [{ name: 'amount', internalType: 'uint256', type: 'uint256' }], name: 'InsufficientFunds' },
  { type: 'error', inputs: [], name: 'InternalMethod' },
  {
    type: 'error',
    inputs: [{ name: 'fee', internalType: 'uint32', type: 'uint32' }],
    name: 'InvalidCrowdfundBasisPointsFee',
  },
  { type: 'error', inputs: [], name: 'InvalidPhase' },
  { type: 'error', inputs: [], name: 'NonExistentEntry' },
  { type: 'error', inputs: [{ name: 'amount', internalType: 'uint256', type: 'uint256' }], name: 'Overfunded' },
  { type: 'error', inputs: [], name: 'ReentrancyError' },
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresAutomatonsManager',
  },
  { type: 'error', inputs: [], name: 'RequiresContinuousSupply' },
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresDiamondOwnership',
  },
  {
    type: 'error',
    inputs: [{ name: 'a', internalType: 'address', type: 'address' }],
    name: 'RequiresDifferentSenderAndRecipient',
  },
  { type: 'error', inputs: [], name: 'RequiresFastCaller' },
  { type: 'error', inputs: [], name: 'RequiresFastContractCaller' },
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresFastGovernorship',
  },
  { type: 'error', inputs: [], name: 'RequiresFastMemberCaller' },
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresFastMembership',
  },
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresGovernorsManager',
  },
  { type: 'error', inputs: [], name: 'RequiresIssuerMemberCaller' },
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresIssuerMembership',
  },
  { type: 'error', inputs: [], name: 'RequiresManagerCaller' },
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresMarketplaceActiveMembership',
  },
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresMarketplaceDeactivatedMember',
  },
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresMarketplaceMembership',
  },
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresMembersManager',
  },
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresNoFastMemberships',
  },
  { type: 'error', inputs: [{ name: 'who', internalType: 'address', type: 'address' }], name: 'RequiresOwner' },
  {
    type: 'error',
    inputs: [{ name: 'holder', internalType: 'address', type: 'address' }],
    name: 'RequiresPositiveBalance',
  },
  { type: 'error', inputs: [], name: 'RequiresTransfersEnabled' },
  { type: 'error', inputs: [{ name: 'who', internalType: 'address', type: 'address' }], name: 'RequiresValidGovernor' },
  { type: 'error', inputs: [{ name: 'who', internalType: 'address', type: 'address' }], name: 'RequiresValidMember' },
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresValidTokenHolder',
  },
  { type: 'error', inputs: [], name: 'TokenContractError' },
  { type: 'error', inputs: [{ name: 'who', internalType: 'address', type: 'address' }], name: 'UnknownBeneficiary' },
  { type: 'error', inputs: [{ name: 'who', internalType: 'address', type: 'address' }], name: 'UnknownPledger' },
  { type: 'error', inputs: [], name: 'UnsupportedOperation' },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IDiamondCut
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const iDiamondCutABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: '_diamondCut',
        internalType: 'struct IDiamondCut.FacetCut[]',
        type: 'tuple[]',
        components: [
          { name: 'facetAddress', internalType: 'address', type: 'address' },
          { name: 'action', internalType: 'enum IDiamondCut.FacetCutAction', type: 'uint8' },
          { name: 'functionSelectors', internalType: 'bytes4[]', type: 'bytes4[]' },
        ],
        indexed: false,
      },
      { name: '_init', internalType: 'address', type: 'address', indexed: false },
      { name: '_calldata', internalType: 'bytes', type: 'bytes', indexed: false },
    ],
    name: 'DiamondCut',
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      {
        name: '_diamondCut',
        internalType: 'struct IDiamondCut.FacetCut[]',
        type: 'tuple[]',
        components: [
          { name: 'facetAddress', internalType: 'address', type: 'address' },
          { name: 'action', internalType: 'enum IDiamondCut.FacetCutAction', type: 'uint8' },
          { name: 'functionSelectors', internalType: 'bytes4[]', type: 'bytes4[]' },
        ],
      },
      { name: '_init', internalType: 'address', type: 'address' },
      { name: '_calldata', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'diamondCut',
    outputs: [],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IDiamondLoupe
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const iDiamondLoupeABI = [
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_functionSelector', internalType: 'bytes4', type: 'bytes4' }],
    name: 'facetAddress',
    outputs: [{ name: 'facetAddress_', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'facetAddresses',
    outputs: [{ name: 'facetAddresses_', internalType: 'address[]', type: 'address[]' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_facet', internalType: 'address', type: 'address' }],
    name: 'facetFunctionSelectors',
    outputs: [{ name: 'facetFunctionSelectors_', internalType: 'bytes4[]', type: 'bytes4[]' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'facets',
    outputs: [
      {
        name: 'facets_',
        internalType: 'struct IDiamondLoupe.Facet[]',
        type: 'tuple[]',
        components: [
          { name: 'facetAddress', internalType: 'address', type: 'address' },
          { name: 'functionSelectors', internalType: 'bytes4[]', type: 'bytes4[]' },
        ],
      },
    ],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IERC1404
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const ierc1404ABI = [
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'recipient', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'detectTransferRestriction',
    outputs: [{ name: '', internalType: 'uint8', type: 'uint8' }],
  },
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [{ name: 'code', internalType: 'uint8', type: 'uint8' }],
    name: 'messageForTransferRestriction',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IERC165
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const ierc165ABI = [
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'interfaceId', internalType: 'bytes4', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IERC173
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const ierc173ABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'previousOwner', internalType: 'address', type: 'address', indexed: true },
      { name: 'newOwner', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'OwnershipTransferred',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'owner',
    outputs: [{ name: 'owner_', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_newOwner', internalType: 'address', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IERC20
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const ierc20ABI = [
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'recipient', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'sender', internalType: 'address', type: 'address' },
      { name: 'recipient', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IHasActiveMembers
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const iHasActiveMembersABI = [
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'member', internalType: 'address', type: 'address' }],
    name: 'activateMember',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'member', internalType: 'address', type: 'address' }],
    name: 'deactivateMember',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'member', internalType: 'address', type: 'address' }],
    name: 'isActiveMember',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IssuerAccessFacet
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const issuerAccessFacetABI = [
  { type: 'error', inputs: [], name: 'RequiresFastContractCaller' },
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresMembersManager',
  },
  { type: 'error', inputs: [{ name: 'who', internalType: 'address', type: 'address' }], name: 'RequiresValidMember' },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'fast', internalType: 'address', type: 'address', indexed: true },
      { name: 'oldGroup', internalType: 'string', type: 'string', indexed: true },
      { name: 'newGroup', internalType: 'string', type: 'string', indexed: true },
    ],
    name: 'FastGroupChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'fast', internalType: 'address', type: 'address', indexed: true }],
    name: 'FastRegistered',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'fast', internalType: 'address', type: 'address', indexed: true }],
    name: 'FastUnregistered',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'fast', internalType: 'address', type: 'address', indexed: true },
      { name: 'governor', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'GovernorshipAdded',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'fast', internalType: 'address', type: 'address', indexed: true },
      { name: 'governor', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'GovernorshipRemoved',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'member', internalType: 'address', type: 'address', indexed: true }],
    name: 'MemberAdded',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'member', internalType: 'address', type: 'address', indexed: true }],
    name: 'MemberRemoved',
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'addMember',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'governor', internalType: 'address', type: 'address' }],
    name: 'governorAddedToFast',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'governor', internalType: 'address', type: 'address' }],
    name: 'governorRemovedFromFast',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'isMember',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'memberCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'governor', internalType: 'address', type: 'address' },
      { name: 'cursor', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateGovernorships',
    outputs: [
      { name: '', internalType: 'address[]', type: 'address[]' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'index', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateMembers',
    outputs: [
      { name: '', internalType: 'address[]', type: 'address[]' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'member', internalType: 'address', type: 'address' }],
    name: 'removeMember',
    outputs: [],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IssuerAutomatonsFacet
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const issuerAutomatonsFacetABI = [
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresAutomatonsManager',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'automaton', internalType: 'address', type: 'address', indexed: true },
      { name: 'privileges', internalType: 'uint32', type: 'uint32', indexed: true },
    ],
    name: 'AutomatonPrivilegesSet',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'automaton', internalType: 'address', type: 'address', indexed: true }],
    name: 'AutomatonRemoved',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'fast', internalType: 'address', type: 'address', indexed: true },
      { name: 'oldGroup', internalType: 'string', type: 'string', indexed: true },
      { name: 'newGroup', internalType: 'string', type: 'string', indexed: true },
    ],
    name: 'FastGroupChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'fast', internalType: 'address', type: 'address', indexed: true }],
    name: 'FastRegistered',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'fast', internalType: 'address', type: 'address', indexed: true }],
    name: 'FastUnregistered',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'fast', internalType: 'address', type: 'address', indexed: true },
      { name: 'governor', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'GovernorshipAdded',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'fast', internalType: 'address', type: 'address', indexed: true },
      { name: 'governor', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'GovernorshipRemoved',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'automaton', internalType: 'address', type: 'address' },
      { name: 'privilege', internalType: 'uint32', type: 'uint32' },
    ],
    name: 'automatonCan',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'automatonCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'automaton', internalType: 'address', type: 'address' }],
    name: 'automatonPrivileges',
    outputs: [{ name: '', internalType: 'uint32', type: 'uint32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'candidate', internalType: 'address', type: 'address' }],
    name: 'isAutomaton',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'cursor', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateAutomatons',
    outputs: [
      { name: '', internalType: 'address[]', type: 'address[]' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'candidate', internalType: 'address', type: 'address' }],
    name: 'removeAutomaton',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'candidate', internalType: 'address', type: 'address' },
      { name: 'privileges', internalType: 'uint32', type: 'uint32' },
    ],
    name: 'setAutomatonPrivileges',
    outputs: [],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IssuerFrontendFacet
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const issuerFrontendFacetABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'fast', internalType: 'address', type: 'address', indexed: true },
      { name: 'oldGroup', internalType: 'string', type: 'string', indexed: true },
      { name: 'newGroup', internalType: 'string', type: 'string', indexed: true },
    ],
    name: 'FastGroupChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'fast', internalType: 'address', type: 'address', indexed: true }],
    name: 'FastRegistered',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'fast', internalType: 'address', type: 'address', indexed: true }],
    name: 'FastUnregistered',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'fast', internalType: 'address', type: 'address', indexed: true },
      { name: 'governor', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'GovernorshipAdded',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'fast', internalType: 'address', type: 'address', indexed: true },
      { name: 'governor', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'GovernorshipRemoved',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'cursor', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateDetailedFasts',
    outputs: [
      {
        name: '',
        internalType: 'struct FastFrontendFacet.Details[]',
        type: 'tuple[]',
        components: [
          { name: 'addr', internalType: 'address', type: 'address' },
          { name: 'name', internalType: 'string', type: 'string' },
          { name: 'symbol', internalType: 'string', type: 'string' },
          { name: 'decimals', internalType: 'uint8', type: 'uint8' },
          { name: 'totalSupply', internalType: 'uint256', type: 'uint256' },
          { name: 'isSemiPublic', internalType: 'bool', type: 'bool' },
          { name: 'hasFixedSupply', internalType: 'bool', type: 'bool' },
          { name: 'transfersDisabled', internalType: 'bool', type: 'bool' },
          { name: 'crowdfundsDefaultBasisPointsFee', internalType: 'uint32', type: 'uint32' },
          { name: 'reserveBalance', internalType: 'uint256', type: 'uint256' },
          { name: 'memberCount', internalType: 'uint256', type: 'uint256' },
          { name: 'governorCount', internalType: 'uint256', type: 'uint256' },
        ],
      },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'group', internalType: 'string', type: 'string' },
      { name: 'cursor', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateDetailedFastsInGroup',
    outputs: [
      {
        name: '',
        internalType: 'struct FastFrontendFacet.Details[]',
        type: 'tuple[]',
        components: [
          { name: 'addr', internalType: 'address', type: 'address' },
          { name: 'name', internalType: 'string', type: 'string' },
          { name: 'symbol', internalType: 'string', type: 'string' },
          { name: 'decimals', internalType: 'uint8', type: 'uint8' },
          { name: 'totalSupply', internalType: 'uint256', type: 'uint256' },
          { name: 'isSemiPublic', internalType: 'bool', type: 'bool' },
          { name: 'hasFixedSupply', internalType: 'bool', type: 'bool' },
          { name: 'transfersDisabled', internalType: 'bool', type: 'bool' },
          { name: 'crowdfundsDefaultBasisPointsFee', internalType: 'uint32', type: 'uint32' },
          { name: 'reserveBalance', internalType: 'uint256', type: 'uint256' },
          { name: 'memberCount', internalType: 'uint256', type: 'uint256' },
          { name: 'governorCount', internalType: 'uint256', type: 'uint256' },
        ],
      },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IssuerInitFacet
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const issuerInitFacetABI = [
  { type: 'error', inputs: [], name: 'AlreadyInitialized' },
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresDiamondOwnership',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'fast', internalType: 'address', type: 'address', indexed: true },
      { name: 'oldGroup', internalType: 'string', type: 'string', indexed: true },
      { name: 'newGroup', internalType: 'string', type: 'string', indexed: true },
    ],
    name: 'FastGroupChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'fast', internalType: 'address', type: 'address', indexed: true }],
    name: 'FastRegistered',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'fast', internalType: 'address', type: 'address', indexed: true }],
    name: 'FastUnregistered',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'fast', internalType: 'address', type: 'address', indexed: true },
      { name: 'governor', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'GovernorshipAdded',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'fast', internalType: 'address', type: 'address', indexed: true },
      { name: 'governor', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'GovernorshipRemoved',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'member', internalType: 'address', type: 'address', indexed: true }],
    name: 'MemberAdded',
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      {
        name: 'params',
        internalType: 'struct IssuerInitFacet.InitializerParams',
        type: 'tuple',
        components: [{ name: 'member', internalType: 'address', type: 'address' }],
      },
    ],
    name: 'initialize',
    outputs: [],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IssuerTopFacet
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const issuerTopFacetABI = [
  { type: 'error', inputs: [], name: 'DuplicateEntry' },
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresIssuerMembership',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'fast', internalType: 'address', type: 'address', indexed: true },
      { name: 'oldGroup', internalType: 'string', type: 'string', indexed: true },
      { name: 'newGroup', internalType: 'string', type: 'string', indexed: true },
    ],
    name: 'FastGroupChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'fast', internalType: 'address', type: 'address', indexed: true }],
    name: 'FastRegistered',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'fast', internalType: 'address', type: 'address', indexed: true }],
    name: 'FastUnregistered',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'fast', internalType: 'address', type: 'address', indexed: true },
      { name: 'governor', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'GovernorshipAdded',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'fast', internalType: 'address', type: 'address', indexed: true },
      { name: 'governor', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'GovernorshipRemoved',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'symbol', internalType: 'string', type: 'string' }],
    name: 'fastBySymbol',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'fastCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'fast', internalType: 'address', type: 'address' }],
    name: 'isFastRegistered',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'cursor', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateFasts',
    outputs: [
      { name: '', internalType: 'address[]', type: 'address[]' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'group', internalType: 'string', type: 'string' },
      { name: 'cursor', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateFastsInGroup',
    outputs: [
      { name: '', internalType: 'address[]', type: 'address[]' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'fast', internalType: 'address', type: 'address' }],
    name: 'registerFast',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'fast', internalType: 'address', type: 'address' },
      { name: 'newGroup', internalType: 'string', type: 'string' },
    ],
    name: 'setFastGroup',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'token', internalType: 'contract IERC20', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
      { name: 'to', internalType: 'address', type: 'address' },
    ],
    name: 'transferERC20Tokens',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'fast', internalType: 'address', type: 'address' }],
    name: 'unregisterFast',
    outputs: [],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// LibDiamond
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const libDiamondABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: '_diamondCut',
        internalType: 'struct IDiamondCut.FacetCut[]',
        type: 'tuple[]',
        components: [
          { name: 'facetAddress', internalType: 'address', type: 'address' },
          { name: 'action', internalType: 'enum IDiamondCut.FacetCutAction', type: 'uint8' },
          { name: 'functionSelectors', internalType: 'bytes4[]', type: 'bytes4[]' },
        ],
        indexed: false,
      },
      { name: '_init', internalType: 'address', type: 'address', indexed: false },
      { name: '_calldata', internalType: 'bytes', type: 'bytes', indexed: false },
    ],
    name: 'DiamondCut',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'previousOwner', internalType: 'address', type: 'address', indexed: true },
      { name: 'newOwner', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'OwnershipTransferred',
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// MarketplaceAccessFacet
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const marketplaceAccessFacetABI = [
  { type: 'error', inputs: [], name: 'RequiresFastContractCaller' },
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresIssuerMembership',
  },
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresMarketplaceActiveMembership',
  },
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresMarketplaceDeactivatedMember',
  },
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresMarketplaceMembership',
  },
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresMembersManager',
  },
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresNoFastMemberships',
  },
  { type: 'error', inputs: [{ name: 'who', internalType: 'address', type: 'address' }], name: 'RequiresValidMember' },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'member', internalType: 'address', type: 'address', indexed: true }],
    name: 'MemberActivated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'member', internalType: 'address', type: 'address', indexed: true }],
    name: 'MemberAdded',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'member', internalType: 'address', type: 'address', indexed: true }],
    name: 'MemberDeactivated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'member', internalType: 'address', type: 'address', indexed: true }],
    name: 'MemberRemoved',
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'member', internalType: 'address', type: 'address' }],
    name: 'activateMember',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'addMember',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'member', internalType: 'address', type: 'address' }],
    name: 'deactivateMember',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'member', internalType: 'address', type: 'address' },
      { name: 'cursor', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'fastMemberships',
    outputs: [
      { name: '', internalType: 'address[]', type: 'address[]' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'candidate', internalType: 'address', type: 'address' }],
    name: 'isActiveMember',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'isMember',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'member', internalType: 'address', type: 'address' }],
    name: 'memberAddedToFast',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'memberCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'member', internalType: 'address', type: 'address' }],
    name: 'memberRemovedFromFast',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'index', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateMembers',
    outputs: [
      { name: '', internalType: 'address[]', type: 'address[]' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'member', internalType: 'address', type: 'address' }],
    name: 'removeMember',
    outputs: [],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// MarketplaceAutomatonsFacet
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const marketplaceAutomatonsFacetABI = [
  {
    type: 'error',
    inputs: [{ name: 'who', internalType: 'address', type: 'address' }],
    name: 'RequiresAutomatonsManager',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'automaton', internalType: 'address', type: 'address', indexed: true },
      { name: 'privileges', internalType: 'uint32', type: 'uint32', indexed: true },
    ],
    name: 'AutomatonPrivilegesSet',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'automaton', internalType: 'address', type: 'address', indexed: true }],
    name: 'AutomatonRemoved',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'member', internalType: 'address', type: 'address', indexed: true }],
    name: 'MemberActivated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'member', internalType: 'address', type: 'address', indexed: true }],
    name: 'MemberDeactivated',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'automaton', internalType: 'address', type: 'address' },
      { name: 'privilege', internalType: 'uint32', type: 'uint32' },
    ],
    name: 'automatonCan',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'automatonCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'automaton', internalType: 'address', type: 'address' }],
    name: 'automatonPrivileges',
    outputs: [{ name: '', internalType: 'uint32', type: 'uint32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'candidate', internalType: 'address', type: 'address' }],
    name: 'isAutomaton',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'cursor', internalType: 'uint256', type: 'uint256' },
      { name: 'perPage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'paginateAutomatons',
    outputs: [
      { name: '', internalType: 'address[]', type: 'address[]' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'candidate', internalType: 'address', type: 'address' }],
    name: 'removeAutomaton',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'candidate', internalType: 'address', type: 'address' },
      { name: 'privileges', internalType: 'uint32', type: 'uint32' },
    ],
    name: 'setAutomatonPrivileges',
    outputs: [],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// MarketplaceInitFacet
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const marketplaceInitFacetABI = [
  { type: 'error', inputs: [], name: 'AlreadyInitialized' },
  { type: 'error', inputs: [], name: 'InternalMethod' },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'member', internalType: 'address', type: 'address', indexed: true }],
    name: 'MemberActivated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'member', internalType: 'address', type: 'address', indexed: true }],
    name: 'MemberDeactivated',
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      {
        name: 'params',
        internalType: 'struct MarketplaceInitFacet.InitializerParams',
        type: 'tuple',
        components: [{ name: 'issuer', internalType: 'address', type: 'address' }],
      },
    ],
    name: 'initialize',
    outputs: [],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// MarketplaceTokenHoldersFacet
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const marketplaceTokenHoldersFacetABI = [
  { type: 'error', inputs: [], name: 'RequiresFastContractCaller' },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'member', internalType: 'address', type: 'address', indexed: true }],
    name: 'MemberActivated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'member', internalType: 'address', type: 'address', indexed: true }],
    name: 'MemberDeactivated',
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'account', internalType: 'address', type: 'address' },
      { name: 'balance', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'fastBalanceChanged',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'holdings',
    outputs: [{ name: '', internalType: 'address[]', type: 'address[]' }],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// MarketplaceTopFacet
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const marketplaceTopFacetABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'member', internalType: 'address', type: 'address', indexed: true }],
    name: 'MemberActivated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'member', internalType: 'address', type: 'address', indexed: true }],
    name: 'MemberDeactivated',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'issuerAddress',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// AFastFacet
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const aFastFacetABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: 'ref', internalType: 'string', type: 'string', indexed: false },
      { name: 'who', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'Burnt',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'basisPointFee', internalType: 'uint32', type: 'uint32', indexed: true }],
    name: 'CrowdfundDefaultBasisPointFeeSet',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'crowdfund', internalType: 'contract Crowdfund', type: 'address', indexed: true }],
    name: 'CrowdfundDeployed',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'crowdfund', internalType: 'contract Crowdfund', type: 'address', indexed: true }],
    name: 'CrowdfundRemoved',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'transfersDisabled', internalType: 'bool', type: 'bool', indexed: false },
      { name: 'crowdfundsDefaultBasisPointsFee', internalType: 'uint32', type: 'uint32', indexed: false },
      { name: 'memberCount', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'governorCount', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'totalSupply', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'reserveBalance', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'DetailsChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Disapproval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'distribution', internalType: 'contract Distribution', type: 'address', indexed: true }],
    name: 'DistributionDeployed',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'distribution', internalType: 'contract Distribution', type: 'address', indexed: true }],
    name: 'DistributionRemoved',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'ref', internalType: 'string', type: 'string', indexed: false },
    ],
    name: 'FastTransfer',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: 'ref', internalType: 'string', type: 'string', indexed: false },
      { name: 'who', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'Minted',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Transfer',
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IFastEvents
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const iFastEventsABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: 'ref', internalType: 'string', type: 'string', indexed: false },
      { name: 'who', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'Burnt',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'basisPointFee', internalType: 'uint32', type: 'uint32', indexed: true }],
    name: 'CrowdfundDefaultBasisPointFeeSet',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'crowdfund', internalType: 'contract Crowdfund', type: 'address', indexed: true }],
    name: 'CrowdfundDeployed',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'crowdfund', internalType: 'contract Crowdfund', type: 'address', indexed: true }],
    name: 'CrowdfundRemoved',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'transfersDisabled', internalType: 'bool', type: 'bool', indexed: false },
      { name: 'crowdfundsDefaultBasisPointsFee', internalType: 'uint32', type: 'uint32', indexed: false },
      { name: 'memberCount', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'governorCount', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'totalSupply', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'reserveBalance', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'DetailsChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Disapproval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'distribution', internalType: 'contract Distribution', type: 'address', indexed: true }],
    name: 'DistributionDeployed',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'distribution', internalType: 'contract Distribution', type: 'address', indexed: true }],
    name: 'DistributionRemoved',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'ref', internalType: 'string', type: 'string', indexed: false },
    ],
    name: 'FastTransfer',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: 'ref', internalType: 'string', type: 'string', indexed: false },
      { name: 'who', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'Minted',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Transfer',
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// AIssuerFacet
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const aIssuerFacetABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'fast', internalType: 'address', type: 'address', indexed: true },
      { name: 'oldGroup', internalType: 'string', type: 'string', indexed: true },
      { name: 'newGroup', internalType: 'string', type: 'string', indexed: true },
    ],
    name: 'FastGroupChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'fast', internalType: 'address', type: 'address', indexed: true }],
    name: 'FastRegistered',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'fast', internalType: 'address', type: 'address', indexed: true }],
    name: 'FastUnregistered',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'fast', internalType: 'address', type: 'address', indexed: true },
      { name: 'governor', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'GovernorshipAdded',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'fast', internalType: 'address', type: 'address', indexed: true },
      { name: 'governor', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'GovernorshipRemoved',
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IIssuerEvents
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const iIssuerEventsABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'fast', internalType: 'address', type: 'address', indexed: true },
      { name: 'oldGroup', internalType: 'string', type: 'string', indexed: true },
      { name: 'newGroup', internalType: 'string', type: 'string', indexed: true },
    ],
    name: 'FastGroupChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'fast', internalType: 'address', type: 'address', indexed: true }],
    name: 'FastRegistered',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'fast', internalType: 'address', type: 'address', indexed: true }],
    name: 'FastUnregistered',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'fast', internalType: 'address', type: 'address', indexed: true },
      { name: 'governor', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'GovernorshipAdded',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'fast', internalType: 'address', type: 'address', indexed: true },
      { name: 'governor', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'GovernorshipRemoved',
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// AMarketplaceFacet
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const aMarketplaceFacetABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'member', internalType: 'address', type: 'address', indexed: true }],
    name: 'MemberActivated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'member', internalType: 'address', type: 'address', indexed: true }],
    name: 'MemberDeactivated',
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IMarketplaceEvents
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const iMarketplaceEventsABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'member', internalType: 'address', type: 'address', indexed: true }],
    name: 'MemberActivated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'member', internalType: 'address', type: 'address', indexed: true }],
    name: 'MemberDeactivated',
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// React
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link fastABI}__.
 */
export function useFastRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof fastABI, TFunctionName>,
>(config: Omit<UseContractReadConfig<typeof fastABI, TFunctionName, TSelectData>, 'abi'> = {} as any) {
  return useContractRead({ abi: fastABI, ...config } as UseContractReadConfig<
    typeof fastABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link fastABI}__.
 */
export function useFastWrite<TFunctionName extends string, TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<PrepareWriteContractResult<typeof fastABI, string>['request']['abi'], TFunctionName, TMode>
    : UseContractWriteConfig<typeof fastABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof fastABI, TFunctionName, TMode>({ abi: fastABI, ...config } as any);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastABI}__.
 */
export function useFastEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof fastABI, TEventName>, 'abi'> = {} as any,
) {
  return useContractEvent({ abi: fastABI, ...config } as UseContractEventConfig<typeof fastABI, TEventName>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastABI}__ and `eventName` set to `"GovernorAdded"`.
 */
export function useFastGovernorAddedEvent(
  config: Omit<UseContractEventConfig<typeof fastABI, 'GovernorAdded'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastABI, eventName: 'GovernorAdded', ...config } as UseContractEventConfig<
    typeof fastABI,
    'GovernorAdded'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastABI}__ and `eventName` set to `"GovernorRemoved"`.
 */
export function useFastGovernorRemovedEvent(
  config: Omit<UseContractEventConfig<typeof fastABI, 'GovernorRemoved'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastABI, eventName: 'GovernorRemoved', ...config } as UseContractEventConfig<
    typeof fastABI,
    'GovernorRemoved'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastABI}__ and `eventName` set to `"MemberAdded"`.
 */
export function useFastMemberAddedEvent(
  config: Omit<UseContractEventConfig<typeof fastABI, 'MemberAdded'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastABI, eventName: 'MemberAdded', ...config } as UseContractEventConfig<
    typeof fastABI,
    'MemberAdded'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastABI}__ and `eventName` set to `"MemberRemoved"`.
 */
export function useFastMemberRemovedEvent(
  config: Omit<UseContractEventConfig<typeof fastABI, 'MemberRemoved'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastABI, eventName: 'MemberRemoved', ...config } as UseContractEventConfig<
    typeof fastABI,
    'MemberRemoved'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastABI}__ and `eventName` set to `"AutomatonPrivilegesSet"`.
 */
export function useFastAutomatonPrivilegesSetEvent(
  config: Omit<UseContractEventConfig<typeof fastABI, 'AutomatonPrivilegesSet'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastABI, eventName: 'AutomatonPrivilegesSet', ...config } as UseContractEventConfig<
    typeof fastABI,
    'AutomatonPrivilegesSet'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastABI}__ and `eventName` set to `"AutomatonRemoved"`.
 */
export function useFastAutomatonRemovedEvent(
  config: Omit<UseContractEventConfig<typeof fastABI, 'AutomatonRemoved'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastABI, eventName: 'AutomatonRemoved', ...config } as UseContractEventConfig<
    typeof fastABI,
    'AutomatonRemoved'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastABI}__ and `eventName` set to `"Approval"`.
 */
export function useFastApprovalEvent(
  config: Omit<UseContractEventConfig<typeof fastABI, 'Approval'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastABI, eventName: 'Approval', ...config } as UseContractEventConfig<
    typeof fastABI,
    'Approval'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastABI}__ and `eventName` set to `"Burnt"`.
 */
export function useFastBurntEvent(
  config: Omit<UseContractEventConfig<typeof fastABI, 'Burnt'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastABI, eventName: 'Burnt', ...config } as UseContractEventConfig<
    typeof fastABI,
    'Burnt'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastABI}__ and `eventName` set to `"CrowdfundDefaultBasisPointFeeSet"`.
 */
export function useFastCrowdfundDefaultBasisPointFeeSetEvent(
  config: Omit<
    UseContractEventConfig<typeof fastABI, 'CrowdfundDefaultBasisPointFeeSet'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: fastABI,
    eventName: 'CrowdfundDefaultBasisPointFeeSet',
    ...config,
  } as UseContractEventConfig<typeof fastABI, 'CrowdfundDefaultBasisPointFeeSet'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastABI}__ and `eventName` set to `"CrowdfundDeployed"`.
 */
export function useFastCrowdfundDeployedEvent(
  config: Omit<UseContractEventConfig<typeof fastABI, 'CrowdfundDeployed'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastABI, eventName: 'CrowdfundDeployed', ...config } as UseContractEventConfig<
    typeof fastABI,
    'CrowdfundDeployed'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastABI}__ and `eventName` set to `"CrowdfundRemoved"`.
 */
export function useFastCrowdfundRemovedEvent(
  config: Omit<UseContractEventConfig<typeof fastABI, 'CrowdfundRemoved'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastABI, eventName: 'CrowdfundRemoved', ...config } as UseContractEventConfig<
    typeof fastABI,
    'CrowdfundRemoved'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastABI}__ and `eventName` set to `"DetailsChanged"`.
 */
export function useFastDetailsChangedEvent(
  config: Omit<UseContractEventConfig<typeof fastABI, 'DetailsChanged'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastABI, eventName: 'DetailsChanged', ...config } as UseContractEventConfig<
    typeof fastABI,
    'DetailsChanged'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastABI}__ and `eventName` set to `"Disapproval"`.
 */
export function useFastDisapprovalEvent(
  config: Omit<UseContractEventConfig<typeof fastABI, 'Disapproval'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastABI, eventName: 'Disapproval', ...config } as UseContractEventConfig<
    typeof fastABI,
    'Disapproval'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastABI}__ and `eventName` set to `"DistributionDeployed"`.
 */
export function useFastDistributionDeployedEvent(
  config: Omit<UseContractEventConfig<typeof fastABI, 'DistributionDeployed'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastABI, eventName: 'DistributionDeployed', ...config } as UseContractEventConfig<
    typeof fastABI,
    'DistributionDeployed'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastABI}__ and `eventName` set to `"DistributionRemoved"`.
 */
export function useFastDistributionRemovedEvent(
  config: Omit<UseContractEventConfig<typeof fastABI, 'DistributionRemoved'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastABI, eventName: 'DistributionRemoved', ...config } as UseContractEventConfig<
    typeof fastABI,
    'DistributionRemoved'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastABI}__ and `eventName` set to `"FastTransfer"`.
 */
export function useFastFastTransferEvent(
  config: Omit<UseContractEventConfig<typeof fastABI, 'FastTransfer'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastABI, eventName: 'FastTransfer', ...config } as UseContractEventConfig<
    typeof fastABI,
    'FastTransfer'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastABI}__ and `eventName` set to `"Minted"`.
 */
export function useFastMintedEvent(
  config: Omit<UseContractEventConfig<typeof fastABI, 'Minted'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastABI, eventName: 'Minted', ...config } as UseContractEventConfig<
    typeof fastABI,
    'Minted'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastABI}__ and `eventName` set to `"Transfer"`.
 */
export function useFastTransferEvent(
  config: Omit<UseContractEventConfig<typeof fastABI, 'Transfer'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastABI, eventName: 'Transfer', ...config } as UseContractEventConfig<
    typeof fastABI,
    'Transfer'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastABI}__ and `eventName` set to `"DiamondCut"`.
 */
export function useFastDiamondCutEvent(
  config: Omit<UseContractEventConfig<typeof fastABI, 'DiamondCut'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastABI, eventName: 'DiamondCut', ...config } as UseContractEventConfig<
    typeof fastABI,
    'DiamondCut'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastABI}__ and `eventName` set to `"OwnershipTransferred"`.
 */
export function useFastOwnershipTransferredEvent(
  config: Omit<UseContractEventConfig<typeof fastABI, 'OwnershipTransferred'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastABI, eventName: 'OwnershipTransferred', ...config } as UseContractEventConfig<
    typeof fastABI,
    'OwnershipTransferred'
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link issuerABI}__.
 */
export function useIssuerRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof issuerABI, TFunctionName>,
>(config: Omit<UseContractReadConfig<typeof issuerABI, TFunctionName, TSelectData>, 'abi'> = {} as any) {
  return useContractRead({ abi: issuerABI, ...config } as UseContractReadConfig<
    typeof issuerABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link issuerABI}__.
 */
export function useIssuerWrite<TFunctionName extends string, TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof issuerABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof issuerABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof issuerABI, TFunctionName, TMode>({ abi: issuerABI, ...config } as any);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link issuerABI}__.
 */
export function useIssuerEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof issuerABI, TEventName>, 'abi'> = {} as any,
) {
  return useContractEvent({ abi: issuerABI, ...config } as UseContractEventConfig<typeof issuerABI, TEventName>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link issuerABI}__ and `eventName` set to `"DiamondCut"`.
 */
export function useIssuerDiamondCutEvent(
  config: Omit<UseContractEventConfig<typeof issuerABI, 'DiamondCut'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: issuerABI, eventName: 'DiamondCut', ...config } as UseContractEventConfig<
    typeof issuerABI,
    'DiamondCut'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link issuerABI}__ and `eventName` set to `"OwnershipTransferred"`.
 */
export function useIssuerOwnershipTransferredEvent(
  config: Omit<UseContractEventConfig<typeof issuerABI, 'OwnershipTransferred'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: issuerABI, eventName: 'OwnershipTransferred', ...config } as UseContractEventConfig<
    typeof issuerABI,
    'OwnershipTransferred'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link issuerABI}__ and `eventName` set to `"MemberAdded"`.
 */
export function useIssuerMemberAddedEvent(
  config: Omit<UseContractEventConfig<typeof issuerABI, 'MemberAdded'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: issuerABI, eventName: 'MemberAdded', ...config } as UseContractEventConfig<
    typeof issuerABI,
    'MemberAdded'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link issuerABI}__ and `eventName` set to `"MemberRemoved"`.
 */
export function useIssuerMemberRemovedEvent(
  config: Omit<UseContractEventConfig<typeof issuerABI, 'MemberRemoved'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: issuerABI, eventName: 'MemberRemoved', ...config } as UseContractEventConfig<
    typeof issuerABI,
    'MemberRemoved'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link issuerABI}__ and `eventName` set to `"AutomatonPrivilegesSet"`.
 */
export function useIssuerAutomatonPrivilegesSetEvent(
  config: Omit<UseContractEventConfig<typeof issuerABI, 'AutomatonPrivilegesSet'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: issuerABI, eventName: 'AutomatonPrivilegesSet', ...config } as UseContractEventConfig<
    typeof issuerABI,
    'AutomatonPrivilegesSet'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link issuerABI}__ and `eventName` set to `"AutomatonRemoved"`.
 */
export function useIssuerAutomatonRemovedEvent(
  config: Omit<UseContractEventConfig<typeof issuerABI, 'AutomatonRemoved'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: issuerABI, eventName: 'AutomatonRemoved', ...config } as UseContractEventConfig<
    typeof issuerABI,
    'AutomatonRemoved'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link issuerABI}__ and `eventName` set to `"FastGroupChanged"`.
 */
export function useIssuerFastGroupChangedEvent(
  config: Omit<UseContractEventConfig<typeof issuerABI, 'FastGroupChanged'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: issuerABI, eventName: 'FastGroupChanged', ...config } as UseContractEventConfig<
    typeof issuerABI,
    'FastGroupChanged'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link issuerABI}__ and `eventName` set to `"FastRegistered"`.
 */
export function useIssuerFastRegisteredEvent(
  config: Omit<UseContractEventConfig<typeof issuerABI, 'FastRegistered'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: issuerABI, eventName: 'FastRegistered', ...config } as UseContractEventConfig<
    typeof issuerABI,
    'FastRegistered'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link issuerABI}__ and `eventName` set to `"FastUnregistered"`.
 */
export function useIssuerFastUnregisteredEvent(
  config: Omit<UseContractEventConfig<typeof issuerABI, 'FastUnregistered'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: issuerABI, eventName: 'FastUnregistered', ...config } as UseContractEventConfig<
    typeof issuerABI,
    'FastUnregistered'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link issuerABI}__ and `eventName` set to `"GovernorshipAdded"`.
 */
export function useIssuerGovernorshipAddedEvent(
  config: Omit<UseContractEventConfig<typeof issuerABI, 'GovernorshipAdded'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: issuerABI, eventName: 'GovernorshipAdded', ...config } as UseContractEventConfig<
    typeof issuerABI,
    'GovernorshipAdded'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link issuerABI}__ and `eventName` set to `"GovernorshipRemoved"`.
 */
export function useIssuerGovernorshipRemovedEvent(
  config: Omit<UseContractEventConfig<typeof issuerABI, 'GovernorshipRemoved'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: issuerABI, eventName: 'GovernorshipRemoved', ...config } as UseContractEventConfig<
    typeof issuerABI,
    'GovernorshipRemoved'
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link marketplaceABI}__.
 */
export function useMarketplaceRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof marketplaceABI, TFunctionName>,
>(config: Omit<UseContractReadConfig<typeof marketplaceABI, TFunctionName, TSelectData>, 'abi'> = {} as any) {
  return useContractRead({ abi: marketplaceABI, ...config } as UseContractReadConfig<
    typeof marketplaceABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link marketplaceABI}__.
 */
export function useMarketplaceWrite<TFunctionName extends string, TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof marketplaceABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof marketplaceABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof marketplaceABI, TFunctionName, TMode>({ abi: marketplaceABI, ...config } as any);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link marketplaceABI}__.
 */
export function useMarketplaceEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof marketplaceABI, TEventName>, 'abi'> = {} as any,
) {
  return useContractEvent({ abi: marketplaceABI, ...config } as UseContractEventConfig<
    typeof marketplaceABI,
    TEventName
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link marketplaceABI}__ and `eventName` set to `"DiamondCut"`.
 */
export function useMarketplaceDiamondCutEvent(
  config: Omit<UseContractEventConfig<typeof marketplaceABI, 'DiamondCut'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: marketplaceABI, eventName: 'DiamondCut', ...config } as UseContractEventConfig<
    typeof marketplaceABI,
    'DiamondCut'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link marketplaceABI}__ and `eventName` set to `"OwnershipTransferred"`.
 */
export function useMarketplaceOwnershipTransferredEvent(
  config: Omit<UseContractEventConfig<typeof marketplaceABI, 'OwnershipTransferred'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({
    abi: marketplaceABI,
    eventName: 'OwnershipTransferred',
    ...config,
  } as UseContractEventConfig<typeof marketplaceABI, 'OwnershipTransferred'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link marketplaceABI}__ and `eventName` set to `"MemberAdded"`.
 */
export function useMarketplaceMemberAddedEvent(
  config: Omit<UseContractEventConfig<typeof marketplaceABI, 'MemberAdded'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: marketplaceABI, eventName: 'MemberAdded', ...config } as UseContractEventConfig<
    typeof marketplaceABI,
    'MemberAdded'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link marketplaceABI}__ and `eventName` set to `"MemberRemoved"`.
 */
export function useMarketplaceMemberRemovedEvent(
  config: Omit<UseContractEventConfig<typeof marketplaceABI, 'MemberRemoved'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: marketplaceABI, eventName: 'MemberRemoved', ...config } as UseContractEventConfig<
    typeof marketplaceABI,
    'MemberRemoved'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link marketplaceABI}__ and `eventName` set to `"AutomatonPrivilegesSet"`.
 */
export function useMarketplaceAutomatonPrivilegesSetEvent(
  config: Omit<
    UseContractEventConfig<typeof marketplaceABI, 'AutomatonPrivilegesSet'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: marketplaceABI,
    eventName: 'AutomatonPrivilegesSet',
    ...config,
  } as UseContractEventConfig<typeof marketplaceABI, 'AutomatonPrivilegesSet'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link marketplaceABI}__ and `eventName` set to `"AutomatonRemoved"`.
 */
export function useMarketplaceAutomatonRemovedEvent(
  config: Omit<UseContractEventConfig<typeof marketplaceABI, 'AutomatonRemoved'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: marketplaceABI, eventName: 'AutomatonRemoved', ...config } as UseContractEventConfig<
    typeof marketplaceABI,
    'AutomatonRemoved'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link marketplaceABI}__ and `eventName` set to `"MemberActivated"`.
 */
export function useMarketplaceMemberActivatedEvent(
  config: Omit<UseContractEventConfig<typeof marketplaceABI, 'MemberActivated'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: marketplaceABI, eventName: 'MemberActivated', ...config } as UseContractEventConfig<
    typeof marketplaceABI,
    'MemberActivated'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link marketplaceABI}__ and `eventName` set to `"MemberDeactivated"`.
 */
export function useMarketplaceMemberDeactivatedEvent(
  config: Omit<UseContractEventConfig<typeof marketplaceABI, 'MemberDeactivated'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: marketplaceABI, eventName: 'MemberDeactivated', ...config } as UseContractEventConfig<
    typeof marketplaceABI,
    'MemberDeactivated'
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link aHasAutomatonsABI}__.
 */
export function useAHasAutomatonsRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof aHasAutomatonsABI, TFunctionName>,
>(config: Omit<UseContractReadConfig<typeof aHasAutomatonsABI, TFunctionName, TSelectData>, 'abi'> = {} as any) {
  return useContractRead({ abi: aHasAutomatonsABI, ...config } as UseContractReadConfig<
    typeof aHasAutomatonsABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link aHasAutomatonsABI}__.
 */
export function useAHasAutomatonsWrite<TFunctionName extends string, TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof aHasAutomatonsABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof aHasAutomatonsABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof aHasAutomatonsABI, TFunctionName, TMode>({ abi: aHasAutomatonsABI, ...config } as any);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link aHasAutomatonsABI}__.
 */
export function useAHasAutomatonsEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof aHasAutomatonsABI, TEventName>, 'abi'> = {} as any,
) {
  return useContractEvent({ abi: aHasAutomatonsABI, ...config } as UseContractEventConfig<
    typeof aHasAutomatonsABI,
    TEventName
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link aHasAutomatonsABI}__ and `eventName` set to `"AutomatonPrivilegesSet"`.
 */
export function useAHasAutomatonsAutomatonPrivilegesSetEvent(
  config: Omit<
    UseContractEventConfig<typeof aHasAutomatonsABI, 'AutomatonPrivilegesSet'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: aHasAutomatonsABI,
    eventName: 'AutomatonPrivilegesSet',
    ...config,
  } as UseContractEventConfig<typeof aHasAutomatonsABI, 'AutomatonPrivilegesSet'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link aHasAutomatonsABI}__ and `eventName` set to `"AutomatonRemoved"`.
 */
export function useAHasAutomatonsAutomatonRemovedEvent(
  config: Omit<UseContractEventConfig<typeof aHasAutomatonsABI, 'AutomatonRemoved'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({
    abi: aHasAutomatonsABI,
    eventName: 'AutomatonRemoved',
    ...config,
  } as UseContractEventConfig<typeof aHasAutomatonsABI, 'AutomatonRemoved'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link aHasGovernorsABI}__.
 */
export function useAHasGovernorsRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof aHasGovernorsABI, TFunctionName>,
>(config: Omit<UseContractReadConfig<typeof aHasGovernorsABI, TFunctionName, TSelectData>, 'abi'> = {} as any) {
  return useContractRead({ abi: aHasGovernorsABI, ...config } as UseContractReadConfig<
    typeof aHasGovernorsABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link aHasGovernorsABI}__.
 */
export function useAHasGovernorsWrite<TFunctionName extends string, TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof aHasGovernorsABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof aHasGovernorsABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof aHasGovernorsABI, TFunctionName, TMode>({ abi: aHasGovernorsABI, ...config } as any);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link aHasGovernorsABI}__.
 */
export function useAHasGovernorsEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof aHasGovernorsABI, TEventName>, 'abi'> = {} as any,
) {
  return useContractEvent({ abi: aHasGovernorsABI, ...config } as UseContractEventConfig<
    typeof aHasGovernorsABI,
    TEventName
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link aHasGovernorsABI}__ and `eventName` set to `"GovernorAdded"`.
 */
export function useAHasGovernorsGovernorAddedEvent(
  config: Omit<UseContractEventConfig<typeof aHasGovernorsABI, 'GovernorAdded'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: aHasGovernorsABI, eventName: 'GovernorAdded', ...config } as UseContractEventConfig<
    typeof aHasGovernorsABI,
    'GovernorAdded'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link aHasGovernorsABI}__ and `eventName` set to `"GovernorRemoved"`.
 */
export function useAHasGovernorsGovernorRemovedEvent(
  config: Omit<UseContractEventConfig<typeof aHasGovernorsABI, 'GovernorRemoved'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: aHasGovernorsABI, eventName: 'GovernorRemoved', ...config } as UseContractEventConfig<
    typeof aHasGovernorsABI,
    'GovernorRemoved'
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link aHasMembersABI}__.
 */
export function useAHasMembersRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof aHasMembersABI, TFunctionName>,
>(config: Omit<UseContractReadConfig<typeof aHasMembersABI, TFunctionName, TSelectData>, 'abi'> = {} as any) {
  return useContractRead({ abi: aHasMembersABI, ...config } as UseContractReadConfig<
    typeof aHasMembersABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link aHasMembersABI}__.
 */
export function useAHasMembersWrite<TFunctionName extends string, TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof aHasMembersABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof aHasMembersABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof aHasMembersABI, TFunctionName, TMode>({ abi: aHasMembersABI, ...config } as any);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link aHasMembersABI}__.
 */
export function useAHasMembersEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof aHasMembersABI, TEventName>, 'abi'> = {} as any,
) {
  return useContractEvent({ abi: aHasMembersABI, ...config } as UseContractEventConfig<
    typeof aHasMembersABI,
    TEventName
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link aHasMembersABI}__ and `eventName` set to `"MemberAdded"`.
 */
export function useAHasMembersMemberAddedEvent(
  config: Omit<UseContractEventConfig<typeof aHasMembersABI, 'MemberAdded'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: aHasMembersABI, eventName: 'MemberAdded', ...config } as UseContractEventConfig<
    typeof aHasMembersABI,
    'MemberAdded'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link aHasMembersABI}__ and `eventName` set to `"MemberRemoved"`.
 */
export function useAHasMembersMemberRemovedEvent(
  config: Omit<UseContractEventConfig<typeof aHasMembersABI, 'MemberRemoved'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: aHasMembersABI, eventName: 'MemberRemoved', ...config } as UseContractEventConfig<
    typeof aHasMembersABI,
    'MemberRemoved'
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20ABI}__.
 */
export function useErc20Read<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof erc20ABI, TFunctionName>,
>(config: Omit<UseContractReadConfig<typeof erc20ABI, TFunctionName, TSelectData>, 'abi'> = {} as any) {
  return useContractRead({ abi: erc20ABI, ...config } as UseContractReadConfig<
    typeof erc20ABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20ABI}__.
 */
export function useErc20Write<TFunctionName extends string, TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof erc20ABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof erc20ABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof erc20ABI, TFunctionName, TMode>({ abi: erc20ABI, ...config } as any);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link crowdfundABI}__.
 */
export function useCrowdfundRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof crowdfundABI, TFunctionName>,
>(config: Omit<UseContractReadConfig<typeof crowdfundABI, TFunctionName, TSelectData>, 'abi'> = {} as any) {
  return useContractRead({ abi: crowdfundABI, ...config } as UseContractReadConfig<
    typeof crowdfundABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link crowdfundABI}__.
 */
export function useCrowdfundWrite<TFunctionName extends string, TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof crowdfundABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof crowdfundABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof crowdfundABI, TFunctionName, TMode>({ abi: crowdfundABI, ...config } as any);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link crowdfundABI}__.
 */
export function useCrowdfundEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof crowdfundABI, TEventName>, 'abi'> = {} as any,
) {
  return useContractEvent({ abi: crowdfundABI, ...config } as UseContractEventConfig<typeof crowdfundABI, TEventName>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link crowdfundABI}__ and `eventName` set to `"Advance"`.
 */
export function useCrowdfundAdvanceEvent(
  config: Omit<UseContractEventConfig<typeof crowdfundABI, 'Advance'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: crowdfundABI, eventName: 'Advance', ...config } as UseContractEventConfig<
    typeof crowdfundABI,
    'Advance'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link crowdfundABI}__ and `eventName` set to `"Pledge"`.
 */
export function useCrowdfundPledgeEvent(
  config: Omit<UseContractEventConfig<typeof crowdfundABI, 'Pledge'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: crowdfundABI, eventName: 'Pledge', ...config } as UseContractEventConfig<
    typeof crowdfundABI,
    'Pledge'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link crowdfundABI}__ and `eventName` set to `"Terminated"`.
 */
export function useCrowdfundTerminatedEvent(
  config: Omit<UseContractEventConfig<typeof crowdfundABI, 'Terminated'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: crowdfundABI, eventName: 'Terminated', ...config } as UseContractEventConfig<
    typeof crowdfundABI,
    'Terminated'
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link distributionABI}__.
 */
export function useDistributionRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof distributionABI, TFunctionName>,
>(config: Omit<UseContractReadConfig<typeof distributionABI, TFunctionName, TSelectData>, 'abi'> = {} as any) {
  return useContractRead({ abi: distributionABI, ...config } as UseContractReadConfig<
    typeof distributionABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link distributionABI}__.
 */
export function useDistributionWrite<TFunctionName extends string, TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof distributionABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof distributionABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof distributionABI, TFunctionName, TMode>({ abi: distributionABI, ...config } as any);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link distributionABI}__.
 */
export function useDistributionEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof distributionABI, TEventName>, 'abi'> = {} as any,
) {
  return useContractEvent({ abi: distributionABI, ...config } as UseContractEventConfig<
    typeof distributionABI,
    TEventName
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link distributionABI}__ and `eventName` set to `"Advance"`.
 */
export function useDistributionAdvanceEvent(
  config: Omit<UseContractEventConfig<typeof distributionABI, 'Advance'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: distributionABI, eventName: 'Advance', ...config } as UseContractEventConfig<
    typeof distributionABI,
    'Advance'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link distributionABI}__ and `eventName` set to `"BeneficiaryAdded"`.
 */
export function useDistributionBeneficiaryAddedEvent(
  config: Omit<UseContractEventConfig<typeof distributionABI, 'BeneficiaryAdded'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: distributionABI, eventName: 'BeneficiaryAdded', ...config } as UseContractEventConfig<
    typeof distributionABI,
    'BeneficiaryAdded'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link distributionABI}__ and `eventName` set to `"BeneficiaryRemoved"`.
 */
export function useDistributionBeneficiaryRemovedEvent(
  config: Omit<UseContractEventConfig<typeof distributionABI, 'BeneficiaryRemoved'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({
    abi: distributionABI,
    eventName: 'BeneficiaryRemoved',
    ...config,
  } as UseContractEventConfig<typeof distributionABI, 'BeneficiaryRemoved'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link distributionABI}__ and `eventName` set to `"Withdrawal"`.
 */
export function useDistributionWithdrawalEvent(
  config: Omit<UseContractEventConfig<typeof distributionABI, 'Withdrawal'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: distributionABI, eventName: 'Withdrawal', ...config } as UseContractEventConfig<
    typeof distributionABI,
    'Withdrawal'
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link fastAccessFacetABI}__.
 */
export function useFastAccessFacetRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof fastAccessFacetABI, TFunctionName>,
>(config: Omit<UseContractReadConfig<typeof fastAccessFacetABI, TFunctionName, TSelectData>, 'abi'> = {} as any) {
  return useContractRead({ abi: fastAccessFacetABI, ...config } as UseContractReadConfig<
    typeof fastAccessFacetABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link fastAccessFacetABI}__.
 */
export function useFastAccessFacetWrite<TFunctionName extends string, TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof fastAccessFacetABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof fastAccessFacetABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof fastAccessFacetABI, TFunctionName, TMode>({
    abi: fastAccessFacetABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastAccessFacetABI}__.
 */
export function useFastAccessFacetEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof fastAccessFacetABI, TEventName>, 'abi'> = {} as any,
) {
  return useContractEvent({ abi: fastAccessFacetABI, ...config } as UseContractEventConfig<
    typeof fastAccessFacetABI,
    TEventName
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastAccessFacetABI}__ and `eventName` set to `"Approval"`.
 */
export function useFastAccessFacetApprovalEvent(
  config: Omit<UseContractEventConfig<typeof fastAccessFacetABI, 'Approval'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastAccessFacetABI, eventName: 'Approval', ...config } as UseContractEventConfig<
    typeof fastAccessFacetABI,
    'Approval'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastAccessFacetABI}__ and `eventName` set to `"Burnt"`.
 */
export function useFastAccessFacetBurntEvent(
  config: Omit<UseContractEventConfig<typeof fastAccessFacetABI, 'Burnt'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastAccessFacetABI, eventName: 'Burnt', ...config } as UseContractEventConfig<
    typeof fastAccessFacetABI,
    'Burnt'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastAccessFacetABI}__ and `eventName` set to `"CrowdfundDefaultBasisPointFeeSet"`.
 */
export function useFastAccessFacetCrowdfundDefaultBasisPointFeeSetEvent(
  config: Omit<
    UseContractEventConfig<typeof fastAccessFacetABI, 'CrowdfundDefaultBasisPointFeeSet'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: fastAccessFacetABI,
    eventName: 'CrowdfundDefaultBasisPointFeeSet',
    ...config,
  } as UseContractEventConfig<typeof fastAccessFacetABI, 'CrowdfundDefaultBasisPointFeeSet'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastAccessFacetABI}__ and `eventName` set to `"CrowdfundDeployed"`.
 */
export function useFastAccessFacetCrowdfundDeployedEvent(
  config: Omit<UseContractEventConfig<typeof fastAccessFacetABI, 'CrowdfundDeployed'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({
    abi: fastAccessFacetABI,
    eventName: 'CrowdfundDeployed',
    ...config,
  } as UseContractEventConfig<typeof fastAccessFacetABI, 'CrowdfundDeployed'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastAccessFacetABI}__ and `eventName` set to `"CrowdfundRemoved"`.
 */
export function useFastAccessFacetCrowdfundRemovedEvent(
  config: Omit<UseContractEventConfig<typeof fastAccessFacetABI, 'CrowdfundRemoved'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({
    abi: fastAccessFacetABI,
    eventName: 'CrowdfundRemoved',
    ...config,
  } as UseContractEventConfig<typeof fastAccessFacetABI, 'CrowdfundRemoved'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastAccessFacetABI}__ and `eventName` set to `"DetailsChanged"`.
 */
export function useFastAccessFacetDetailsChangedEvent(
  config: Omit<UseContractEventConfig<typeof fastAccessFacetABI, 'DetailsChanged'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastAccessFacetABI, eventName: 'DetailsChanged', ...config } as UseContractEventConfig<
    typeof fastAccessFacetABI,
    'DetailsChanged'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastAccessFacetABI}__ and `eventName` set to `"Disapproval"`.
 */
export function useFastAccessFacetDisapprovalEvent(
  config: Omit<UseContractEventConfig<typeof fastAccessFacetABI, 'Disapproval'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastAccessFacetABI, eventName: 'Disapproval', ...config } as UseContractEventConfig<
    typeof fastAccessFacetABI,
    'Disapproval'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastAccessFacetABI}__ and `eventName` set to `"DistributionDeployed"`.
 */
export function useFastAccessFacetDistributionDeployedEvent(
  config: Omit<
    UseContractEventConfig<typeof fastAccessFacetABI, 'DistributionDeployed'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: fastAccessFacetABI,
    eventName: 'DistributionDeployed',
    ...config,
  } as UseContractEventConfig<typeof fastAccessFacetABI, 'DistributionDeployed'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastAccessFacetABI}__ and `eventName` set to `"DistributionRemoved"`.
 */
export function useFastAccessFacetDistributionRemovedEvent(
  config: Omit<
    UseContractEventConfig<typeof fastAccessFacetABI, 'DistributionRemoved'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: fastAccessFacetABI,
    eventName: 'DistributionRemoved',
    ...config,
  } as UseContractEventConfig<typeof fastAccessFacetABI, 'DistributionRemoved'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastAccessFacetABI}__ and `eventName` set to `"FastTransfer"`.
 */
export function useFastAccessFacetFastTransferEvent(
  config: Omit<UseContractEventConfig<typeof fastAccessFacetABI, 'FastTransfer'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastAccessFacetABI, eventName: 'FastTransfer', ...config } as UseContractEventConfig<
    typeof fastAccessFacetABI,
    'FastTransfer'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastAccessFacetABI}__ and `eventName` set to `"GovernorAdded"`.
 */
export function useFastAccessFacetGovernorAddedEvent(
  config: Omit<UseContractEventConfig<typeof fastAccessFacetABI, 'GovernorAdded'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastAccessFacetABI, eventName: 'GovernorAdded', ...config } as UseContractEventConfig<
    typeof fastAccessFacetABI,
    'GovernorAdded'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastAccessFacetABI}__ and `eventName` set to `"GovernorRemoved"`.
 */
export function useFastAccessFacetGovernorRemovedEvent(
  config: Omit<UseContractEventConfig<typeof fastAccessFacetABI, 'GovernorRemoved'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({
    abi: fastAccessFacetABI,
    eventName: 'GovernorRemoved',
    ...config,
  } as UseContractEventConfig<typeof fastAccessFacetABI, 'GovernorRemoved'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastAccessFacetABI}__ and `eventName` set to `"MemberAdded"`.
 */
export function useFastAccessFacetMemberAddedEvent(
  config: Omit<UseContractEventConfig<typeof fastAccessFacetABI, 'MemberAdded'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastAccessFacetABI, eventName: 'MemberAdded', ...config } as UseContractEventConfig<
    typeof fastAccessFacetABI,
    'MemberAdded'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastAccessFacetABI}__ and `eventName` set to `"MemberRemoved"`.
 */
export function useFastAccessFacetMemberRemovedEvent(
  config: Omit<UseContractEventConfig<typeof fastAccessFacetABI, 'MemberRemoved'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastAccessFacetABI, eventName: 'MemberRemoved', ...config } as UseContractEventConfig<
    typeof fastAccessFacetABI,
    'MemberRemoved'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastAccessFacetABI}__ and `eventName` set to `"Minted"`.
 */
export function useFastAccessFacetMintedEvent(
  config: Omit<UseContractEventConfig<typeof fastAccessFacetABI, 'Minted'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastAccessFacetABI, eventName: 'Minted', ...config } as UseContractEventConfig<
    typeof fastAccessFacetABI,
    'Minted'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastAccessFacetABI}__ and `eventName` set to `"Transfer"`.
 */
export function useFastAccessFacetTransferEvent(
  config: Omit<UseContractEventConfig<typeof fastAccessFacetABI, 'Transfer'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastAccessFacetABI, eventName: 'Transfer', ...config } as UseContractEventConfig<
    typeof fastAccessFacetABI,
    'Transfer'
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link fastAutomatonsFacetABI}__.
 */
export function useFastAutomatonsFacetRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof fastAutomatonsFacetABI, TFunctionName>,
>(config: Omit<UseContractReadConfig<typeof fastAutomatonsFacetABI, TFunctionName, TSelectData>, 'abi'> = {} as any) {
  return useContractRead({ abi: fastAutomatonsFacetABI, ...config } as UseContractReadConfig<
    typeof fastAutomatonsFacetABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link fastAutomatonsFacetABI}__.
 */
export function useFastAutomatonsFacetWrite<TFunctionName extends string, TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof fastAutomatonsFacetABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof fastAutomatonsFacetABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof fastAutomatonsFacetABI, TFunctionName, TMode>({
    abi: fastAutomatonsFacetABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastAutomatonsFacetABI}__.
 */
export function useFastAutomatonsFacetEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof fastAutomatonsFacetABI, TEventName>, 'abi'> = {} as any,
) {
  return useContractEvent({ abi: fastAutomatonsFacetABI, ...config } as UseContractEventConfig<
    typeof fastAutomatonsFacetABI,
    TEventName
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastAutomatonsFacetABI}__ and `eventName` set to `"Approval"`.
 */
export function useFastAutomatonsFacetApprovalEvent(
  config: Omit<UseContractEventConfig<typeof fastAutomatonsFacetABI, 'Approval'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastAutomatonsFacetABI, eventName: 'Approval', ...config } as UseContractEventConfig<
    typeof fastAutomatonsFacetABI,
    'Approval'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastAutomatonsFacetABI}__ and `eventName` set to `"AutomatonPrivilegesSet"`.
 */
export function useFastAutomatonsFacetAutomatonPrivilegesSetEvent(
  config: Omit<
    UseContractEventConfig<typeof fastAutomatonsFacetABI, 'AutomatonPrivilegesSet'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: fastAutomatonsFacetABI,
    eventName: 'AutomatonPrivilegesSet',
    ...config,
  } as UseContractEventConfig<typeof fastAutomatonsFacetABI, 'AutomatonPrivilegesSet'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastAutomatonsFacetABI}__ and `eventName` set to `"AutomatonRemoved"`.
 */
export function useFastAutomatonsFacetAutomatonRemovedEvent(
  config: Omit<
    UseContractEventConfig<typeof fastAutomatonsFacetABI, 'AutomatonRemoved'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: fastAutomatonsFacetABI,
    eventName: 'AutomatonRemoved',
    ...config,
  } as UseContractEventConfig<typeof fastAutomatonsFacetABI, 'AutomatonRemoved'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastAutomatonsFacetABI}__ and `eventName` set to `"Burnt"`.
 */
export function useFastAutomatonsFacetBurntEvent(
  config: Omit<UseContractEventConfig<typeof fastAutomatonsFacetABI, 'Burnt'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastAutomatonsFacetABI, eventName: 'Burnt', ...config } as UseContractEventConfig<
    typeof fastAutomatonsFacetABI,
    'Burnt'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastAutomatonsFacetABI}__ and `eventName` set to `"CrowdfundDefaultBasisPointFeeSet"`.
 */
export function useFastAutomatonsFacetCrowdfundDefaultBasisPointFeeSetEvent(
  config: Omit<
    UseContractEventConfig<typeof fastAutomatonsFacetABI, 'CrowdfundDefaultBasisPointFeeSet'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: fastAutomatonsFacetABI,
    eventName: 'CrowdfundDefaultBasisPointFeeSet',
    ...config,
  } as UseContractEventConfig<typeof fastAutomatonsFacetABI, 'CrowdfundDefaultBasisPointFeeSet'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastAutomatonsFacetABI}__ and `eventName` set to `"CrowdfundDeployed"`.
 */
export function useFastAutomatonsFacetCrowdfundDeployedEvent(
  config: Omit<
    UseContractEventConfig<typeof fastAutomatonsFacetABI, 'CrowdfundDeployed'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: fastAutomatonsFacetABI,
    eventName: 'CrowdfundDeployed',
    ...config,
  } as UseContractEventConfig<typeof fastAutomatonsFacetABI, 'CrowdfundDeployed'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastAutomatonsFacetABI}__ and `eventName` set to `"CrowdfundRemoved"`.
 */
export function useFastAutomatonsFacetCrowdfundRemovedEvent(
  config: Omit<
    UseContractEventConfig<typeof fastAutomatonsFacetABI, 'CrowdfundRemoved'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: fastAutomatonsFacetABI,
    eventName: 'CrowdfundRemoved',
    ...config,
  } as UseContractEventConfig<typeof fastAutomatonsFacetABI, 'CrowdfundRemoved'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastAutomatonsFacetABI}__ and `eventName` set to `"DetailsChanged"`.
 */
export function useFastAutomatonsFacetDetailsChangedEvent(
  config: Omit<
    UseContractEventConfig<typeof fastAutomatonsFacetABI, 'DetailsChanged'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: fastAutomatonsFacetABI,
    eventName: 'DetailsChanged',
    ...config,
  } as UseContractEventConfig<typeof fastAutomatonsFacetABI, 'DetailsChanged'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastAutomatonsFacetABI}__ and `eventName` set to `"Disapproval"`.
 */
export function useFastAutomatonsFacetDisapprovalEvent(
  config: Omit<UseContractEventConfig<typeof fastAutomatonsFacetABI, 'Disapproval'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({
    abi: fastAutomatonsFacetABI,
    eventName: 'Disapproval',
    ...config,
  } as UseContractEventConfig<typeof fastAutomatonsFacetABI, 'Disapproval'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastAutomatonsFacetABI}__ and `eventName` set to `"DistributionDeployed"`.
 */
export function useFastAutomatonsFacetDistributionDeployedEvent(
  config: Omit<
    UseContractEventConfig<typeof fastAutomatonsFacetABI, 'DistributionDeployed'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: fastAutomatonsFacetABI,
    eventName: 'DistributionDeployed',
    ...config,
  } as UseContractEventConfig<typeof fastAutomatonsFacetABI, 'DistributionDeployed'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastAutomatonsFacetABI}__ and `eventName` set to `"DistributionRemoved"`.
 */
export function useFastAutomatonsFacetDistributionRemovedEvent(
  config: Omit<
    UseContractEventConfig<typeof fastAutomatonsFacetABI, 'DistributionRemoved'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: fastAutomatonsFacetABI,
    eventName: 'DistributionRemoved',
    ...config,
  } as UseContractEventConfig<typeof fastAutomatonsFacetABI, 'DistributionRemoved'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastAutomatonsFacetABI}__ and `eventName` set to `"FastTransfer"`.
 */
export function useFastAutomatonsFacetFastTransferEvent(
  config: Omit<UseContractEventConfig<typeof fastAutomatonsFacetABI, 'FastTransfer'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({
    abi: fastAutomatonsFacetABI,
    eventName: 'FastTransfer',
    ...config,
  } as UseContractEventConfig<typeof fastAutomatonsFacetABI, 'FastTransfer'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastAutomatonsFacetABI}__ and `eventName` set to `"Minted"`.
 */
export function useFastAutomatonsFacetMintedEvent(
  config: Omit<UseContractEventConfig<typeof fastAutomatonsFacetABI, 'Minted'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastAutomatonsFacetABI, eventName: 'Minted', ...config } as UseContractEventConfig<
    typeof fastAutomatonsFacetABI,
    'Minted'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastAutomatonsFacetABI}__ and `eventName` set to `"Transfer"`.
 */
export function useFastAutomatonsFacetTransferEvent(
  config: Omit<UseContractEventConfig<typeof fastAutomatonsFacetABI, 'Transfer'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastAutomatonsFacetABI, eventName: 'Transfer', ...config } as UseContractEventConfig<
    typeof fastAutomatonsFacetABI,
    'Transfer'
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link fastCrowdfundsFacetABI}__.
 */
export function useFastCrowdfundsFacetRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof fastCrowdfundsFacetABI, TFunctionName>,
>(config: Omit<UseContractReadConfig<typeof fastCrowdfundsFacetABI, TFunctionName, TSelectData>, 'abi'> = {} as any) {
  return useContractRead({ abi: fastCrowdfundsFacetABI, ...config } as UseContractReadConfig<
    typeof fastCrowdfundsFacetABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link fastCrowdfundsFacetABI}__.
 */
export function useFastCrowdfundsFacetWrite<TFunctionName extends string, TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof fastCrowdfundsFacetABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof fastCrowdfundsFacetABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof fastCrowdfundsFacetABI, TFunctionName, TMode>({
    abi: fastCrowdfundsFacetABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastCrowdfundsFacetABI}__.
 */
export function useFastCrowdfundsFacetEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof fastCrowdfundsFacetABI, TEventName>, 'abi'> = {} as any,
) {
  return useContractEvent({ abi: fastCrowdfundsFacetABI, ...config } as UseContractEventConfig<
    typeof fastCrowdfundsFacetABI,
    TEventName
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastCrowdfundsFacetABI}__ and `eventName` set to `"Approval"`.
 */
export function useFastCrowdfundsFacetApprovalEvent(
  config: Omit<UseContractEventConfig<typeof fastCrowdfundsFacetABI, 'Approval'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastCrowdfundsFacetABI, eventName: 'Approval', ...config } as UseContractEventConfig<
    typeof fastCrowdfundsFacetABI,
    'Approval'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastCrowdfundsFacetABI}__ and `eventName` set to `"Burnt"`.
 */
export function useFastCrowdfundsFacetBurntEvent(
  config: Omit<UseContractEventConfig<typeof fastCrowdfundsFacetABI, 'Burnt'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastCrowdfundsFacetABI, eventName: 'Burnt', ...config } as UseContractEventConfig<
    typeof fastCrowdfundsFacetABI,
    'Burnt'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastCrowdfundsFacetABI}__ and `eventName` set to `"CrowdfundDefaultBasisPointFeeSet"`.
 */
export function useFastCrowdfundsFacetCrowdfundDefaultBasisPointFeeSetEvent(
  config: Omit<
    UseContractEventConfig<typeof fastCrowdfundsFacetABI, 'CrowdfundDefaultBasisPointFeeSet'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: fastCrowdfundsFacetABI,
    eventName: 'CrowdfundDefaultBasisPointFeeSet',
    ...config,
  } as UseContractEventConfig<typeof fastCrowdfundsFacetABI, 'CrowdfundDefaultBasisPointFeeSet'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastCrowdfundsFacetABI}__ and `eventName` set to `"CrowdfundDeployed"`.
 */
export function useFastCrowdfundsFacetCrowdfundDeployedEvent(
  config: Omit<
    UseContractEventConfig<typeof fastCrowdfundsFacetABI, 'CrowdfundDeployed'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: fastCrowdfundsFacetABI,
    eventName: 'CrowdfundDeployed',
    ...config,
  } as UseContractEventConfig<typeof fastCrowdfundsFacetABI, 'CrowdfundDeployed'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastCrowdfundsFacetABI}__ and `eventName` set to `"CrowdfundRemoved"`.
 */
export function useFastCrowdfundsFacetCrowdfundRemovedEvent(
  config: Omit<
    UseContractEventConfig<typeof fastCrowdfundsFacetABI, 'CrowdfundRemoved'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: fastCrowdfundsFacetABI,
    eventName: 'CrowdfundRemoved',
    ...config,
  } as UseContractEventConfig<typeof fastCrowdfundsFacetABI, 'CrowdfundRemoved'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastCrowdfundsFacetABI}__ and `eventName` set to `"DetailsChanged"`.
 */
export function useFastCrowdfundsFacetDetailsChangedEvent(
  config: Omit<
    UseContractEventConfig<typeof fastCrowdfundsFacetABI, 'DetailsChanged'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: fastCrowdfundsFacetABI,
    eventName: 'DetailsChanged',
    ...config,
  } as UseContractEventConfig<typeof fastCrowdfundsFacetABI, 'DetailsChanged'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastCrowdfundsFacetABI}__ and `eventName` set to `"Disapproval"`.
 */
export function useFastCrowdfundsFacetDisapprovalEvent(
  config: Omit<UseContractEventConfig<typeof fastCrowdfundsFacetABI, 'Disapproval'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({
    abi: fastCrowdfundsFacetABI,
    eventName: 'Disapproval',
    ...config,
  } as UseContractEventConfig<typeof fastCrowdfundsFacetABI, 'Disapproval'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastCrowdfundsFacetABI}__ and `eventName` set to `"DistributionDeployed"`.
 */
export function useFastCrowdfundsFacetDistributionDeployedEvent(
  config: Omit<
    UseContractEventConfig<typeof fastCrowdfundsFacetABI, 'DistributionDeployed'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: fastCrowdfundsFacetABI,
    eventName: 'DistributionDeployed',
    ...config,
  } as UseContractEventConfig<typeof fastCrowdfundsFacetABI, 'DistributionDeployed'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastCrowdfundsFacetABI}__ and `eventName` set to `"DistributionRemoved"`.
 */
export function useFastCrowdfundsFacetDistributionRemovedEvent(
  config: Omit<
    UseContractEventConfig<typeof fastCrowdfundsFacetABI, 'DistributionRemoved'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: fastCrowdfundsFacetABI,
    eventName: 'DistributionRemoved',
    ...config,
  } as UseContractEventConfig<typeof fastCrowdfundsFacetABI, 'DistributionRemoved'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastCrowdfundsFacetABI}__ and `eventName` set to `"FastTransfer"`.
 */
export function useFastCrowdfundsFacetFastTransferEvent(
  config: Omit<UseContractEventConfig<typeof fastCrowdfundsFacetABI, 'FastTransfer'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({
    abi: fastCrowdfundsFacetABI,
    eventName: 'FastTransfer',
    ...config,
  } as UseContractEventConfig<typeof fastCrowdfundsFacetABI, 'FastTransfer'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastCrowdfundsFacetABI}__ and `eventName` set to `"Minted"`.
 */
export function useFastCrowdfundsFacetMintedEvent(
  config: Omit<UseContractEventConfig<typeof fastCrowdfundsFacetABI, 'Minted'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastCrowdfundsFacetABI, eventName: 'Minted', ...config } as UseContractEventConfig<
    typeof fastCrowdfundsFacetABI,
    'Minted'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastCrowdfundsFacetABI}__ and `eventName` set to `"Transfer"`.
 */
export function useFastCrowdfundsFacetTransferEvent(
  config: Omit<UseContractEventConfig<typeof fastCrowdfundsFacetABI, 'Transfer'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastCrowdfundsFacetABI, eventName: 'Transfer', ...config } as UseContractEventConfig<
    typeof fastCrowdfundsFacetABI,
    'Transfer'
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link fastDistributionsFacetABI}__.
 */
export function useFastDistributionsFacetRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof fastDistributionsFacetABI, TFunctionName>,
>(
  config: Omit<UseContractReadConfig<typeof fastDistributionsFacetABI, TFunctionName, TSelectData>, 'abi'> = {} as any,
) {
  return useContractRead({ abi: fastDistributionsFacetABI, ...config } as UseContractReadConfig<
    typeof fastDistributionsFacetABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link fastDistributionsFacetABI}__.
 */
export function useFastDistributionsFacetWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof fastDistributionsFacetABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof fastDistributionsFacetABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof fastDistributionsFacetABI, TFunctionName, TMode>({
    abi: fastDistributionsFacetABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastDistributionsFacetABI}__.
 */
export function useFastDistributionsFacetEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof fastDistributionsFacetABI, TEventName>, 'abi'> = {} as any,
) {
  return useContractEvent({ abi: fastDistributionsFacetABI, ...config } as UseContractEventConfig<
    typeof fastDistributionsFacetABI,
    TEventName
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastDistributionsFacetABI}__ and `eventName` set to `"Approval"`.
 */
export function useFastDistributionsFacetApprovalEvent(
  config: Omit<UseContractEventConfig<typeof fastDistributionsFacetABI, 'Approval'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({
    abi: fastDistributionsFacetABI,
    eventName: 'Approval',
    ...config,
  } as UseContractEventConfig<typeof fastDistributionsFacetABI, 'Approval'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastDistributionsFacetABI}__ and `eventName` set to `"Burnt"`.
 */
export function useFastDistributionsFacetBurntEvent(
  config: Omit<UseContractEventConfig<typeof fastDistributionsFacetABI, 'Burnt'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastDistributionsFacetABI, eventName: 'Burnt', ...config } as UseContractEventConfig<
    typeof fastDistributionsFacetABI,
    'Burnt'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastDistributionsFacetABI}__ and `eventName` set to `"CrowdfundDefaultBasisPointFeeSet"`.
 */
export function useFastDistributionsFacetCrowdfundDefaultBasisPointFeeSetEvent(
  config: Omit<
    UseContractEventConfig<typeof fastDistributionsFacetABI, 'CrowdfundDefaultBasisPointFeeSet'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: fastDistributionsFacetABI,
    eventName: 'CrowdfundDefaultBasisPointFeeSet',
    ...config,
  } as UseContractEventConfig<typeof fastDistributionsFacetABI, 'CrowdfundDefaultBasisPointFeeSet'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastDistributionsFacetABI}__ and `eventName` set to `"CrowdfundDeployed"`.
 */
export function useFastDistributionsFacetCrowdfundDeployedEvent(
  config: Omit<
    UseContractEventConfig<typeof fastDistributionsFacetABI, 'CrowdfundDeployed'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: fastDistributionsFacetABI,
    eventName: 'CrowdfundDeployed',
    ...config,
  } as UseContractEventConfig<typeof fastDistributionsFacetABI, 'CrowdfundDeployed'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastDistributionsFacetABI}__ and `eventName` set to `"CrowdfundRemoved"`.
 */
export function useFastDistributionsFacetCrowdfundRemovedEvent(
  config: Omit<
    UseContractEventConfig<typeof fastDistributionsFacetABI, 'CrowdfundRemoved'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: fastDistributionsFacetABI,
    eventName: 'CrowdfundRemoved',
    ...config,
  } as UseContractEventConfig<typeof fastDistributionsFacetABI, 'CrowdfundRemoved'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastDistributionsFacetABI}__ and `eventName` set to `"DetailsChanged"`.
 */
export function useFastDistributionsFacetDetailsChangedEvent(
  config: Omit<
    UseContractEventConfig<typeof fastDistributionsFacetABI, 'DetailsChanged'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: fastDistributionsFacetABI,
    eventName: 'DetailsChanged',
    ...config,
  } as UseContractEventConfig<typeof fastDistributionsFacetABI, 'DetailsChanged'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastDistributionsFacetABI}__ and `eventName` set to `"Disapproval"`.
 */
export function useFastDistributionsFacetDisapprovalEvent(
  config: Omit<
    UseContractEventConfig<typeof fastDistributionsFacetABI, 'Disapproval'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: fastDistributionsFacetABI,
    eventName: 'Disapproval',
    ...config,
  } as UseContractEventConfig<typeof fastDistributionsFacetABI, 'Disapproval'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastDistributionsFacetABI}__ and `eventName` set to `"DistributionDeployed"`.
 */
export function useFastDistributionsFacetDistributionDeployedEvent(
  config: Omit<
    UseContractEventConfig<typeof fastDistributionsFacetABI, 'DistributionDeployed'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: fastDistributionsFacetABI,
    eventName: 'DistributionDeployed',
    ...config,
  } as UseContractEventConfig<typeof fastDistributionsFacetABI, 'DistributionDeployed'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastDistributionsFacetABI}__ and `eventName` set to `"DistributionRemoved"`.
 */
export function useFastDistributionsFacetDistributionRemovedEvent(
  config: Omit<
    UseContractEventConfig<typeof fastDistributionsFacetABI, 'DistributionRemoved'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: fastDistributionsFacetABI,
    eventName: 'DistributionRemoved',
    ...config,
  } as UseContractEventConfig<typeof fastDistributionsFacetABI, 'DistributionRemoved'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastDistributionsFacetABI}__ and `eventName` set to `"FastTransfer"`.
 */
export function useFastDistributionsFacetFastTransferEvent(
  config: Omit<
    UseContractEventConfig<typeof fastDistributionsFacetABI, 'FastTransfer'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: fastDistributionsFacetABI,
    eventName: 'FastTransfer',
    ...config,
  } as UseContractEventConfig<typeof fastDistributionsFacetABI, 'FastTransfer'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastDistributionsFacetABI}__ and `eventName` set to `"Minted"`.
 */
export function useFastDistributionsFacetMintedEvent(
  config: Omit<UseContractEventConfig<typeof fastDistributionsFacetABI, 'Minted'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastDistributionsFacetABI, eventName: 'Minted', ...config } as UseContractEventConfig<
    typeof fastDistributionsFacetABI,
    'Minted'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastDistributionsFacetABI}__ and `eventName` set to `"Transfer"`.
 */
export function useFastDistributionsFacetTransferEvent(
  config: Omit<UseContractEventConfig<typeof fastDistributionsFacetABI, 'Transfer'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({
    abi: fastDistributionsFacetABI,
    eventName: 'Transfer',
    ...config,
  } as UseContractEventConfig<typeof fastDistributionsFacetABI, 'Transfer'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link fastFrontendFacetABI}__.
 */
export function useFastFrontendFacetRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof fastFrontendFacetABI, TFunctionName>,
>(config: Omit<UseContractReadConfig<typeof fastFrontendFacetABI, TFunctionName, TSelectData>, 'abi'> = {} as any) {
  return useContractRead({ abi: fastFrontendFacetABI, ...config } as UseContractReadConfig<
    typeof fastFrontendFacetABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link fastFrontendFacetABI}__.
 */
export function useFastFrontendFacetWrite<TFunctionName extends string, TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof fastFrontendFacetABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof fastFrontendFacetABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof fastFrontendFacetABI, TFunctionName, TMode>({
    abi: fastFrontendFacetABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastFrontendFacetABI}__.
 */
export function useFastFrontendFacetEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof fastFrontendFacetABI, TEventName>, 'abi'> = {} as any,
) {
  return useContractEvent({ abi: fastFrontendFacetABI, ...config } as UseContractEventConfig<
    typeof fastFrontendFacetABI,
    TEventName
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastFrontendFacetABI}__ and `eventName` set to `"Approval"`.
 */
export function useFastFrontendFacetApprovalEvent(
  config: Omit<UseContractEventConfig<typeof fastFrontendFacetABI, 'Approval'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastFrontendFacetABI, eventName: 'Approval', ...config } as UseContractEventConfig<
    typeof fastFrontendFacetABI,
    'Approval'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastFrontendFacetABI}__ and `eventName` set to `"Burnt"`.
 */
export function useFastFrontendFacetBurntEvent(
  config: Omit<UseContractEventConfig<typeof fastFrontendFacetABI, 'Burnt'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastFrontendFacetABI, eventName: 'Burnt', ...config } as UseContractEventConfig<
    typeof fastFrontendFacetABI,
    'Burnt'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastFrontendFacetABI}__ and `eventName` set to `"CrowdfundDefaultBasisPointFeeSet"`.
 */
export function useFastFrontendFacetCrowdfundDefaultBasisPointFeeSetEvent(
  config: Omit<
    UseContractEventConfig<typeof fastFrontendFacetABI, 'CrowdfundDefaultBasisPointFeeSet'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: fastFrontendFacetABI,
    eventName: 'CrowdfundDefaultBasisPointFeeSet',
    ...config,
  } as UseContractEventConfig<typeof fastFrontendFacetABI, 'CrowdfundDefaultBasisPointFeeSet'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastFrontendFacetABI}__ and `eventName` set to `"CrowdfundDeployed"`.
 */
export function useFastFrontendFacetCrowdfundDeployedEvent(
  config: Omit<
    UseContractEventConfig<typeof fastFrontendFacetABI, 'CrowdfundDeployed'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: fastFrontendFacetABI,
    eventName: 'CrowdfundDeployed',
    ...config,
  } as UseContractEventConfig<typeof fastFrontendFacetABI, 'CrowdfundDeployed'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastFrontendFacetABI}__ and `eventName` set to `"CrowdfundRemoved"`.
 */
export function useFastFrontendFacetCrowdfundRemovedEvent(
  config: Omit<
    UseContractEventConfig<typeof fastFrontendFacetABI, 'CrowdfundRemoved'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: fastFrontendFacetABI,
    eventName: 'CrowdfundRemoved',
    ...config,
  } as UseContractEventConfig<typeof fastFrontendFacetABI, 'CrowdfundRemoved'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastFrontendFacetABI}__ and `eventName` set to `"DetailsChanged"`.
 */
export function useFastFrontendFacetDetailsChangedEvent(
  config: Omit<UseContractEventConfig<typeof fastFrontendFacetABI, 'DetailsChanged'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({
    abi: fastFrontendFacetABI,
    eventName: 'DetailsChanged',
    ...config,
  } as UseContractEventConfig<typeof fastFrontendFacetABI, 'DetailsChanged'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastFrontendFacetABI}__ and `eventName` set to `"Disapproval"`.
 */
export function useFastFrontendFacetDisapprovalEvent(
  config: Omit<UseContractEventConfig<typeof fastFrontendFacetABI, 'Disapproval'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastFrontendFacetABI, eventName: 'Disapproval', ...config } as UseContractEventConfig<
    typeof fastFrontendFacetABI,
    'Disapproval'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastFrontendFacetABI}__ and `eventName` set to `"DistributionDeployed"`.
 */
export function useFastFrontendFacetDistributionDeployedEvent(
  config: Omit<
    UseContractEventConfig<typeof fastFrontendFacetABI, 'DistributionDeployed'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: fastFrontendFacetABI,
    eventName: 'DistributionDeployed',
    ...config,
  } as UseContractEventConfig<typeof fastFrontendFacetABI, 'DistributionDeployed'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastFrontendFacetABI}__ and `eventName` set to `"DistributionRemoved"`.
 */
export function useFastFrontendFacetDistributionRemovedEvent(
  config: Omit<
    UseContractEventConfig<typeof fastFrontendFacetABI, 'DistributionRemoved'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: fastFrontendFacetABI,
    eventName: 'DistributionRemoved',
    ...config,
  } as UseContractEventConfig<typeof fastFrontendFacetABI, 'DistributionRemoved'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastFrontendFacetABI}__ and `eventName` set to `"FastTransfer"`.
 */
export function useFastFrontendFacetFastTransferEvent(
  config: Omit<UseContractEventConfig<typeof fastFrontendFacetABI, 'FastTransfer'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastFrontendFacetABI, eventName: 'FastTransfer', ...config } as UseContractEventConfig<
    typeof fastFrontendFacetABI,
    'FastTransfer'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastFrontendFacetABI}__ and `eventName` set to `"Minted"`.
 */
export function useFastFrontendFacetMintedEvent(
  config: Omit<UseContractEventConfig<typeof fastFrontendFacetABI, 'Minted'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastFrontendFacetABI, eventName: 'Minted', ...config } as UseContractEventConfig<
    typeof fastFrontendFacetABI,
    'Minted'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastFrontendFacetABI}__ and `eventName` set to `"Transfer"`.
 */
export function useFastFrontendFacetTransferEvent(
  config: Omit<UseContractEventConfig<typeof fastFrontendFacetABI, 'Transfer'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastFrontendFacetABI, eventName: 'Transfer', ...config } as UseContractEventConfig<
    typeof fastFrontendFacetABI,
    'Transfer'
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link fastHistoryFacetABI}__.
 */
export function useFastHistoryFacetRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof fastHistoryFacetABI, TFunctionName>,
>(config: Omit<UseContractReadConfig<typeof fastHistoryFacetABI, TFunctionName, TSelectData>, 'abi'> = {} as any) {
  return useContractRead({ abi: fastHistoryFacetABI, ...config } as UseContractReadConfig<
    typeof fastHistoryFacetABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link fastHistoryFacetABI}__.
 */
export function useFastHistoryFacetWrite<TFunctionName extends string, TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof fastHistoryFacetABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof fastHistoryFacetABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof fastHistoryFacetABI, TFunctionName, TMode>({
    abi: fastHistoryFacetABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastHistoryFacetABI}__.
 */
export function useFastHistoryFacetEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof fastHistoryFacetABI, TEventName>, 'abi'> = {} as any,
) {
  return useContractEvent({ abi: fastHistoryFacetABI, ...config } as UseContractEventConfig<
    typeof fastHistoryFacetABI,
    TEventName
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastHistoryFacetABI}__ and `eventName` set to `"Approval"`.
 */
export function useFastHistoryFacetApprovalEvent(
  config: Omit<UseContractEventConfig<typeof fastHistoryFacetABI, 'Approval'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastHistoryFacetABI, eventName: 'Approval', ...config } as UseContractEventConfig<
    typeof fastHistoryFacetABI,
    'Approval'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastHistoryFacetABI}__ and `eventName` set to `"Burnt"`.
 */
export function useFastHistoryFacetBurntEvent(
  config: Omit<UseContractEventConfig<typeof fastHistoryFacetABI, 'Burnt'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastHistoryFacetABI, eventName: 'Burnt', ...config } as UseContractEventConfig<
    typeof fastHistoryFacetABI,
    'Burnt'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastHistoryFacetABI}__ and `eventName` set to `"CrowdfundDefaultBasisPointFeeSet"`.
 */
export function useFastHistoryFacetCrowdfundDefaultBasisPointFeeSetEvent(
  config: Omit<
    UseContractEventConfig<typeof fastHistoryFacetABI, 'CrowdfundDefaultBasisPointFeeSet'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: fastHistoryFacetABI,
    eventName: 'CrowdfundDefaultBasisPointFeeSet',
    ...config,
  } as UseContractEventConfig<typeof fastHistoryFacetABI, 'CrowdfundDefaultBasisPointFeeSet'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastHistoryFacetABI}__ and `eventName` set to `"CrowdfundDeployed"`.
 */
export function useFastHistoryFacetCrowdfundDeployedEvent(
  config: Omit<
    UseContractEventConfig<typeof fastHistoryFacetABI, 'CrowdfundDeployed'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: fastHistoryFacetABI,
    eventName: 'CrowdfundDeployed',
    ...config,
  } as UseContractEventConfig<typeof fastHistoryFacetABI, 'CrowdfundDeployed'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastHistoryFacetABI}__ and `eventName` set to `"CrowdfundRemoved"`.
 */
export function useFastHistoryFacetCrowdfundRemovedEvent(
  config: Omit<UseContractEventConfig<typeof fastHistoryFacetABI, 'CrowdfundRemoved'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({
    abi: fastHistoryFacetABI,
    eventName: 'CrowdfundRemoved',
    ...config,
  } as UseContractEventConfig<typeof fastHistoryFacetABI, 'CrowdfundRemoved'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastHistoryFacetABI}__ and `eventName` set to `"DetailsChanged"`.
 */
export function useFastHistoryFacetDetailsChangedEvent(
  config: Omit<UseContractEventConfig<typeof fastHistoryFacetABI, 'DetailsChanged'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({
    abi: fastHistoryFacetABI,
    eventName: 'DetailsChanged',
    ...config,
  } as UseContractEventConfig<typeof fastHistoryFacetABI, 'DetailsChanged'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastHistoryFacetABI}__ and `eventName` set to `"Disapproval"`.
 */
export function useFastHistoryFacetDisapprovalEvent(
  config: Omit<UseContractEventConfig<typeof fastHistoryFacetABI, 'Disapproval'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastHistoryFacetABI, eventName: 'Disapproval', ...config } as UseContractEventConfig<
    typeof fastHistoryFacetABI,
    'Disapproval'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastHistoryFacetABI}__ and `eventName` set to `"DistributionDeployed"`.
 */
export function useFastHistoryFacetDistributionDeployedEvent(
  config: Omit<
    UseContractEventConfig<typeof fastHistoryFacetABI, 'DistributionDeployed'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: fastHistoryFacetABI,
    eventName: 'DistributionDeployed',
    ...config,
  } as UseContractEventConfig<typeof fastHistoryFacetABI, 'DistributionDeployed'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastHistoryFacetABI}__ and `eventName` set to `"DistributionRemoved"`.
 */
export function useFastHistoryFacetDistributionRemovedEvent(
  config: Omit<
    UseContractEventConfig<typeof fastHistoryFacetABI, 'DistributionRemoved'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: fastHistoryFacetABI,
    eventName: 'DistributionRemoved',
    ...config,
  } as UseContractEventConfig<typeof fastHistoryFacetABI, 'DistributionRemoved'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastHistoryFacetABI}__ and `eventName` set to `"FastTransfer"`.
 */
export function useFastHistoryFacetFastTransferEvent(
  config: Omit<UseContractEventConfig<typeof fastHistoryFacetABI, 'FastTransfer'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastHistoryFacetABI, eventName: 'FastTransfer', ...config } as UseContractEventConfig<
    typeof fastHistoryFacetABI,
    'FastTransfer'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastHistoryFacetABI}__ and `eventName` set to `"Minted"`.
 */
export function useFastHistoryFacetMintedEvent(
  config: Omit<UseContractEventConfig<typeof fastHistoryFacetABI, 'Minted'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastHistoryFacetABI, eventName: 'Minted', ...config } as UseContractEventConfig<
    typeof fastHistoryFacetABI,
    'Minted'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastHistoryFacetABI}__ and `eventName` set to `"Transfer"`.
 */
export function useFastHistoryFacetTransferEvent(
  config: Omit<UseContractEventConfig<typeof fastHistoryFacetABI, 'Transfer'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastHistoryFacetABI, eventName: 'Transfer', ...config } as UseContractEventConfig<
    typeof fastHistoryFacetABI,
    'Transfer'
  >);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link fastInitFacetABI}__.
 */
export function useFastInitFacetWrite<TFunctionName extends string, TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof fastInitFacetABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof fastInitFacetABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof fastInitFacetABI, TFunctionName, TMode>({ abi: fastInitFacetABI, ...config } as any);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastInitFacetABI}__.
 */
export function useFastInitFacetEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof fastInitFacetABI, TEventName>, 'abi'> = {} as any,
) {
  return useContractEvent({ abi: fastInitFacetABI, ...config } as UseContractEventConfig<
    typeof fastInitFacetABI,
    TEventName
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastInitFacetABI}__ and `eventName` set to `"Approval"`.
 */
export function useFastInitFacetApprovalEvent(
  config: Omit<UseContractEventConfig<typeof fastInitFacetABI, 'Approval'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastInitFacetABI, eventName: 'Approval', ...config } as UseContractEventConfig<
    typeof fastInitFacetABI,
    'Approval'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastInitFacetABI}__ and `eventName` set to `"Burnt"`.
 */
export function useFastInitFacetBurntEvent(
  config: Omit<UseContractEventConfig<typeof fastInitFacetABI, 'Burnt'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastInitFacetABI, eventName: 'Burnt', ...config } as UseContractEventConfig<
    typeof fastInitFacetABI,
    'Burnt'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastInitFacetABI}__ and `eventName` set to `"CrowdfundDefaultBasisPointFeeSet"`.
 */
export function useFastInitFacetCrowdfundDefaultBasisPointFeeSetEvent(
  config: Omit<
    UseContractEventConfig<typeof fastInitFacetABI, 'CrowdfundDefaultBasisPointFeeSet'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: fastInitFacetABI,
    eventName: 'CrowdfundDefaultBasisPointFeeSet',
    ...config,
  } as UseContractEventConfig<typeof fastInitFacetABI, 'CrowdfundDefaultBasisPointFeeSet'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastInitFacetABI}__ and `eventName` set to `"CrowdfundDeployed"`.
 */
export function useFastInitFacetCrowdfundDeployedEvent(
  config: Omit<UseContractEventConfig<typeof fastInitFacetABI, 'CrowdfundDeployed'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({
    abi: fastInitFacetABI,
    eventName: 'CrowdfundDeployed',
    ...config,
  } as UseContractEventConfig<typeof fastInitFacetABI, 'CrowdfundDeployed'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastInitFacetABI}__ and `eventName` set to `"CrowdfundRemoved"`.
 */
export function useFastInitFacetCrowdfundRemovedEvent(
  config: Omit<UseContractEventConfig<typeof fastInitFacetABI, 'CrowdfundRemoved'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastInitFacetABI, eventName: 'CrowdfundRemoved', ...config } as UseContractEventConfig<
    typeof fastInitFacetABI,
    'CrowdfundRemoved'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastInitFacetABI}__ and `eventName` set to `"DetailsChanged"`.
 */
export function useFastInitFacetDetailsChangedEvent(
  config: Omit<UseContractEventConfig<typeof fastInitFacetABI, 'DetailsChanged'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastInitFacetABI, eventName: 'DetailsChanged', ...config } as UseContractEventConfig<
    typeof fastInitFacetABI,
    'DetailsChanged'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastInitFacetABI}__ and `eventName` set to `"Disapproval"`.
 */
export function useFastInitFacetDisapprovalEvent(
  config: Omit<UseContractEventConfig<typeof fastInitFacetABI, 'Disapproval'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastInitFacetABI, eventName: 'Disapproval', ...config } as UseContractEventConfig<
    typeof fastInitFacetABI,
    'Disapproval'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastInitFacetABI}__ and `eventName` set to `"DistributionDeployed"`.
 */
export function useFastInitFacetDistributionDeployedEvent(
  config: Omit<
    UseContractEventConfig<typeof fastInitFacetABI, 'DistributionDeployed'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: fastInitFacetABI,
    eventName: 'DistributionDeployed',
    ...config,
  } as UseContractEventConfig<typeof fastInitFacetABI, 'DistributionDeployed'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastInitFacetABI}__ and `eventName` set to `"DistributionRemoved"`.
 */
export function useFastInitFacetDistributionRemovedEvent(
  config: Omit<UseContractEventConfig<typeof fastInitFacetABI, 'DistributionRemoved'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({
    abi: fastInitFacetABI,
    eventName: 'DistributionRemoved',
    ...config,
  } as UseContractEventConfig<typeof fastInitFacetABI, 'DistributionRemoved'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastInitFacetABI}__ and `eventName` set to `"FastTransfer"`.
 */
export function useFastInitFacetFastTransferEvent(
  config: Omit<UseContractEventConfig<typeof fastInitFacetABI, 'FastTransfer'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastInitFacetABI, eventName: 'FastTransfer', ...config } as UseContractEventConfig<
    typeof fastInitFacetABI,
    'FastTransfer'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastInitFacetABI}__ and `eventName` set to `"GovernorAdded"`.
 */
export function useFastInitFacetGovernorAddedEvent(
  config: Omit<UseContractEventConfig<typeof fastInitFacetABI, 'GovernorAdded'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastInitFacetABI, eventName: 'GovernorAdded', ...config } as UseContractEventConfig<
    typeof fastInitFacetABI,
    'GovernorAdded'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastInitFacetABI}__ and `eventName` set to `"MemberAdded"`.
 */
export function useFastInitFacetMemberAddedEvent(
  config: Omit<UseContractEventConfig<typeof fastInitFacetABI, 'MemberAdded'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastInitFacetABI, eventName: 'MemberAdded', ...config } as UseContractEventConfig<
    typeof fastInitFacetABI,
    'MemberAdded'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastInitFacetABI}__ and `eventName` set to `"Minted"`.
 */
export function useFastInitFacetMintedEvent(
  config: Omit<UseContractEventConfig<typeof fastInitFacetABI, 'Minted'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastInitFacetABI, eventName: 'Minted', ...config } as UseContractEventConfig<
    typeof fastInitFacetABI,
    'Minted'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastInitFacetABI}__ and `eventName` set to `"Transfer"`.
 */
export function useFastInitFacetTransferEvent(
  config: Omit<UseContractEventConfig<typeof fastInitFacetABI, 'Transfer'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastInitFacetABI, eventName: 'Transfer', ...config } as UseContractEventConfig<
    typeof fastInitFacetABI,
    'Transfer'
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link fastTokenFacetABI}__.
 */
export function useFastTokenFacetRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof fastTokenFacetABI, TFunctionName>,
>(config: Omit<UseContractReadConfig<typeof fastTokenFacetABI, TFunctionName, TSelectData>, 'abi'> = {} as any) {
  return useContractRead({ abi: fastTokenFacetABI, ...config } as UseContractReadConfig<
    typeof fastTokenFacetABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link fastTokenFacetABI}__.
 */
export function useFastTokenFacetWrite<TFunctionName extends string, TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof fastTokenFacetABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof fastTokenFacetABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof fastTokenFacetABI, TFunctionName, TMode>({ abi: fastTokenFacetABI, ...config } as any);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastTokenFacetABI}__.
 */
export function useFastTokenFacetEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof fastTokenFacetABI, TEventName>, 'abi'> = {} as any,
) {
  return useContractEvent({ abi: fastTokenFacetABI, ...config } as UseContractEventConfig<
    typeof fastTokenFacetABI,
    TEventName
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastTokenFacetABI}__ and `eventName` set to `"Approval"`.
 */
export function useFastTokenFacetApprovalEvent(
  config: Omit<UseContractEventConfig<typeof fastTokenFacetABI, 'Approval'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastTokenFacetABI, eventName: 'Approval', ...config } as UseContractEventConfig<
    typeof fastTokenFacetABI,
    'Approval'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastTokenFacetABI}__ and `eventName` set to `"Burnt"`.
 */
export function useFastTokenFacetBurntEvent(
  config: Omit<UseContractEventConfig<typeof fastTokenFacetABI, 'Burnt'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastTokenFacetABI, eventName: 'Burnt', ...config } as UseContractEventConfig<
    typeof fastTokenFacetABI,
    'Burnt'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastTokenFacetABI}__ and `eventName` set to `"CrowdfundDefaultBasisPointFeeSet"`.
 */
export function useFastTokenFacetCrowdfundDefaultBasisPointFeeSetEvent(
  config: Omit<
    UseContractEventConfig<typeof fastTokenFacetABI, 'CrowdfundDefaultBasisPointFeeSet'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: fastTokenFacetABI,
    eventName: 'CrowdfundDefaultBasisPointFeeSet',
    ...config,
  } as UseContractEventConfig<typeof fastTokenFacetABI, 'CrowdfundDefaultBasisPointFeeSet'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastTokenFacetABI}__ and `eventName` set to `"CrowdfundDeployed"`.
 */
export function useFastTokenFacetCrowdfundDeployedEvent(
  config: Omit<UseContractEventConfig<typeof fastTokenFacetABI, 'CrowdfundDeployed'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({
    abi: fastTokenFacetABI,
    eventName: 'CrowdfundDeployed',
    ...config,
  } as UseContractEventConfig<typeof fastTokenFacetABI, 'CrowdfundDeployed'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastTokenFacetABI}__ and `eventName` set to `"CrowdfundRemoved"`.
 */
export function useFastTokenFacetCrowdfundRemovedEvent(
  config: Omit<UseContractEventConfig<typeof fastTokenFacetABI, 'CrowdfundRemoved'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({
    abi: fastTokenFacetABI,
    eventName: 'CrowdfundRemoved',
    ...config,
  } as UseContractEventConfig<typeof fastTokenFacetABI, 'CrowdfundRemoved'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastTokenFacetABI}__ and `eventName` set to `"DetailsChanged"`.
 */
export function useFastTokenFacetDetailsChangedEvent(
  config: Omit<UseContractEventConfig<typeof fastTokenFacetABI, 'DetailsChanged'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastTokenFacetABI, eventName: 'DetailsChanged', ...config } as UseContractEventConfig<
    typeof fastTokenFacetABI,
    'DetailsChanged'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastTokenFacetABI}__ and `eventName` set to `"Disapproval"`.
 */
export function useFastTokenFacetDisapprovalEvent(
  config: Omit<UseContractEventConfig<typeof fastTokenFacetABI, 'Disapproval'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastTokenFacetABI, eventName: 'Disapproval', ...config } as UseContractEventConfig<
    typeof fastTokenFacetABI,
    'Disapproval'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastTokenFacetABI}__ and `eventName` set to `"DistributionDeployed"`.
 */
export function useFastTokenFacetDistributionDeployedEvent(
  config: Omit<
    UseContractEventConfig<typeof fastTokenFacetABI, 'DistributionDeployed'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: fastTokenFacetABI,
    eventName: 'DistributionDeployed',
    ...config,
  } as UseContractEventConfig<typeof fastTokenFacetABI, 'DistributionDeployed'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastTokenFacetABI}__ and `eventName` set to `"DistributionRemoved"`.
 */
export function useFastTokenFacetDistributionRemovedEvent(
  config: Omit<
    UseContractEventConfig<typeof fastTokenFacetABI, 'DistributionRemoved'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: fastTokenFacetABI,
    eventName: 'DistributionRemoved',
    ...config,
  } as UseContractEventConfig<typeof fastTokenFacetABI, 'DistributionRemoved'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastTokenFacetABI}__ and `eventName` set to `"FastTransfer"`.
 */
export function useFastTokenFacetFastTransferEvent(
  config: Omit<UseContractEventConfig<typeof fastTokenFacetABI, 'FastTransfer'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastTokenFacetABI, eventName: 'FastTransfer', ...config } as UseContractEventConfig<
    typeof fastTokenFacetABI,
    'FastTransfer'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastTokenFacetABI}__ and `eventName` set to `"Minted"`.
 */
export function useFastTokenFacetMintedEvent(
  config: Omit<UseContractEventConfig<typeof fastTokenFacetABI, 'Minted'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastTokenFacetABI, eventName: 'Minted', ...config } as UseContractEventConfig<
    typeof fastTokenFacetABI,
    'Minted'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastTokenFacetABI}__ and `eventName` set to `"Transfer"`.
 */
export function useFastTokenFacetTransferEvent(
  config: Omit<UseContractEventConfig<typeof fastTokenFacetABI, 'Transfer'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastTokenFacetABI, eventName: 'Transfer', ...config } as UseContractEventConfig<
    typeof fastTokenFacetABI,
    'Transfer'
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link fastTopFacetABI}__.
 */
export function useFastTopFacetRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof fastTopFacetABI, TFunctionName>,
>(config: Omit<UseContractReadConfig<typeof fastTopFacetABI, TFunctionName, TSelectData>, 'abi'> = {} as any) {
  return useContractRead({ abi: fastTopFacetABI, ...config } as UseContractReadConfig<
    typeof fastTopFacetABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link fastTopFacetABI}__.
 */
export function useFastTopFacetWrite<TFunctionName extends string, TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof fastTopFacetABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof fastTopFacetABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof fastTopFacetABI, TFunctionName, TMode>({ abi: fastTopFacetABI, ...config } as any);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastTopFacetABI}__.
 */
export function useFastTopFacetEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof fastTopFacetABI, TEventName>, 'abi'> = {} as any,
) {
  return useContractEvent({ abi: fastTopFacetABI, ...config } as UseContractEventConfig<
    typeof fastTopFacetABI,
    TEventName
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastTopFacetABI}__ and `eventName` set to `"Approval"`.
 */
export function useFastTopFacetApprovalEvent(
  config: Omit<UseContractEventConfig<typeof fastTopFacetABI, 'Approval'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastTopFacetABI, eventName: 'Approval', ...config } as UseContractEventConfig<
    typeof fastTopFacetABI,
    'Approval'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastTopFacetABI}__ and `eventName` set to `"Burnt"`.
 */
export function useFastTopFacetBurntEvent(
  config: Omit<UseContractEventConfig<typeof fastTopFacetABI, 'Burnt'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastTopFacetABI, eventName: 'Burnt', ...config } as UseContractEventConfig<
    typeof fastTopFacetABI,
    'Burnt'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastTopFacetABI}__ and `eventName` set to `"CrowdfundDefaultBasisPointFeeSet"`.
 */
export function useFastTopFacetCrowdfundDefaultBasisPointFeeSetEvent(
  config: Omit<
    UseContractEventConfig<typeof fastTopFacetABI, 'CrowdfundDefaultBasisPointFeeSet'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: fastTopFacetABI,
    eventName: 'CrowdfundDefaultBasisPointFeeSet',
    ...config,
  } as UseContractEventConfig<typeof fastTopFacetABI, 'CrowdfundDefaultBasisPointFeeSet'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastTopFacetABI}__ and `eventName` set to `"CrowdfundDeployed"`.
 */
export function useFastTopFacetCrowdfundDeployedEvent(
  config: Omit<UseContractEventConfig<typeof fastTopFacetABI, 'CrowdfundDeployed'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastTopFacetABI, eventName: 'CrowdfundDeployed', ...config } as UseContractEventConfig<
    typeof fastTopFacetABI,
    'CrowdfundDeployed'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastTopFacetABI}__ and `eventName` set to `"CrowdfundRemoved"`.
 */
export function useFastTopFacetCrowdfundRemovedEvent(
  config: Omit<UseContractEventConfig<typeof fastTopFacetABI, 'CrowdfundRemoved'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastTopFacetABI, eventName: 'CrowdfundRemoved', ...config } as UseContractEventConfig<
    typeof fastTopFacetABI,
    'CrowdfundRemoved'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastTopFacetABI}__ and `eventName` set to `"DetailsChanged"`.
 */
export function useFastTopFacetDetailsChangedEvent(
  config: Omit<UseContractEventConfig<typeof fastTopFacetABI, 'DetailsChanged'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastTopFacetABI, eventName: 'DetailsChanged', ...config } as UseContractEventConfig<
    typeof fastTopFacetABI,
    'DetailsChanged'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastTopFacetABI}__ and `eventName` set to `"Disapproval"`.
 */
export function useFastTopFacetDisapprovalEvent(
  config: Omit<UseContractEventConfig<typeof fastTopFacetABI, 'Disapproval'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastTopFacetABI, eventName: 'Disapproval', ...config } as UseContractEventConfig<
    typeof fastTopFacetABI,
    'Disapproval'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastTopFacetABI}__ and `eventName` set to `"DistributionDeployed"`.
 */
export function useFastTopFacetDistributionDeployedEvent(
  config: Omit<UseContractEventConfig<typeof fastTopFacetABI, 'DistributionDeployed'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({
    abi: fastTopFacetABI,
    eventName: 'DistributionDeployed',
    ...config,
  } as UseContractEventConfig<typeof fastTopFacetABI, 'DistributionDeployed'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastTopFacetABI}__ and `eventName` set to `"DistributionRemoved"`.
 */
export function useFastTopFacetDistributionRemovedEvent(
  config: Omit<UseContractEventConfig<typeof fastTopFacetABI, 'DistributionRemoved'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({
    abi: fastTopFacetABI,
    eventName: 'DistributionRemoved',
    ...config,
  } as UseContractEventConfig<typeof fastTopFacetABI, 'DistributionRemoved'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastTopFacetABI}__ and `eventName` set to `"FastTransfer"`.
 */
export function useFastTopFacetFastTransferEvent(
  config: Omit<UseContractEventConfig<typeof fastTopFacetABI, 'FastTransfer'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastTopFacetABI, eventName: 'FastTransfer', ...config } as UseContractEventConfig<
    typeof fastTopFacetABI,
    'FastTransfer'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastTopFacetABI}__ and `eventName` set to `"Minted"`.
 */
export function useFastTopFacetMintedEvent(
  config: Omit<UseContractEventConfig<typeof fastTopFacetABI, 'Minted'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastTopFacetABI, eventName: 'Minted', ...config } as UseContractEventConfig<
    typeof fastTopFacetABI,
    'Minted'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link fastTopFacetABI}__ and `eventName` set to `"Transfer"`.
 */
export function useFastTopFacetTransferEvent(
  config: Omit<UseContractEventConfig<typeof fastTopFacetABI, 'Transfer'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: fastTopFacetABI, eventName: 'Transfer', ...config } as UseContractEventConfig<
    typeof fastTopFacetABI,
    'Transfer'
  >);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iDiamondCutABI}__.
 */
export function useIDiamondCutWrite<TFunctionName extends string, TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof iDiamondCutABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof iDiamondCutABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof iDiamondCutABI, TFunctionName, TMode>({ abi: iDiamondCutABI, ...config } as any);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link iDiamondCutABI}__.
 */
export function useIDiamondCutEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof iDiamondCutABI, TEventName>, 'abi'> = {} as any,
) {
  return useContractEvent({ abi: iDiamondCutABI, ...config } as UseContractEventConfig<
    typeof iDiamondCutABI,
    TEventName
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link iDiamondCutABI}__ and `eventName` set to `"DiamondCut"`.
 */
export function useIDiamondCutDiamondCutEvent(
  config: Omit<UseContractEventConfig<typeof iDiamondCutABI, 'DiamondCut'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: iDiamondCutABI, eventName: 'DiamondCut', ...config } as UseContractEventConfig<
    typeof iDiamondCutABI,
    'DiamondCut'
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iDiamondLoupeABI}__.
 */
export function useIDiamondLoupeRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof iDiamondLoupeABI, TFunctionName>,
>(config: Omit<UseContractReadConfig<typeof iDiamondLoupeABI, TFunctionName, TSelectData>, 'abi'> = {} as any) {
  return useContractRead({ abi: iDiamondLoupeABI, ...config } as UseContractReadConfig<
    typeof iDiamondLoupeABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc1404ABI}__.
 */
export function useIerc1404Read<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof ierc1404ABI, TFunctionName>,
>(config: Omit<UseContractReadConfig<typeof ierc1404ABI, TFunctionName, TSelectData>, 'abi'> = {} as any) {
  return useContractRead({ abi: ierc1404ABI, ...config } as UseContractReadConfig<
    typeof ierc1404ABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc165ABI}__.
 */
export function useIerc165Read<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof ierc165ABI, TFunctionName>,
>(config: Omit<UseContractReadConfig<typeof ierc165ABI, TFunctionName, TSelectData>, 'abi'> = {} as any) {
  return useContractRead({ abi: ierc165ABI, ...config } as UseContractReadConfig<
    typeof ierc165ABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc173ABI}__.
 */
export function useIerc173Read<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof ierc173ABI, TFunctionName>,
>(config: Omit<UseContractReadConfig<typeof ierc173ABI, TFunctionName, TSelectData>, 'abi'> = {} as any) {
  return useContractRead({ abi: ierc173ABI, ...config } as UseContractReadConfig<
    typeof ierc173ABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc173ABI}__.
 */
export function useIerc173Write<TFunctionName extends string, TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof ierc173ABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof ierc173ABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof ierc173ABI, TFunctionName, TMode>({ abi: ierc173ABI, ...config } as any);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link ierc173ABI}__.
 */
export function useIerc173Event<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof ierc173ABI, TEventName>, 'abi'> = {} as any,
) {
  return useContractEvent({ abi: ierc173ABI, ...config } as UseContractEventConfig<typeof ierc173ABI, TEventName>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link ierc173ABI}__ and `eventName` set to `"OwnershipTransferred"`.
 */
export function useIerc173OwnershipTransferredEvent(
  config: Omit<UseContractEventConfig<typeof ierc173ABI, 'OwnershipTransferred'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: ierc173ABI, eventName: 'OwnershipTransferred', ...config } as UseContractEventConfig<
    typeof ierc173ABI,
    'OwnershipTransferred'
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20ABI}__.
 */
export function useIerc20Read<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof ierc20ABI, TFunctionName>,
>(config: Omit<UseContractReadConfig<typeof ierc20ABI, TFunctionName, TSelectData>, 'abi'> = {} as any) {
  return useContractRead({ abi: ierc20ABI, ...config } as UseContractReadConfig<
    typeof ierc20ABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc20ABI}__.
 */
export function useIerc20Write<TFunctionName extends string, TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof ierc20ABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof ierc20ABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof ierc20ABI, TFunctionName, TMode>({ abi: ierc20ABI, ...config } as any);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iHasActiveMembersABI}__.
 */
export function useIHasActiveMembersRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof iHasActiveMembersABI, TFunctionName>,
>(config: Omit<UseContractReadConfig<typeof iHasActiveMembersABI, TFunctionName, TSelectData>, 'abi'> = {} as any) {
  return useContractRead({ abi: iHasActiveMembersABI, ...config } as UseContractReadConfig<
    typeof iHasActiveMembersABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iHasActiveMembersABI}__.
 */
export function useIHasActiveMembersWrite<TFunctionName extends string, TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof iHasActiveMembersABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof iHasActiveMembersABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof iHasActiveMembersABI, TFunctionName, TMode>({
    abi: iHasActiveMembersABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link issuerAccessFacetABI}__.
 */
export function useIssuerAccessFacetRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof issuerAccessFacetABI, TFunctionName>,
>(config: Omit<UseContractReadConfig<typeof issuerAccessFacetABI, TFunctionName, TSelectData>, 'abi'> = {} as any) {
  return useContractRead({ abi: issuerAccessFacetABI, ...config } as UseContractReadConfig<
    typeof issuerAccessFacetABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link issuerAccessFacetABI}__.
 */
export function useIssuerAccessFacetWrite<TFunctionName extends string, TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof issuerAccessFacetABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof issuerAccessFacetABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof issuerAccessFacetABI, TFunctionName, TMode>({
    abi: issuerAccessFacetABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link issuerAccessFacetABI}__.
 */
export function useIssuerAccessFacetEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof issuerAccessFacetABI, TEventName>, 'abi'> = {} as any,
) {
  return useContractEvent({ abi: issuerAccessFacetABI, ...config } as UseContractEventConfig<
    typeof issuerAccessFacetABI,
    TEventName
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link issuerAccessFacetABI}__ and `eventName` set to `"FastGroupChanged"`.
 */
export function useIssuerAccessFacetFastGroupChangedEvent(
  config: Omit<
    UseContractEventConfig<typeof issuerAccessFacetABI, 'FastGroupChanged'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: issuerAccessFacetABI,
    eventName: 'FastGroupChanged',
    ...config,
  } as UseContractEventConfig<typeof issuerAccessFacetABI, 'FastGroupChanged'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link issuerAccessFacetABI}__ and `eventName` set to `"FastRegistered"`.
 */
export function useIssuerAccessFacetFastRegisteredEvent(
  config: Omit<UseContractEventConfig<typeof issuerAccessFacetABI, 'FastRegistered'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({
    abi: issuerAccessFacetABI,
    eventName: 'FastRegistered',
    ...config,
  } as UseContractEventConfig<typeof issuerAccessFacetABI, 'FastRegistered'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link issuerAccessFacetABI}__ and `eventName` set to `"FastUnregistered"`.
 */
export function useIssuerAccessFacetFastUnregisteredEvent(
  config: Omit<
    UseContractEventConfig<typeof issuerAccessFacetABI, 'FastUnregistered'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: issuerAccessFacetABI,
    eventName: 'FastUnregistered',
    ...config,
  } as UseContractEventConfig<typeof issuerAccessFacetABI, 'FastUnregistered'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link issuerAccessFacetABI}__ and `eventName` set to `"GovernorshipAdded"`.
 */
export function useIssuerAccessFacetGovernorshipAddedEvent(
  config: Omit<
    UseContractEventConfig<typeof issuerAccessFacetABI, 'GovernorshipAdded'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: issuerAccessFacetABI,
    eventName: 'GovernorshipAdded',
    ...config,
  } as UseContractEventConfig<typeof issuerAccessFacetABI, 'GovernorshipAdded'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link issuerAccessFacetABI}__ and `eventName` set to `"GovernorshipRemoved"`.
 */
export function useIssuerAccessFacetGovernorshipRemovedEvent(
  config: Omit<
    UseContractEventConfig<typeof issuerAccessFacetABI, 'GovernorshipRemoved'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: issuerAccessFacetABI,
    eventName: 'GovernorshipRemoved',
    ...config,
  } as UseContractEventConfig<typeof issuerAccessFacetABI, 'GovernorshipRemoved'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link issuerAccessFacetABI}__ and `eventName` set to `"MemberAdded"`.
 */
export function useIssuerAccessFacetMemberAddedEvent(
  config: Omit<UseContractEventConfig<typeof issuerAccessFacetABI, 'MemberAdded'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: issuerAccessFacetABI, eventName: 'MemberAdded', ...config } as UseContractEventConfig<
    typeof issuerAccessFacetABI,
    'MemberAdded'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link issuerAccessFacetABI}__ and `eventName` set to `"MemberRemoved"`.
 */
export function useIssuerAccessFacetMemberRemovedEvent(
  config: Omit<UseContractEventConfig<typeof issuerAccessFacetABI, 'MemberRemoved'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({
    abi: issuerAccessFacetABI,
    eventName: 'MemberRemoved',
    ...config,
  } as UseContractEventConfig<typeof issuerAccessFacetABI, 'MemberRemoved'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link issuerAutomatonsFacetABI}__.
 */
export function useIssuerAutomatonsFacetRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof issuerAutomatonsFacetABI, TFunctionName>,
>(config: Omit<UseContractReadConfig<typeof issuerAutomatonsFacetABI, TFunctionName, TSelectData>, 'abi'> = {} as any) {
  return useContractRead({ abi: issuerAutomatonsFacetABI, ...config } as UseContractReadConfig<
    typeof issuerAutomatonsFacetABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link issuerAutomatonsFacetABI}__.
 */
export function useIssuerAutomatonsFacetWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof issuerAutomatonsFacetABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof issuerAutomatonsFacetABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof issuerAutomatonsFacetABI, TFunctionName, TMode>({
    abi: issuerAutomatonsFacetABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link issuerAutomatonsFacetABI}__.
 */
export function useIssuerAutomatonsFacetEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof issuerAutomatonsFacetABI, TEventName>, 'abi'> = {} as any,
) {
  return useContractEvent({ abi: issuerAutomatonsFacetABI, ...config } as UseContractEventConfig<
    typeof issuerAutomatonsFacetABI,
    TEventName
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link issuerAutomatonsFacetABI}__ and `eventName` set to `"AutomatonPrivilegesSet"`.
 */
export function useIssuerAutomatonsFacetAutomatonPrivilegesSetEvent(
  config: Omit<
    UseContractEventConfig<typeof issuerAutomatonsFacetABI, 'AutomatonPrivilegesSet'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: issuerAutomatonsFacetABI,
    eventName: 'AutomatonPrivilegesSet',
    ...config,
  } as UseContractEventConfig<typeof issuerAutomatonsFacetABI, 'AutomatonPrivilegesSet'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link issuerAutomatonsFacetABI}__ and `eventName` set to `"AutomatonRemoved"`.
 */
export function useIssuerAutomatonsFacetAutomatonRemovedEvent(
  config: Omit<
    UseContractEventConfig<typeof issuerAutomatonsFacetABI, 'AutomatonRemoved'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: issuerAutomatonsFacetABI,
    eventName: 'AutomatonRemoved',
    ...config,
  } as UseContractEventConfig<typeof issuerAutomatonsFacetABI, 'AutomatonRemoved'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link issuerAutomatonsFacetABI}__ and `eventName` set to `"FastGroupChanged"`.
 */
export function useIssuerAutomatonsFacetFastGroupChangedEvent(
  config: Omit<
    UseContractEventConfig<typeof issuerAutomatonsFacetABI, 'FastGroupChanged'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: issuerAutomatonsFacetABI,
    eventName: 'FastGroupChanged',
    ...config,
  } as UseContractEventConfig<typeof issuerAutomatonsFacetABI, 'FastGroupChanged'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link issuerAutomatonsFacetABI}__ and `eventName` set to `"FastRegistered"`.
 */
export function useIssuerAutomatonsFacetFastRegisteredEvent(
  config: Omit<
    UseContractEventConfig<typeof issuerAutomatonsFacetABI, 'FastRegistered'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: issuerAutomatonsFacetABI,
    eventName: 'FastRegistered',
    ...config,
  } as UseContractEventConfig<typeof issuerAutomatonsFacetABI, 'FastRegistered'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link issuerAutomatonsFacetABI}__ and `eventName` set to `"FastUnregistered"`.
 */
export function useIssuerAutomatonsFacetFastUnregisteredEvent(
  config: Omit<
    UseContractEventConfig<typeof issuerAutomatonsFacetABI, 'FastUnregistered'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: issuerAutomatonsFacetABI,
    eventName: 'FastUnregistered',
    ...config,
  } as UseContractEventConfig<typeof issuerAutomatonsFacetABI, 'FastUnregistered'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link issuerAutomatonsFacetABI}__ and `eventName` set to `"GovernorshipAdded"`.
 */
export function useIssuerAutomatonsFacetGovernorshipAddedEvent(
  config: Omit<
    UseContractEventConfig<typeof issuerAutomatonsFacetABI, 'GovernorshipAdded'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: issuerAutomatonsFacetABI,
    eventName: 'GovernorshipAdded',
    ...config,
  } as UseContractEventConfig<typeof issuerAutomatonsFacetABI, 'GovernorshipAdded'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link issuerAutomatonsFacetABI}__ and `eventName` set to `"GovernorshipRemoved"`.
 */
export function useIssuerAutomatonsFacetGovernorshipRemovedEvent(
  config: Omit<
    UseContractEventConfig<typeof issuerAutomatonsFacetABI, 'GovernorshipRemoved'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: issuerAutomatonsFacetABI,
    eventName: 'GovernorshipRemoved',
    ...config,
  } as UseContractEventConfig<typeof issuerAutomatonsFacetABI, 'GovernorshipRemoved'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link issuerFrontendFacetABI}__.
 */
export function useIssuerFrontendFacetRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof issuerFrontendFacetABI, TFunctionName>,
>(config: Omit<UseContractReadConfig<typeof issuerFrontendFacetABI, TFunctionName, TSelectData>, 'abi'> = {} as any) {
  return useContractRead({ abi: issuerFrontendFacetABI, ...config } as UseContractReadConfig<
    typeof issuerFrontendFacetABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link issuerFrontendFacetABI}__.
 */
export function useIssuerFrontendFacetEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof issuerFrontendFacetABI, TEventName>, 'abi'> = {} as any,
) {
  return useContractEvent({ abi: issuerFrontendFacetABI, ...config } as UseContractEventConfig<
    typeof issuerFrontendFacetABI,
    TEventName
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link issuerFrontendFacetABI}__ and `eventName` set to `"FastGroupChanged"`.
 */
export function useIssuerFrontendFacetFastGroupChangedEvent(
  config: Omit<
    UseContractEventConfig<typeof issuerFrontendFacetABI, 'FastGroupChanged'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: issuerFrontendFacetABI,
    eventName: 'FastGroupChanged',
    ...config,
  } as UseContractEventConfig<typeof issuerFrontendFacetABI, 'FastGroupChanged'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link issuerFrontendFacetABI}__ and `eventName` set to `"FastRegistered"`.
 */
export function useIssuerFrontendFacetFastRegisteredEvent(
  config: Omit<
    UseContractEventConfig<typeof issuerFrontendFacetABI, 'FastRegistered'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: issuerFrontendFacetABI,
    eventName: 'FastRegistered',
    ...config,
  } as UseContractEventConfig<typeof issuerFrontendFacetABI, 'FastRegistered'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link issuerFrontendFacetABI}__ and `eventName` set to `"FastUnregistered"`.
 */
export function useIssuerFrontendFacetFastUnregisteredEvent(
  config: Omit<
    UseContractEventConfig<typeof issuerFrontendFacetABI, 'FastUnregistered'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: issuerFrontendFacetABI,
    eventName: 'FastUnregistered',
    ...config,
  } as UseContractEventConfig<typeof issuerFrontendFacetABI, 'FastUnregistered'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link issuerFrontendFacetABI}__ and `eventName` set to `"GovernorshipAdded"`.
 */
export function useIssuerFrontendFacetGovernorshipAddedEvent(
  config: Omit<
    UseContractEventConfig<typeof issuerFrontendFacetABI, 'GovernorshipAdded'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: issuerFrontendFacetABI,
    eventName: 'GovernorshipAdded',
    ...config,
  } as UseContractEventConfig<typeof issuerFrontendFacetABI, 'GovernorshipAdded'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link issuerFrontendFacetABI}__ and `eventName` set to `"GovernorshipRemoved"`.
 */
export function useIssuerFrontendFacetGovernorshipRemovedEvent(
  config: Omit<
    UseContractEventConfig<typeof issuerFrontendFacetABI, 'GovernorshipRemoved'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: issuerFrontendFacetABI,
    eventName: 'GovernorshipRemoved',
    ...config,
  } as UseContractEventConfig<typeof issuerFrontendFacetABI, 'GovernorshipRemoved'>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link issuerInitFacetABI}__.
 */
export function useIssuerInitFacetWrite<TFunctionName extends string, TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof issuerInitFacetABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof issuerInitFacetABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof issuerInitFacetABI, TFunctionName, TMode>({
    abi: issuerInitFacetABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link issuerInitFacetABI}__.
 */
export function useIssuerInitFacetEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof issuerInitFacetABI, TEventName>, 'abi'> = {} as any,
) {
  return useContractEvent({ abi: issuerInitFacetABI, ...config } as UseContractEventConfig<
    typeof issuerInitFacetABI,
    TEventName
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link issuerInitFacetABI}__ and `eventName` set to `"FastGroupChanged"`.
 */
export function useIssuerInitFacetFastGroupChangedEvent(
  config: Omit<UseContractEventConfig<typeof issuerInitFacetABI, 'FastGroupChanged'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({
    abi: issuerInitFacetABI,
    eventName: 'FastGroupChanged',
    ...config,
  } as UseContractEventConfig<typeof issuerInitFacetABI, 'FastGroupChanged'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link issuerInitFacetABI}__ and `eventName` set to `"FastRegistered"`.
 */
export function useIssuerInitFacetFastRegisteredEvent(
  config: Omit<UseContractEventConfig<typeof issuerInitFacetABI, 'FastRegistered'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: issuerInitFacetABI, eventName: 'FastRegistered', ...config } as UseContractEventConfig<
    typeof issuerInitFacetABI,
    'FastRegistered'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link issuerInitFacetABI}__ and `eventName` set to `"FastUnregistered"`.
 */
export function useIssuerInitFacetFastUnregisteredEvent(
  config: Omit<UseContractEventConfig<typeof issuerInitFacetABI, 'FastUnregistered'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({
    abi: issuerInitFacetABI,
    eventName: 'FastUnregistered',
    ...config,
  } as UseContractEventConfig<typeof issuerInitFacetABI, 'FastUnregistered'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link issuerInitFacetABI}__ and `eventName` set to `"GovernorshipAdded"`.
 */
export function useIssuerInitFacetGovernorshipAddedEvent(
  config: Omit<UseContractEventConfig<typeof issuerInitFacetABI, 'GovernorshipAdded'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({
    abi: issuerInitFacetABI,
    eventName: 'GovernorshipAdded',
    ...config,
  } as UseContractEventConfig<typeof issuerInitFacetABI, 'GovernorshipAdded'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link issuerInitFacetABI}__ and `eventName` set to `"GovernorshipRemoved"`.
 */
export function useIssuerInitFacetGovernorshipRemovedEvent(
  config: Omit<
    UseContractEventConfig<typeof issuerInitFacetABI, 'GovernorshipRemoved'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: issuerInitFacetABI,
    eventName: 'GovernorshipRemoved',
    ...config,
  } as UseContractEventConfig<typeof issuerInitFacetABI, 'GovernorshipRemoved'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link issuerInitFacetABI}__ and `eventName` set to `"MemberAdded"`.
 */
export function useIssuerInitFacetMemberAddedEvent(
  config: Omit<UseContractEventConfig<typeof issuerInitFacetABI, 'MemberAdded'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: issuerInitFacetABI, eventName: 'MemberAdded', ...config } as UseContractEventConfig<
    typeof issuerInitFacetABI,
    'MemberAdded'
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link issuerTopFacetABI}__.
 */
export function useIssuerTopFacetRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof issuerTopFacetABI, TFunctionName>,
>(config: Omit<UseContractReadConfig<typeof issuerTopFacetABI, TFunctionName, TSelectData>, 'abi'> = {} as any) {
  return useContractRead({ abi: issuerTopFacetABI, ...config } as UseContractReadConfig<
    typeof issuerTopFacetABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link issuerTopFacetABI}__.
 */
export function useIssuerTopFacetWrite<TFunctionName extends string, TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof issuerTopFacetABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof issuerTopFacetABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof issuerTopFacetABI, TFunctionName, TMode>({ abi: issuerTopFacetABI, ...config } as any);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link issuerTopFacetABI}__.
 */
export function useIssuerTopFacetEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof issuerTopFacetABI, TEventName>, 'abi'> = {} as any,
) {
  return useContractEvent({ abi: issuerTopFacetABI, ...config } as UseContractEventConfig<
    typeof issuerTopFacetABI,
    TEventName
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link issuerTopFacetABI}__ and `eventName` set to `"FastGroupChanged"`.
 */
export function useIssuerTopFacetFastGroupChangedEvent(
  config: Omit<UseContractEventConfig<typeof issuerTopFacetABI, 'FastGroupChanged'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({
    abi: issuerTopFacetABI,
    eventName: 'FastGroupChanged',
    ...config,
  } as UseContractEventConfig<typeof issuerTopFacetABI, 'FastGroupChanged'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link issuerTopFacetABI}__ and `eventName` set to `"FastRegistered"`.
 */
export function useIssuerTopFacetFastRegisteredEvent(
  config: Omit<UseContractEventConfig<typeof issuerTopFacetABI, 'FastRegistered'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: issuerTopFacetABI, eventName: 'FastRegistered', ...config } as UseContractEventConfig<
    typeof issuerTopFacetABI,
    'FastRegistered'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link issuerTopFacetABI}__ and `eventName` set to `"FastUnregistered"`.
 */
export function useIssuerTopFacetFastUnregisteredEvent(
  config: Omit<UseContractEventConfig<typeof issuerTopFacetABI, 'FastUnregistered'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({
    abi: issuerTopFacetABI,
    eventName: 'FastUnregistered',
    ...config,
  } as UseContractEventConfig<typeof issuerTopFacetABI, 'FastUnregistered'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link issuerTopFacetABI}__ and `eventName` set to `"GovernorshipAdded"`.
 */
export function useIssuerTopFacetGovernorshipAddedEvent(
  config: Omit<UseContractEventConfig<typeof issuerTopFacetABI, 'GovernorshipAdded'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({
    abi: issuerTopFacetABI,
    eventName: 'GovernorshipAdded',
    ...config,
  } as UseContractEventConfig<typeof issuerTopFacetABI, 'GovernorshipAdded'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link issuerTopFacetABI}__ and `eventName` set to `"GovernorshipRemoved"`.
 */
export function useIssuerTopFacetGovernorshipRemovedEvent(
  config: Omit<
    UseContractEventConfig<typeof issuerTopFacetABI, 'GovernorshipRemoved'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: issuerTopFacetABI,
    eventName: 'GovernorshipRemoved',
    ...config,
  } as UseContractEventConfig<typeof issuerTopFacetABI, 'GovernorshipRemoved'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link libDiamondABI}__.
 */
export function useLibDiamondEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof libDiamondABI, TEventName>, 'abi'> = {} as any,
) {
  return useContractEvent({ abi: libDiamondABI, ...config } as UseContractEventConfig<
    typeof libDiamondABI,
    TEventName
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link libDiamondABI}__ and `eventName` set to `"DiamondCut"`.
 */
export function useLibDiamondDiamondCutEvent(
  config: Omit<UseContractEventConfig<typeof libDiamondABI, 'DiamondCut'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: libDiamondABI, eventName: 'DiamondCut', ...config } as UseContractEventConfig<
    typeof libDiamondABI,
    'DiamondCut'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link libDiamondABI}__ and `eventName` set to `"OwnershipTransferred"`.
 */
export function useLibDiamondOwnershipTransferredEvent(
  config: Omit<UseContractEventConfig<typeof libDiamondABI, 'OwnershipTransferred'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({
    abi: libDiamondABI,
    eventName: 'OwnershipTransferred',
    ...config,
  } as UseContractEventConfig<typeof libDiamondABI, 'OwnershipTransferred'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link marketplaceAccessFacetABI}__.
 */
export function useMarketplaceAccessFacetRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof marketplaceAccessFacetABI, TFunctionName>,
>(
  config: Omit<UseContractReadConfig<typeof marketplaceAccessFacetABI, TFunctionName, TSelectData>, 'abi'> = {} as any,
) {
  return useContractRead({ abi: marketplaceAccessFacetABI, ...config } as UseContractReadConfig<
    typeof marketplaceAccessFacetABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link marketplaceAccessFacetABI}__.
 */
export function useMarketplaceAccessFacetWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof marketplaceAccessFacetABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof marketplaceAccessFacetABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof marketplaceAccessFacetABI, TFunctionName, TMode>({
    abi: marketplaceAccessFacetABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link marketplaceAccessFacetABI}__.
 */
export function useMarketplaceAccessFacetEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof marketplaceAccessFacetABI, TEventName>, 'abi'> = {} as any,
) {
  return useContractEvent({ abi: marketplaceAccessFacetABI, ...config } as UseContractEventConfig<
    typeof marketplaceAccessFacetABI,
    TEventName
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link marketplaceAccessFacetABI}__ and `eventName` set to `"MemberActivated"`.
 */
export function useMarketplaceAccessFacetMemberActivatedEvent(
  config: Omit<
    UseContractEventConfig<typeof marketplaceAccessFacetABI, 'MemberActivated'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: marketplaceAccessFacetABI,
    eventName: 'MemberActivated',
    ...config,
  } as UseContractEventConfig<typeof marketplaceAccessFacetABI, 'MemberActivated'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link marketplaceAccessFacetABI}__ and `eventName` set to `"MemberAdded"`.
 */
export function useMarketplaceAccessFacetMemberAddedEvent(
  config: Omit<
    UseContractEventConfig<typeof marketplaceAccessFacetABI, 'MemberAdded'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: marketplaceAccessFacetABI,
    eventName: 'MemberAdded',
    ...config,
  } as UseContractEventConfig<typeof marketplaceAccessFacetABI, 'MemberAdded'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link marketplaceAccessFacetABI}__ and `eventName` set to `"MemberDeactivated"`.
 */
export function useMarketplaceAccessFacetMemberDeactivatedEvent(
  config: Omit<
    UseContractEventConfig<typeof marketplaceAccessFacetABI, 'MemberDeactivated'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: marketplaceAccessFacetABI,
    eventName: 'MemberDeactivated',
    ...config,
  } as UseContractEventConfig<typeof marketplaceAccessFacetABI, 'MemberDeactivated'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link marketplaceAccessFacetABI}__ and `eventName` set to `"MemberRemoved"`.
 */
export function useMarketplaceAccessFacetMemberRemovedEvent(
  config: Omit<
    UseContractEventConfig<typeof marketplaceAccessFacetABI, 'MemberRemoved'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: marketplaceAccessFacetABI,
    eventName: 'MemberRemoved',
    ...config,
  } as UseContractEventConfig<typeof marketplaceAccessFacetABI, 'MemberRemoved'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link marketplaceAutomatonsFacetABI}__.
 */
export function useMarketplaceAutomatonsFacetRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof marketplaceAutomatonsFacetABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof marketplaceAutomatonsFacetABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({ abi: marketplaceAutomatonsFacetABI, ...config } as UseContractReadConfig<
    typeof marketplaceAutomatonsFacetABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link marketplaceAutomatonsFacetABI}__.
 */
export function useMarketplaceAutomatonsFacetWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof marketplaceAutomatonsFacetABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof marketplaceAutomatonsFacetABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof marketplaceAutomatonsFacetABI, TFunctionName, TMode>({
    abi: marketplaceAutomatonsFacetABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link marketplaceAutomatonsFacetABI}__.
 */
export function useMarketplaceAutomatonsFacetEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof marketplaceAutomatonsFacetABI, TEventName>, 'abi'> = {} as any,
) {
  return useContractEvent({ abi: marketplaceAutomatonsFacetABI, ...config } as UseContractEventConfig<
    typeof marketplaceAutomatonsFacetABI,
    TEventName
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link marketplaceAutomatonsFacetABI}__ and `eventName` set to `"AutomatonPrivilegesSet"`.
 */
export function useMarketplaceAutomatonsFacetAutomatonPrivilegesSetEvent(
  config: Omit<
    UseContractEventConfig<typeof marketplaceAutomatonsFacetABI, 'AutomatonPrivilegesSet'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: marketplaceAutomatonsFacetABI,
    eventName: 'AutomatonPrivilegesSet',
    ...config,
  } as UseContractEventConfig<typeof marketplaceAutomatonsFacetABI, 'AutomatonPrivilegesSet'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link marketplaceAutomatonsFacetABI}__ and `eventName` set to `"AutomatonRemoved"`.
 */
export function useMarketplaceAutomatonsFacetAutomatonRemovedEvent(
  config: Omit<
    UseContractEventConfig<typeof marketplaceAutomatonsFacetABI, 'AutomatonRemoved'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: marketplaceAutomatonsFacetABI,
    eventName: 'AutomatonRemoved',
    ...config,
  } as UseContractEventConfig<typeof marketplaceAutomatonsFacetABI, 'AutomatonRemoved'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link marketplaceAutomatonsFacetABI}__ and `eventName` set to `"MemberActivated"`.
 */
export function useMarketplaceAutomatonsFacetMemberActivatedEvent(
  config: Omit<
    UseContractEventConfig<typeof marketplaceAutomatonsFacetABI, 'MemberActivated'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: marketplaceAutomatonsFacetABI,
    eventName: 'MemberActivated',
    ...config,
  } as UseContractEventConfig<typeof marketplaceAutomatonsFacetABI, 'MemberActivated'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link marketplaceAutomatonsFacetABI}__ and `eventName` set to `"MemberDeactivated"`.
 */
export function useMarketplaceAutomatonsFacetMemberDeactivatedEvent(
  config: Omit<
    UseContractEventConfig<typeof marketplaceAutomatonsFacetABI, 'MemberDeactivated'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: marketplaceAutomatonsFacetABI,
    eventName: 'MemberDeactivated',
    ...config,
  } as UseContractEventConfig<typeof marketplaceAutomatonsFacetABI, 'MemberDeactivated'>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link marketplaceInitFacetABI}__.
 */
export function useMarketplaceInitFacetWrite<TFunctionName extends string, TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof marketplaceInitFacetABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof marketplaceInitFacetABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof marketplaceInitFacetABI, TFunctionName, TMode>({
    abi: marketplaceInitFacetABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link marketplaceInitFacetABI}__.
 */
export function useMarketplaceInitFacetEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof marketplaceInitFacetABI, TEventName>, 'abi'> = {} as any,
) {
  return useContractEvent({ abi: marketplaceInitFacetABI, ...config } as UseContractEventConfig<
    typeof marketplaceInitFacetABI,
    TEventName
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link marketplaceInitFacetABI}__ and `eventName` set to `"MemberActivated"`.
 */
export function useMarketplaceInitFacetMemberActivatedEvent(
  config: Omit<
    UseContractEventConfig<typeof marketplaceInitFacetABI, 'MemberActivated'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: marketplaceInitFacetABI,
    eventName: 'MemberActivated',
    ...config,
  } as UseContractEventConfig<typeof marketplaceInitFacetABI, 'MemberActivated'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link marketplaceInitFacetABI}__ and `eventName` set to `"MemberDeactivated"`.
 */
export function useMarketplaceInitFacetMemberDeactivatedEvent(
  config: Omit<
    UseContractEventConfig<typeof marketplaceInitFacetABI, 'MemberDeactivated'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: marketplaceInitFacetABI,
    eventName: 'MemberDeactivated',
    ...config,
  } as UseContractEventConfig<typeof marketplaceInitFacetABI, 'MemberDeactivated'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link marketplaceTokenHoldersFacetABI}__.
 */
export function useMarketplaceTokenHoldersFacetRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof marketplaceTokenHoldersFacetABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof marketplaceTokenHoldersFacetABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({ abi: marketplaceTokenHoldersFacetABI, ...config } as UseContractReadConfig<
    typeof marketplaceTokenHoldersFacetABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link marketplaceTokenHoldersFacetABI}__.
 */
export function useMarketplaceTokenHoldersFacetWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof marketplaceTokenHoldersFacetABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof marketplaceTokenHoldersFacetABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof marketplaceTokenHoldersFacetABI, TFunctionName, TMode>({
    abi: marketplaceTokenHoldersFacetABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link marketplaceTokenHoldersFacetABI}__.
 */
export function useMarketplaceTokenHoldersFacetEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof marketplaceTokenHoldersFacetABI, TEventName>, 'abi'> = {} as any,
) {
  return useContractEvent({ abi: marketplaceTokenHoldersFacetABI, ...config } as UseContractEventConfig<
    typeof marketplaceTokenHoldersFacetABI,
    TEventName
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link marketplaceTokenHoldersFacetABI}__ and `eventName` set to `"MemberActivated"`.
 */
export function useMarketplaceTokenHoldersFacetMemberActivatedEvent(
  config: Omit<
    UseContractEventConfig<typeof marketplaceTokenHoldersFacetABI, 'MemberActivated'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: marketplaceTokenHoldersFacetABI,
    eventName: 'MemberActivated',
    ...config,
  } as UseContractEventConfig<typeof marketplaceTokenHoldersFacetABI, 'MemberActivated'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link marketplaceTokenHoldersFacetABI}__ and `eventName` set to `"MemberDeactivated"`.
 */
export function useMarketplaceTokenHoldersFacetMemberDeactivatedEvent(
  config: Omit<
    UseContractEventConfig<typeof marketplaceTokenHoldersFacetABI, 'MemberDeactivated'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: marketplaceTokenHoldersFacetABI,
    eventName: 'MemberDeactivated',
    ...config,
  } as UseContractEventConfig<typeof marketplaceTokenHoldersFacetABI, 'MemberDeactivated'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link marketplaceTopFacetABI}__.
 */
export function useMarketplaceTopFacetRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof marketplaceTopFacetABI, TFunctionName>,
>(config: Omit<UseContractReadConfig<typeof marketplaceTopFacetABI, TFunctionName, TSelectData>, 'abi'> = {} as any) {
  return useContractRead({ abi: marketplaceTopFacetABI, ...config } as UseContractReadConfig<
    typeof marketplaceTopFacetABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link marketplaceTopFacetABI}__.
 */
export function useMarketplaceTopFacetEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof marketplaceTopFacetABI, TEventName>, 'abi'> = {} as any,
) {
  return useContractEvent({ abi: marketplaceTopFacetABI, ...config } as UseContractEventConfig<
    typeof marketplaceTopFacetABI,
    TEventName
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link marketplaceTopFacetABI}__ and `eventName` set to `"MemberActivated"`.
 */
export function useMarketplaceTopFacetMemberActivatedEvent(
  config: Omit<
    UseContractEventConfig<typeof marketplaceTopFacetABI, 'MemberActivated'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: marketplaceTopFacetABI,
    eventName: 'MemberActivated',
    ...config,
  } as UseContractEventConfig<typeof marketplaceTopFacetABI, 'MemberActivated'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link marketplaceTopFacetABI}__ and `eventName` set to `"MemberDeactivated"`.
 */
export function useMarketplaceTopFacetMemberDeactivatedEvent(
  config: Omit<
    UseContractEventConfig<typeof marketplaceTopFacetABI, 'MemberDeactivated'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: marketplaceTopFacetABI,
    eventName: 'MemberDeactivated',
    ...config,
  } as UseContractEventConfig<typeof marketplaceTopFacetABI, 'MemberDeactivated'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link aFastFacetABI}__.
 */
export function useAFastFacetEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof aFastFacetABI, TEventName>, 'abi'> = {} as any,
) {
  return useContractEvent({ abi: aFastFacetABI, ...config } as UseContractEventConfig<
    typeof aFastFacetABI,
    TEventName
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link aFastFacetABI}__ and `eventName` set to `"Approval"`.
 */
export function useAFastFacetApprovalEvent(
  config: Omit<UseContractEventConfig<typeof aFastFacetABI, 'Approval'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: aFastFacetABI, eventName: 'Approval', ...config } as UseContractEventConfig<
    typeof aFastFacetABI,
    'Approval'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link aFastFacetABI}__ and `eventName` set to `"Burnt"`.
 */
export function useAFastFacetBurntEvent(
  config: Omit<UseContractEventConfig<typeof aFastFacetABI, 'Burnt'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: aFastFacetABI, eventName: 'Burnt', ...config } as UseContractEventConfig<
    typeof aFastFacetABI,
    'Burnt'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link aFastFacetABI}__ and `eventName` set to `"CrowdfundDefaultBasisPointFeeSet"`.
 */
export function useAFastFacetCrowdfundDefaultBasisPointFeeSetEvent(
  config: Omit<
    UseContractEventConfig<typeof aFastFacetABI, 'CrowdfundDefaultBasisPointFeeSet'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: aFastFacetABI,
    eventName: 'CrowdfundDefaultBasisPointFeeSet',
    ...config,
  } as UseContractEventConfig<typeof aFastFacetABI, 'CrowdfundDefaultBasisPointFeeSet'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link aFastFacetABI}__ and `eventName` set to `"CrowdfundDeployed"`.
 */
export function useAFastFacetCrowdfundDeployedEvent(
  config: Omit<UseContractEventConfig<typeof aFastFacetABI, 'CrowdfundDeployed'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: aFastFacetABI, eventName: 'CrowdfundDeployed', ...config } as UseContractEventConfig<
    typeof aFastFacetABI,
    'CrowdfundDeployed'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link aFastFacetABI}__ and `eventName` set to `"CrowdfundRemoved"`.
 */
export function useAFastFacetCrowdfundRemovedEvent(
  config: Omit<UseContractEventConfig<typeof aFastFacetABI, 'CrowdfundRemoved'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: aFastFacetABI, eventName: 'CrowdfundRemoved', ...config } as UseContractEventConfig<
    typeof aFastFacetABI,
    'CrowdfundRemoved'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link aFastFacetABI}__ and `eventName` set to `"DetailsChanged"`.
 */
export function useAFastFacetDetailsChangedEvent(
  config: Omit<UseContractEventConfig<typeof aFastFacetABI, 'DetailsChanged'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: aFastFacetABI, eventName: 'DetailsChanged', ...config } as UseContractEventConfig<
    typeof aFastFacetABI,
    'DetailsChanged'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link aFastFacetABI}__ and `eventName` set to `"Disapproval"`.
 */
export function useAFastFacetDisapprovalEvent(
  config: Omit<UseContractEventConfig<typeof aFastFacetABI, 'Disapproval'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: aFastFacetABI, eventName: 'Disapproval', ...config } as UseContractEventConfig<
    typeof aFastFacetABI,
    'Disapproval'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link aFastFacetABI}__ and `eventName` set to `"DistributionDeployed"`.
 */
export function useAFastFacetDistributionDeployedEvent(
  config: Omit<UseContractEventConfig<typeof aFastFacetABI, 'DistributionDeployed'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({
    abi: aFastFacetABI,
    eventName: 'DistributionDeployed',
    ...config,
  } as UseContractEventConfig<typeof aFastFacetABI, 'DistributionDeployed'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link aFastFacetABI}__ and `eventName` set to `"DistributionRemoved"`.
 */
export function useAFastFacetDistributionRemovedEvent(
  config: Omit<UseContractEventConfig<typeof aFastFacetABI, 'DistributionRemoved'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: aFastFacetABI, eventName: 'DistributionRemoved', ...config } as UseContractEventConfig<
    typeof aFastFacetABI,
    'DistributionRemoved'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link aFastFacetABI}__ and `eventName` set to `"FastTransfer"`.
 */
export function useAFastFacetFastTransferEvent(
  config: Omit<UseContractEventConfig<typeof aFastFacetABI, 'FastTransfer'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: aFastFacetABI, eventName: 'FastTransfer', ...config } as UseContractEventConfig<
    typeof aFastFacetABI,
    'FastTransfer'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link aFastFacetABI}__ and `eventName` set to `"Minted"`.
 */
export function useAFastFacetMintedEvent(
  config: Omit<UseContractEventConfig<typeof aFastFacetABI, 'Minted'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: aFastFacetABI, eventName: 'Minted', ...config } as UseContractEventConfig<
    typeof aFastFacetABI,
    'Minted'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link aFastFacetABI}__ and `eventName` set to `"Transfer"`.
 */
export function useAFastFacetTransferEvent(
  config: Omit<UseContractEventConfig<typeof aFastFacetABI, 'Transfer'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: aFastFacetABI, eventName: 'Transfer', ...config } as UseContractEventConfig<
    typeof aFastFacetABI,
    'Transfer'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link iFastEventsABI}__.
 */
export function useIFastEventsEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof iFastEventsABI, TEventName>, 'abi'> = {} as any,
) {
  return useContractEvent({ abi: iFastEventsABI, ...config } as UseContractEventConfig<
    typeof iFastEventsABI,
    TEventName
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link iFastEventsABI}__ and `eventName` set to `"Approval"`.
 */
export function useIFastEventsApprovalEvent(
  config: Omit<UseContractEventConfig<typeof iFastEventsABI, 'Approval'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: iFastEventsABI, eventName: 'Approval', ...config } as UseContractEventConfig<
    typeof iFastEventsABI,
    'Approval'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link iFastEventsABI}__ and `eventName` set to `"Burnt"`.
 */
export function useIFastEventsBurntEvent(
  config: Omit<UseContractEventConfig<typeof iFastEventsABI, 'Burnt'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: iFastEventsABI, eventName: 'Burnt', ...config } as UseContractEventConfig<
    typeof iFastEventsABI,
    'Burnt'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link iFastEventsABI}__ and `eventName` set to `"CrowdfundDefaultBasisPointFeeSet"`.
 */
export function useIFastEventsCrowdfundDefaultBasisPointFeeSetEvent(
  config: Omit<
    UseContractEventConfig<typeof iFastEventsABI, 'CrowdfundDefaultBasisPointFeeSet'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: iFastEventsABI,
    eventName: 'CrowdfundDefaultBasisPointFeeSet',
    ...config,
  } as UseContractEventConfig<typeof iFastEventsABI, 'CrowdfundDefaultBasisPointFeeSet'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link iFastEventsABI}__ and `eventName` set to `"CrowdfundDeployed"`.
 */
export function useIFastEventsCrowdfundDeployedEvent(
  config: Omit<UseContractEventConfig<typeof iFastEventsABI, 'CrowdfundDeployed'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: iFastEventsABI, eventName: 'CrowdfundDeployed', ...config } as UseContractEventConfig<
    typeof iFastEventsABI,
    'CrowdfundDeployed'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link iFastEventsABI}__ and `eventName` set to `"CrowdfundRemoved"`.
 */
export function useIFastEventsCrowdfundRemovedEvent(
  config: Omit<UseContractEventConfig<typeof iFastEventsABI, 'CrowdfundRemoved'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: iFastEventsABI, eventName: 'CrowdfundRemoved', ...config } as UseContractEventConfig<
    typeof iFastEventsABI,
    'CrowdfundRemoved'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link iFastEventsABI}__ and `eventName` set to `"DetailsChanged"`.
 */
export function useIFastEventsDetailsChangedEvent(
  config: Omit<UseContractEventConfig<typeof iFastEventsABI, 'DetailsChanged'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: iFastEventsABI, eventName: 'DetailsChanged', ...config } as UseContractEventConfig<
    typeof iFastEventsABI,
    'DetailsChanged'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link iFastEventsABI}__ and `eventName` set to `"Disapproval"`.
 */
export function useIFastEventsDisapprovalEvent(
  config: Omit<UseContractEventConfig<typeof iFastEventsABI, 'Disapproval'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: iFastEventsABI, eventName: 'Disapproval', ...config } as UseContractEventConfig<
    typeof iFastEventsABI,
    'Disapproval'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link iFastEventsABI}__ and `eventName` set to `"DistributionDeployed"`.
 */
export function useIFastEventsDistributionDeployedEvent(
  config: Omit<UseContractEventConfig<typeof iFastEventsABI, 'DistributionDeployed'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({
    abi: iFastEventsABI,
    eventName: 'DistributionDeployed',
    ...config,
  } as UseContractEventConfig<typeof iFastEventsABI, 'DistributionDeployed'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link iFastEventsABI}__ and `eventName` set to `"DistributionRemoved"`.
 */
export function useIFastEventsDistributionRemovedEvent(
  config: Omit<UseContractEventConfig<typeof iFastEventsABI, 'DistributionRemoved'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({
    abi: iFastEventsABI,
    eventName: 'DistributionRemoved',
    ...config,
  } as UseContractEventConfig<typeof iFastEventsABI, 'DistributionRemoved'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link iFastEventsABI}__ and `eventName` set to `"FastTransfer"`.
 */
export function useIFastEventsFastTransferEvent(
  config: Omit<UseContractEventConfig<typeof iFastEventsABI, 'FastTransfer'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: iFastEventsABI, eventName: 'FastTransfer', ...config } as UseContractEventConfig<
    typeof iFastEventsABI,
    'FastTransfer'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link iFastEventsABI}__ and `eventName` set to `"Minted"`.
 */
export function useIFastEventsMintedEvent(
  config: Omit<UseContractEventConfig<typeof iFastEventsABI, 'Minted'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: iFastEventsABI, eventName: 'Minted', ...config } as UseContractEventConfig<
    typeof iFastEventsABI,
    'Minted'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link iFastEventsABI}__ and `eventName` set to `"Transfer"`.
 */
export function useIFastEventsTransferEvent(
  config: Omit<UseContractEventConfig<typeof iFastEventsABI, 'Transfer'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: iFastEventsABI, eventName: 'Transfer', ...config } as UseContractEventConfig<
    typeof iFastEventsABI,
    'Transfer'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link aIssuerFacetABI}__.
 */
export function useAIssuerFacetEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof aIssuerFacetABI, TEventName>, 'abi'> = {} as any,
) {
  return useContractEvent({ abi: aIssuerFacetABI, ...config } as UseContractEventConfig<
    typeof aIssuerFacetABI,
    TEventName
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link aIssuerFacetABI}__ and `eventName` set to `"FastGroupChanged"`.
 */
export function useAIssuerFacetFastGroupChangedEvent(
  config: Omit<UseContractEventConfig<typeof aIssuerFacetABI, 'FastGroupChanged'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: aIssuerFacetABI, eventName: 'FastGroupChanged', ...config } as UseContractEventConfig<
    typeof aIssuerFacetABI,
    'FastGroupChanged'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link aIssuerFacetABI}__ and `eventName` set to `"FastRegistered"`.
 */
export function useAIssuerFacetFastRegisteredEvent(
  config: Omit<UseContractEventConfig<typeof aIssuerFacetABI, 'FastRegistered'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: aIssuerFacetABI, eventName: 'FastRegistered', ...config } as UseContractEventConfig<
    typeof aIssuerFacetABI,
    'FastRegistered'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link aIssuerFacetABI}__ and `eventName` set to `"FastUnregistered"`.
 */
export function useAIssuerFacetFastUnregisteredEvent(
  config: Omit<UseContractEventConfig<typeof aIssuerFacetABI, 'FastUnregistered'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: aIssuerFacetABI, eventName: 'FastUnregistered', ...config } as UseContractEventConfig<
    typeof aIssuerFacetABI,
    'FastUnregistered'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link aIssuerFacetABI}__ and `eventName` set to `"GovernorshipAdded"`.
 */
export function useAIssuerFacetGovernorshipAddedEvent(
  config: Omit<UseContractEventConfig<typeof aIssuerFacetABI, 'GovernorshipAdded'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: aIssuerFacetABI, eventName: 'GovernorshipAdded', ...config } as UseContractEventConfig<
    typeof aIssuerFacetABI,
    'GovernorshipAdded'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link aIssuerFacetABI}__ and `eventName` set to `"GovernorshipRemoved"`.
 */
export function useAIssuerFacetGovernorshipRemovedEvent(
  config: Omit<UseContractEventConfig<typeof aIssuerFacetABI, 'GovernorshipRemoved'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({
    abi: aIssuerFacetABI,
    eventName: 'GovernorshipRemoved',
    ...config,
  } as UseContractEventConfig<typeof aIssuerFacetABI, 'GovernorshipRemoved'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link iIssuerEventsABI}__.
 */
export function useIIssuerEventsEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof iIssuerEventsABI, TEventName>, 'abi'> = {} as any,
) {
  return useContractEvent({ abi: iIssuerEventsABI, ...config } as UseContractEventConfig<
    typeof iIssuerEventsABI,
    TEventName
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link iIssuerEventsABI}__ and `eventName` set to `"FastGroupChanged"`.
 */
export function useIIssuerEventsFastGroupChangedEvent(
  config: Omit<UseContractEventConfig<typeof iIssuerEventsABI, 'FastGroupChanged'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: iIssuerEventsABI, eventName: 'FastGroupChanged', ...config } as UseContractEventConfig<
    typeof iIssuerEventsABI,
    'FastGroupChanged'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link iIssuerEventsABI}__ and `eventName` set to `"FastRegistered"`.
 */
export function useIIssuerEventsFastRegisteredEvent(
  config: Omit<UseContractEventConfig<typeof iIssuerEventsABI, 'FastRegistered'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: iIssuerEventsABI, eventName: 'FastRegistered', ...config } as UseContractEventConfig<
    typeof iIssuerEventsABI,
    'FastRegistered'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link iIssuerEventsABI}__ and `eventName` set to `"FastUnregistered"`.
 */
export function useIIssuerEventsFastUnregisteredEvent(
  config: Omit<UseContractEventConfig<typeof iIssuerEventsABI, 'FastUnregistered'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({ abi: iIssuerEventsABI, eventName: 'FastUnregistered', ...config } as UseContractEventConfig<
    typeof iIssuerEventsABI,
    'FastUnregistered'
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link iIssuerEventsABI}__ and `eventName` set to `"GovernorshipAdded"`.
 */
export function useIIssuerEventsGovernorshipAddedEvent(
  config: Omit<UseContractEventConfig<typeof iIssuerEventsABI, 'GovernorshipAdded'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({
    abi: iIssuerEventsABI,
    eventName: 'GovernorshipAdded',
    ...config,
  } as UseContractEventConfig<typeof iIssuerEventsABI, 'GovernorshipAdded'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link iIssuerEventsABI}__ and `eventName` set to `"GovernorshipRemoved"`.
 */
export function useIIssuerEventsGovernorshipRemovedEvent(
  config: Omit<UseContractEventConfig<typeof iIssuerEventsABI, 'GovernorshipRemoved'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({
    abi: iIssuerEventsABI,
    eventName: 'GovernorshipRemoved',
    ...config,
  } as UseContractEventConfig<typeof iIssuerEventsABI, 'GovernorshipRemoved'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link aMarketplaceFacetABI}__.
 */
export function useAMarketplaceFacetEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof aMarketplaceFacetABI, TEventName>, 'abi'> = {} as any,
) {
  return useContractEvent({ abi: aMarketplaceFacetABI, ...config } as UseContractEventConfig<
    typeof aMarketplaceFacetABI,
    TEventName
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link aMarketplaceFacetABI}__ and `eventName` set to `"MemberActivated"`.
 */
export function useAMarketplaceFacetMemberActivatedEvent(
  config: Omit<UseContractEventConfig<typeof aMarketplaceFacetABI, 'MemberActivated'>, 'abi' | 'eventName'> = {} as any,
) {
  return useContractEvent({
    abi: aMarketplaceFacetABI,
    eventName: 'MemberActivated',
    ...config,
  } as UseContractEventConfig<typeof aMarketplaceFacetABI, 'MemberActivated'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link aMarketplaceFacetABI}__ and `eventName` set to `"MemberDeactivated"`.
 */
export function useAMarketplaceFacetMemberDeactivatedEvent(
  config: Omit<
    UseContractEventConfig<typeof aMarketplaceFacetABI, 'MemberDeactivated'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: aMarketplaceFacetABI,
    eventName: 'MemberDeactivated',
    ...config,
  } as UseContractEventConfig<typeof aMarketplaceFacetABI, 'MemberDeactivated'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link iMarketplaceEventsABI}__.
 */
export function useIMarketplaceEventsEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof iMarketplaceEventsABI, TEventName>, 'abi'> = {} as any,
) {
  return useContractEvent({ abi: iMarketplaceEventsABI, ...config } as UseContractEventConfig<
    typeof iMarketplaceEventsABI,
    TEventName
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link iMarketplaceEventsABI}__ and `eventName` set to `"MemberActivated"`.
 */
export function useIMarketplaceEventsMemberActivatedEvent(
  config: Omit<
    UseContractEventConfig<typeof iMarketplaceEventsABI, 'MemberActivated'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: iMarketplaceEventsABI,
    eventName: 'MemberActivated',
    ...config,
  } as UseContractEventConfig<typeof iMarketplaceEventsABI, 'MemberActivated'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link iMarketplaceEventsABI}__ and `eventName` set to `"MemberDeactivated"`.
 */
export function useIMarketplaceEventsMemberDeactivatedEvent(
  config: Omit<
    UseContractEventConfig<typeof iMarketplaceEventsABI, 'MemberDeactivated'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: iMarketplaceEventsABI,
    eventName: 'MemberDeactivated',
    ...config,
  } as UseContractEventConfig<typeof iMarketplaceEventsABI, 'MemberDeactivated'>);
}
