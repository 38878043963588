import { Button, Modal } from "antd";
import { PropsWithModalState } from "../../../hooks/useModalState";

interface Props {
  readonly onCloseProposal: () => void;
}

export const CloseProposalModal = ({ state: { isVisible, close }, onCloseProposal }: PropsWithModalState<Props>) => {
  const handleAction = () => {
    onCloseProposal();
    close();
  };

  return (
    <Modal
      className="ModalWrapper"
      closable={false}
      footer={null}
      maskClosable={false}
      open={isVisible}
      onCancel={close}>
      <h2>
        DO YOU WANT TO CLOSE
        <br />
        THIS PROPOSAL?
      </h2>
      <p>YOU CANNOT UNDO THIS ACTION</p>
      <div className="ButtonPairWrapper">
        <Button onClick={close}>Cancel</Button>
        <Button onClick={handleAction}>Close Proposal</Button>
      </div>
    </Modal>
  );
};
