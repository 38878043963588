import { HeaderButton } from "./common";
import { useModalState } from "../../../../hooks/useModalState";
import { MembershipDetails } from "../../../../providers/FastContractContext/types";
import { PropsWithSymbolState } from "../../../../providers/FastSymbolProvider";
import { EthAddress } from "../../../../types/ethers";
import { InviteToFastModal } from "../InviteToFastModal";
import { LocationShareModal } from "../LocationShareModal";

interface UserButtonsProps {
  readonly symbol: string;
  readonly address: EthAddress;
  readonly decimals: number;
  readonly membership: MembershipDetails;
}

export const UserButtons = ({ symbol, membership }: PropsWithSymbolState<UserButtonsProps>) => {
  const shareModalState = useModalState(false);
  const inviteModalState = useModalState(false);
  return (
    <>
      {
        // TODO: Add back in when we have Slack integration.
        membership?.isGovernor && false && (
          <HeaderButton name={"Add To Slack"} onClick={console.log} icon={<i className="icon-slack" />} />
        )
      }
      <HeaderButton name={"Share"} onClick={shareModalState.open} icon={<i className="icon-reply" />} />
      <HeaderButton name={`Invite to ${symbol}`} onClick={inviteModalState.open} />
      <LocationShareModal state={shareModalState} />
      <InviteToFastModal state={inviteModalState} />
    </>
  );
};
