import { getContract } from "@wagmi/core";
import { WalletClient, readContracts } from "wagmi";
import { erc20ABI } from "../__generated__/contracts";
import { BigNumberFormatter } from "../hooks/useBigNumberFormatter";
import { ERC20Contract } from "../types/contracts";
import { EthAddress } from "../types/ethers";

export interface ERC20Info {
  readonly address: EthAddress;
  readonly contract: ERC20Contract;
  readonly name: string;
  readonly symbol: string;
  readonly decimals: number;
  readonly formatter: BigNumberFormatter;
}

export const erc20Fetcher = (address: EthAddress, walletClient: WalletClient): Promise<ERC20Info> => {
  const common = { abi: erc20ABI, address, walletClient };
  return readContracts({
    contracts: [
      { ...common, functionName: "name" },
      { ...common, functionName: "symbol" },
      { ...common, functionName: "decimals" },
    ],
  }).then(([{ result: name }, { result: symbol }, { result: decimals }]): ERC20Info => {
    if (name == undefined || symbol == undefined || decimals == undefined) throw new Error("Empty ERC20 result.");
    return {
      address,
      contract: getContract({ abi: erc20ABI, address, walletClient }),
      name,
      symbol,
      decimals,
      formatter: new BigNumberFormatter(decimals),
    };
  });
};
