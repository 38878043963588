export interface Props {
  readonly unit?: string;
  readonly value?: number;
  readonly text?: string;
  readonly description: string;
  readonly isLocked: boolean;
}

export const DataBox = ({ unit, value, text, description, isLocked }: Props) => {
  return (
    <div className="DataBox">
      <div className="DataBox-Top">
        <p className={isLocked ? "DataBox-Description-Locked" : ""}>{unit}</p>
        <p className={isLocked ? "DataBox-Description-Locked" : ""}>{!isLocked ? value : "*****"}</p>
        <p>{text}</p>
      </div>
      <div className="DataBox-Bottom">
        <p className={isLocked ? "DataBox-Description-Locked" : ""}>{description}</p>
        {isLocked && <i className="icon-lock" />}
      </div>
    </div>
  );
};
