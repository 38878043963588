import { readContract, readContracts, writeContract } from "@wagmi/core";
import { Button } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useCallback, useEffect, useMemo } from "react";
import { Status } from "./Status";
import { distributionABI } from "../../../../__generated__/contracts";
import { ResolvableAddress } from "../../../../components/ResolvableAddress";
import { PaginatedTable } from "../../../../components/tables/PaginatedTable";
import { useErrorHelpers } from "../../../../hooks/useErrorHelpers";
import {
  ResolvedIdentity,
  useAddressesResolver,
} from "../../../../providers/AddressResolver.tsx/AddressResolverProvider";
import { useChain } from "../../../../providers/ChainClient";
import { useDistribution } from "../../../../providers/DistributionProvider/DistributionProvider";
import { Phase } from "../../../../providers/DistributionProvider/types";
import { EthAddress } from "../../../../types/ethers";
import { SimpleToast } from "../../../../types/toasts";

interface BeneficiaryDetails {
  readonly beneficiary: EthAddress;
  readonly beneficiaryFullName: Promise<ResolvedIdentity>;
  readonly owing: bigint;
  readonly withdrawn: boolean;
}

interface Props {
  readonly setIsLoading: (isLoading: boolean) => void;
}

export const Beneficiaries = ({ setIsLoading }: Props) => {
  const {
    info: { address, beneficiaryCount, erc20Info, phase },
    isReceivedWithdrawal,
  } = useDistribution();
  const { chain, signerAddress } = useChain();
  const { resolveAddresses } = useAddressesResolver();
  const { handleError } = useErrorHelpers();

  const explorer = chain.blockExplorers.default.url;

  // When we get an asynchronous update of receiving withdrawal, we disable a spinner.
  useEffect(() => {
    isReceivedWithdrawal && setIsLoading(false);
  }, [isReceivedWithdrawal, setIsLoading]);

  const fetchTotal = useCallback(() => Promise.resolve(Number(beneficiaryCount)), [beneficiaryCount]);

  const fetchItems = useCallback(
    (index: number, perPage: number) => {
      const common = { abi: distributionABI, address };

      return beneficiaryCount > 0n || isReceivedWithdrawal
        ? readContract({
            ...common,
            functionName: "paginateBeneficiaries",
            args: [BigInt(index), BigInt(perPage)],
          })
            .then(([beneficiariesAddresses]) =>
              Promise.all(
                beneficiariesAddresses.map((beneficiaryAddress) =>
                  Promise.all([
                    beneficiaryAddress,
                    readContracts({
                      contracts: [
                        { ...common, functionName: "owings", args: [beneficiaryAddress] },
                        { ...common, functionName: "withdrawn", args: [beneficiaryAddress] },
                      ],
                    }),
                  ]),
                ),
              ),
            )
            .then((beneficiaries): ReadonlyArray<BeneficiaryDetails> => {
              const addresses = beneficiaries.map((beneficiary) => beneficiary[0]);
              const resolving = resolveAddresses(addresses);

              return beneficiaries.reduce<ReadonlyArray<BeneficiaryDetails>>(
                (acc, [beneficiary, [{ result: owing }, { result: withdrawn }]]) => {
                  if (!owing || withdrawn === undefined) return acc;

                  return [
                    ...acc,
                    {
                      beneficiary,
                      beneficiaryFullName: resolving[beneficiary],
                      owing,
                      withdrawn,
                    },
                  ];
                },
                [],
              );
            })
        : Promise.resolve([]);
    },
    [address, beneficiaryCount, isReceivedWithdrawal, resolveAddresses],
  );

  const onWithdraw = useCallback(() => {
    setIsLoading(true);
    SimpleToast.success("Distribution Withdrawal.");

    writeContract({
      abi: distributionABI,
      address,
      functionName: "withdraw",
      args: [signerAddress],
    })
      .then(() => {
        SimpleToast.success("Distribution was withdrawn.");
      })
      .catch((err) => handleError("An error occurred while withdrawing the distribution.", err));
  }, [address, handleError, signerAddress, setIsLoading]);

  const columns: ColumnsType<BeneficiaryDetails> = useMemo(
    () => [
      {
        title: "Name",
        render: (_, { beneficiary, beneficiaryFullName }) => (
          <ResolvableAddress address={beneficiary} explorer={explorer} fullName={beneficiaryFullName} />
        ),
      },
      {
        title: `Amount (${erc20Info.symbol})`,
        render: (_, { owing }) => erc20Info.formatter.format(owing, erc20Info.decimals),
      },
      {
        title: "Status",
        render: (_, { withdrawn }) =>
          phase >= Phase.Withdrawal ? (
            <div className="StatusColumn">
              <Status phase={phase} withdrawn={withdrawn} />
            </div>
          ) : (
            "Not sent yet"
          ),
      },
      {
        title: "",
        render: (_, { beneficiary, withdrawn }) =>
          phase === Phase.Withdrawal && beneficiary === signerAddress ? (
            <Button disabled={withdrawn} onClick={onWithdraw}>
              Withdraw
            </Button>
          ) : (
            <></>
          ),
      },
    ],
    [explorer, erc20Info.decimals, erc20Info.formatter, erc20Info.symbol, phase, onWithdraw, signerAddress],
  );

  return (
    <div className="Distributions-Beneficiaries">
      <h2>Beneficiaries</h2>
      <PaginatedTable columns={columns} fetchTotal={fetchTotal} fetchItems={fetchItems} perPage={10} />
    </div>
  );
};
