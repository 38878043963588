import { PropsWithChildren } from "react";
import { PhaseChangedCallback } from "./CrowdfundProvider";

interface Props {
  readonly address: string;
  // These are setters passed by parent context.
  readonly onPhaseChanged: PhaseChangedCallback;
}

// This component is a top-level listener for Crowdfund events changes.
// We don't want to have this logic in the Crowdfund contract provider, as it contains
// business-specific logic and is in charge of informing the parent context of
// changes happening on-chain in the Crowdfund contracts.
export const Listener = (props: PropsWithChildren<Props>) => {
  const { children } = props;
  return <>{children}</>;
};
