import { Button, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { PropsWithModalState } from "../../../../hooks/useModalState";
import { useFastSymbol } from "../../../../providers/FastSymbolProvider";
import { Paths } from "../../../../routing";

export const DoneModal = ({ state: { isVisible, close } }: PropsWithModalState) => {
  const { symbol } = useFastSymbol();
  const navigate = useNavigate();

  return (
    <Modal
      className="ModalWrapper"
      closable={false}
      footer={null}
      maskClosable={false}
      open={isVisible}
      onCancel={close}>
      <h2>Distribution created</h2>
      <p>
        Now, the issuer needs to accept the transaction and add the fee. After the transaction will automatically
        proceed.
      </p>
      <Button onClick={() => navigate(Paths.Fasts.One.ManageFunds.distributions.replace(":symbol", symbol))}>
        Back to distributions
      </Button>
    </Modal>
  );
};
