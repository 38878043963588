import { MaticLogo, MetaMask, USDCLogo, Web3AuthLogo } from "../assets";
import { ConnectorNames, TokenNames } from "../helpers/chain";
import { assertUnreachable } from "../helpers/utils";

export enum AuthenticationSteps {
  WalletConnection,
  Logging,
}

const titleForAuthenticationStep = (step: AuthenticationSteps) => {
  switch (step) {
    case AuthenticationSteps.WalletConnection:
      return "Connecting the wallet";
    case AuthenticationSteps.Logging:
      return "Login";
    default:
      assertUnreachable(step);
  }
};

export const AuthenticationStepItems = [
  { title: titleForAuthenticationStep(AuthenticationSteps.WalletConnection) },
  { title: titleForAuthenticationStep(AuthenticationSteps.Logging) },
];

export const logoFor = (attribute: string) => {
  switch (attribute) {
    case ConnectorNames.MetaMask:
      return MetaMask;
    case TokenNames.Matic:
      return MaticLogo;
    case TokenNames.USDC:
      return USDCLogo;
    case ConnectorNames.Web3Auth:
      return Web3AuthLogo;
    default:
      return undefined;
  }
};
