import { Button, Form, Input } from "antd";
import { OnboardingSettingsFragment, useConfirmOnboardEthAddressMutation } from "../../../../__generated__/graphql";
import { RequiredFieldRule, formatDateForSignature } from "../../../../helpers/utils";
import { useErrorHelpers } from "../../../../hooks/useErrorHelpers";
import { useChain } from "../../../../providers/ChainClient";
import { StepProps } from "../types";

interface Props extends StepProps {
  readonly fullName: string;
  readonly settings: OnboardingSettingsFragment;
}

interface FormData {
  readonly ethAddress: string;
}

export const SelectWalletAddress = ({ fullName, onboard, refreshOnboard, settings }: Props) => {
  const { signerAddress, walletClient } = useChain();
  const { handleError } = useErrorHelpers();
  const [confirmOnboardEthAddress] = useConfirmOnboardEthAddressMutation();

  const onFinish = ({ ethAddress }: FormData) => {
    const transmittedAt = formatDateForSignature(new Date(Date.now()));
    const digest = settings.confirmEthAddressTemplate
      .replace("{{full_name}}", `${fullName}`)
      .replace("{{eth_address}}", `${signerAddress}`)
      .replace("{{iso8601_timestamp}}", transmittedAt);

    walletClient
      .signMessage({ message: digest })
      .then((ethSignature) => {
        const { id: onboardId, claimToken } = onboard;
        return confirmOnboardEthAddress({
          variables: { input: { claimToken, ethAddress, ethSignature, onboardId, transmittedAt } },
        });
      })
      .then((res) => {
        if (!res.data?.confirmOnboardEthAddress) throw new Error("Invalid response from server.");
        else refreshOnboard(res.data?.confirmOnboardEthAddress?.onboard);
      })
      .catch((err) => handleError("An error occured while confirming the wallet address", err));
  };

  return (
    <div className="ConnectWallet Padding">
      <h2>Wallet address</h2>
      <p className="Bold">
        You will need a digital wallet address to gain access to the platform and to hold the FAST (tokens) you earn.
      </p>

      <Form layout="vertical" requiredMark onFinish={onFinish}>
        <Form.Item
          initialValue={signerAddress}
          label="Please, confirm if you want to connect on TVG-Network with this address"
          name="ethAddress"
          rules={[...RequiredFieldRule]}>
          <Input disabled type="string" placeholder="0x91B1d..." />
        </Form.Item>
        <Button htmlType="submit">
          Confirm Address
          <i className="icon-sign" />
        </Button>
      </Form>

      <p>Please connect to the wallet address that you'd like to be associated with your profile.</p>
      <p>
        This will be the address to access the TokenSphere platform and security tokens, and <b>cannot be changed</b>.
      </p>
      <p>
        We accept{" "}
        <a href="https://metamask.io/download/" target="_blank">
          Metamask{" "}
        </a>
        and{" "}
        <a href="https://www.coinbase.com/wallet/downloads" target="_blank">
          Coinbase
        </a>
        .
      </p>
    </div>
  );
};
