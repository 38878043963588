import { ColumnsType } from "antd/lib/table";
import { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { ResolvedIdentityFragment } from "../../__generated__/graphql";
import { ResolvableAddress } from "../../components/ResolvableAddress";
import { PaginatedTable } from "../../components/tables/PaginatedTable";
import { useAddressesResolver } from "../../providers/AddressResolver.tsx/AddressResolverProvider";
import { useChain } from "../../providers/ChainClient";
import { useFastContext } from "../../providers/FastContractContext/FastContractContext";
import { EthAddress } from "../../types/ethers";

interface TransferProof {
  readonly spender: EthAddress;
  readonly from: EthAddress;
  readonly to: EthAddress;
  readonly amount: bigint;
  readonly blockNumber: bigint;
  readonly ref: string;
  // Augmented at runtime.
  readonly spenderFullName: Promise<ResolvedIdentityFragment | null>;
  readonly fromFullName: Promise<ResolvedIdentityFragment | null>;
  readonly toFullName: Promise<ResolvedIdentityFragment | null>;
}

export const Transfers = () => {
  const { chain } = useChain();
  const {
    details: { symbol, contract: fast, formatter },
  } = useFastContext();
  const explorer = chain.blockExplorers.default.url;
  const { resolveAddresses } = useAddressesResolver();

  const fetchTotal = useCallback(() => fast.read.transferProofCount().then(Number), [fast.read]);
  const fetchItems = useCallback(
    (index: number, pageSize: number) =>
      fast.read
        // Load transfer proofs.
        .paginateTransferProofs([BigInt(index), BigInt(pageSize)])
        // We now are ready to collect addresses and resolve them in the background.
        .then(([proofs]): ReadonlyArray<TransferProof> => {
          // Collect all addresses.
          const addresses = proofs.reduce<ReadonlyArray<EthAddress>>(
            (acc, { spender, from, to }) => [...acc, spender, from, to],
            [],
          );
          // Start resolving the addresses.
          const resolving = resolveAddresses(addresses);
          // Return an array of `TransferProof`.
          return proofs.map(
            (proof): TransferProof => ({
              ...proof,
              fromFullName: resolving[proof.from],
              toFullName: resolving[proof.to],
              spenderFullName: resolving[proof.spender],
            }),
          );
        }),
    [fast, resolveAddresses],
  );

  // Members Table Columns
  const columns: ColumnsType<TransferProof> = useMemo(
    () => [
      {
        title: "Block",
        render: (_, { blockNumber }) => (
          <Link to={`${explorer}/block/${blockNumber.toString()}`} target="_blank">
            {blockNumber.toString()}
          </Link>
        ),
      },
      {
        title: "Reference",
        render: (_, { ref }) => ref || "N/A",
      },
      {
        title: "From",
        render: (_, { from, fromFullName }) => (
          <ResolvableAddress address={from} fullName={fromFullName} explorer={explorer} />
        ),
      },
      {
        title: "To",
        render: (_, { to, toFullName }) => <ResolvableAddress address={to} fullName={toFullName} explorer={explorer} />,
      },
      {
        title: `Amount (${symbol})`,
        render: (_, { amount }) => formatter.format(amount, 2),
      },
    ],
    [explorer, symbol, formatter],
  );

  return (
    <section className="FastTab">
      <h3>List of historical transfers within this network</h3>
      <PaginatedTable columns={columns} fetchItems={fetchItems} fetchTotal={fetchTotal} reverse />
    </section>
  );
};
