import { Button, Form, Input, Modal } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useCallback, useEffect, useState } from "react";
import { PropsWithModalState } from "../../hooks/useModalState";
import { EthAddress } from "../../types/ethers";

interface Props {
  readonly erc20Error?: string;
  readonly erc20Hint?: string;
  readonly title?: string;
  readonly token: string;
  readonly setToken: (address: string) => void;
}

interface FormData {
  readonly tokenAddress: EthAddress;
}

export const ChangeTokenModal = ({
  erc20Hint,
  erc20Error,
  title = "Change Token",
  token,
  setToken,
  state: { isVisible, close },
}: PropsWithModalState<Props>) => {
  const [form] = useForm<FormData>();
  const [initialToken, setInitialToken] = useState<string>();

  useEffect(() => setInitialToken(token), []);

  const onAccept = useCallback(
    ({ tokenAddress }: FormData) => {
      if (erc20Hint) {
        setToken(tokenAddress);
        setInitialToken(tokenAddress);
        close();
      }
    },
    [close, erc20Hint, setInitialToken, setToken],
  );

  const onClose = useCallback(() => {
    if (initialToken) {
      setToken(initialToken);
      form.setFieldValue("tokenAddress", initialToken);
    }
    close();
  }, [close, initialToken, form, setToken]);

  const onTokenAddressChanged: React.ChangeEventHandler<HTMLInputElement> = (e) => setToken(e.target.value);

  return (
    <Modal className="InvestmentDrawdowns-Modal" footer={null} open={isVisible} onCancel={onClose}>
      <h2>{title}</h2>

      <Form form={form} layout="vertical" requiredMark onFinish={onAccept}>
        <Form.Item initialValue={initialToken} extra={erc20Hint || erc20Error} label="Set Token" name="tokenAddress">
          <Input type="string" placeholder="Set Token" onChange={onTokenAddressChanged} />
        </Form.Item>

        <p>
          We recommend USDC as the default currency given its coverage of over 190 countries and ease of on and off
          ramp.
        </p>

        <div className="ButtonPairWrapper">
          <Button onClick={onClose}>Cancel</Button>
          <Button disabled={!!erc20Error || !erc20Hint} htmlType="submit">
            Accept
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
