import { Toaster } from "react-hot-toast";
import { BrowserRouter } from "react-router-dom";
import { CurvedBackground } from "./components/layout/CurvedBackground";
import { AddressesResolverProvider } from "./providers/AddressResolver.tsx/AddressResolverProvider";
import { APIClient } from "./providers/APIClient";
import { ChainClient } from "./providers/ChainClient";
import { SessionProvider } from "./providers/SessionProvider/SessionProvider";
import { TopContractsProvider } from "./providers/TopContractsProvider";
import NavRoutes from "./routing/Routes";

export const App = () => (
  <BrowserRouter>
    <ChainClient>
      <APIClient>
        <SessionProvider>
          <Toaster />
          <CurvedBackground />
          <TopContractsProvider>
            <AddressesResolverProvider>
              <NavRoutes />
            </AddressesResolverProvider>
          </TopContractsProvider>
        </SessionProvider>
      </APIClient>
    </ChainClient>
  </BrowserRouter>
);
