import { Button, Checkbox } from "antd";
import type { CheckboxValueType } from "antd/es/checkbox/Group";
import { useState } from "react";
import { OnboardDocument } from "./OnboardDocument";
import { OnboardingSettingsFragment, useSignOnboardDocumentsMutation } from "../../../../__generated__/graphql";
import { formatDateForSignature, formatDocumentsToSigningTemplate } from "../../../../helpers/utils";
import { useErrorHelpers } from "../../../../hooks/useErrorHelpers";
import { useChain } from "../../../../providers/ChainClient";
import { EthAddress, shortenAddress } from "../../../../types/ethers";
import { StepProps } from "../types";

interface Props extends StepProps {
  readonly ethAddress: EthAddress;
  readonly fullName: string;
  readonly settings: OnboardingSettingsFragment;
}

export const Documentation = ({ ethAddress, fullName, onboard, refreshOnboard, settings }: Props) => {
  const [submittable, setSubmittable] = useState(settings.requiredDocuments.length === 0);
  const { walletClient } = useChain();
  const { handleError } = useErrorHelpers();
  const [signOnboardDocuments] = useSignOnboardDocumentsMutation();

  const onChange = (checkedValues: CheckboxValueType[]) =>
    setSubmittable(checkedValues.length === settings.requiredDocuments.length);

  const onSignDocuments = () => {
    const transmittedAt = formatDateForSignature(new Date(Date.now()));
    const digest = settings.documentsSigningTemplate
      .replace("{{full_name}}", `${fullName}`)
      .replace("{{{documents}}}", formatDocumentsToSigningTemplate(settings.requiredDocuments))
      .replace("{{iso8601_timestamp}}", transmittedAt);

    walletClient
      .signMessage({ message: digest })
      .then((ethSignature) => {
        const { id: onboardId, claimToken } = onboard;
        const documentIds = settings.requiredDocuments.map(({ id }) => id);
        return signOnboardDocuments({
          variables: { input: { claimToken, documentIds, ethSignature, onboardId, transmittedAt } },
        });
      })
      .then((res) => {
        if (!res.data?.signOnboardDocuments) throw new Error("Invalid response from server.");
        else refreshOnboard(res.data?.signOnboardDocuments?.onboard);
      })
      .catch((err) => handleError("An error occured while signing the required document", err));
  };

  return (
    <div className="Documentation Padding">
      <h2>Documentation</h2>
      <p>Please read and sign the below documents for membership: </p>

      {!settings.requiredDocuments.length && <h4>There are no documents to sign.</h4>}

      <Checkbox.Group onChange={onChange}>
        {settings.requiredDocuments.map((document) => (
          <OnboardDocument key={document.id} name={document.name} url={document.downloadUrl} />
        ))}
      </Checkbox.Group>

      <p>I {fullName}, have read and agree to the terms and conditions set out in the above documents</p>

      <Button disabled={!submittable} onClick={onSignDocuments}>
        {submittable ? (
          <>
            Sign with
            <p>{shortenAddress(ethAddress)}</p>
            <i className="icon-sign" />
          </>
        ) : (
          "Sign"
        )}
      </Button>
    </div>
  );
};
