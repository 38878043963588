import { Pages as Errors } from "./errors/index";
import { Pages as Fast } from "./fast/index";
import { Home } from "./Home";
import { Pages as IssuerDashboard } from "./IssuerDashboard/index";

export const Pages = {
  Home,
  Fast,
  Errors,
  IssuerDashboard,
};
