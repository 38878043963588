import { Describe, array, enums, is, number, optional, string, type, union, unknown } from "superstruct";
import { BaseError as ViemError } from "viem";

export type EthAddress = Readonly<`0x${string}`>;

// Type guard on EthAddress.
export const isEthAddress = (address: string): address is EthAddress =>
  address.startsWith("0x") && address.length === 42;

// Throws if the passed string isn't an address.
export const enforceEthAddress = (address: string) => {
  if (!isEthAddress(address)) throw "An ethereum address must be valid at this point of execution flow.";
  else return address;
};

export const shortenAddress = (address: EthAddress) => `${address.substring(0, 8)}...`;

export const shortenAddressForTable = (address: EthAddress) => `${address.slice(0, 5)}...${address.slice(-4)}`;

enum MetamaskErrorCode {
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
  NOT_IMPLEMENTED = "NOT_IMPLEMENTED",
  UNSUPPORTED_OPERATION = "UNSUPPORTED_OPERATION",
  NETWORK_ERROR = "NETWORK_ERROR",
  SERVER_ERROR = "SERVER_ERROR",
  TIMEOUT = "TIMEOUT",
  BUFFER_OVERRUN = "BUFFER_OVERRUN",
  NUMERIC_FAULT = "NUMERIC_FAULT",
  MISSING_NEW = "MISSING_NEW",
  INVALID_ARGUMENT = "INVALID_ARGUMENT",
  MISSING_ARGUMENT = "MISSING_ARGUMENT",
  UNEXPECTED_ARGUMENT = "UNEXPECTED_ARGUMENT",
  CALL_EXCEPTION = "CALL_EXCEPTION",
  INSUFFICIENT_FUNDS = "INSUFFICIENT_FUNDS",
  NONCE_EXPIRED = "NONCE_EXPIRED",
  REPLACEMENT_UNDERPRICED = "REPLACEMENT_UNDERPRICED",
  UNPREDICTABLE_GAS_LIMIT = "UNPREDICTABLE_GAS_LIMIT",
  TRANSACTION_REPLACED = "TRANSACTION_REPLACED",
  ACTION_REJECTED = "ACTION_REJECTED",
}

export interface WagmiError {
  readonly name: string;
  readonly message: string;
  readonly shortMessage: string;
  readonly cause: {
    readonly name: string;
    readonly message: string;
    readonly shortMessage: string;
    readonly data: {
      readonly errorName: string;
      readonly args: ReadonlyArray<unknown>;
      readonly abiItem: {
        readonly type: string;
        readonly name: string;
        readonly inputs: ReadonlyArray<unknown>;
      };
    };
  };
}
export const sWagmiError = type({
  name: string(),
  message: string(),
  shortMessage: string(),
  cause: type({
    name: string(),
    message: string(),
    shortMessage: string(),
    data: type({
      errorName: string(),
      args: unknown(),
      abiItem: type({
        type: string(),
        name: string(),
        inputs: unknown(),
      }),
    }),
  }),
});
export const isWagmiError = (err: unknown): err is WagmiError => is(err, sWagmiError);

// Low level possible code types.
export type EthErrCode = string | number | MetamaskErrorCode;
export const sEthErrCode = union([string(), number(), enums(Object.values(MetamaskErrorCode))]);

// Simple error with code.
export interface EthErrWithCode {
  readonly code: EthErrCode;
}
export const sEthErrWithCode: Describe<EthErrWithCode> = type({
  code: sEthErrCode,
});
export const isEthErrWithCode = (err: unknown): err is EthErrWithCode => is(err, sEthErrWithCode);

// Error with full data.
export interface EthErrWithData {
  readonly error: {
    readonly code: EthErrCode;
    readonly message: string;
    readonly data: {
      readonly code: EthErrCode;
      readonly message: string;
      readonly data: string;
    };
  };
}
export const sEthErrWithData: Describe<EthErrWithData> = type({
  error: type({
    code: sEthErrCode,
    message: string(),
    data: type({
      code: sEthErrCode,
      message: string(),
      data: string(),
    }),
  }),
});
export const isEthErrWithData = (err: unknown): err is EthErrWithData => is(err, sEthErrWithData);

export const sViemError = type({
  name: string(),
  message: string(),
  stack: optional(string()),
  details: optional(string()),
  docsPath: optional(string()),
  metaMessages: optional(array(string())),
  shortMessage: string(),
  version: string(),
  cause: optional(
    type({
      name: string(),
      message: string(),
      details: optional(string()),
      code: optional(number()),
      shortMessage: string(),
    }),
  ),
});
export const isViemError = (err: unknown): err is ViemError => is(err, sViemError);
