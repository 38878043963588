import { getContract } from "@wagmi/core";
import { Button } from "antd";
import { ColumnsType } from "antd/lib/table";
import classnames from "classnames";
import { useCallback, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { crowdfundABI, useFastCrowdfundDeployedEvent } from "../../../__generated__/contracts";
import { ResolvableAddress } from "../../../components/ResolvableAddress";
import { CollapsingColumnTitle } from "../../../components/tables/CollapsingColumnTitle";
import { PaginatedTable } from "../../../components/tables/PaginatedTable";
import { useDisplayingDecimals } from "../../../hooks/useDecimalChange";
import { useAddressesResolver } from "../../../providers/AddressResolver.tsx/AddressResolverProvider";
import { useChain } from "../../../providers/ChainClient";
import * as CrowdfundThings from "../../../providers/CrowdfundProvider/types";
import { erc20Fetcher } from "../../../providers/ERC20Fetcher";
import { useFastContext } from "../../../providers/FastContractContext/FastContractContext";
import { useFastSymbol } from "../../../providers/FastSymbolProvider";
import { useTopContracts } from "../../../providers/TopContractsProvider";
import { Paths, makePath } from "../../../routing";
import { EthAddress } from "../../../types/ethers";

export const List = () => {
  const { symbol } = useFastSymbol();
  const { chain, walletClient } = useChain();
  const {
    membership,
    details: { address: fastAddress, contract: fast, decimals },
  } = useFastContext();
  const { isIssuerMember } = useTopContracts();
  const { resolveAddresses } = useAddressesResolver();
  const navigate = useNavigate();
  const { numberOfDecimals, changeNumberOfDecimals } = useDisplayingDecimals(decimals);

  const explorer = chain.blockExplorers.default.url;

  useFastCrowdfundDeployedEvent({
    address: fastAddress,
    listener: ([{ args: eventData }]) => {
      const { crowdfund } = eventData;
      crowdfund && console.debug(`We detected that a new crowdfund was deployed at ${crowdfund}. What should we do?`);
    },
  });

  const isIssuerAndNotMember = useMemo(
    () => isIssuerMember && !membership.isMember,
    [isIssuerMember, membership.isMember],
  );

  // Simply generates links compliant with `/fast/:symbol/drawdowns/:address`.
  const crowdfundLink = useCallback(
    (address: string) => makePath(Paths.Fasts.One.ManageFunds.Drawdowns.one, { symbol, drawdown: address }),
    [symbol],
  );

  const fetchTotal = useCallback(() => fast.read.crowdfundCount().then(Number), [fast]);
  const fetchItems = useCallback(
    (index: number, perPage: number) =>
      // Fetch crowdfund addresses from FAST contract.
      fast.read
        // Get a page of crowdfunds.
        .paginateDetailedCrowdfunds([BigInt(index), BigInt(perPage)])
        .then(([crowdfundsDetails]) =>
          Promise.all(
            crowdfundsDetails.map((details) =>
              Promise.all([details, erc20Fetcher(details.params.token, walletClient)]),
            ),
          ),
        )
        .then((stuff) =>
          stuff.map(([details, erc20Info]): CrowdfundThings.Info => {
            const { VERSION: version, addr: address, phase, ...rest } = details;

            return {
              ...rest,
              address,
              version,
              erc20Info,
              phase: CrowdfundThings.enforceNumericPhase(phase),
              contract: getContract({ abi: crowdfundABI, address, walletClient }),
            };
          }),
        )
        // We now are ready to collect addresses and resolve them in the background.
        .then((crowdfunds): ReadonlyArray<CrowdfundThings.Info> => {
          // Collect all addresses.
          const addresses = crowdfunds.reduce<ReadonlyArray<EthAddress>>(
            (acc, { params: { owner, beneficiary } }) => [...acc, owner, beneficiary],
            [],
          );
          // Start resolving the addresses.
          const resolving = resolveAddresses(addresses);
          // Return an array of `CrowdfundThings.Info`.
          return crowdfunds.map((crowdfund) => ({
            ...crowdfund,
            params: {
              ...crowdfund.params,
              address: resolving[crowdfund.address],
              ownerFullName: resolving[crowdfund.params.owner],
              beneficiaryFullName: resolving[crowdfund.params.beneficiary],
            },
          }));
        }),
    [fast, resolveAddresses, walletClient],
  );

  const columns: ColumnsType<CrowdfundThings.Info> = useMemo(
    () => [
      {
        title: "Block",
        render: (_, { creationBlock }) => (
          <Link to={`${explorer}/block/${creationBlock.toString()}`} title="View block on block explorer" target="_blank">
            {creationBlock.toString()}
          </Link>
        ),
      },
      {
        title: "Reference",
        render: (_, { address, params: { ref } }) => (
          <>
            {ref}
            <Link to={`${explorer}/address/${address}`} title="View on block explorer" target="_blank">
              <i className="icon-new-window"></i>
            </Link>
          </>
        )
      },
      {
        title: "Requested by",
        render: (_, { params: { owner, ownerFullName } }) => (
          <ResolvableAddress address={owner} fullName={ownerFullName} explorer={explorer} />
        ),
      },
      {
        title: "Beneficiary",
        render: (_, { params: { beneficiary, beneficiaryFullName } }) => (
          <ResolvableAddress address={beneficiary} fullName={beneficiaryFullName} explorer={explorer} />
        ),
      },
      {
        title: "Pledgers",
        render: (_, { pledgerCount }) => pledgerCount.toString(10),
      },
      {
        title: (
          <CollapsingColumnTitle
            decimals={decimals}
            numberOfDecimals={numberOfDecimals}
            title="Raised"
            changeNumberOfDecimals={changeNumberOfDecimals}
          />
        ),
        render: (_, { collected, erc20Info: { symbol: currencySymbol, formatter } }) => {
          const formattedAmount = formatter.format(collected, numberOfDecimals);

          return formattedAmount.includes("...") ? (
            <p title={formatter.format(collected, decimals)}>{`${formattedAmount} ${currencySymbol}`}</p>
          ) : (
            `${formattedAmount} ${currencySymbol}`
          );
        },
      },
      {
        title: " Status",
        render: (_, { phase }) => {
          const formattedPhase = CrowdfundThings.formatPhase(phase);
          return (
            <div
              className={classnames("InvestmentDrawdowns-Phase", `InvestmentDrawdowns-Phase-${formattedPhase.status}`)}>
              {formattedPhase.status}
              {phase === CrowdfundThings.Phase.Setup && isIssuerMember && <div>Needs approval</div>}
            </div>
          );
        },
      },
      {
        title: "",
        render: (_, { address, phase }) => {
          const isDisabled = phase === CrowdfundThings.Phase.Setup && !isIssuerMember;
          const label =
            phase === CrowdfundThings.Phase.Funding && isIssuerAndNotMember
              ? CrowdfundThings.formatPhase(CrowdfundThings.Phase.Success).buttonLabel
              : CrowdfundThings.formatPhase(phase).buttonLabel;
          const buttonClass =
            phase > 1 || (phase === CrowdfundThings.Phase.Funding && isIssuerAndNotMember) ? "Button-Secondary" : "";
          return (
            <Button disabled={isDisabled} className={buttonClass} onClick={() => navigate(crowdfundLink(address))}>
              {label}
            </Button>
          );
        },
      },
    ],
    [
      changeNumberOfDecimals,
      crowdfundLink,
      decimals,
      explorer,
      isIssuerAndNotMember,
      isIssuerMember,
      navigate,
      numberOfDecimals,
    ],
  );

  return (
    <section className="FastTab InvestmentDrawdowns">
      <div className="TitleBlockWithButton">
        <h2>INVESTMENT DRAWDOWNS</h2>
        {membership.isGovernor && (
          <Button onClick={() => navigate(Paths.Fasts.One.ManageFunds.Drawdowns.new.replace(":symbol", symbol))}>
            New Investment Drawdown
          </Button>
        )}
      </div>
      <PaginatedTable columns={columns} fetchItems={fetchItems} fetchTotal={fetchTotal} reverse />
    </section>
  );
};
