import { Button, Form, Input, Radio, Spin } from "antd";
import { useForm, useWatch } from "antd/lib/form/Form";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CloseProposalModal } from "./CloseProposalModal";
import { VoteModal } from "./VoteModal";
import { VoteResultBlock } from "./VoteResultBlock";
import { useCloseProposalMutation, useVoteMutation } from "../../../__generated__/graphql";
import { Breadcrumbs } from "../../../components/Breadcrumbs";
import { StatusLabel } from "../../../components/uiElements/StatusLabel";
import { RequiredFieldRule, formatDateForSignature, stringToShortIsoDate } from "../../../helpers/utils";
import { useErrorHelpers } from "../../../hooks/useErrorHelpers";
import { useModalState } from "../../../hooks/useModalState";
import { useAppContext } from "../../../providers/APIClient";
import { useChain } from "../../../providers/ChainClient";
import { useFastContext } from "../../../providers/FastContractContext/FastContractContext";
import { useFastSymbol } from "../../../providers/FastSymbolProvider";
import { useProposal } from "../../../providers/ProposalProvider/ProposalProvider";
import { Paths } from "../../../routing";
import { SimpleToast } from "../../../types/toasts";
import { useFastDetails } from "../Top";

interface VotingForm {
  readonly vote: string;
  readonly comment: string;
}

export const View = () => {
  const { info: proposal, updateProposal } = useProposal();
  const { symbol } = useFastSymbol();
  const { walletClient } = useChain();
  const { sessionData } = useAppContext();
  const { fastDetails, updateFastDetails } = useFastDetails();
  const {
    membership: { isGovernor },
  } = useFastContext();
  const [voteMutation] = useVoteMutation();
  const [closeProposalMutation] = useCloseProposalMutation();
  const { handleError } = useErrorHelpers();
  const navigate = useNavigate();
  const voteModalState = useModalState(false);
  const closeProposalModalState = useModalState(false);
  const [form] = useForm<VotingForm>();
  const commentValue = useWatch("comment", form);
  const voteValue = useWatch("vote", form);
  const [isLoading, setIsLoading] = useState(false);

  const onVote = useCallback(() => {
    if (proposal.hasVoted || !sessionData?.identity.fullName) return;
    setIsLoading(true);

    const transmittedAt = formatDateForSignature(new Date(Date.now()));
    const digest = `I {{full_name}} confirm that I voted "{{choice}}" at {{iso8601_timestamp}} using {{eth_address}}.

Proposal: {{proposal}}
Votable: {{votable}}`
      .replace("{{full_name}}", `${sessionData.identity.fullName}`)
      .replace("{{choice}}", voteValue)
      .replace("{{iso8601_timestamp}}", transmittedAt)
      .replace("{{eth_address}}", `${sessionData.identity.ethAddress}`)
      .replace("{{proposal}}", `{${proposal.title}, ${proposal.id}}`)
      .replace("{{votable}}", `{Fast, ${fastDetails.id}}`);

    walletClient
      .signMessage({ message: digest })
      .then((ethSignature) =>
        voteMutation({
          variables: {
            input: { choice: voteValue, comment: commentValue, ethSignature, proposalId: proposal.id, transmittedAt },
          },
        }),
      )
      .then((res) => {
        if (!res.data?.vote) throw new Error("Invalid response from server.");
        SimpleToast.success("You have successfully voted.");
        return updateFastDetails().then(() =>
          updateProposal().then(() => navigate(Paths.Fasts.One.proposals.replace(":symbol", symbol))),
        );
      })
      .catch((err) => handleError("An error occured while voting", err))
      .finally(() => setIsLoading(false));
  }, [
    commentValue,
    fastDetails.id,
    handleError,
    proposal.hasVoted,
    proposal.id,
    proposal.title,
    navigate,
    sessionData?.identity.ethAddress,
    sessionData?.identity.fullName,
    symbol,
    voteMutation,
    voteValue,
    walletClient,
    updateFastDetails,
    updateProposal,
  ]);

  const onCloseProposal = useCallback(() => {
    setIsLoading(true);

    closeProposalMutation({ variables: { input: { proposalId: proposal.id } } })
      .then((res) => {
        if (!res.data?.closeProposal) throw new Error("Invalid response from server.");
        SimpleToast.success("The Proposal was closed.");
        return updateFastDetails().then(() => navigate(Paths.Fasts.One.proposals.replace(":symbol", symbol)));
      })
      .catch((err) => handleError("An error occured while closing the proposal", err))
      .finally(() => setIsLoading(false));
  }, [closeProposalMutation, proposal.id, handleError, navigate, symbol, updateFastDetails]);

  const renderCloseProposal = useMemo(() => {
    // Proposer or Governor can close the proposal.
    if (isGovernor || proposal.proposer?.fullName === sessionData?.identity.fullName) {
      return (
        <>
          <Button className="Button-Secondary" onClick={closeProposalModalState.open}>
            Close Proposal
          </Button>

          <CloseProposalModal state={closeProposalModalState} onCloseProposal={onCloseProposal} />
        </>
      );
    }
  }, [closeProposalModalState, proposal.proposer?.fullName, isGovernor, sessionData?.identity.fullName, onCloseProposal]);

  return (
    <>
      <div className="Content-Header">
        <Breadcrumbs />
        <h1>{proposal.title}</h1>
      </div>

      <section className="Content-Body">
        <div className="Proposal-View">
          <Spin spinning={isLoading} size="large">
            <div className="Proposal-About">
              <div className="Proposal-Info">
                <StatusLabel isClosed={proposal.state === "CLOSED"} status={proposal.state} />
                <p>{`Published on ${stringToShortIsoDate(proposal.openedAt)} by ${proposal.proposer.fullName || ""}`}</p>
                <div className="Proposal-DescriptionBlock">
                  <p>Description</p>
                  <p>{proposal.description}</p>
                </div>
                {proposal.state !== "CLOSED" && renderCloseProposal}
              </div>

              {proposal.state !== "CLOSED" && !proposal.hasVoted && (
                <div className="Proposal-Voting">
                  <h4>Cast your vote</h4>
                  <Form
                    autoComplete="off"
                    disabled={isLoading}
                    form={form}
                    layout="vertical"
                    requiredMark
                    onFinish={voteModalState.open}>
                    <Form.Item name="vote" rules={[...RequiredFieldRule]}>
                      <Radio.Group>
                        {proposal.choices.map((choice) => (
                          <Radio key={choice} value={choice}>
                            {choice}
                          </Radio>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item label="Add your comment" name="comment" rules={[...RequiredFieldRule]}>
                      <Input.TextArea maxLength={300} rows={5} placeholder="Type your message" showCount />
                    </Form.Item>
                    <Button htmlType="submit">
                      Vote
                      <i className="icon-sign" />
                    </Button>
                  </Form>

                  <VoteModal description={commentValue} state={voteModalState} vote={voteValue} onVote={onVote} />
                </div>
              )}

          </div>
            {proposal.tally && proposal.votes && <VoteResultBlock tally={proposal.tally} votes={proposal.votes} />}
          </Spin>
        </div>
      </section>
    </>
  );
};
