import { HTMLMotionProps, MotionProps, motion } from "framer-motion";
import { PropsWithChildren } from "react";

const defaultInitialProp = { y: 20, opacity: 0 };
const defaultAnimateProp = { y: 0, opacity: 1 };
const defaultTransition = {
  delay: 0.25,
  duration: 0.2,
};

export const AppearanceTransition = ({
  animate,
  children,
  className,
  initial,
  transition,
}: PropsWithChildren<MotionProps & HTMLMotionProps<"div">>) => (
  <motion.div
    className={className}
    initial={initial ? initial : defaultInitialProp}
    animate={animate ? animate : defaultAnimateProp}
    transition={transition ? transition : defaultTransition}>
    {children}
  </motion.div>
);
