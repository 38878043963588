import { useParams } from "react-router-dom";
import { InviteToPlatform } from "./InviteToPlatform";
import { FastsDropdown } from "../../components/FastsDropdown";
import { AppearanceTransition } from "../../components/layout/AppearanceTransition";

export const Header = () => {
  const { symbol } = useParams();

  return (
    <>
      <AppearanceTransition initial={{ y: -70, opacity: 0 }} animate={{ y: 0, opacity: 1 }}>
        <header className="Header">
          <FastsDropdown />
          {!symbol && <InviteToPlatform />}
        </header>
      </AppearanceTransition>
    </>
  );
};
