import { Button, Image, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { CheckMarkIcon } from "../../../../assets";
import { PropsWithModalState } from "../../../../hooks/useModalState";
import { useFastSymbol } from "../../../../providers/FastSymbolProvider";
import { Paths } from "../../../../routing";

interface Props {
  readonly refValue: string;
}

export const DrawdownCreatedModal = ({ refValue, state: { isVisible, close } }: PropsWithModalState<Props>) => {
  const { symbol } = useFastSymbol();
  const navigate = useNavigate();

  return (
    <Modal
      className="InvestmentDrawdowns-Modal"
      closable={false}
      footer={null}
      maskClosable={false}
      open={isVisible}
      onCancel={close}>
      <Image alt="Check mark" src={CheckMarkIcon} preview={false} />
      <h2>DRAFT CREATED</h2>
      <p>Reference: {refValue}</p>
      <p>Once the SPC Board accepts the request you and all your FAST members will be notified to make investments.</p>
      <Button onClick={() => navigate(Paths.Fasts.One.ManageFunds.drawdowns.replace(":symbol", symbol))}>
        Understood
      </Button>
    </Modal>
  );
};
