import { WriteContractResult, writeContract } from "@wagmi/core";
import { Button, Col, Form, InputNumber, Row, Space, Switch } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useMemo, useState } from "react";
import { fastABI } from "../../../__generated__/contracts";
import { useErrorHelpers } from "../../../hooks/useErrorHelpers";
import { FastDetails } from "../../../providers/FastContractContext/types";
import { EthAddress } from "../../../types/ethers";

interface SettingsForm {
  readonly transfersEnabled: boolean;
  readonly crowdfundsDefaultFee: number;
}

interface Props {
  readonly address: EthAddress;
  readonly fastDetails: FastDetails;
}

export const Settings = ({ address, fastDetails }: Props) => {
  const { trackTransaction } = useErrorHelpers();
  const [form] = useForm<SettingsForm>();
  const [isLoading, setIsLoading] = useState(false);

  const initialValues: SettingsForm = useMemo(
    () => ({
      transfersEnabled: !fastDetails.transfersDisabled,
      crowdfundsDefaultFee: fastDetails.crowdfundsDefaultBasisPointsFee / 100,
    }),
    [fastDetails],
  );

  const onFinish = (formData: SettingsForm) => {
    let tx: Promise<WriteContractResult>;
    if (formData.transfersEnabled !== !fastDetails.transfersDisabled) {
      tx = writeContract({
        abi: fastABI,
        address,
        functionName: "setTransfersDisabled",
        args: [!formData.transfersEnabled],
      });
      void trackTransaction(tx, "Enabling / Disabling Transfers", "Success!", setIsLoading);
    } else if (formData.crowdfundsDefaultFee * 100 != fastDetails.crowdfundsDefaultBasisPointsFee) {
      tx = writeContract({
        abi: fastABI,
        address,
        functionName: "setCrowdfundsDefaultBasisPointFee",
        args: [formData.crowdfundsDefaultFee * 100],
      });
      void trackTransaction(tx, "Changing Crowdfunds Default Fee", "Success!", setIsLoading);
    }
  };

  return (
    <Row>
      <Col>
        <h2>Settings</h2>
        <Form
          form={form}
          initialValues={initialValues}
          layout="horizontal"
          labelAlign="left"
          labelCol={{ flex: "180px" }}
          requiredMark
          onFinish={onFinish}
          disabled={isLoading}>
          <Row>
            <Col>
              <Form.Item required name="transfersEnabled" label="Transfers Activity" valuePropName="checked">
                <Switch />
              </Form.Item>
              <Form.Item required name="crowdfundsDefaultFee" label="Crowdfunds Default Fee">
                <InputNumber min={0} max={100} precision={2} step={0.5} />
              </Form.Item>
            </Col>
          </Row>
          <Space>
            <Button htmlType="submit">Save</Button>
          </Space>
        </Form>
      </Col>
    </Row>
  );
};
