import { writeContract } from "@wagmi/core";
import { Button } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useCallback, useMemo } from "react";
import { AddAddressModal } from "./AddAddressModal";
import { fastABI } from "../../../__generated__/contracts";
import { ResolvableAddress } from "../../../components/ResolvableAddress";
import { AddressColumn } from "../../../components/tables/AddressColumn";
import { PaginatedTable } from "../../../components/tables/PaginatedTable";
import { useErrorHelpers } from "../../../hooks/useErrorHelpers";
import { useModalState } from "../../../hooks/useModalState";
import { ResolvedIdentity, useAddressesResolver } from "../../../providers/AddressResolver.tsx/AddressResolverProvider";
import { useChain } from "../../../providers/ChainClient";
import { useFastContext } from "../../../providers/FastContractContext/FastContractContext";
import { useTopContracts } from "../../../providers/TopContractsProvider";
import { EthAddress } from "../../../types/ethers";

interface GovernorDetails {
  readonly address: EthAddress;
  readonly isMember: boolean;
  // Augmented at runtime.
  readonly ethBalance: bigint;
  readonly fullName?: Promise<ResolvedIdentity>;
}

export const GovernorsTable = () => {
  const { chain } = useChain();
  const { isIssuerMember } = useTopContracts();
  const {
    details: { address: fastAddress, contract: fast },
  } = useFastContext();
  const { trackTransaction } = useErrorHelpers();
  const { resolveAddresses } = useAddressesResolver();
  const addAddressModalState = useModalState(false);

  const explorer = chain.blockExplorers.default.url;

  const fetchTotal = useCallback(() => fast.read.governorCount().then(Number), [fast]);
  const fetchItems = useCallback(
    (index: number, pageSize: number) =>
      fast.read
        .paginateDetailedGovernors([BigInt(index), BigInt(pageSize)])
        // Make the `GovernorDetails` list.
        .then(([governors]) => {
          type Acc = { governors: ReadonlyArray<GovernorDetails>; addresses: ReadonlyArray<EthAddress> };
          return governors.reduce<Acc>(
            (acc, { addr: address, ethBalance, isMember }): Acc => {
              return {
                governors: [...acc.governors, { address, ethBalance, isMember }],
                addresses: [...acc.addresses, address],
              };
            },
            { governors: [], addresses: [] },
          );
        })
        // We now are ready to collect addresses and resolve them in the background.
        .then(({ governors, addresses }): ReadonlyArray<GovernorDetails> => {
          const resolving = resolveAddresses(addresses);
          // Augment the governor details with the full name promise.
          return governors.map((governor) => ({ ...governor, fullName: resolving[governor.address] }));
        }),
    [fast, resolveAddresses],
  );

  const issuanceCols: ColumnsType<GovernorDetails> = useMemo(() => {
    const onRemoveGovernor = (address: EthAddress) => {
      const tx = writeContract({
        abi: fastABI,
        address: fastAddress,
        functionName: "removeGovernor",
        args: [address],
      });
      void trackTransaction(tx, "Coordinator Removal", () => `Coordinator ${address} has been removed`);
    };

    return [
      {
        title: "",
        render: (_, { address }) => (
          <Button className="Table-Remove-Button" onClick={() => onRemoveGovernor(address)}>
            <i className="icon-trash" />
          </Button>
        ),
      },
    ];
  }, [fastAddress, trackTransaction]);

  // Governors Table Columns
  const columns: ColumnsType<GovernorDetails> = useMemo(
    () => [
      {
        title: "Coordinator",
        render: (_, { address, fullName }) => (
          <ResolvableAddress address={address} fullName={fullName} explorer={explorer} />
        ),
      },
      {
        title: "Address",
        render: (_, { address }) => <AddressColumn ethAddress={address} />,
      },
      ...(isIssuerMember ? issuanceCols : []),
    ],
    [explorer, isIssuerMember, issuanceCols],
  );

  return (
    <section className="FastTab Community">
      <div className="Community-Top">
        <h3>Current coordinators</h3>
        <Button onClick={addAddressModalState.open}>Add Coordinator</Button>
        <AddAddressModal state={addAddressModalState} />
      </div>
      <PaginatedTable fetchTotal={fetchTotal} fetchItems={fetchItems} columns={columns} />
    </section>
  );
};
