import { Image } from "antd";
import { CGEmail } from "../../helpers/utils";

type FastInfo = {
  readonly logoUrl?: string | null;
  readonly name: string;
  readonly symbol: string;
};

interface Props {
  readonly fastsInfo: FastInfo[];
}

export const InfoBlock = ({ fastsInfo }: Props) => (
  <>
    <p>The TokenSphere Platform hosts FASTs across different sectors and maturities.</p>
    <p>
      You can take this opportunity to become a part of several others FASTs and join their communities by requesting
      membership.
    </p>
    <p>You are now applying to join:</p>
    <div className="Onboarding-Fasts">
      {fastsInfo.map((fast) => (
        <div key={fast.symbol} className="Onboarding-Block">
          {fast.logoUrl && <Image alt="Fast Logo" src={fast.logoUrl} preview={false} />}
          <div>
            <h4>{fast.name}</h4>
            <h4>{fast.symbol}</h4>
          </div>
        </div>
      ))}
    </div>
    <p>
      For any question, please <a href={`mailto:${CGEmail}`}>contact us.</a>
    </p>
  </>
);
