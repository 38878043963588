import { Rule } from "antd/lib/form";
import { DocumentFragment } from "../__generated__/graphql";

export type Nullable<T> = T | null;

// Helpful constants.
export const CGEmail = "contact@tokensphere.io";
export const RequiredFieldRule: ReadonlyArray<Rule> = [{ required: true, message: "Required field" }];
export const DecimalStringRule: ReadonlyArray<Rule> = [
  { pattern: /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/, message: "Invalid number" },
];
export const RequiredEmailRule: ReadonlyArray<Rule> = [
  {
    type: "email",
    message: "The input is not valid Email",
  },
  {
    required: true,
    message: "Required field",
  },
];

export const enum Environment {
  Production = "Production",
  Staging = "Staging",
  Development = "Development",
}

const REACT_APP_BRANCH = process.env["REACT_APP_BRANCH"];
export const Env =
  REACT_APP_BRANCH === "master"
    ? Environment.Production
    : REACT_APP_BRANCH === "develop"
    ? Environment.Staging
    : Environment.Development;
console.info(`Environment: ${Env}.`);

export const isProduction = Env === Environment.Production;

// Enforces that some code should never be reached.
export const assertUnreachable = (x: never): never => {
  throw new Error(`Value should be 'never' but found ${JSON.stringify(x)} instead.`);
};

// Returns true whether the passed parameter is an enum.
export const isEnum = <T extends { [s: string]: unknown } | ArrayLike<unknown>>(
  value: unknown,
  e: T,
): value is T[keyof T] => Object.values(e).includes(value as T[keyof T]);

// Returns a signed-plaintext-compatible string representation of the passed date.
export const formatDateForSignature = (d: Date) => d.toISOString().split(".")[0] + "Z";

// Returns a formatted string like "dd/mm/yyyy".
export const stringToShortIsoDate = (inputDate: string) =>
  new Date(inputDate).toISOString().substring(0, 10).split("-").reverse().join("/");

// Returns a formatted string for signing onboarding documents.
export const formatDocumentsToSigningTemplate = (documents: ReadonlyArray<DocumentFragment>) =>
  `[${[...documents]
    .sort((a, b) => (a.id > b.id ? 1 : -1))
    .map(({ id, name }) => `{${name}, ${id}}`)
    .join(", ")}]`;
