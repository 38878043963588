import { ApolloQueryResult } from "@apollo/client";
import { PropsWithChildren, useEffect, useState } from "react";
import { Error as ErrorComponent } from "./../../errors/Error";
import { FastDetailsQuery, FullFastDetailsFragment, useFastDetailsQuery } from "../../../__generated__/graphql";
import { Loader } from "../../../components/uiElements/Loader";
import { useAppContext } from "../../../providers/APIClient";
import { useFastContext } from "../../../providers/FastContractContext/FastContractContext";
import { useFastSymbol } from "../../../providers/FastSymbolProvider";
import { createGenericContext } from "../../../providers/utils";

interface ContextState {
  readonly fastDetails: FullFastDetailsFragment;
  readonly updateFastDetails: () => Promise<ApolloQueryResult<FastDetailsQuery>>;
}

// It's important to note that results from codegen based operations are typed as their
// inner fragments, not as the types we would expect. This is good, because it means that
// we know at compile-time which fields are present and which are not.
const [useFastDetails, Provider] = createGenericContext<ContextState>("FastTop");

export const Top = ({ children }: PropsWithChildren) => {
  // We really just need the symbol for the FAST currently in scope.
  const { symbol } = useFastSymbol();
  const { membership } = useFastContext();
  const { sessionData } = useAppContext();

  const { isMember } = membership;
  const [fastDetails, setFastDetails] = useState<FullFastDetailsFragment>();
  const {
    error,
    loading,
    data,
    refetch: updateFastDetails,
  } = useFastDetailsQuery({
    variables: { symbol, isMember, identityId: sessionData?.identity.id ?? "" },
  });

  useEffect(() => {
    if (!data) return;
    const details = data.fasts?.edges?.at(0)?.node;

    if (!details) throw new Error("Unexpected server response");
    setFastDetails(details);
  }, [data]);

  if (error) return <ErrorComponent reason={`Failed to query ${symbol} details.`} />;
  else if (loading) return <Loader label={`Loading FAST ${symbol} Details...`} />;
  else if (!fastDetails) return null;

  return <Provider value={{ fastDetails, updateFastDetails }}>{children}</Provider>;
};

export { useFastDetails };
