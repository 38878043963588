import { readContract } from "@wagmi/core";
import { ColumnsType } from "antd/lib/table";
import { BigNumber } from "bignumber.js";
import { useCallback, useMemo } from "react";
import { formatUnits } from "viem";
import { fastABI } from "../../../../__generated__/contracts";
import { ResolvedIdentityFragment } from "../../../../__generated__/graphql";
import { ResolvableAddress } from "../../../../components/ResolvableAddress";
import { AddressColumn } from "../../../../components/tables/AddressColumn";
import { PaginatedTable } from "../../../../components/tables/PaginatedTable";
import { useAddressesResolver } from "../../../../providers/AddressResolver.tsx/AddressResolverProvider";
import { useChain } from "../../../../providers/ChainClient";
import { useFastContext } from "../../../../providers/FastContractContext/FastContractContext";
import { EthAddress } from "../../../../types/ethers";

export interface MemberInfo {
  readonly address: EthAddress;
  readonly balance: BigNumber;
  readonly fullName?: Promise<ResolvedIdentityFragment | null>;
}

export const CurrentHoldersTable = () => {
  const { chain } = useChain();
  const {
    details: { address: fastAddress, decimals, totalSupply: fastTotalSupply },
  } = useFastContext();
  const { resolveAddresses } = useAddressesResolver();

  const explorer = chain.blockExplorers.default.url;

  // Calculate floating point total supply.
  const totalSupply = useMemo(() => BigNumber(formatUnits(fastTotalSupply, decimals)), [decimals, fastTotalSupply]);

  const fetchTotal = useCallback(
    () => readContract({ abi: fastABI, address: fastAddress, functionName: "memberCount" }).then(Number),
    [fastAddress],
  );

  const fetchItems = useCallback(
    (index: number, pageSize: number) =>
      readContract({
        abi: fastABI,
        address: fastAddress,
        functionName: "paginateDetailedMembers",
        args: [BigInt(index), BigInt(pageSize)],
      })
        .then(([members]) => {
          type Acc = { members: ReadonlyArray<MemberInfo>; addresses: ReadonlyArray<EthAddress> };
          return members.reduce<Acc>(
            (acc, { addr, balance }): Acc => {
              const address = addr;
              return {
                members: [
                  ...acc.members,
                  {
                    address,
                    balance: BigNumber(formatUnits(balance, decimals)),
                  },
                ],
                addresses: [...acc.addresses, address],
              };
            },
            { members: [], addresses: [] },
          );
        })
        // We now are ready to collect addresses and resolve them in the background.
        .then(({ members, addresses }): ReadonlyArray<MemberInfo> => {
          const resolving = resolveAddresses(addresses);
          // Augment the member details with the full name promise.
          return members.map((member) => ({ ...member, fullName: resolving[member.address] }));
        }),
    [decimals, fastAddress, resolveAddresses],
  );

  const columns: ColumnsType<MemberInfo> = useMemo(
    () => [
      {
        title: "Name",
        render: (_, { address, fullName }) => (
          <ResolvableAddress address={address} fullName={fullName} explorer={explorer} />
        ),
      },
      {
        title: "Address",
        render: (_, { address }) => <AddressColumn ethAddress={address} />,
      },
      {
        title: "FAST Holding",
        render: (_, { balance }) => balance.decimalPlaces(2, BigNumber.ROUND_HALF_EVEN).toString(),
      },
      {
        title: "% Holding ",
        render: (_, { balance }) =>
          `${balance.multipliedBy(100).dividedBy(totalSupply).decimalPlaces(2, BigNumber.ROUND_HALF_EVEN).toString()}%`,
      },
    ],
    [explorer, totalSupply],
  );

  return (
    <div className="Distributions-CurrentHolders">
      {totalSupply.eq(0) ? (
        <h3>The total supply of this FAST equals 0</h3>
      ) : (
        <>
          <h2>Current holders</h2>
          <PaginatedTable columns={columns} fetchTotal={fetchTotal} fetchItems={fetchItems} perPage={10} />
        </>
      )}
    </div>
  );
};
