import { Checkbox } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";

interface Props {
  readonly name: string;
  readonly url: string;
}

export const OnboardDocument = ({ name, url }: Props) => {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <div className="OnboardDocument">
      <h4>{name}</h4>
      <div className="OnboardDocument-ContolPanel">
        <Link className="OnboardDocument-Link" to={url} target="_blank" onClick={() => setIsOpened(true)}>
          Open
          <i className="icon-new-window" />
        </Link>
        <Checkbox disabled={!isOpened} value={name}>
          Accept
        </Checkbox>
      </div>
    </div>
  );
};
