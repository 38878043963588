/// Scalars.

/**
 * @minLength 1
 * @maxLength 12
 */
export type Tag = string;

/**
 * @minLength 1
 * @maxLength 32
 */
export type Title = string;

/** @pattern ^https?:\/\/[^\\s\/$.?#].[^\\s]*$ */
export type URL = string;

/** @pattern ^https:\/\/([0-9a-z]+.)*linkedin.com\/[\/0-9a-z-]+$ */
export type LinkedInURL = string;

/// Enums.

export enum Stage {
  PreSeed = "Pre-seed",
  Seed = "Seed",
  Growth = "Growth",
  Scale = "Scale",
  LargeEnterprises = "Large Enterprises",
}

export enum Visibility {
  Private = "PRIVATE",
  Public = "PUBLIC",
  Community = "COMMUNITY",
}

export enum Currency {
  USD = "$",
  GBP = "£",
  EUR = "€",
}

/// Objects.

export interface Link {
  readonly url: URL;
  readonly title: Title;
  readonly visibility: Visibility;
}

export interface Document {
  readonly documentId: string;
  readonly title: Title;
  readonly visibility: Visibility;
}

export interface TeamMember {
  readonly imageId: string;
  readonly fullName: Title;
  readonly linkedinProfileUrl: LinkedInURL;
}
