import { ColumnsType } from "antd/lib/table";
import { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { ResolvedIdentityFragment } from "../../../__generated__/graphql";
import { ResolvableAddress } from "../../../components/ResolvableAddress";
import { PaginatedTable } from "../../../components/tables/PaginatedTable";
import { BigNumberFormatter } from "../../../hooks/useBigNumberFormatter";
import { useAddressesResolver } from "../../../providers/AddressResolver.tsx/AddressResolverProvider";
import { useChain } from "../../../providers/ChainClient";
import { useFastContext } from "../../../providers/FastContractContext/FastContractContext";
import { EthAddress } from "../../../types/ethers";

interface TransferProof {
  readonly spender: EthAddress;
  readonly from: EthAddress;
  readonly to: EthAddress;
  readonly amount: bigint;
  readonly blockNumber: bigint;
  readonly ref: string;
  // Augmented at runtime.
  readonly spenderFullName: Promise<ResolvedIdentityFragment | null>;
  readonly fromFullName: Promise<ResolvedIdentityFragment | null>;
  readonly toFullName: Promise<ResolvedIdentityFragment | null>;
}

interface Props {
  readonly formatter: BigNumberFormatter;
}

export const TransferHistory = ({ formatter }: Props) => {
  const { chain, signerAddress } = useChain();
  const {
    details: { contract: fast, symbol },
  } = useFastContext();
  const { resolveAddresses } = useAddressesResolver();
  const explorer = chain.blockExplorers.default.url;

  const fetchTotal = () => fast.read.transferProofByInvolveeCount([signerAddress]).then(Number);
  const fetchItems = useCallback(
    (index: number, perPage: number) =>
      fast.read
        .paginateTransferProofsByInvolvee([signerAddress, BigInt(index), BigInt(perPage)])
        // We now are ready to collect addresses and resolve them in the background.
        .then(([proofs]): ReadonlyArray<TransferProof> => {
          // Collect all addresses.
          const addresses = proofs.reduce<ReadonlyArray<EthAddress>>(
            (acc, { spender, from, to }) => [...acc, spender, from, to],
            [],
          );
          // Start resolving the addresses.
          const resolving = resolveAddresses(addresses);
          // Return an array of `TransferProof`.
          return proofs.map((proof): TransferProof => {
            const { from, to, spender } = proof;
            return {
              ...proof,
              fromFullName: resolving[from],
              toFullName: resolving[to],
              spenderFullName: resolving[spender],
            };
          });
        }),
    [signerAddress, fast, resolveAddresses],
  );

  const otherParty = useCallback(
    (proof: TransferProof) => {
      const iAmSender = proof.from === signerAddress;
      const [party, fullName] = iAmSender ? [proof.to, proof.toFullName] : [proof.from, proof.fromFullName];
      return <ResolvableAddress address={party} fullName={fullName} explorer={explorer} />;
    },
    [signerAddress, explorer],
  );

  const columns: ColumnsType<TransferProof> = useMemo(
    () => [
      {
        title: "Block",
        render: (_, { blockNumber }) => (
          <Link to={`${explorer}/block/${blockNumber.toString()}`} target="_blank">
            {blockNumber.toString()}
          </Link>
        ),
      },
      {
        title: "Party",
        render: (_, proof) => otherParty(proof),
      },
      {
        title: "Reference",
        render: (_, { ref }) => ref,
      },
      {
        title: `Amount (${symbol})`,
        render: (_, { from, amount }) => `${signerAddress === from ? "-" : "+"}${formatter.format(amount, 2)}`,
      },
    ],
    [signerAddress, explorer, formatter, otherParty, symbol],
  );

  return (
    <div className="TableWithTitle">
      <h2>Transfer History</h2>
      <PaginatedTable columns={columns} fetchTotal={fetchTotal} fetchItems={fetchItems} perPage={10} reverse />
    </div>
  );
};
