export const ConfirmationCorrectKYC = {
  label: true,
  title: "Welcome to the TokenSphere platform",
  text: "We have sent you a confirmation email and your FAST Coordinator will soon get in touch with the next steps and give you access to your community and tokens.",
};

export const ConfirmationManuallyReviewKYC = {
  label: false,
  title: "Your application has been submitted",
  text: "We will need to manually review your documents and will get in touch if we need further information.",
};

export type ConfirmationType = typeof ConfirmationCorrectKYC;
