import { EthAddress, isEthAddress, shortenAddressForTable } from "../../types/ethers";
import { CopyPopover } from "../uiElements/CopyPopover";

interface Props {
  readonly ethAddress: EthAddress | string;
}

export const AddressColumn = ({ ethAddress }: Props) => (
  <div className="AddressColumn">
    {isEthAddress(ethAddress) ? (
      <>
        <span>{shortenAddressForTable(ethAddress)}</span>
        <CopyPopover message={ethAddress} />
      </>
    ) : (
      <>{ethAddress}</>
    )}
  </div>
);
