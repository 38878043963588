import { ApolloError, ApolloQueryResult } from "@apollo/client";
import { Button, Select, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { EmptyTable } from "./EmptyTable";
import { PageInfo } from "../../__generated__/graphql";
import { useErrorHelpers } from "../../hooks/useErrorHelpers";

type PaginationQueryVariables = {
  readonly after?: string | null;
  readonly before?: string | null;
  readonly first?: number;
  readonly last?: number;
};

interface Props<T, Q> {
  readonly columns: ColumnsType<T>;
  readonly data: ReadonlyArray<T>;
  readonly error?: ApolloError;
  readonly pageInfo: PageInfo;
  readonly pageSize: number;
  readonly refetch: (variables?: Partial<PaginationQueryVariables> | undefined) => Promise<ApolloQueryResult<Q>>;
  readonly setPageSize: (pageSize: number) => void;
}

const pageSizeOptions = [
  { label: "10 / page", value: 10 },
  { label: "20 / page", value: 20 },
  { label: "30 / page", value: 30 },
];

export const CursorPaginatedTable = <T extends object, Q>({
  columns,
  data,
  error,
  pageInfo,
  pageSize,
  refetch,
  setPageSize,
}: Props<T, Q>) => {
  const { handleError } = useErrorHelpers();
  const [items, setItems] = useState<ReadonlyArray<T>>([]);
  const [isLoading, setIsLoading] = useState(false);

  // It needs to add a key to table rows.
  useEffect(() => {
    const itemsWithKey = data.map((item, index) => ({ ...item, key: index }));
    setItems(itemsWithKey);
  }, [data]);

  const goToPreviousPage = () => {
    if (!pageInfo || !pageInfo.hasPreviousPage) return;
    setIsLoading(true);

    refetch({ first: undefined, last: pageSize, before: pageInfo.startCursor, after: undefined })
      .catch((err) => handleError("An error occured while loading the data", err))
      .finally(() => setIsLoading(false));
  };

  const goToNextPage = () => {
    if (!pageInfo || !pageInfo.hasNextPage) return;
    setIsLoading(true);

    refetch({ first: pageSize, after: pageInfo.endCursor })
      .catch((err) => handleError("An error occured while loading the data", err))
      .finally(() => setIsLoading(false));
  };

  const changePageSize = (newPageSize: number) => {
    setPageSize(newPageSize);

    refetch({ first: newPageSize, before: undefined, after: undefined }).catch((err) =>
      handleError("An error occured while loading the data", err),
    );
  };

  if (error) {
    return (
      <div className="Table-Error">
        <h3>{error.name}</h3>
        <p>{error.message}</p>
      </div>
    );
  } else {
    return items.length === 0 ? (
      <EmptyTable text="No items found." />
    ) : (
      <>
        <div className="AlternativePagination">
          <Select
            className="AlternativePagination-Select"
            disabled={isLoading}
            options={pageSizeOptions}
            defaultValue={pageSizeOptions[0].value}
            onChange={changePageSize}
          />
          <Button disabled={isLoading || !pageInfo.hasPreviousPage} onClick={goToPreviousPage}>
            <i className="icon-arrow-left" />
            Prev
          </Button>
          <Button disabled={isLoading || !pageInfo.hasNextPage} onClick={goToNextPage}>
            Next
            <i className="icon-arrow-right" />
          </Button>
        </div>
        <Table
          className="Table"
          dataSource={items}
          columns={columns}
          loading={isLoading}
          pagination={false}
          rowClassName="Table-Row"
        />
      </>
    );
  }
};
