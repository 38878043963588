import { getContract } from "@wagmi/core";
import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { RawFastDetails } from "./FastContractContext/types";
import { useTopContracts } from "./TopContractsProvider";
import { createGenericContext } from "./utils";
import { fastABI } from "../__generated__/contracts";
import { useMyFastsLazyQuery } from "../__generated__/graphql";
import { Loader } from "../components/uiElements/Loader";
import { Error as ErrorPage } from "../pages/errors/Error";
import { EthAddress, enforceEthAddress } from "../types/ethers";

type State = ReadonlyArray<Readonly<RawFastDetails>>;

const [useFastsList, Provider] = createGenericContext<State>("FastsListProvider");

export const FastsListProvider = ({ children }: PropsWithChildren) => {
  const { issuer, isIssuerMember } = useTopContracts();
  const [myFastsQuery] = useMyFastsLazyQuery();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [detailedFasts, setDetailedFasts] = useState<State>();

  const fetchIssuerPaginateDetailedFasts = useCallback(() => {
    // Mark the section as being loaded.
    setIsLoading(true);
    // Load FASTS - upon success populate the state.
    issuer.read
      .paginateDetailedFasts([0n, 5000n])
      .then(([paginatedFastsData]) => {
        setDetailedFasts(paginatedFastsData.map(({ addr, ...rest }) => ({ ...rest, address: addr })));
      })
      .catch((err) => {
        console.error("Failed to load FASTs details.", err);
        setError(true);
      })
      .finally(() => setIsLoading(false));
  }, [setIsLoading, setDetailedFasts, issuer]);

  const fetchMyFastsQuery = useCallback(() => {
    setIsLoading(true);
    // Load FASTs that the user belongs to.
    myFastsQuery()
      .then((res) => {
        const fasts = res.data?.me?.memberships?.edges;
        if (!fasts) {
          setError(true);
          throw new Error("Unexpected server response");
        }
        return fasts;
      })
      // Only keep valid addresses.
      .then((fasts) =>
        fasts.reduce<ReadonlyArray<EthAddress>>(
          (acc, edge) => (edge?.node?.ethAddress ? [...acc, enforceEthAddress(edge.node.ethAddress)] : []),
          [],
        ),
      )
      // Grab contracts.
      .then((addresses) => addresses.map((addr) => getContract({ abi: fastABI, address: addr })))
      // Call contracts.
      .then((contracts) => Promise.all(contracts.map((c) => c.read.details())))
      // Transform the results into a more usable format.
      .then((results) => results.map(({ addr: address, ...rest }) => ({ ...rest, address })))
      // Update state.
      .then(setDetailedFasts)
      // Update state using the valid results. We simply need to change the `addr` field of each result into `address`.
      .catch((err) => {
        console.error("Failed to load FASTs details.", err);
        setError(true);
      })
      .finally(() => setIsLoading(false));
  }, [myFastsQuery]);

  useEffect(() => {
    isIssuerMember ? fetchIssuerPaginateDetailedFasts() : fetchMyFastsQuery();
  }, [isIssuerMember, fetchIssuerPaginateDetailedFasts, fetchMyFastsQuery]);

  if (error) return <ErrorPage reason="Failed to load list of FASTs." />;
  else if (isLoading) return <Loader label="Loading list of FASTs..." withLogo />;
  else if (!detailedFasts) return null;

  return <Provider value={detailedFasts}>{children}</Provider>;
};

export { useFastsList };
