import { Button, Form, InputNumber } from "antd";
import { useForm, useWatch } from "antd/lib/form/Form";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ResolvableAddress } from "../../../../../components/ResolvableAddress";
import { RequiredFieldRule } from "../../../../../helpers/utils";
import { useModalState } from "../../../../../hooks/useModalState";
import { Info } from "../../../../../providers/CrowdfundProvider/types";
import { useFastContext } from "../../../../../providers/FastContractContext/FastContractContext";
import {
  AcceptInvestmentDrawdown,
  InvestmentDrawdownNames,
  RejectInvestmentDrawdown,
  RequestDrawdown,
} from "../../types";
import { RequestDrawdownModal } from "../RequestDrawdownModal";

interface Props {
  readonly explorer: string;
  readonly info: Info;
  readonly names: InvestmentDrawdownNames;
}

interface FormData {
  readonly basisPointsFee: string;
}

export const RequestedStep = ({ explorer, info, names }: Props) => {
  const completeDrawdownModalState = useModalState(false);
  const [modalType, setModalType] = useState(AcceptInvestmentDrawdown);
  const [submittable, setSubmittable] = useState(false);
  const {
    details: { crowdfundsDefaultBasisPointsFee: defaultFee },
  } = useFastContext();
  const [form] = useForm<FormData>();
  const feeValue = useWatch("basisPointsFee", form);

  useEffect(() => {
    feeValue ? setSubmittable(true) : setSubmittable(false);
  }, [feeValue]);

  const handleOpenModal = useCallback(
    (type: RequestDrawdown) => () => {
      setModalType(type);
      completeDrawdownModalState.open();
    },
    [completeDrawdownModalState],
  );

  return (
    <div className="RequestedStep">
      <div>
        <div>
          Requested by:&nbsp;
          <ResolvableAddress
            address={info.params.owner}
            fullName={names.ownerFullName}
            explorer={explorer}
            withCopyButton={false}
          />
        </div>
        <div>
          Recipient:&nbsp;
          <ResolvableAddress
            address={info.params.beneficiary}
            fullName={names.beneficiaryFullName}
            explorer={explorer}
            withCopyButton={false}
          />
        </div>
        <div>Reference / SAFT ID: {info.params.ref}</div>
        <div>Currency: {info.erc20Info.symbol}</div>
        <div>
          <Link to={`${explorer}/address/${info.address}`} target="_blank">
            View on block explorer
          </Link>
        </div>
      </div>

      <div>
        <Form form={form} initialValues={{ basisPointsFee: defaultFee / 100 }} layout="vertical" requiredMark>
          <Form.Item label="Platform Fee, %" name="basisPointsFee" rules={[...RequiredFieldRule]}>
            <InputNumber type="number" min={0} max={100} precision={2} step={0.5} placeholder="Add percentage fee" />
          </Form.Item>
        </Form>
        <div className="ButtonPairWrapper">
          <Button onClick={handleOpenModal(RejectInvestmentDrawdown)}>Reject</Button>
          <Button disabled={!submittable} onClick={handleOpenModal(AcceptInvestmentDrawdown)}>
            Accept
          </Button>
          <RequestDrawdownModal
            drawdownAddress={info.address}
            feeValue={feeValue}
            refValue={info.params.ref}
            state={completeDrawdownModalState}
            type={modalType}
          />
        </div>
      </div>
    </div>
  );
};
