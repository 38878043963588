import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { zeroAddress } from "viem";
import { ResolvedIdentityFragment, useResolveAddressesLazyQuery } from "../../__generated__/graphql";
import { useErrorHelpers } from "../../hooks/useErrorHelpers";
import { EthAddress } from "../../types/ethers";
import { useAppContext } from "../APIClient";
import { useChain } from "../ChainClient";
import { createGenericContext } from "../utils";

// Pay attention here - when the stash has queried for an identity and
// it didn't return an identity, it stores it as null rather than undefined.
export type ResolvedIdentity = ResolvedIdentityFragment | null;
export type Stash = Record<EthAddress, ResolvedIdentity>;
export type PromiseStash = Record<EthAddress, Promise<ResolvedIdentity>>;
// Same here - returning null means that the query was made, but it didn't return an identity.
type AddressesResolver = (ethAddresses: ReadonlyArray<EthAddress>) => PromiseStash;

// Helper function allowing to generate a list of null resolved promises.
const nullPromise = Promise.resolve(null);
const nullPromises = (items: ReadonlyArray<EthAddress>) =>
  items.reduce<PromiseStash>((acc, addr) => ({ ...acc, [addr]: nullPromise }), {});

interface ContextState {
  readonly resolveAddresses: AddressesResolver;
}

const [useAddressesResolver, Provider] = createGenericContext<ContextState>();
export { useAddressesResolver };

export const AddressesResolverProvider = ({ children }: PropsWithChildren) => {
  const { chain } = useChain();
  const { sessionData } = useAppContext();
  const { handleError } = useErrorHelpers();

  // A few well-known addresses.
  const initialStash: Stash = useMemo(
    () => ({
      [zeroAddress]: { id: "0", fullName: "<RESERVE>" },
      [chain.issuerAddress]: { id: "1", fullName: "<SPC>" },
      [chain.marketplaceAddress]: { id: "2", fullName: "<MARKETPLACE>" },
    }),
    [chain],
  );
  const [stash, setStash] = useState<Stash>(initialStash);
  const [query] = useResolveAddressesLazyQuery();

  // Whenever the authenticated user ID changes, we want to reset the stash.
  useEffect(() => setStash(initialStash), [initialStash]);

  // Whenever the authenticated user ID changes, we want to reset the stash.
  const stuff = useMemo(() => {
    const resolveAddresses: AddressesResolver = (ethAddresses: ReadonlyArray<EthAddress>) => {
      // Not much we can do if the user isn't authenticated.
      if (!sessionData) return nullPromises(ethAddresses);
      // We won't be resolving identities that we've already resolved or are in the process of resolving.
      const uniqueEthAddresses = [...new Set(ethAddresses)];
      const missing = uniqueEthAddresses.filter((ethAddress) => !(ethAddress in stash));
      if (missing.length !== 0) console.debug(`Cache miss: ${missing.length}/${uniqueEthAddresses.length}`);

      // No need for a query - all addresses were already resolved.
      if (missing.length === 0)
        return ethAddresses.reduce((acc, addr) => ({ ...acc, [addr]: Promise.resolve(stash[addr]) }), {});

      // Query the server... We're also returning the query so that the caller can observe it.
      // TODO: The server only accepts up to X addresses at a time. We should split the query into multiple queries,
      // await them, and then merge the results.
      const q = query({ variables: { ethAddresses: missing } })
        .then(({ data }) => {
          const identities = data?.lookupIdentities;
          if (!identities) return nullPromises(ethAddresses);
          // Remap the identities into a stash.
          const resolved = identities.reduce<Stash>(
            (acc, identity, i) => (identity !== undefined ? { ...acc, [missing[i]]: identity } : acc),
            {},
          );
          // Merge the new stash with the existing stash.
          setStash((s) => ({ ...s, ...resolved }));
        })
        .catch((err) => {
          handleError("Resolve Identities", err);
          return nullPromises(ethAddresses);
        });
      return ethAddresses.reduce<PromiseStash>((acc, addr) => ({ ...acc, [addr]: q.then(() => stash[addr]) }), {});
    };

    return { resolveAddresses };
  }, [query, handleError, stash, sessionData]);

  return <Provider value={stuff}>{children}</Provider>;
};
