import { OpacityTransition } from "../../components/layout/OpacityTransition";
import { NetworkName } from "../../components/NetworkName";

interface Props {
  readonly symbol?: string;
}

export const NotFound = ({ symbol }: Props) => (
  <OpacityTransition className="PageNotFound">
    <NetworkName />
    <h1 className="PageNotFound-Title">
      {symbol ? "Section" : "Page"}
      <br />
      not found
    </h1>
    <h3 className="PageNotFound-Description">Please check the URL and try again</h3>
    <br />
  </OpacityTransition>
);
