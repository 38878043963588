import { JsonValue } from '../graphql/types/JsonScalar';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: string; output: string; }
  EthAddress: { input: string; output: string; }
  EthSignature: { input: string; output: string; }
  Json: { input: JsonValue; output: JsonValue; }
};

/** Information about the membership request to accept. */
export type AcceptMembershipRequestInput = {
  /** The membership request id. */
  readonly membershipRequestId: Scalars['ID']['input'];
};

export type AcceptMembershipRequestPayload = {
  readonly __typename?: 'AcceptMembershipRequestPayload';
  /** The membership request after the operation. */
  readonly membershipRequest: MembershipRequest;
};

/** Information about the FAST to amend. */
export type AmendFastInput = {
  /** The form data to attach to the FAST as a JSON string. */
  readonly data: Scalars['Json']['input'];
  /** The FAST id. */
  readonly fastId: Scalars['ID']['input'];
  /** The schema version to validate against. */
  readonly schemaVersion: Scalars['String']['input'];
};

export type AmendFastPayload = {
  readonly __typename?: 'AmendFastPayload';
  /** The FAST after the opearation. */
  readonly fast: Fast;
};

/** Information about the onboard to amend. */
export type AmendOnboardInput = {
  /** The claim token. */
  readonly claimToken: Scalars['String']['input'];
  /** The form data to attach to the onboard as a JSON string. */
  readonly data: Scalars['Json']['input'];
  /** The onboard id. */
  readonly onboardId: Scalars['ID']['input'];
  /** The schema version to validate against. */
  readonly schemaVersion: Scalars['String']['input'];
};

export type AmendOnboardPayload = {
  readonly __typename?: 'AmendOnboardPayload';
  /** The onboard after the operation. */
  readonly onboard: Onboard;
};

/** Represents an Applicant. */
export type Applicant = {
  readonly __typename?: 'Applicant';
  /** The applicant's email address. */
  readonly emailAddress?: Maybe<Scalars['String']['output']>;
  /** The applicant's eth address. */
  readonly ethAddress?: Maybe<Scalars['EthAddress']['output']>;
  /** The applicant's full name. */
  readonly fullName?: Maybe<Scalars['String']['output']>;
  /** The applicant's identity check status. */
  readonly identityCheckStatus?: Maybe<OnboardIdentityCheckStatus>;
  /** The applicant's linked in. */
  readonly linkedIn?: Maybe<Scalars['String']['output']>;
};

/** The attachment creation input information. */
export type AttachInput = {
  /** The document to use as an attachment. */
  readonly documentId: Scalars['ID']['input'];
  /** The object to attach the document to. */
  readonly documentableId: Scalars['ID']['input'];
  /** The scope in which this attachment lives in. */
  readonly scope: Scalars['String']['input'];
};

export type AttachPayload = {
  readonly __typename?: 'AttachPayload';
  /** The newly created attachment. */
  readonly attachment: Attachment;
};

/** Represents an attachment in our system. It links a document to a documentable. */
export type Attachment = Node & {
  readonly __typename?: 'Attachment';
  /** The documents which this attachment relates to. */
  readonly document: Document;
  /** The documentable object that this attachment is linked to. */
  readonly documentable: Documentable;
  /** The ID of an object */
  readonly id: Scalars['ID']['output'];
  /** The object's creation timestmap. */
  readonly insertedAt: Scalars['DateTime']['output'];
  /** The scope in which this attachment belongs to. */
  readonly scope: Scalars['String']['output'];
  /** The object's last update timestamp. */
  readonly updatedAt: Scalars['DateTime']['output'];
};

/** A list of attachments. */
export type AttachmentConnection = {
  readonly __typename?: 'AttachmentConnection';
  readonly edges?: Maybe<ReadonlyArray<Maybe<AttachmentEdge>>>;
  readonly pageInfo: PageInfo;
};

export type AttachmentEdge = {
  readonly __typename?: 'AttachmentEdge';
  readonly cursor?: Maybe<Scalars['String']['output']>;
  readonly node?: Maybe<Attachment>;
};

/** The identity's credentials. */
export type AuthenticateInput = {
  /** The selected chain id when forming the plaintext. */
  readonly chainId: Scalars['Int']['input'];
  /** The users wallet address. */
  readonly ethAddress: Scalars['EthAddress']['input'];
  /** The signature derived from the signing operation. */
  readonly ethSignature: Scalars['EthSignature']['input'];
  /** The timestamp used when forming the plaintext. */
  readonly transmittedAt: Scalars['DateTime']['input'];
};

export type AuthenticatePayload = {
  readonly __typename?: 'AuthenticatePayload';
  /** The session that was created. */
  readonly session: Session;
};

/** Represents a Bot in our system. */
export type Bot = Node & {
  readonly __typename?: 'Bot';
  /** Associated FASTs. */
  readonly fasts?: Maybe<ReadonlyArray<Fast>>;
  /** The ID of an object */
  readonly id: Scalars['ID']['output'];
  /** Metadata associated with this Bot */
  readonly meta: Meta;
};

/** The given Onboard claim token. */
export type ClaimOnboardInput = {
  /** The claim token. */
  readonly claimToken: Scalars['String']['input'];
  /** The onboard id. */
  readonly onboardId: Scalars['ID']['input'];
};

export type ClaimOnboardPayload = {
  readonly __typename?: 'ClaimOnboardPayload';
  /** The onboard after the operation. */
  readonly onboard: Onboard;
};

/** Information about the Proposal. */
export type CloseProposalInput = {
  /** The proposal to close. */
  readonly proposalId: Scalars['ID']['input'];
};

export type CloseProposalPayload = {
  readonly __typename?: 'CloseProposalPayload';
  /** The result of the operation. */
  readonly proposal: Proposal;
};

/** The given Onboard claim token. */
export type ConfirmOnboardEthAddressInput = {
  /** The claim token. */
  readonly claimToken: Scalars['String']['input'];
  /** The users wallet address. */
  readonly ethAddress: Scalars['EthAddress']['input'];
  /** The signature derived from the signing operation. */
  readonly ethSignature: Scalars['EthSignature']['input'];
  /** The onboard id. */
  readonly onboardId: Scalars['ID']['input'];
  /** The timestamp used when forming the plaintext. */
  readonly transmittedAt: Scalars['DateTime']['input'];
};

export type ConfirmOnboardEthAddressPayload = {
  readonly __typename?: 'ConfirmOnboardEthAddressPayload';
  /** The onboard after the operation. */
  readonly onboard: Onboard;
};

/** The uploaded documents details. */
export type CreateDocumentInput = {
  /** Content type to upload. */
  readonly contentType: Scalars['String']['input'];
  /** If provided, a name for this document. */
  readonly name?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDocumentPayload = {
  readonly __typename?: 'CreateDocumentPayload';
  /** The related Document. */
  readonly document: Document;
  /** The cloud storage URL to PUT the data to. */
  readonly signedUrl: Scalars['String']['output'];
};

/** Information about the Proposal. */
export type CreateProposalInput = {
  /** The choices for the proposal. */
  readonly choices: ReadonlyArray<Scalars['String']['input']>;
  /** The description of the proposal. */
  readonly description: Scalars['String']['input'];
  /** The title of the proposal. */
  readonly title: Scalars['String']['input'];
  /** The votable to create a proposal for. */
  readonly votableId: Scalars['ID']['input'];
};

export type CreateProposalPayload = {
  readonly __typename?: 'CreateProposalPayload';
  /** The result of the operation. */
  readonly proposal: Proposal;
};

/** The test case output, top level details and calculated distributions per FAST holder. */
export type DistributionTestCaseOutput = {
  readonly __typename?: 'DistributionTestCaseOutput';
  /** Calculated distributions. */
  readonly distributions: ReadonlyArray<HoldingDistribution>;
  /** The final fee that was taken (taken fee + remainder). */
  readonly finalFee: Scalars['String']['output'];
  /** Calculated ratio for the distribution. */
  readonly ratio: Scalars['String']['output'];
  /** Calculated remainder of the distribution (in wei). */
  readonly remainder: Scalars['String']['output'];
  /** The fee that was taken. */
  readonly takenFee: Scalars['String']['output'];
  /** Total distributed amount. */
  readonly totalDistributed: Scalars['String']['output'];
};

/** A document. */
export type Document = Node & {
  readonly __typename?: 'Document';
  /** Attachment count */
  readonly attachmentCount: Scalars['Int']['output'];
  /** Attachments referencing this document. */
  readonly attachments?: Maybe<AttachmentConnection>;
  /** Content type of the document. */
  readonly contentType: Scalars['String']['output'];
  /** Generates a signed URL allowing to download this document. */
  readonly downloadUrl: Scalars['String']['output'];
  /** The ID of an object */
  readonly id: Scalars['ID']['output'];
  /** The object's creation timestmap. */
  readonly insertedAt: Scalars['DateTime']['output'];
  /** A name for this document, if it was provided. */
  readonly name: Scalars['String']['output'];
  /** The object's last update timestamp. */
  readonly updatedAt: Scalars['DateTime']['output'];
  /** The user to whom this document belongs. */
  readonly user: User;
};


/** A document. */
export type DocumentAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** A list of documents. */
export type DocumentConnection = {
  readonly __typename?: 'DocumentConnection';
  readonly edges?: Maybe<ReadonlyArray<Maybe<DocumentEdge>>>;
  readonly pageInfo: PageInfo;
};

export type DocumentEdge = {
  readonly __typename?: 'DocumentEdge';
  readonly cursor?: Maybe<Scalars['String']['output']>;
  readonly node?: Maybe<Document>;
};

export type Documentable = Fast | Proposal;

/** Slack channel mapping input. */
export type EditSlackChannelMappingsInput = {
  /** The FAST id. */
  readonly fastId: Scalars['ID']['input'];
  /** The channel mappings for this Bot. */
  readonly slackChannelMappings: Scalars['Json']['input'];
};

export type EditSlackChannelMappingsPayload = {
  readonly __typename?: 'EditSlackChannelMappingsPayload';
  /** The FAST after Slack channels have been updated. */
  readonly fast: Fast;
};

/** Represents a FAST in our system. */
export type Fast = IsDocumentable & IsVotable & Node & {
  readonly __typename?: 'Fast';
  /** The add to slack button */
  readonly addToSlackButton: Scalars['String']['output'];
  /** Allows to query possible scopes to be applied while attaching documents to an object. */
  readonly allowedDocumentationScopes: ReadonlyArray<Scalars['String']['output']>;
  /** Documents attached to this object within a given scope. */
  readonly attachments?: Maybe<AttachmentConnection>;
  /** Associated Bots. */
  readonly bots: ReadonlyArray<Bot>;
  /** The number of decimals for this FAST */
  readonly decimals: Scalars['Int']['output'];
  /** The eth_address of the FAST */
  readonly ethAddress: Scalars['EthAddress']['output'];
  /** The form that was submitted with the FAST details. */
  readonly form?: Maybe<JsonDocument>;
  /** The ID of an object */
  readonly id: Scalars['ID']['output'];
  /** The logo of the FAST, if specified */
  readonly logoUrl?: Maybe<Scalars['String']['output']>;
  /** Membership requests available on the parent object. */
  readonly membershipRequests?: Maybe<MembershipRequestConnection>;
  /** The name of the FAST */
  readonly name: Scalars['String']['output'];
  /** Proposals attached to this object. */
  readonly proposals?: Maybe<ProposalConnection>;
  /** The whitelisted public FAST details form. */
  readonly publicForm?: Maybe<Scalars['Json']['output']>;
  /** Slack channel mappings. */
  readonly slackChannelMappings: Scalars['Json']['output'];
  /** The symbol of the FAST */
  readonly symbol: Scalars['String']['output'];
  /** The visibility level of this FAST */
  readonly visibility: FastVisibility;
};


/** Represents a FAST in our system. */
export type FastAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  scope?: InputMaybe<Scalars['String']['input']>;
};


/** Represents a FAST in our system. */
export type FastMembershipRequestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  fastId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  states?: InputMaybe<ReadonlyArray<MembershipRequestState>>;
};


/** Represents a FAST in our system. */
export type FastProposalsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** A list of fasts. */
export type FastConnection = {
  readonly __typename?: 'FastConnection';
  readonly edges?: Maybe<ReadonlyArray<Maybe<FastEdge>>>;
  readonly pageInfo: PageInfo;
};

export type FastEdge = {
  readonly __typename?: 'FastEdge';
  readonly cursor?: Maybe<Scalars['String']['output']>;
  readonly node?: Maybe<Fast>;
};

/** The FAST token holders balance. */
export type FastHolding = {
  /** Address of the token holder. */
  readonly ethAddress: Scalars['EthAddress']['input'];
  /** Holding amount. */
  readonly holding: Scalars['String']['input'];
};

/** FAST visibility level. */
export enum FastVisibility {
  Community = 'COMMUNITY',
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

/** The FAST token holder, their balance and calculated distribution. */
export type HoldingDistribution = {
  readonly __typename?: 'HoldingDistribution';
  /** Calculated distribution. */
  readonly distribution: Scalars['String']['output'];
  /** Address of the token holder. */
  readonly ethAddress: Scalars['EthAddress']['output'];
  /** Holding amount. */
  readonly holding: Scalars['String']['output'];
};

/** Represents a identity in our system. */
export type Identity = Node & {
  readonly __typename?: 'Identity';
  /** Documents available on the parent object. */
  readonly documents?: Maybe<DocumentConnection>;
  /** The identity's email address */
  readonly emailAddress?: Maybe<Scalars['String']['output']>;
  /** The identity's wallet address */
  readonly ethAddress: Scalars['EthAddress']['output'];
  /** The identity's full name */
  readonly fullName?: Maybe<Scalars['String']['output']>;
  /** The FAST governorships for this identity. */
  readonly governorships?: Maybe<FastConnection>;
  /** The ID of an object */
  readonly id: Scalars['ID']['output'];
  /** Membership requests for this Identity. */
  readonly membershipRequests: ReadonlyArray<MembershipRequest>;
  /** The FAST memberships for this identity. */
  readonly memberships?: Maybe<FastConnection>;
  /** The associated Onboard for this Identity, if it exists. */
  readonly onboard?: Maybe<Onboard>;
  /** The traits of this identity */
  readonly traits: ReadonlyArray<IdentityTraits>;
  /** The type of this identity */
  readonly type: IdentityTypes;
  /** The associated User for this Identity */
  readonly user: User;
};


/** Represents a identity in our system. */
export type IdentityDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Represents a identity in our system. */
export type IdentityGovernorshipsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Represents a identity in our system. */
export type IdentityMembershipsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** A list of identities. */
export type IdentityConnection = {
  readonly __typename?: 'IdentityConnection';
  readonly edges?: Maybe<ReadonlyArray<Maybe<IdentityEdge>>>;
  readonly pageInfo: PageInfo;
};

export type IdentityEdge = {
  readonly __typename?: 'IdentityEdge';
  readonly cursor?: Maybe<Scalars['String']['output']>;
  readonly node?: Maybe<Identity>;
};

/** Identity traits. */
export enum IdentityTraits {
  Issuer = 'ISSUER'
}

/** Possible types for onboards. */
export enum IdentityType {
  Individual = 'INDIVIDUAL',
  Organisation = 'ORGANISATION'
}

/** Identity types. */
export enum IdentityTypes {
  Individual = 'INDIVIDUAL',
  Organisation = 'ORGANISATION'
}

/**
 * The documentable interface enforces the ability for documentable nodes to expose
 * attachment functions.
 */
export type IsDocumentable = {
  /** Allows to query possible scopes to be applied while attaching documents to an object. */
  readonly allowedDocumentationScopes: ReadonlyArray<Scalars['String']['output']>;
  /** Documents attached to this object based on a given scope. */
  readonly attachments?: Maybe<AttachmentConnection>;
};


/**
 * The documentable interface enforces the ability for documentable nodes to expose
 * attachment functions.
 */
export type IsDocumentableAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  scope?: InputMaybe<Scalars['String']['input']>;
};

/**
 * The votable interface is a contract that items which can be the target of an election
 * can implement. It mainly consists of a list of proposals that can be queried.
 */
export type IsVotable = {
  /** Proposals attached to this object. */
  readonly proposals?: Maybe<ProposalConnection>;
};


/**
 * The votable interface is a contract that items which can be the target of an election
 * can implement. It mainly consists of a list of proposals that can be queried.
 */
export type IsVotableProposalsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Information about the Onboard issue. */
export type IssueOnboardInput = {
  /** The email address request an email verification for. */
  readonly emailAddress: Scalars['String']['input'];
  /** If supplied, the FAST to request membership of. */
  readonly fastId?: InputMaybe<Scalars['ID']['input']>;
};

export type IssueOnboardPayload = {
  readonly __typename?: 'IssueOnboardPayload';
  /** The result of the operation. */
  readonly result: Scalars['Boolean']['output'];
};

/** A JSON-schema-backed document. */
export type JsonDocument = Node & {
  readonly __typename?: 'JsonDocument';
  /** The form data itself. */
  readonly data: Scalars['Json']['output'];
  /** The form data hash for verification. */
  readonly hash?: Maybe<Scalars['String']['output']>;
  /** The ID of an object */
  readonly id: Scalars['ID']['output'];
  /** The schema name that is used to validate the input data. */
  readonly schemaName: Scalars['String']['output'];
  /** The schema version that is used to validate the input data. */
  readonly schemaVersion: Scalars['String']['output'];
  /** Whether or not the input data was validated against the schema. */
  readonly validated: Scalars['Boolean']['output'];
};

/** Represents a membership request. */
export type MembershipRequest = Node & {
  readonly __typename?: 'MembershipRequest';
  /** When the Membership Request was accepted. */
  readonly acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  /** When the Membership Request was amended. */
  readonly amendedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The applicant. */
  readonly applicant: Applicant;
  /** When the Membership Request was closed. */
  readonly closedAt?: Maybe<Scalars['DateTime']['output']>;
  /** When the Membership Request had it's documents signed. */
  readonly documentsSignedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The associated FAST. */
  readonly fast: Fast;
  /** The ID of an object */
  readonly id: Scalars['ID']['output'];
  /** The associated Identity, if it exists. */
  readonly identity?: Maybe<Identity>;
  /** The object's creation timestmap. */
  readonly insertedAt: Scalars['DateTime']['output'];
  /** When the Membership Request was issued. */
  readonly issuedAt: Scalars['DateTime']['output'];
  /** The associated Onboard, if it exists. */
  readonly onboard?: Maybe<Onboard>;
  /** When the Membership Request was rejected. */
  readonly rejectedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The associated sending Identity (the invitor). */
  readonly sender: Identity;
  /** The state of the membership request. */
  readonly state: MembershipRequestState;
  /** The object's last update timestamp. */
  readonly updatedAt: Scalars['DateTime']['output'];
};

/** A list of membership requests. */
export type MembershipRequestConnection = {
  readonly __typename?: 'MembershipRequestConnection';
  readonly edges?: Maybe<ReadonlyArray<Maybe<MembershipRequestEdge>>>;
  readonly pageInfo: PageInfo;
};

export type MembershipRequestEdge = {
  readonly __typename?: 'MembershipRequestEdge';
  readonly cursor?: Maybe<Scalars['String']['output']>;
  readonly node?: Maybe<MembershipRequest>;
};

/** Represents the settings for onboarding to a FAST. */
export type MembershipRequestSettings = {
  readonly __typename?: 'MembershipRequestSettings';
  /** The plaintext template for document signing. */
  readonly documentsSigningTemplate: Scalars['String']['output'];
  /** The required documents for onboarding. */
  readonly requiredDocuments: ReadonlyArray<Document>;
};

/** Membership request state. */
export enum MembershipRequestState {
  Accepted = 'ACCEPTED',
  Amended = 'AMENDED',
  Closed = 'CLOSED',
  Issued = 'ISSUED',
  Rejected = 'REJECTED'
}

export type Meta = {
  readonly __typename?: 'Meta';
  /** The type of Bot this relates to. */
  readonly type: Scalars['String']['output'];
};

export type Node = {
  /** The ID of the object. */
  readonly id: Scalars['ID']['output'];
};

/** Represents an onboarding object. */
export type Onboard = Node & {
  readonly __typename?: 'Onboard';
  /** When the Onboard was amended. */
  readonly amendedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The claim token of the Onboard. */
  readonly claimToken: Scalars['String']['output'];
  /** When the Onboard was claimed. */
  readonly claimedAt?: Maybe<Scalars['DateTime']['output']>;
  /** When the Onboard was closed. */
  readonly closedAt?: Maybe<Scalars['DateTime']['output']>;
  /** When the documents were signed for the Onboard. */
  readonly documentsSignedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The email address of this onboard. */
  readonly emailAddress: Scalars['String']['output'];
  /** The eth address of this onboard. */
  readonly ethAddress?: Maybe<Scalars['EthAddress']['output']>;
  /** When the eth address was confirmed on the Onboard. */
  readonly ethAddressConfirmedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The form that was submitted with the Onboard details. */
  readonly form: JsonDocument;
  /** The full name of this onboard. */
  readonly fullName?: Maybe<Scalars['String']['output']>;
  /** The ID of an object */
  readonly id: Scalars['ID']['output'];
  /** The identity check status (from Onfido). */
  readonly identityCheckStatus: OnboardIdentityCheckStatus;
  /** When the identity check response happened on the Onboard. */
  readonly identityCheckedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The object's creation timestmap. */
  readonly insertedAt: Scalars['DateTime']['output'];
  /** When the Onboard issuance email was sent. */
  readonly issuanceEmailSentAt?: Maybe<Scalars['DateTime']['output']>;
  /** When the Onboard was issued. */
  readonly issuedAt: Scalars['DateTime']['output'];
  /** The associated membership requests. */
  readonly membershipRequests: ReadonlyArray<MembershipRequest>;
  /** The current step of the onboarding process. */
  readonly onboardingStep: OnboardingStep;
  /** The user who received this onboard. */
  readonly recipient?: Maybe<User>;
  /** The state of the Onboard. */
  readonly state: OnboardStates;
  /** When the Onboard was submitted. */
  readonly submittedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The type of the Onboard. */
  readonly type: IdentityType;
  /** The object's last update timestamp. */
  readonly updatedAt: Scalars['DateTime']['output'];
};

/** A list of onboards. */
export type OnboardConnection = {
  readonly __typename?: 'OnboardConnection';
  readonly edges?: Maybe<ReadonlyArray<Maybe<OnboardEdge>>>;
  readonly pageInfo: PageInfo;
};

export type OnboardEdge = {
  readonly __typename?: 'OnboardEdge';
  readonly cursor?: Maybe<Scalars['String']['output']>;
  readonly node?: Maybe<Onboard>;
};

/** Possible identity check statuses for onboards. */
export enum OnboardIdentityCheckStatus {
  Approved = 'APPROVED',
  Declined = 'DECLINED',
  Unchecked = 'UNCHECKED'
}

/** Possible states for onboards. */
export enum OnboardStates {
  Claimed = 'CLAIMED',
  Closed = 'CLOSED',
  Incomplete = 'INCOMPLETE',
  Issued = 'ISSUED',
  Submitted = 'SUBMITTED'
}

/** Represents the settings for onboarding. */
export type OnboardingSettings = {
  readonly __typename?: 'OnboardingSettings';
  /** The eth address confirmation template. */
  readonly confirmEthAddressTemplate: Scalars['String']['output'];
  /** The plaintext template for document signing. */
  readonly documentsSigningTemplate: Scalars['String']['output'];
  /** The required documents for onboarding. */
  readonly requiredDocuments: ReadonlyArray<Document>;
};

/** Possible steps for an onboard. */
export enum OnboardingStep {
  ConfirmEthAddress = 'CONFIRM_ETH_ADDRESS',
  FillDetails = 'FILL_DETAILS',
  Finish = 'FINISH',
  PerformKyc = 'PERFORM_KYC',
  ReviewKyc = 'REVIEW_KYC',
  SignDocuments = 'SIGN_DOCUMENTS',
  VerifyEmail = 'VERIFY_EMAIL',
  WaitForChain = 'WAIT_FOR_CHAIN'
}

export type PageInfo = {
  readonly __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  readonly endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  readonly hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  readonly hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  readonly startCursor?: Maybe<Scalars['String']['output']>;
};

/** Represents a proposal object. */
export type Proposal = IsDocumentable & Node & {
  readonly __typename?: 'Proposal';
  /** Allows to query possible scopes to be applied while attaching documents to an object. */
  readonly allowedDocumentationScopes: ReadonlyArray<Scalars['String']['output']>;
  /** Documents attached to this object within a given scope. */
  readonly attachments?: Maybe<AttachmentConnection>;
  /** The choices that a voter can pick from when casting a vote on this proposal. */
  readonly choices: ReadonlyArray<Scalars['String']['output']>;
  /** When this proposal was closed. */
  readonly closedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The description of this proposal. */
  readonly description: Scalars['String']['output'];
  /** Indicates whether or not the current viewing identity has already voted for this proposal. */
  readonly hasVoted: Scalars['Boolean']['output'];
  /** The ID of an object */
  readonly id: Scalars['ID']['output'];
  /** The object's creation timestmap. */
  readonly insertedAt: Scalars['DateTime']['output'];
  /** When this proposal was opened. */
  readonly openedAt: Scalars['DateTime']['output'];
  /** The identity who created this proposal. */
  readonly proposer: Identity;
  /** The state of this proposal. */
  readonly state: ProposalStates;
  /**
   * The up-to-date tally for this proposal. It allows to get the current
   * tally without having to iterate over all the votes.
   * The tally field is nullable because it's only available to members who have voted.
   */
  readonly tally?: Maybe<ReadonlyArray<ProposalTallyItem>>;
  /** The title of this proposal. */
  readonly title: Scalars['String']['output'];
  /** The object's last update timestamp. */
  readonly updatedAt: Scalars['DateTime']['output'];
  /** The votable object this proposal targets - e.g. the election subject. */
  readonly votable: Votable;
  /** The vote, if it exists for the current viewing identity. */
  readonly vote?: Maybe<Vote>;
  /**
   * The votes that already have been recorded for this proposal.
   * This can be nullable as it's only available to members who have voted.
   */
  readonly votes?: Maybe<VoteConnection>;
};


/** Represents a proposal object. */
export type ProposalAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  scope?: InputMaybe<Scalars['String']['input']>;
};


/** Represents a proposal object. */
export type ProposalHasVotedArgs = {
  identityId: Scalars['ID']['input'];
};


/** Represents a proposal object. */
export type ProposalVoteArgs = {
  identityId: Scalars['ID']['input'];
};


/** Represents a proposal object. */
export type ProposalVotesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** A list of proposals. */
export type ProposalConnection = {
  readonly __typename?: 'ProposalConnection';
  readonly edges?: Maybe<ReadonlyArray<Maybe<ProposalEdge>>>;
  readonly pageInfo: PageInfo;
};

export type ProposalEdge = {
  readonly __typename?: 'ProposalEdge';
  readonly cursor?: Maybe<Scalars['String']['output']>;
  readonly node?: Maybe<Proposal>;
};

/** Possible states for proposals. */
export enum ProposalStates {
  Closed = 'CLOSED',
  Open = 'OPEN'
}

/** The `choice` `count` key value pair used in the proposal tally field. */
export type ProposalTallyItem = {
  readonly __typename?: 'ProposalTallyItem';
  readonly choice: Scalars['String']['output'];
  readonly count: Scalars['Int']['output'];
};

/** Information about the membership request to reject. */
export type RejectMembershipRequestInput = {
  /** The membership request id. */
  readonly membershipRequestId: Scalars['ID']['input'];
};

export type RejectMembershipRequestPayload = {
  readonly __typename?: 'RejectMembershipRequestPayload';
  /** The membership request after the operation. */
  readonly membershipRequest: MembershipRequest;
};

/** The FAST to request membership for. */
export type RequestFastMembershipInput = {
  /** The FAST id. */
  readonly fastId: Scalars['ID']['input'];
};

export type RequestFastMembershipPayload = {
  readonly __typename?: 'RequestFastMembershipPayload';
  /** The result of the operation. */
  readonly result: Scalars['Boolean']['output'];
};

export type RequestOnboardIdentityCheckInput = {
  /** The claim token. */
  readonly claimToken: Scalars['String']['input'];
  /** The onboard id. */
  readonly onboardId: Scalars['ID']['input'];
};

export type RequestOnboardIdentityCheckPayload = {
  readonly __typename?: 'RequestOnboardIdentityCheckPayload';
  /** A token or null if no token has been created yet. */
  readonly token?: Maybe<Scalars['String']['output']>;
  /** The workflow run id or null if no run has been created yet. */
  readonly workflowRunId?: Maybe<Scalars['String']['output']>;
};

export type RootMutationType = {
  readonly __typename?: 'RootMutationType';
  /** This mutation allows for testing distributions. */
  readonly _calculateDistributions?: Maybe<_CalculateDistributionsPayload>;
  /** This mutation allows for accepting a membership request. */
  readonly acceptMembershipRequest?: Maybe<AcceptMembershipRequestPayload>;
  /** This mutation allows for updating an FAST. */
  readonly amendFast?: Maybe<AmendFastPayload>;
  /** This mutation allows for updating an onboard. */
  readonly amendOnboard?: Maybe<AmendOnboardPayload>;
  /** Allows to link an existing document to any documentable object. */
  readonly attach?: Maybe<AttachPayload>;
  /** This mutation authenticates an identity and lets them retrieve their bearer token. */
  readonly authenticate?: Maybe<AuthenticatePayload>;
  /** This mutation allows for verifying the email address on an Onboard. */
  readonly claimOnboard?: Maybe<ClaimOnboardPayload>;
  /** This mutation allows for closing a proposal. */
  readonly closeProposal?: Maybe<CloseProposalPayload>;
  /** This mutation allows for verifying ownership of the `ethAddress` on the Onboard. */
  readonly confirmOnboardEthAddress?: Maybe<ConfirmOnboardEthAddressPayload>;
  /** This mutation generates a signed url for use when uploading documents. */
  readonly createDocument?: Maybe<CreateDocumentPayload>;
  /** This mutation allows for creating a proposal. */
  readonly createProposal?: Maybe<CreateProposalPayload>;
  /** This mutation edits the Slack channel mapping for a FAST. */
  readonly editSlackChannelMappings?: Maybe<EditSlackChannelMappingsPayload>;
  /** This mutation allows for issuing an Onboard. */
  readonly issueOnboard?: Maybe<IssueOnboardPayload>;
  /** This mutation allows for rejecting a membership request. */
  readonly rejectMembershipRequest?: Maybe<RejectMembershipRequestPayload>;
  /** This mutation allows for requesting membership to a FAST. */
  readonly requestFastMembership?: Maybe<RequestFastMembershipPayload>;
  /** Request an SDK token for identity checks for an Onboard. */
  readonly requestOnboardIdentityCheck?: Maybe<RequestOnboardIdentityCheckPayload>;
  /** This mutation allows for signing the required documents for a FAST. */
  readonly signMembershipRequestDocuments?: Maybe<SignMembershipRequestDocumentsPayload>;
  /** This mutation allows for signing the onboard documents. */
  readonly signOnboardDocuments?: Maybe<SignOnboardDocumentsPayload>;
  /** This mutation allows for voting on a proposal. */
  readonly vote?: Maybe<VotePayload>;
};


export type RootMutationType_CalculateDistributionsArgs = {
  input: _CalculateDistributionsInput;
};


export type RootMutationTypeAcceptMembershipRequestArgs = {
  input: AcceptMembershipRequestInput;
};


export type RootMutationTypeAmendFastArgs = {
  input: AmendFastInput;
};


export type RootMutationTypeAmendOnboardArgs = {
  input: AmendOnboardInput;
};


export type RootMutationTypeAttachArgs = {
  input: AttachInput;
};


export type RootMutationTypeAuthenticateArgs = {
  input: AuthenticateInput;
};


export type RootMutationTypeClaimOnboardArgs = {
  input: ClaimOnboardInput;
};


export type RootMutationTypeCloseProposalArgs = {
  input: CloseProposalInput;
};


export type RootMutationTypeConfirmOnboardEthAddressArgs = {
  input: ConfirmOnboardEthAddressInput;
};


export type RootMutationTypeCreateDocumentArgs = {
  input: CreateDocumentInput;
};


export type RootMutationTypeCreateProposalArgs = {
  input: CreateProposalInput;
};


export type RootMutationTypeEditSlackChannelMappingsArgs = {
  input: EditSlackChannelMappingsInput;
};


export type RootMutationTypeIssueOnboardArgs = {
  input: IssueOnboardInput;
};


export type RootMutationTypeRejectMembershipRequestArgs = {
  input: RejectMembershipRequestInput;
};


export type RootMutationTypeRequestFastMembershipArgs = {
  input: RequestFastMembershipInput;
};


export type RootMutationTypeRequestOnboardIdentityCheckArgs = {
  input: RequestOnboardIdentityCheckInput;
};


export type RootMutationTypeSignMembershipRequestDocumentsArgs = {
  input: SignMembershipRequestDocumentsInput;
};


export type RootMutationTypeSignOnboardDocumentsArgs = {
  input: SignOnboardDocumentsInput;
};


export type RootMutationTypeVoteArgs = {
  input: VoteInput;
};

export type RootQueryType = {
  readonly __typename?: 'RootQueryType';
  /** The onboard that matches this id and claim token. */
  readonly claimedOnboard: Onboard;
  /** Documents available on the parent object. */
  readonly documents?: Maybe<DocumentConnection>;
  /** All FASTs that exist within this parent. */
  readonly fasts?: Maybe<FastConnection>;
  /** Identities available on the parent object. */
  readonly identities?: Maybe<IdentityConnection>;
  /** Look up identities by eth_addresses. */
  readonly lookupIdentities?: Maybe<ReadonlyArray<Maybe<Identity>>>;
  /** Checks a bearer token and returns the identity matching it if it's valid. */
  readonly me?: Maybe<Identity>;
  /** Required information to onboard to a FAST. */
  readonly membershipRequestSettings: MembershipRequestSettings;
  /** Membership requests available on the parent object. */
  readonly membershipRequests?: Maybe<MembershipRequestConnection>;
  /** Exposes any node given its global ID. */
  readonly node?: Maybe<Node>;
  readonly nodes: ReadonlyArray<Maybe<Node>>;
  /** Required information to onboard. */
  readonly onboardingSettings: OnboardingSettings;
  /** Onboards available on the parent object. */
  readonly onboards?: Maybe<OnboardConnection>;
  /** Proposals attached to this object. */
  readonly proposals?: Maybe<ProposalConnection>;
  /** A query allowing to obtain server-side settings and environment information. */
  readonly settings: Scalars['Json']['output'];
};


export type RootQueryTypeClaimedOnboardArgs = {
  claimToken: Scalars['String']['input'];
  onboardId: Scalars['ID']['input'];
};


export type RootQueryTypeDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type RootQueryTypeFastsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  ethAddress?: InputMaybe<Scalars['EthAddress']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  symbol?: InputMaybe<Scalars['String']['input']>;
};


export type RootQueryTypeIdentitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fulltext?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type RootQueryTypeLookupIdentitiesArgs = {
  ethAddresses: ReadonlyArray<Scalars['EthAddress']['input']>;
};


export type RootQueryTypeMembershipRequestSettingsArgs = {
  membershipRequestId: Scalars['ID']['input'];
};


export type RootQueryTypeMembershipRequestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  fastId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  states?: InputMaybe<ReadonlyArray<MembershipRequestState>>;
};


export type RootQueryTypeNodeArgs = {
  id: Scalars['ID']['input'];
};


export type RootQueryTypeNodesArgs = {
  ids: ReadonlyArray<Scalars['ID']['input']>;
};


export type RootQueryTypeOnboardingSettingsArgs = {
  claimToken: Scalars['String']['input'];
  onboardId: Scalars['ID']['input'];
};


export type RootQueryTypeOnboardsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type RootQueryTypeProposalsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type RootSubscriptionType = {
  readonly __typename?: 'RootSubscriptionType';
  readonly globalMessages: Scalars['Json']['output'];
  readonly identityMessages: Scalars['Json']['output'];
  readonly onboardUpdated: Onboard;
};


export type RootSubscriptionTypeGlobalMessagesArgs = {
  authorisation: SubscriptionAuth;
};


export type RootSubscriptionTypeIdentityMessagesArgs = {
  authorisation: SubscriptionAuth;
};


export type RootSubscriptionTypeOnboardUpdatedArgs = {
  claimToken: Scalars['String']['input'];
  onboardId: Scalars['ID']['input'];
};

/** Represents a session in our system. */
export type Session = Node & {
  readonly __typename?: 'Session';
  /** The session's bearer token. */
  readonly bearerToken: Scalars['String']['output'];
  /** The ID of an object */
  readonly id: Scalars['ID']['output'];
  /** The identity to whom this session belongs to. */
  readonly identity: Identity;
};

export type SignMembershipRequestDocumentsInput = {
  /** A list of documents that have been agreed to. */
  readonly documentIds: ReadonlyArray<Scalars['ID']['input']>;
  /** The signature for the given plaintext. */
  readonly ethSignature: Scalars['EthSignature']['input'];
  /** The membership request id. */
  readonly membershipRequestId: Scalars['ID']['input'];
  /** The timestamp when the plaintext was signed. */
  readonly transmittedAt: Scalars['DateTime']['input'];
};

export type SignMembershipRequestDocumentsPayload = {
  readonly __typename?: 'SignMembershipRequestDocumentsPayload';
  /** The membership request after the operation. */
  readonly membershipRequest: MembershipRequest;
};

export type SignOnboardDocumentsInput = {
  /** The claim token. */
  readonly claimToken: Scalars['String']['input'];
  /** A list of documents that have been agreed to. */
  readonly documentIds: ReadonlyArray<Scalars['ID']['input']>;
  /** The signature for the given plaintext. */
  readonly ethSignature: Scalars['EthSignature']['input'];
  /** The onboard id. */
  readonly onboardId: Scalars['ID']['input'];
  /** The timestamp when the plaintext was signed. */
  readonly transmittedAt: Scalars['DateTime']['input'];
};

export type SignOnboardDocumentsPayload = {
  readonly __typename?: 'SignOnboardDocumentsPayload';
  /** The onboard after the operation. */
  readonly onboard: Onboard;
};

/**
 * A subscription authorization object.
 *
 * Please note that connecting to the GraphQL's websocket doesn't require any sort of
 * authentication. Instead, credentials are passed at subscription-time - not on transport
 * level.
 *
 * Therefore, the socket doesn't need to be closed when the authentication or session
 * context changes - instead, all subscription could be canceled, and new ones should
 * be established on the same socket.
 */
export type SubscriptionAuth = {
  /** The bearer token. */
  readonly bearerToken: Scalars['String']['input'];
};

/** Represents a user in our system. */
export type User = Node & {
  readonly __typename?: 'User';
  /** The users email address */
  readonly emailAddress: Scalars['String']['output'];
  /** The ID of an object */
  readonly id: Scalars['ID']['output'];
  /** Identities available on the parent object. */
  readonly identities?: Maybe<IdentityConnection>;
  /** Onboards available on the parent object. */
  readonly onboards?: Maybe<OnboardConnection>;
};


/** Represents a user in our system. */
export type UserIdentitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fulltext?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Represents a user in our system. */
export type UserOnboardsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type Votable = Fast;

/** Represents a vote object. */
export type Vote = Node & {
  readonly __typename?: 'Vote';
  /** The choice that was picked when casting this vote, as made available by the proposal. */
  readonly choice: Scalars['String']['output'];
  /** The comment that was added to the vote if it was given when voting. */
  readonly comment?: Maybe<Scalars['String']['output']>;
  /** The ID of an object */
  readonly id: Scalars['ID']['output'];
  /** The object's creation timestmap. */
  readonly insertedAt: Scalars['DateTime']['output'];
  /** The proposal in which this vote was cast. */
  readonly proposal: Proposal;
  /** The object's last update timestamp. */
  readonly updatedAt: Scalars['DateTime']['output'];
  /** The identity who cast this vote. */
  readonly voter: Identity;
};

/** A list of votes. */
export type VoteConnection = {
  readonly __typename?: 'VoteConnection';
  readonly edges?: Maybe<ReadonlyArray<Maybe<VoteEdge>>>;
  readonly pageInfo: PageInfo;
};

export type VoteEdge = {
  readonly __typename?: 'VoteEdge';
  readonly cursor?: Maybe<Scalars['String']['output']>;
  readonly node?: Maybe<Vote>;
};

/** Information about the Proposal. */
export type VoteInput = {
  /** The choice to vote for. */
  readonly choice: Scalars['String']['input'];
  /** If supplied, a comment along with the vote. */
  readonly comment?: InputMaybe<Scalars['String']['input']>;
  /** The signature for the given plaintext. */
  readonly ethSignature: Scalars['EthSignature']['input'];
  /** The proposal to vote on. */
  readonly proposalId: Scalars['ID']['input'];
  /** The timestamp when the plaintext was signed. */
  readonly transmittedAt: Scalars['DateTime']['input'];
};

export type VotePayload = {
  readonly __typename?: 'VotePayload';
  /** The result of the operation. */
  readonly vote: Vote;
};

/** Information about the Distribution to test. */
export type _CalculateDistributionsInput = {
  /** Distribution amount. */
  readonly distributionAmount: Scalars['String']['input'];
  /** The fee amount (BPS). */
  readonly feeAmount: Scalars['Int']['input'];
  /** List of token holders. */
  readonly holdings: ReadonlyArray<FastHolding>;
  /** Total supply of the FAST. */
  readonly totalSupply: Scalars['String']['input'];
};

export type _CalculateDistributionsPayload = {
  readonly __typename?: '_CalculateDistributionsPayload';
  /** Test case output. */
  readonly testCaseOutput: DistributionTestCaseOutput;
};

export type ResolveAddressQueryVariables = Exact<{
  fullName: Scalars['String']['input'];
  numberOfIdentities: Scalars['Int']['input'];
}>;


export type ResolveAddressQuery = (
  { readonly __typename?: 'RootQueryType' }
  & { readonly identities?: Maybe<(
    { readonly __typename?: 'IdentityConnection' }
    & { readonly edges?: Maybe<ReadonlyArray<Maybe<(
      { readonly __typename?: 'IdentityEdge' }
      & { readonly node?: Maybe<(
        { readonly __typename?: 'Identity' }
        & Pick<Identity, 'id' | 'ethAddress' | 'fullName'>
      )> }
    )>>> }
  )> }
);

export type ResolveIdentityQueryVariables = Exact<{
  ethAddress: Scalars['EthAddress']['input'];
}>;


export type ResolveIdentityQuery = (
  { readonly __typename?: 'RootQueryType' }
  & { readonly lookupIdentities?: Maybe<ReadonlyArray<Maybe<(
    { readonly __typename?: 'Identity' }
    & Pick<Identity, 'id' | 'fullName'>
  )>>> }
);

export type CreateDocumentMutationVariables = Exact<{
  input: CreateDocumentInput;
}>;


export type CreateDocumentMutation = (
  { readonly __typename?: 'RootMutationType' }
  & { readonly createDocument?: Maybe<(
    { readonly __typename?: 'CreateDocumentPayload' }
    & Pick<CreateDocumentPayload, 'signedUrl'>
    & { readonly document: (
      { readonly __typename?: 'Document' }
      & Pick<Document, 'id' | 'contentType' | 'downloadUrl'>
    ) }
  )> }
);

export type CreateDocumentDocumentFragment = (
  { readonly __typename?: 'Document' }
  & Pick<Document, 'id' | 'contentType' | 'downloadUrl'>
);

export type FullFastDetailsFragment = (
  { readonly __typename?: 'Fast' }
  & Pick<Fast, 'id' | 'visibility' | 'name' | 'logoUrl'>
  & { readonly form?: Maybe<(
    { readonly __typename?: 'JsonDocument' }
    & Pick<JsonDocument, 'schemaVersion' | 'data'>
  )>, readonly proposals?: Maybe<(
    { readonly __typename?: 'ProposalConnection' }
    & { readonly edges?: Maybe<ReadonlyArray<Maybe<(
      { readonly __typename?: 'ProposalEdge' }
      & { readonly node?: Maybe<(
        { readonly __typename?: 'Proposal' }
        & Pick<Proposal, 'id' | 'title' | 'description' | 'choices' | 'state' | 'hasVoted' | 'openedAt' | 'closedAt'>
        & { readonly tally?: Maybe<ReadonlyArray<(
          { readonly __typename?: 'ProposalTallyItem' }
          & Pick<ProposalTallyItem, 'choice' | 'count'>
        )>>, readonly proposer: (
          { readonly __typename?: 'Identity' }
          & Pick<Identity, 'fullName' | 'ethAddress'>
        ) }
      )> }
    )>>> }
  )> }
);

export type FullFastDetailsAsAnyoneFragment = (
  { readonly __typename?: 'Fast' }
  & Pick<Fast, 'id' | 'visibility' | 'name' | 'logoUrl'>
);

export type FullFastDetailsAsMemberFragment = (
  { readonly __typename?: 'Fast' }
  & { readonly form?: Maybe<(
    { readonly __typename?: 'JsonDocument' }
    & Pick<JsonDocument, 'schemaVersion' | 'data'>
  )>, readonly proposals?: Maybe<(
    { readonly __typename?: 'ProposalConnection' }
    & { readonly edges?: Maybe<ReadonlyArray<Maybe<(
      { readonly __typename?: 'ProposalEdge' }
      & { readonly node?: Maybe<(
        { readonly __typename?: 'Proposal' }
        & Pick<Proposal, 'id' | 'title' | 'description' | 'choices' | 'state' | 'hasVoted' | 'openedAt' | 'closedAt'>
        & { readonly tally?: Maybe<ReadonlyArray<(
          { readonly __typename?: 'ProposalTallyItem' }
          & Pick<ProposalTallyItem, 'choice' | 'count'>
        )>>, readonly proposer: (
          { readonly __typename?: 'Identity' }
          & Pick<Identity, 'fullName' | 'ethAddress'>
        ) }
      )> }
    )>>> }
  )> }
);

export type AcceptMemebershipRequestMutationVariables = Exact<{
  input: AcceptMembershipRequestInput;
}>;


export type AcceptMemebershipRequestMutation = (
  { readonly __typename?: 'RootMutationType' }
  & { readonly acceptMembershipRequest?: Maybe<(
    { readonly __typename?: 'AcceptMembershipRequestPayload' }
    & { readonly membershipRequest: (
      { readonly __typename?: 'MembershipRequest' }
      & Pick<MembershipRequest, 'id' | 'state' | 'acceptedAt' | 'insertedAt' | 'updatedAt' | 'rejectedAt'>
    ) }
  )> }
);

export type MembershipRequestsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  isIdentityCheckStatusNeeded?: InputMaybe<Scalars['Boolean']['input']>;
  fastId?: InputMaybe<Scalars['ID']['input']>;
  states?: InputMaybe<ReadonlyArray<MembershipRequestState> | MembershipRequestState>;
}>;


export type MembershipRequestsQuery = (
  { readonly __typename?: 'RootQueryType' }
  & { readonly membershipRequests?: Maybe<(
    { readonly __typename?: 'MembershipRequestConnection' }
    & { readonly edges?: Maybe<ReadonlyArray<Maybe<(
      { readonly __typename?: 'MembershipRequestEdge' }
      & { readonly node?: Maybe<(
        { readonly __typename?: 'MembershipRequest' }
        & Pick<MembershipRequest, 'id' | 'state' | 'acceptedAt' | 'issuedAt' | 'rejectedAt'>
        & { readonly fast: (
          { readonly __typename?: 'Fast' }
          & Pick<Fast, 'id' | 'symbol'>
        ), readonly applicant: (
          { readonly __typename?: 'Applicant' }
          & MakeOptional<Pick<Applicant, 'fullName' | 'ethAddress' | 'linkedIn' | 'identityCheckStatus'>, 'identityCheckStatus'>
        ) }
      )> }
    )>>>, readonly pageInfo: (
      { readonly __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>
    ) }
  )> }
);

export type RejectMembershipRequestMutationVariables = Exact<{
  input: RejectMembershipRequestInput;
}>;


export type RejectMembershipRequestMutation = (
  { readonly __typename?: 'RootMutationType' }
  & { readonly rejectMembershipRequest?: Maybe<(
    { readonly __typename?: 'RejectMembershipRequestPayload' }
    & { readonly membershipRequest: (
      { readonly __typename?: 'MembershipRequest' }
      & Pick<MembershipRequest, 'id' | 'state' | 'acceptedAt' | 'insertedAt' | 'updatedAt' | 'rejectedAt'>
    ) }
  )> }
);

export type MembershipRequestFragment = (
  { readonly __typename?: 'MembershipRequest' }
  & Pick<MembershipRequest, 'id' | 'state' | 'acceptedAt' | 'issuedAt' | 'rejectedAt'>
  & { readonly fast: (
    { readonly __typename?: 'Fast' }
    & Pick<Fast, 'id' | 'symbol'>
  ), readonly applicant: (
    { readonly __typename?: 'Applicant' }
    & MakeOptional<Pick<Applicant, 'fullName' | 'ethAddress' | 'linkedIn' | 'identityCheckStatus'>, 'identityCheckStatus'>
  ) }
);

export type FastMembershipRequestFragment = (
  { readonly __typename?: 'Fast' }
  & Pick<Fast, 'id' | 'symbol'>
);

export type MembershipRequestActionFragment = (
  { readonly __typename?: 'MembershipRequest' }
  & Pick<MembershipRequest, 'id' | 'state' | 'acceptedAt' | 'insertedAt' | 'updatedAt' | 'rejectedAt'>
);

export type PageInfoFragment = (
  { readonly __typename?: 'PageInfo' }
  & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>
);

export type AmendOnboardMutationVariables = Exact<{
  input: AmendOnboardInput;
}>;


export type AmendOnboardMutation = (
  { readonly __typename?: 'RootMutationType' }
  & { readonly amendOnboard?: Maybe<(
    { readonly __typename?: 'AmendOnboardPayload' }
    & { readonly onboard: (
      { readonly __typename?: 'Onboard' }
      & Pick<Onboard, 'id' | 'state' | 'identityCheckStatus' | 'emailAddress' | 'claimToken' | 'claimedAt' | 'documentsSignedAt' | 'submittedAt' | 'ethAddress' | 'onboardingStep'>
      & { readonly form: (
        { readonly __typename?: 'JsonDocument' }
        & Pick<JsonDocument, 'schemaVersion' | 'data'>
      ), readonly membershipRequests: ReadonlyArray<(
        { readonly __typename?: 'MembershipRequest' }
        & Pick<MembershipRequest, 'id' | 'state' | 'issuedAt' | 'acceptedAt' | 'rejectedAt' | 'closedAt'>
        & { readonly fast: (
          { readonly __typename?: 'Fast' }
          & Pick<Fast, 'id' | 'logoUrl' | 'name' | 'symbol'>
        ) }
      )> }
    ) }
  )> }
);

export type ClaimOnboardMutationVariables = Exact<{
  input: ClaimOnboardInput;
}>;


export type ClaimOnboardMutation = (
  { readonly __typename?: 'RootMutationType' }
  & { readonly claimOnboard?: Maybe<(
    { readonly __typename?: 'ClaimOnboardPayload' }
    & { readonly onboard: (
      { readonly __typename?: 'Onboard' }
      & Pick<Onboard, 'id' | 'state' | 'identityCheckStatus' | 'emailAddress' | 'claimToken' | 'claimedAt' | 'documentsSignedAt' | 'submittedAt' | 'ethAddress' | 'onboardingStep'>
      & { readonly form: (
        { readonly __typename?: 'JsonDocument' }
        & Pick<JsonDocument, 'schemaVersion' | 'data'>
      ), readonly membershipRequests: ReadonlyArray<(
        { readonly __typename?: 'MembershipRequest' }
        & Pick<MembershipRequest, 'id' | 'state' | 'issuedAt' | 'acceptedAt' | 'rejectedAt' | 'closedAt'>
        & { readonly fast: (
          { readonly __typename?: 'Fast' }
          & Pick<Fast, 'id' | 'logoUrl' | 'name' | 'symbol'>
        ) }
      )> }
    ) }
  )> }
);

export type ConfirmOnboardEthAddressMutationVariables = Exact<{
  input: ConfirmOnboardEthAddressInput;
}>;


export type ConfirmOnboardEthAddressMutation = (
  { readonly __typename?: 'RootMutationType' }
  & { readonly confirmOnboardEthAddress?: Maybe<(
    { readonly __typename?: 'ConfirmOnboardEthAddressPayload' }
    & { readonly onboard: (
      { readonly __typename?: 'Onboard' }
      & Pick<Onboard, 'id' | 'state' | 'identityCheckStatus' | 'emailAddress' | 'claimToken' | 'claimedAt' | 'documentsSignedAt' | 'submittedAt' | 'ethAddress' | 'onboardingStep'>
      & { readonly form: (
        { readonly __typename?: 'JsonDocument' }
        & Pick<JsonDocument, 'schemaVersion' | 'data'>
      ), readonly membershipRequests: ReadonlyArray<(
        { readonly __typename?: 'MembershipRequest' }
        & Pick<MembershipRequest, 'id' | 'state' | 'issuedAt' | 'acceptedAt' | 'rejectedAt' | 'closedAt'>
        & { readonly fast: (
          { readonly __typename?: 'Fast' }
          & Pick<Fast, 'id' | 'logoUrl' | 'name' | 'symbol'>
        ) }
      )> }
    ) }
  )> }
);

export type MembershipRequestSettingsQueryVariables = Exact<{
  membershipRequestId: Scalars['ID']['input'];
}>;


export type MembershipRequestSettingsQuery = (
  { readonly __typename?: 'RootQueryType' }
  & { readonly membershipRequestSettings: (
    { readonly __typename?: 'MembershipRequestSettings' }
    & Pick<MembershipRequestSettings, 'documentsSigningTemplate'>
    & { readonly requiredDocuments: ReadonlyArray<(
      { readonly __typename?: 'Document' }
      & Pick<Document, 'id' | 'downloadUrl' | 'name'>
    )> }
  ) }
);

export type OnboardingSettingsQueryVariables = Exact<{
  onboardId: Scalars['ID']['input'];
  claimToken: Scalars['String']['input'];
}>;


export type OnboardingSettingsQuery = (
  { readonly __typename?: 'RootQueryType' }
  & { readonly onboardingSettings: (
    { readonly __typename?: 'OnboardingSettings' }
    & Pick<OnboardingSettings, 'confirmEthAddressTemplate' | 'documentsSigningTemplate'>
    & { readonly requiredDocuments: ReadonlyArray<(
      { readonly __typename?: 'Document' }
      & Pick<Document, 'id' | 'downloadUrl' | 'name'>
    )> }
  ) }
);

export type RequestOnboardIdentityCheckMutationVariables = Exact<{
  input: RequestOnboardIdentityCheckInput;
}>;


export type RequestOnboardIdentityCheckMutation = (
  { readonly __typename?: 'RootMutationType' }
  & { readonly requestOnboardIdentityCheck?: Maybe<(
    { readonly __typename?: 'RequestOnboardIdentityCheckPayload' }
    & Pick<RequestOnboardIdentityCheckPayload, 'token' | 'workflowRunId'>
  )> }
);

export type SignMembershipRequestDocumentsMutationVariables = Exact<{
  input: SignMembershipRequestDocumentsInput;
}>;


export type SignMembershipRequestDocumentsMutation = (
  { readonly __typename?: 'RootMutationType' }
  & { readonly signMembershipRequestDocuments?: Maybe<(
    { readonly __typename?: 'SignMembershipRequestDocumentsPayload' }
    & { readonly membershipRequest: (
      { readonly __typename?: 'MembershipRequest' }
      & Pick<MembershipRequest, 'id' | 'state' | 'documentsSignedAt'>
    ) }
  )> }
);

export type SignOnboardDocumentsMutationVariables = Exact<{
  input: SignOnboardDocumentsInput;
}>;


export type SignOnboardDocumentsMutation = (
  { readonly __typename?: 'RootMutationType' }
  & { readonly signOnboardDocuments?: Maybe<(
    { readonly __typename?: 'SignOnboardDocumentsPayload' }
    & { readonly onboard: (
      { readonly __typename?: 'Onboard' }
      & Pick<Onboard, 'id' | 'state' | 'identityCheckStatus' | 'emailAddress' | 'claimToken' | 'claimedAt' | 'documentsSignedAt' | 'submittedAt' | 'ethAddress' | 'onboardingStep'>
      & { readonly form: (
        { readonly __typename?: 'JsonDocument' }
        & Pick<JsonDocument, 'schemaVersion' | 'data'>
      ), readonly membershipRequests: ReadonlyArray<(
        { readonly __typename?: 'MembershipRequest' }
        & Pick<MembershipRequest, 'id' | 'state' | 'issuedAt' | 'acceptedAt' | 'rejectedAt' | 'closedAt'>
        & { readonly fast: (
          { readonly __typename?: 'Fast' }
          & Pick<Fast, 'id' | 'logoUrl' | 'name' | 'symbol'>
        ) }
      )> }
    ) }
  )> }
);

export type WatchOnboardUpdateSubscriptionVariables = Exact<{
  claimToken: Scalars['String']['input'];
  onboardId: Scalars['ID']['input'];
}>;


export type WatchOnboardUpdateSubscription = (
  { readonly __typename?: 'RootSubscriptionType' }
  & { readonly onboardUpdated: (
    { readonly __typename?: 'Onboard' }
    & Pick<Onboard, 'id' | 'state' | 'identityCheckStatus' | 'emailAddress' | 'claimToken' | 'claimedAt' | 'documentsSignedAt' | 'submittedAt' | 'ethAddress' | 'onboardingStep'>
    & { readonly form: (
      { readonly __typename?: 'JsonDocument' }
      & Pick<JsonDocument, 'schemaVersion' | 'data'>
    ), readonly membershipRequests: ReadonlyArray<(
      { readonly __typename?: 'MembershipRequest' }
      & Pick<MembershipRequest, 'id' | 'state' | 'issuedAt' | 'acceptedAt' | 'rejectedAt' | 'closedAt'>
      & { readonly fast: (
        { readonly __typename?: 'Fast' }
        & Pick<Fast, 'id' | 'logoUrl' | 'name' | 'symbol'>
      ) }
    )> }
  ) }
);

export type OnboardingOnboardFragment = (
  { readonly __typename?: 'Onboard' }
  & Pick<Onboard, 'id' | 'state' | 'identityCheckStatus' | 'emailAddress' | 'claimToken' | 'claimedAt' | 'documentsSignedAt' | 'submittedAt' | 'ethAddress' | 'onboardingStep'>
  & { readonly form: (
    { readonly __typename?: 'JsonDocument' }
    & Pick<JsonDocument, 'schemaVersion' | 'data'>
  ), readonly membershipRequests: ReadonlyArray<(
    { readonly __typename?: 'MembershipRequest' }
    & Pick<MembershipRequest, 'id' | 'state' | 'issuedAt' | 'acceptedAt' | 'rejectedAt' | 'closedAt'>
    & { readonly fast: (
      { readonly __typename?: 'Fast' }
      & Pick<Fast, 'id' | 'logoUrl' | 'name' | 'symbol'>
    ) }
  )> }
);

export type MembershipRequestOnboardingFragment = (
  { readonly __typename?: 'MembershipRequest' }
  & Pick<MembershipRequest, 'id' | 'state' | 'issuedAt' | 'acceptedAt' | 'rejectedAt' | 'closedAt'>
  & { readonly fast: (
    { readonly __typename?: 'Fast' }
    & Pick<Fast, 'id' | 'logoUrl' | 'name' | 'symbol'>
  ) }
);

export type FastFragment = (
  { readonly __typename?: 'Fast' }
  & Pick<Fast, 'id' | 'logoUrl' | 'name' | 'symbol'>
);

export type OnboardingSettingsFragment = (
  { readonly __typename?: 'OnboardingSettings' }
  & Pick<OnboardingSettings, 'confirmEthAddressTemplate' | 'documentsSigningTemplate'>
  & { readonly requiredDocuments: ReadonlyArray<(
    { readonly __typename?: 'Document' }
    & Pick<Document, 'id' | 'downloadUrl' | 'name'>
  )> }
);

export type MembershipRequestSettingsFragment = (
  { readonly __typename?: 'MembershipRequestSettings' }
  & Pick<MembershipRequestSettings, 'documentsSigningTemplate'>
  & { readonly requiredDocuments: ReadonlyArray<(
    { readonly __typename?: 'Document' }
    & Pick<Document, 'id' | 'downloadUrl' | 'name'>
  )> }
);

export type MembershipRequestSignDocumentsFragment = (
  { readonly __typename?: 'MembershipRequest' }
  & Pick<MembershipRequest, 'id' | 'state' | 'documentsSignedAt'>
);

export type DocumentFragment = (
  { readonly __typename?: 'Document' }
  & Pick<Document, 'id' | 'downloadUrl' | 'name'>
);

export type IssueOnboardMutationVariables = Exact<{
  input: IssueOnboardInput;
}>;


export type IssueOnboardMutation = (
  { readonly __typename?: 'RootMutationType' }
  & { readonly issueOnboard?: Maybe<(
    { readonly __typename?: 'IssueOnboardPayload' }
    & Pick<IssueOnboardPayload, 'result'>
  )> }
);

export type DocumentDetailsQueryVariables = Exact<{
  documentId: Scalars['ID']['input'];
}>;


export type DocumentDetailsQuery = (
  { readonly __typename?: 'RootQueryType' }
  & { readonly node?: Maybe<{ readonly __typename?: 'Attachment' | 'Bot' | 'Fast' | 'Identity' | 'JsonDocument' | 'MembershipRequest' | 'Onboard' | 'Proposal' | 'Session' | 'User' | 'Vote' } | (
    { readonly __typename?: 'Document' }
    & Pick<Document, 'id' | 'contentType' | 'downloadUrl'>
  )> }
);

export type DocumentDetailsDocumentFragment = (
  { readonly __typename?: 'Document' }
  & Pick<Document, 'id' | 'contentType' | 'downloadUrl'>
);

export type AmendFastMutationVariables = Exact<{
  input: AmendFastInput;
  isMember: Scalars['Boolean']['input'];
  identityId: Scalars['ID']['input'];
}>;


export type AmendFastMutation = (
  { readonly __typename?: 'RootMutationType' }
  & { readonly amendFast?: Maybe<(
    { readonly __typename?: 'AmendFastPayload' }
    & { readonly fast: (
      { readonly __typename?: 'Fast' }
      & Pick<Fast, 'id' | 'visibility' | 'name' | 'logoUrl'>
      & { readonly form?: Maybe<(
        { readonly __typename?: 'JsonDocument' }
        & Pick<JsonDocument, 'schemaVersion' | 'data'>
      )>, readonly proposals?: Maybe<(
        { readonly __typename?: 'ProposalConnection' }
        & { readonly edges?: Maybe<ReadonlyArray<Maybe<(
          { readonly __typename?: 'ProposalEdge' }
          & { readonly node?: Maybe<(
            { readonly __typename?: 'Proposal' }
            & Pick<Proposal, 'id' | 'title' | 'description' | 'choices' | 'state' | 'hasVoted' | 'openedAt' | 'closedAt'>
            & { readonly tally?: Maybe<ReadonlyArray<(
              { readonly __typename?: 'ProposalTallyItem' }
              & Pick<ProposalTallyItem, 'choice' | 'count'>
            )>>, readonly proposer: (
              { readonly __typename?: 'Identity' }
              & Pick<Identity, 'fullName' | 'ethAddress'>
            ) }
          )> }
        )>>> }
      )> }
    ) }
  )> }
);

export type FastDetailsQueryVariables = Exact<{
  symbol: Scalars['String']['input'];
  isMember: Scalars['Boolean']['input'];
  identityId: Scalars['ID']['input'];
}>;


export type FastDetailsQuery = (
  { readonly __typename?: 'RootQueryType' }
  & { readonly fasts?: Maybe<(
    { readonly __typename?: 'FastConnection' }
    & { readonly edges?: Maybe<ReadonlyArray<Maybe<(
      { readonly __typename?: 'FastEdge' }
      & { readonly node?: Maybe<(
        { readonly __typename?: 'Fast' }
        & Pick<Fast, 'id' | 'visibility' | 'name' | 'logoUrl'>
        & { readonly form?: Maybe<(
          { readonly __typename?: 'JsonDocument' }
          & Pick<JsonDocument, 'schemaVersion' | 'data'>
        )>, readonly proposals?: Maybe<(
          { readonly __typename?: 'ProposalConnection' }
          & { readonly edges?: Maybe<ReadonlyArray<Maybe<(
            { readonly __typename?: 'ProposalEdge' }
            & { readonly node?: Maybe<(
              { readonly __typename?: 'Proposal' }
              & Pick<Proposal, 'id' | 'title' | 'description' | 'choices' | 'state' | 'hasVoted' | 'openedAt' | 'closedAt'>
              & { readonly tally?: Maybe<ReadonlyArray<(
                { readonly __typename?: 'ProposalTallyItem' }
                & Pick<ProposalTallyItem, 'choice' | 'count'>
              )>>, readonly proposer: (
                { readonly __typename?: 'Identity' }
                & Pick<Identity, 'fullName' | 'ethAddress'>
              ) }
            )> }
          )>>> }
        )> }
      )> }
    )>>> }
  )> }
);

export type ResolveAddressesQueryVariables = Exact<{
  ethAddresses: ReadonlyArray<Scalars['EthAddress']['input']> | Scalars['EthAddress']['input'];
}>;


export type ResolveAddressesQuery = (
  { readonly __typename?: 'RootQueryType' }
  & { readonly lookupIdentities?: Maybe<ReadonlyArray<Maybe<(
    { readonly __typename?: 'Identity' }
    & Pick<Identity, 'id' | 'fullName'>
  )>>> }
);

export type ResolvedIdentityFragment = (
  { readonly __typename?: 'Identity' }
  & Pick<Identity, 'id' | 'fullName'>
);

export type MyFastsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyFastsQuery = (
  { readonly __typename?: 'RootQueryType' }
  & { readonly me?: Maybe<(
    { readonly __typename?: 'Identity' }
    & { readonly memberships?: Maybe<(
      { readonly __typename?: 'FastConnection' }
      & { readonly edges?: Maybe<ReadonlyArray<Maybe<(
        { readonly __typename?: 'FastEdge' }
        & { readonly node?: Maybe<(
          { readonly __typename?: 'Fast' }
          & Pick<Fast, 'id' | 'ethAddress'>
        )> }
      )>>> }
    )> }
  )> }
);

export type CloseProposalMutationVariables = Exact<{
  input: CloseProposalInput;
}>;


export type CloseProposalMutation = (
  { readonly __typename?: 'RootMutationType' }
  & { readonly closeProposal?: Maybe<(
    { readonly __typename?: 'CloseProposalPayload' }
    & { readonly proposal: (
      { readonly __typename?: 'Proposal' }
      & Pick<Proposal, 'id' | 'title' | 'description' | 'choices' | 'state' | 'openedAt' | 'closedAt'>
    ) }
  )> }
);

export type CreateProposalRequestMutationVariables = Exact<{
  input: CreateProposalInput;
}>;


export type CreateProposalRequestMutation = (
  { readonly __typename?: 'RootMutationType' }
  & { readonly createProposal?: Maybe<(
    { readonly __typename?: 'CreateProposalPayload' }
    & { readonly proposal: (
      { readonly __typename?: 'Proposal' }
      & Pick<Proposal, 'id' | 'title' | 'description' | 'choices' | 'state' | 'openedAt' | 'closedAt'>
    ) }
  )> }
);

export type ProposalQueryVariables = Exact<{
  proposalId: Scalars['ID']['input'];
  identityId: Scalars['ID']['input'];
  hasVoted?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type ProposalQuery = (
  { readonly __typename?: 'RootQueryType' }
  & { readonly proposal?: Maybe<{ readonly __typename?: 'Attachment' | 'Bot' | 'Document' | 'Fast' | 'Identity' | 'JsonDocument' | 'MembershipRequest' | 'Onboard' | 'Session' | 'User' | 'Vote' } | (
    { readonly __typename?: 'Proposal' }
    & Pick<Proposal, 'id' | 'title' | 'description' | 'choices' | 'state' | 'hasVoted' | 'openedAt' | 'closedAt'>
    & { readonly proposer: (
      { readonly __typename?: 'Identity' }
      & Pick<Identity, 'fullName' | 'ethAddress'>
    ), readonly tally?: Maybe<ReadonlyArray<(
      { readonly __typename?: 'ProposalTallyItem' }
      & Pick<ProposalTallyItem, 'choice' | 'count'>
    )>>, readonly vote?: Maybe<(
      { readonly __typename?: 'Vote' }
      & Pick<Vote, 'id' | 'choice' | 'comment' | 'insertedAt'>
    )>, readonly votes?: Maybe<(
      { readonly __typename?: 'VoteConnection' }
      & { readonly edges?: Maybe<ReadonlyArray<Maybe<(
        { readonly __typename?: 'VoteEdge' }
        & { readonly node?: Maybe<(
          { readonly __typename?: 'Vote' }
          & Pick<Vote, 'id' | 'choice' | 'comment' | 'insertedAt'>
        )> }
      )>>> }
    )> }
  )> }
);

export type ProposalsQueryVariables = Exact<{
  identityId: Scalars['ID']['input'];
}>;


export type ProposalsQuery = (
  { readonly __typename?: 'RootQueryType' }
  & { readonly proposals?: Maybe<(
    { readonly __typename?: 'ProposalConnection' }
    & { readonly edges?: Maybe<ReadonlyArray<Maybe<(
      { readonly __typename?: 'ProposalEdge' }
      & { readonly node?: Maybe<(
        { readonly __typename?: 'Proposal' }
        & Pick<Proposal, 'id' | 'title' | 'description' | 'choices' | 'state' | 'hasVoted' | 'openedAt' | 'closedAt'>
        & { readonly proposer: (
          { readonly __typename?: 'Identity' }
          & Pick<Identity, 'fullName' | 'ethAddress'>
        ) }
      )> }
    )>>> }
  )> }
);

export type VoteMutationVariables = Exact<{
  input: VoteInput;
}>;


export type VoteMutation = (
  { readonly __typename?: 'RootMutationType' }
  & { readonly vote?: Maybe<(
    { readonly __typename?: 'VotePayload' }
    & { readonly vote: (
      { readonly __typename?: 'Vote' }
      & Pick<Vote, 'id' | 'choice' | 'comment'>
    ) }
  )> }
);

export type ShortProposalFragment = (
  { readonly __typename?: 'Proposal' }
  & Pick<Proposal, 'id' | 'title' | 'description' | 'choices' | 'state' | 'openedAt' | 'closedAt'>
);

export type ProposalFragment = (
  { readonly __typename?: 'Proposal' }
  & Pick<Proposal, 'id' | 'title' | 'description' | 'choices' | 'state' | 'hasVoted' | 'openedAt' | 'closedAt'>
  & { readonly proposer: (
    { readonly __typename?: 'Identity' }
    & Pick<Identity, 'fullName' | 'ethAddress'>
  ) }
);

export type ProposalCommonListFragment = (
  { readonly __typename?: 'Proposal' }
  & Pick<Proposal, 'id' | 'title' | 'description' | 'choices' | 'state' | 'hasVoted' | 'openedAt' | 'closedAt'>
  & { readonly tally?: Maybe<ReadonlyArray<(
    { readonly __typename?: 'ProposalTallyItem' }
    & Pick<ProposalTallyItem, 'choice' | 'count'>
  )>>, readonly proposer: (
    { readonly __typename?: 'Identity' }
    & Pick<Identity, 'fullName' | 'ethAddress'>
  ) }
);

export type ProposalVotesFragment = (
  { readonly __typename?: 'Proposal' }
  & { readonly tally?: Maybe<ReadonlyArray<(
    { readonly __typename?: 'ProposalTallyItem' }
    & Pick<ProposalTallyItem, 'choice' | 'count'>
  )>>, readonly vote?: Maybe<(
    { readonly __typename?: 'Vote' }
    & Pick<Vote, 'id' | 'choice' | 'comment' | 'insertedAt'>
  )>, readonly votes?: Maybe<(
    { readonly __typename?: 'VoteConnection' }
    & { readonly edges?: Maybe<ReadonlyArray<Maybe<(
      { readonly __typename?: 'VoteEdge' }
      & { readonly node?: Maybe<(
        { readonly __typename?: 'Vote' }
        & Pick<Vote, 'id' | 'choice' | 'comment' | 'insertedAt'>
      )> }
    )>>> }
  )> }
);

export type ProposalWithVotesFragment = (
  { readonly __typename?: 'Proposal' }
  & Pick<Proposal, 'id' | 'title' | 'description' | 'choices' | 'state' | 'hasVoted' | 'openedAt' | 'closedAt'>
  & { readonly proposer: (
    { readonly __typename?: 'Identity' }
    & Pick<Identity, 'fullName' | 'ethAddress'>
  ), readonly tally?: Maybe<ReadonlyArray<(
    { readonly __typename?: 'ProposalTallyItem' }
    & Pick<ProposalTallyItem, 'choice' | 'count'>
  )>>, readonly vote?: Maybe<(
    { readonly __typename?: 'Vote' }
    & Pick<Vote, 'id' | 'choice' | 'comment' | 'insertedAt'>
  )>, readonly votes?: Maybe<(
    { readonly __typename?: 'VoteConnection' }
    & { readonly edges?: Maybe<ReadonlyArray<Maybe<(
      { readonly __typename?: 'VoteEdge' }
      & { readonly node?: Maybe<(
        { readonly __typename?: 'Vote' }
        & Pick<Vote, 'id' | 'choice' | 'comment' | 'insertedAt'>
      )> }
    )>>> }
  )> }
);

export type ProposalVoteFragment = (
  { readonly __typename?: 'Vote' }
  & Pick<Vote, 'id' | 'choice' | 'comment' | 'insertedAt'>
);

export type VoteFragment = (
  { readonly __typename?: 'Vote' }
  & Pick<Vote, 'id' | 'choice' | 'comment'>
);

export type AuthenticateMutationVariables = Exact<{
  input: AuthenticateInput;
}>;


export type AuthenticateMutation = (
  { readonly __typename?: 'RootMutationType' }
  & { readonly authenticate?: Maybe<(
    { readonly __typename?: 'AuthenticatePayload' }
    & { readonly session: (
      { readonly __typename?: 'Session' }
      & Pick<Session, 'id' | 'bearerToken'>
      & { readonly identity: (
        { readonly __typename?: 'Identity' }
        & Pick<Identity, 'id' | 'traits' | 'emailAddress' | 'ethAddress' | 'fullName'>
        & { readonly membershipRequests: ReadonlyArray<(
          { readonly __typename?: 'MembershipRequest' }
          & Pick<MembershipRequest, 'id' | 'documentsSignedAt'>
          & { readonly fast: (
            { readonly __typename?: 'Fast' }
            & Pick<Fast, 'logoUrl' | 'name' | 'symbol'>
          ) }
        )> }
      ) }
    ) }
  )> }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { readonly __typename?: 'RootQueryType' }
  & { readonly me?: Maybe<(
    { readonly __typename?: 'Identity' }
    & Pick<Identity, 'id' | 'traits' | 'emailAddress' | 'ethAddress' | 'fullName'>
    & { readonly membershipRequests: ReadonlyArray<(
      { readonly __typename?: 'MembershipRequest' }
      & Pick<MembershipRequest, 'id' | 'documentsSignedAt'>
      & { readonly fast: (
        { readonly __typename?: 'Fast' }
        & Pick<Fast, 'logoUrl' | 'name' | 'symbol'>
      ) }
    )> }
  )> }
);

export type SessionSessionFragment = (
  { readonly __typename?: 'Session' }
  & Pick<Session, 'id' | 'bearerToken'>
  & { readonly identity: (
    { readonly __typename?: 'Identity' }
    & Pick<Identity, 'id' | 'traits' | 'emailAddress' | 'ethAddress' | 'fullName'>
    & { readonly membershipRequests: ReadonlyArray<(
      { readonly __typename?: 'MembershipRequest' }
      & Pick<MembershipRequest, 'id' | 'documentsSignedAt'>
      & { readonly fast: (
        { readonly __typename?: 'Fast' }
        & Pick<Fast, 'logoUrl' | 'name' | 'symbol'>
      ) }
    )> }
  ) }
);

export type SessionIdentityFragment = (
  { readonly __typename?: 'Identity' }
  & Pick<Identity, 'id' | 'traits' | 'emailAddress' | 'ethAddress' | 'fullName'>
  & { readonly membershipRequests: ReadonlyArray<(
    { readonly __typename?: 'MembershipRequest' }
    & Pick<MembershipRequest, 'id' | 'documentsSignedAt'>
    & { readonly fast: (
      { readonly __typename?: 'Fast' }
      & Pick<Fast, 'logoUrl' | 'name' | 'symbol'>
    ) }
  )> }
);

export type MembershipRequestIdentityFragment = (
  { readonly __typename?: 'MembershipRequest' }
  & Pick<MembershipRequest, 'id' | 'documentsSignedAt'>
  & { readonly fast: (
    { readonly __typename?: 'Fast' }
    & Pick<Fast, 'logoUrl' | 'name' | 'symbol'>
  ) }
);

export type FastIdentityFragment = (
  { readonly __typename?: 'Fast' }
  & Pick<Fast, 'logoUrl' | 'name' | 'symbol'>
);

export type SettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type SettingsQuery = (
  { readonly __typename?: 'RootQueryType' }
  & Pick<RootQueryType, 'settings'>
);

export const CreateDocumentDocumentFragmentDoc = gql`
    fragment CreateDocumentDocument on Document {
  id
  contentType
  downloadUrl
}
    `;
export const FullFastDetailsAsAnyoneFragmentDoc = gql`
    fragment FullFastDetailsAsAnyone on Fast {
  id
  visibility
  name
  logoUrl
}
    `;
export const ProposalFragmentDoc = gql`
    fragment Proposal on Proposal {
  id
  title
  description
  choices
  state
  hasVoted(identityId: $identityId)
  openedAt
  closedAt
  proposer {
    fullName
    ethAddress
  }
}
    `;
export const ProposalCommonListFragmentDoc = gql`
    fragment ProposalCommonList on Proposal {
  ...Proposal
  tally {
    choice
    count
  }
}
    `;
export const FullFastDetailsAsMemberFragmentDoc = gql`
    fragment FullFastDetailsAsMember on Fast {
  form {
    schemaVersion
    data
  }
  proposals(first: 100) {
    edges {
      node {
        ...ProposalCommonList
      }
    }
  }
}
    `;
export const FullFastDetailsFragmentDoc = gql`
    fragment FullFastDetails on Fast {
  ...FullFastDetailsAsAnyone
  ...FullFastDetailsAsMember @include(if: $isMember)
}
    `;
export const FastMembershipRequestFragmentDoc = gql`
    fragment FastMembershipRequest on Fast {
  id
  symbol
}
    `;
export const MembershipRequestFragmentDoc = gql`
    fragment MembershipRequest on MembershipRequest {
  id
  state
  fast {
    ...FastMembershipRequest
  }
  acceptedAt
  issuedAt
  rejectedAt
  applicant {
    fullName
    ethAddress
    linkedIn
    identityCheckStatus @include(if: $isIdentityCheckStatusNeeded)
  }
}
    `;
export const MembershipRequestActionFragmentDoc = gql`
    fragment MembershipRequestAction on MembershipRequest {
  id
  state
  acceptedAt
  insertedAt
  updatedAt
  rejectedAt
}
    `;
export const PageInfoFragmentDoc = gql`
    fragment PageInfo on PageInfo {
  endCursor
  hasNextPage
  hasPreviousPage
  startCursor
}
    `;
export const FastFragmentDoc = gql`
    fragment Fast on Fast {
  id
  logoUrl
  name
  symbol
}
    `;
export const MembershipRequestOnboardingFragmentDoc = gql`
    fragment MembershipRequestOnboarding on MembershipRequest {
  id
  state
  fast {
    ...Fast
  }
  issuedAt
  acceptedAt
  rejectedAt
  closedAt
}
    `;
export const OnboardingOnboardFragmentDoc = gql`
    fragment OnboardingOnboard on Onboard {
  id
  state
  identityCheckStatus
  emailAddress
  claimToken
  claimedAt
  documentsSignedAt
  submittedAt
  ethAddress
  onboardingStep
  form {
    schemaVersion
    data
  }
  membershipRequests {
    ...MembershipRequestOnboarding
  }
}
    `;
export const DocumentFragmentDoc = gql`
    fragment Document on Document {
  id
  downloadUrl
  name
}
    `;
export const OnboardingSettingsFragmentDoc = gql`
    fragment OnboardingSettings on OnboardingSettings {
  requiredDocuments {
    ...Document
  }
  confirmEthAddressTemplate
  documentsSigningTemplate
}
    `;
export const MembershipRequestSettingsFragmentDoc = gql`
    fragment MembershipRequestSettings on MembershipRequestSettings {
  requiredDocuments {
    ...Document
  }
  documentsSigningTemplate
}
    `;
export const MembershipRequestSignDocumentsFragmentDoc = gql`
    fragment MembershipRequestSignDocuments on MembershipRequest {
  id
  state
  documentsSignedAt
}
    `;
export const DocumentDetailsDocumentFragmentDoc = gql`
    fragment DocumentDetailsDocument on Document {
  id
  contentType
  downloadUrl
}
    `;
export const ResolvedIdentityFragmentDoc = gql`
    fragment ResolvedIdentity on Identity {
  id
  fullName
}
    `;
export const ShortProposalFragmentDoc = gql`
    fragment ShortProposal on Proposal {
  id
  title
  description
  choices
  state
  openedAt
  closedAt
}
    `;
export const ProposalVoteFragmentDoc = gql`
    fragment ProposalVote on Vote {
  id
  choice
  comment
  insertedAt
}
    `;
export const ProposalVotesFragmentDoc = gql`
    fragment ProposalVotes on Proposal {
  tally {
    choice
    count
  }
  vote(identityId: $identityId) {
    ...ProposalVote
  }
  votes(first: 100) {
    edges {
      node {
        ...ProposalVote
      }
    }
  }
}
    `;
export const ProposalWithVotesFragmentDoc = gql`
    fragment ProposalWithVotes on Proposal {
  ...Proposal
  ...ProposalVotes @include(if: $hasVoted)
}
    `;
export const VoteFragmentDoc = gql`
    fragment Vote on Vote {
  id
  choice
  comment
}
    `;
export const FastIdentityFragmentDoc = gql`
    fragment FastIdentity on Fast {
  logoUrl
  name
  symbol
}
    `;
export const MembershipRequestIdentityFragmentDoc = gql`
    fragment MembershipRequestIdentity on MembershipRequest {
  id
  documentsSignedAt
  fast {
    ...FastIdentity
  }
}
    `;
export const SessionIdentityFragmentDoc = gql`
    fragment SessionIdentity on Identity {
  id
  traits
  emailAddress
  ethAddress
  fullName
  membershipRequests {
    ...MembershipRequestIdentity
  }
}
    `;
export const SessionSessionFragmentDoc = gql`
    fragment SessionSession on Session {
  id
  bearerToken
  identity {
    ...SessionIdentity
  }
}
    `;
export const ResolveAddressDocument = gql`
    query ResolveAddress($fullName: String!, $numberOfIdentities: Int!) {
  identities(first: $numberOfIdentities, fullName: $fullName) {
    edges {
      node {
        id
        ethAddress
        fullName
      }
    }
  }
}
    `;

/**
 * __useResolveAddressQuery__
 *
 * To run a query within a React component, call `useResolveAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useResolveAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResolveAddressQuery({
 *   variables: {
 *      fullName: // value for 'fullName'
 *      numberOfIdentities: // value for 'numberOfIdentities'
 *   },
 * });
 */
export function useResolveAddressQuery(baseOptions: Apollo.QueryHookOptions<ResolveAddressQuery, ResolveAddressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResolveAddressQuery, ResolveAddressQueryVariables>(ResolveAddressDocument, options);
      }
export function useResolveAddressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResolveAddressQuery, ResolveAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResolveAddressQuery, ResolveAddressQueryVariables>(ResolveAddressDocument, options);
        }
export type ResolveAddressQueryHookResult = ReturnType<typeof useResolveAddressQuery>;
export type ResolveAddressLazyQueryHookResult = ReturnType<typeof useResolveAddressLazyQuery>;
export type ResolveAddressQueryResult = Apollo.QueryResult<ResolveAddressQuery, ResolveAddressQueryVariables>;
export const ResolveIdentityDocument = gql`
    query ResolveIdentity($ethAddress: EthAddress!) {
  lookupIdentities(ethAddresses: [$ethAddress]) {
    id
    fullName
  }
}
    `;

/**
 * __useResolveIdentityQuery__
 *
 * To run a query within a React component, call `useResolveIdentityQuery` and pass it any options that fit your needs.
 * When your component renders, `useResolveIdentityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResolveIdentityQuery({
 *   variables: {
 *      ethAddress: // value for 'ethAddress'
 *   },
 * });
 */
export function useResolveIdentityQuery(baseOptions: Apollo.QueryHookOptions<ResolveIdentityQuery, ResolveIdentityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResolveIdentityQuery, ResolveIdentityQueryVariables>(ResolveIdentityDocument, options);
      }
export function useResolveIdentityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResolveIdentityQuery, ResolveIdentityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResolveIdentityQuery, ResolveIdentityQueryVariables>(ResolveIdentityDocument, options);
        }
export type ResolveIdentityQueryHookResult = ReturnType<typeof useResolveIdentityQuery>;
export type ResolveIdentityLazyQueryHookResult = ReturnType<typeof useResolveIdentityLazyQuery>;
export type ResolveIdentityQueryResult = Apollo.QueryResult<ResolveIdentityQuery, ResolveIdentityQueryVariables>;
export const CreateDocumentDocument = gql`
    mutation CreateDocument($input: CreateDocumentInput!) {
  createDocument(input: $input) {
    signedUrl
    document {
      ...CreateDocumentDocument
    }
  }
}
    ${CreateDocumentDocumentFragmentDoc}`;
export type CreateDocumentMutationFn = Apollo.MutationFunction<CreateDocumentMutation, CreateDocumentMutationVariables>;

/**
 * __useCreateDocumentMutation__
 *
 * To run a mutation, you first call `useCreateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentMutation, { data, loading, error }] = useCreateDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDocumentMutation(baseOptions?: Apollo.MutationHookOptions<CreateDocumentMutation, CreateDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDocumentMutation, CreateDocumentMutationVariables>(CreateDocumentDocument, options);
      }
export type CreateDocumentMutationHookResult = ReturnType<typeof useCreateDocumentMutation>;
export type CreateDocumentMutationResult = Apollo.MutationResult<CreateDocumentMutation>;
export type CreateDocumentMutationOptions = Apollo.BaseMutationOptions<CreateDocumentMutation, CreateDocumentMutationVariables>;
export const AcceptMemebershipRequestDocument = gql`
    mutation AcceptMemebershipRequest($input: AcceptMembershipRequestInput!) {
  acceptMembershipRequest(input: $input) {
    membershipRequest {
      ...MembershipRequestAction
    }
  }
}
    ${MembershipRequestActionFragmentDoc}`;
export type AcceptMemebershipRequestMutationFn = Apollo.MutationFunction<AcceptMemebershipRequestMutation, AcceptMemebershipRequestMutationVariables>;

/**
 * __useAcceptMemebershipRequestMutation__
 *
 * To run a mutation, you first call `useAcceptMemebershipRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptMemebershipRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptMemebershipRequestMutation, { data, loading, error }] = useAcceptMemebershipRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptMemebershipRequestMutation(baseOptions?: Apollo.MutationHookOptions<AcceptMemebershipRequestMutation, AcceptMemebershipRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptMemebershipRequestMutation, AcceptMemebershipRequestMutationVariables>(AcceptMemebershipRequestDocument, options);
      }
export type AcceptMemebershipRequestMutationHookResult = ReturnType<typeof useAcceptMemebershipRequestMutation>;
export type AcceptMemebershipRequestMutationResult = Apollo.MutationResult<AcceptMemebershipRequestMutation>;
export type AcceptMemebershipRequestMutationOptions = Apollo.BaseMutationOptions<AcceptMemebershipRequestMutation, AcceptMemebershipRequestMutationVariables>;
export const MembershipRequestsDocument = gql`
    query MembershipRequests($after: String, $before: String, $first: Int, $last: Int, $isIdentityCheckStatusNeeded: Boolean = true, $fastId: ID, $states: [MembershipRequestState!]) {
  membershipRequests(
    after: $after
    before: $before
    first: $first
    last: $last
    fastId: $fastId
    states: $states
  ) {
    edges {
      node {
        ...MembershipRequest
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
}
    ${MembershipRequestFragmentDoc}
${FastMembershipRequestFragmentDoc}
${PageInfoFragmentDoc}`;

/**
 * __useMembershipRequestsQuery__
 *
 * To run a query within a React component, call `useMembershipRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembershipRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembershipRequestsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      isIdentityCheckStatusNeeded: // value for 'isIdentityCheckStatusNeeded'
 *      fastId: // value for 'fastId'
 *      states: // value for 'states'
 *   },
 * });
 */
export function useMembershipRequestsQuery(baseOptions?: Apollo.QueryHookOptions<MembershipRequestsQuery, MembershipRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MembershipRequestsQuery, MembershipRequestsQueryVariables>(MembershipRequestsDocument, options);
      }
export function useMembershipRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MembershipRequestsQuery, MembershipRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MembershipRequestsQuery, MembershipRequestsQueryVariables>(MembershipRequestsDocument, options);
        }
export type MembershipRequestsQueryHookResult = ReturnType<typeof useMembershipRequestsQuery>;
export type MembershipRequestsLazyQueryHookResult = ReturnType<typeof useMembershipRequestsLazyQuery>;
export type MembershipRequestsQueryResult = Apollo.QueryResult<MembershipRequestsQuery, MembershipRequestsQueryVariables>;
export const RejectMembershipRequestDocument = gql`
    mutation RejectMembershipRequest($input: RejectMembershipRequestInput!) {
  rejectMembershipRequest(input: $input) {
    membershipRequest {
      ...MembershipRequestAction
    }
  }
}
    ${MembershipRequestActionFragmentDoc}`;
export type RejectMembershipRequestMutationFn = Apollo.MutationFunction<RejectMembershipRequestMutation, RejectMembershipRequestMutationVariables>;

/**
 * __useRejectMembershipRequestMutation__
 *
 * To run a mutation, you first call `useRejectMembershipRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectMembershipRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectMembershipRequestMutation, { data, loading, error }] = useRejectMembershipRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectMembershipRequestMutation(baseOptions?: Apollo.MutationHookOptions<RejectMembershipRequestMutation, RejectMembershipRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectMembershipRequestMutation, RejectMembershipRequestMutationVariables>(RejectMembershipRequestDocument, options);
      }
export type RejectMembershipRequestMutationHookResult = ReturnType<typeof useRejectMembershipRequestMutation>;
export type RejectMembershipRequestMutationResult = Apollo.MutationResult<RejectMembershipRequestMutation>;
export type RejectMembershipRequestMutationOptions = Apollo.BaseMutationOptions<RejectMembershipRequestMutation, RejectMembershipRequestMutationVariables>;
export const AmendOnboardDocument = gql`
    mutation AmendOnboard($input: AmendOnboardInput!) {
  amendOnboard(input: $input) {
    onboard {
      ...OnboardingOnboard
    }
  }
}
    ${OnboardingOnboardFragmentDoc}
${MembershipRequestOnboardingFragmentDoc}
${FastFragmentDoc}`;
export type AmendOnboardMutationFn = Apollo.MutationFunction<AmendOnboardMutation, AmendOnboardMutationVariables>;

/**
 * __useAmendOnboardMutation__
 *
 * To run a mutation, you first call `useAmendOnboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAmendOnboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [amendOnboardMutation, { data, loading, error }] = useAmendOnboardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAmendOnboardMutation(baseOptions?: Apollo.MutationHookOptions<AmendOnboardMutation, AmendOnboardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AmendOnboardMutation, AmendOnboardMutationVariables>(AmendOnboardDocument, options);
      }
export type AmendOnboardMutationHookResult = ReturnType<typeof useAmendOnboardMutation>;
export type AmendOnboardMutationResult = Apollo.MutationResult<AmendOnboardMutation>;
export type AmendOnboardMutationOptions = Apollo.BaseMutationOptions<AmendOnboardMutation, AmendOnboardMutationVariables>;
export const ClaimOnboardDocument = gql`
    mutation ClaimOnboard($input: ClaimOnboardInput!) {
  claimOnboard(input: $input) {
    onboard {
      ...OnboardingOnboard
    }
  }
}
    ${OnboardingOnboardFragmentDoc}
${MembershipRequestOnboardingFragmentDoc}
${FastFragmentDoc}`;
export type ClaimOnboardMutationFn = Apollo.MutationFunction<ClaimOnboardMutation, ClaimOnboardMutationVariables>;

/**
 * __useClaimOnboardMutation__
 *
 * To run a mutation, you first call `useClaimOnboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimOnboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimOnboardMutation, { data, loading, error }] = useClaimOnboardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClaimOnboardMutation(baseOptions?: Apollo.MutationHookOptions<ClaimOnboardMutation, ClaimOnboardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClaimOnboardMutation, ClaimOnboardMutationVariables>(ClaimOnboardDocument, options);
      }
export type ClaimOnboardMutationHookResult = ReturnType<typeof useClaimOnboardMutation>;
export type ClaimOnboardMutationResult = Apollo.MutationResult<ClaimOnboardMutation>;
export type ClaimOnboardMutationOptions = Apollo.BaseMutationOptions<ClaimOnboardMutation, ClaimOnboardMutationVariables>;
export const ConfirmOnboardEthAddressDocument = gql`
    mutation ConfirmOnboardEthAddress($input: ConfirmOnboardEthAddressInput!) {
  confirmOnboardEthAddress(input: $input) {
    onboard {
      ...OnboardingOnboard
    }
  }
}
    ${OnboardingOnboardFragmentDoc}
${MembershipRequestOnboardingFragmentDoc}
${FastFragmentDoc}`;
export type ConfirmOnboardEthAddressMutationFn = Apollo.MutationFunction<ConfirmOnboardEthAddressMutation, ConfirmOnboardEthAddressMutationVariables>;

/**
 * __useConfirmOnboardEthAddressMutation__
 *
 * To run a mutation, you first call `useConfirmOnboardEthAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmOnboardEthAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmOnboardEthAddressMutation, { data, loading, error }] = useConfirmOnboardEthAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmOnboardEthAddressMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmOnboardEthAddressMutation, ConfirmOnboardEthAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmOnboardEthAddressMutation, ConfirmOnboardEthAddressMutationVariables>(ConfirmOnboardEthAddressDocument, options);
      }
export type ConfirmOnboardEthAddressMutationHookResult = ReturnType<typeof useConfirmOnboardEthAddressMutation>;
export type ConfirmOnboardEthAddressMutationResult = Apollo.MutationResult<ConfirmOnboardEthAddressMutation>;
export type ConfirmOnboardEthAddressMutationOptions = Apollo.BaseMutationOptions<ConfirmOnboardEthAddressMutation, ConfirmOnboardEthAddressMutationVariables>;
export const MembershipRequestSettingsDocument = gql`
    query MembershipRequestSettings($membershipRequestId: ID!) {
  membershipRequestSettings(membershipRequestId: $membershipRequestId) {
    ...MembershipRequestSettings
  }
}
    ${MembershipRequestSettingsFragmentDoc}
${DocumentFragmentDoc}`;

/**
 * __useMembershipRequestSettingsQuery__
 *
 * To run a query within a React component, call `useMembershipRequestSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembershipRequestSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembershipRequestSettingsQuery({
 *   variables: {
 *      membershipRequestId: // value for 'membershipRequestId'
 *   },
 * });
 */
export function useMembershipRequestSettingsQuery(baseOptions: Apollo.QueryHookOptions<MembershipRequestSettingsQuery, MembershipRequestSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MembershipRequestSettingsQuery, MembershipRequestSettingsQueryVariables>(MembershipRequestSettingsDocument, options);
      }
export function useMembershipRequestSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MembershipRequestSettingsQuery, MembershipRequestSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MembershipRequestSettingsQuery, MembershipRequestSettingsQueryVariables>(MembershipRequestSettingsDocument, options);
        }
export type MembershipRequestSettingsQueryHookResult = ReturnType<typeof useMembershipRequestSettingsQuery>;
export type MembershipRequestSettingsLazyQueryHookResult = ReturnType<typeof useMembershipRequestSettingsLazyQuery>;
export type MembershipRequestSettingsQueryResult = Apollo.QueryResult<MembershipRequestSettingsQuery, MembershipRequestSettingsQueryVariables>;
export const OnboardingSettingsDocument = gql`
    query OnboardingSettings($onboardId: ID!, $claimToken: String!) {
  onboardingSettings(onboardId: $onboardId, claimToken: $claimToken) {
    ...OnboardingSettings
  }
}
    ${OnboardingSettingsFragmentDoc}
${DocumentFragmentDoc}`;

/**
 * __useOnboardingSettingsQuery__
 *
 * To run a query within a React component, call `useOnboardingSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingSettingsQuery({
 *   variables: {
 *      onboardId: // value for 'onboardId'
 *      claimToken: // value for 'claimToken'
 *   },
 * });
 */
export function useOnboardingSettingsQuery(baseOptions: Apollo.QueryHookOptions<OnboardingSettingsQuery, OnboardingSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OnboardingSettingsQuery, OnboardingSettingsQueryVariables>(OnboardingSettingsDocument, options);
      }
export function useOnboardingSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OnboardingSettingsQuery, OnboardingSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OnboardingSettingsQuery, OnboardingSettingsQueryVariables>(OnboardingSettingsDocument, options);
        }
export type OnboardingSettingsQueryHookResult = ReturnType<typeof useOnboardingSettingsQuery>;
export type OnboardingSettingsLazyQueryHookResult = ReturnType<typeof useOnboardingSettingsLazyQuery>;
export type OnboardingSettingsQueryResult = Apollo.QueryResult<OnboardingSettingsQuery, OnboardingSettingsQueryVariables>;
export const RequestOnboardIdentityCheckDocument = gql`
    mutation RequestOnboardIdentityCheck($input: RequestOnboardIdentityCheckInput!) {
  requestOnboardIdentityCheck(input: $input) {
    token
    workflowRunId
  }
}
    `;
export type RequestOnboardIdentityCheckMutationFn = Apollo.MutationFunction<RequestOnboardIdentityCheckMutation, RequestOnboardIdentityCheckMutationVariables>;

/**
 * __useRequestOnboardIdentityCheckMutation__
 *
 * To run a mutation, you first call `useRequestOnboardIdentityCheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestOnboardIdentityCheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestOnboardIdentityCheckMutation, { data, loading, error }] = useRequestOnboardIdentityCheckMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestOnboardIdentityCheckMutation(baseOptions?: Apollo.MutationHookOptions<RequestOnboardIdentityCheckMutation, RequestOnboardIdentityCheckMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestOnboardIdentityCheckMutation, RequestOnboardIdentityCheckMutationVariables>(RequestOnboardIdentityCheckDocument, options);
      }
export type RequestOnboardIdentityCheckMutationHookResult = ReturnType<typeof useRequestOnboardIdentityCheckMutation>;
export type RequestOnboardIdentityCheckMutationResult = Apollo.MutationResult<RequestOnboardIdentityCheckMutation>;
export type RequestOnboardIdentityCheckMutationOptions = Apollo.BaseMutationOptions<RequestOnboardIdentityCheckMutation, RequestOnboardIdentityCheckMutationVariables>;
export const SignMembershipRequestDocumentsDocument = gql`
    mutation SignMembershipRequestDocuments($input: SignMembershipRequestDocumentsInput!) {
  signMembershipRequestDocuments(input: $input) {
    membershipRequest {
      ...MembershipRequestSignDocuments
    }
  }
}
    ${MembershipRequestSignDocumentsFragmentDoc}`;
export type SignMembershipRequestDocumentsMutationFn = Apollo.MutationFunction<SignMembershipRequestDocumentsMutation, SignMembershipRequestDocumentsMutationVariables>;

/**
 * __useSignMembershipRequestDocumentsMutation__
 *
 * To run a mutation, you first call `useSignMembershipRequestDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignMembershipRequestDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signMembershipRequestDocumentsMutation, { data, loading, error }] = useSignMembershipRequestDocumentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignMembershipRequestDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<SignMembershipRequestDocumentsMutation, SignMembershipRequestDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignMembershipRequestDocumentsMutation, SignMembershipRequestDocumentsMutationVariables>(SignMembershipRequestDocumentsDocument, options);
      }
export type SignMembershipRequestDocumentsMutationHookResult = ReturnType<typeof useSignMembershipRequestDocumentsMutation>;
export type SignMembershipRequestDocumentsMutationResult = Apollo.MutationResult<SignMembershipRequestDocumentsMutation>;
export type SignMembershipRequestDocumentsMutationOptions = Apollo.BaseMutationOptions<SignMembershipRequestDocumentsMutation, SignMembershipRequestDocumentsMutationVariables>;
export const SignOnboardDocumentsDocument = gql`
    mutation SignOnboardDocuments($input: SignOnboardDocumentsInput!) {
  signOnboardDocuments(input: $input) {
    onboard {
      ...OnboardingOnboard
    }
  }
}
    ${OnboardingOnboardFragmentDoc}
${MembershipRequestOnboardingFragmentDoc}
${FastFragmentDoc}`;
export type SignOnboardDocumentsMutationFn = Apollo.MutationFunction<SignOnboardDocumentsMutation, SignOnboardDocumentsMutationVariables>;

/**
 * __useSignOnboardDocumentsMutation__
 *
 * To run a mutation, you first call `useSignOnboardDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignOnboardDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signOnboardDocumentsMutation, { data, loading, error }] = useSignOnboardDocumentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignOnboardDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<SignOnboardDocumentsMutation, SignOnboardDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignOnboardDocumentsMutation, SignOnboardDocumentsMutationVariables>(SignOnboardDocumentsDocument, options);
      }
export type SignOnboardDocumentsMutationHookResult = ReturnType<typeof useSignOnboardDocumentsMutation>;
export type SignOnboardDocumentsMutationResult = Apollo.MutationResult<SignOnboardDocumentsMutation>;
export type SignOnboardDocumentsMutationOptions = Apollo.BaseMutationOptions<SignOnboardDocumentsMutation, SignOnboardDocumentsMutationVariables>;
export const WatchOnboardUpdateDocument = gql`
    subscription WatchOnboardUpdate($claimToken: String!, $onboardId: ID!) {
  onboardUpdated(claimToken: $claimToken, onboardId: $onboardId) {
    ...OnboardingOnboard
  }
}
    ${OnboardingOnboardFragmentDoc}
${MembershipRequestOnboardingFragmentDoc}
${FastFragmentDoc}`;

/**
 * __useWatchOnboardUpdateSubscription__
 *
 * To run a query within a React component, call `useWatchOnboardUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWatchOnboardUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatchOnboardUpdateSubscription({
 *   variables: {
 *      claimToken: // value for 'claimToken'
 *      onboardId: // value for 'onboardId'
 *   },
 * });
 */
export function useWatchOnboardUpdateSubscription(baseOptions: Apollo.SubscriptionHookOptions<WatchOnboardUpdateSubscription, WatchOnboardUpdateSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<WatchOnboardUpdateSubscription, WatchOnboardUpdateSubscriptionVariables>(WatchOnboardUpdateDocument, options);
      }
export type WatchOnboardUpdateSubscriptionHookResult = ReturnType<typeof useWatchOnboardUpdateSubscription>;
export type WatchOnboardUpdateSubscriptionResult = Apollo.SubscriptionResult<WatchOnboardUpdateSubscription>;
export const IssueOnboardDocument = gql`
    mutation IssueOnboard($input: IssueOnboardInput!) {
  issueOnboard(input: $input) {
    result
  }
}
    `;
export type IssueOnboardMutationFn = Apollo.MutationFunction<IssueOnboardMutation, IssueOnboardMutationVariables>;

/**
 * __useIssueOnboardMutation__
 *
 * To run a mutation, you first call `useIssueOnboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssueOnboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issueOnboardMutation, { data, loading, error }] = useIssueOnboardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIssueOnboardMutation(baseOptions?: Apollo.MutationHookOptions<IssueOnboardMutation, IssueOnboardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IssueOnboardMutation, IssueOnboardMutationVariables>(IssueOnboardDocument, options);
      }
export type IssueOnboardMutationHookResult = ReturnType<typeof useIssueOnboardMutation>;
export type IssueOnboardMutationResult = Apollo.MutationResult<IssueOnboardMutation>;
export type IssueOnboardMutationOptions = Apollo.BaseMutationOptions<IssueOnboardMutation, IssueOnboardMutationVariables>;
export const DocumentDetailsDocument = gql`
    query DocumentDetails($documentId: ID!) {
  node(id: $documentId) {
    ...DocumentDetailsDocument
  }
}
    ${DocumentDetailsDocumentFragmentDoc}`;

/**
 * __useDocumentDetailsQuery__
 *
 * To run a query within a React component, call `useDocumentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentDetailsQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useDocumentDetailsQuery(baseOptions: Apollo.QueryHookOptions<DocumentDetailsQuery, DocumentDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentDetailsQuery, DocumentDetailsQueryVariables>(DocumentDetailsDocument, options);
      }
export function useDocumentDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentDetailsQuery, DocumentDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentDetailsQuery, DocumentDetailsQueryVariables>(DocumentDetailsDocument, options);
        }
export type DocumentDetailsQueryHookResult = ReturnType<typeof useDocumentDetailsQuery>;
export type DocumentDetailsLazyQueryHookResult = ReturnType<typeof useDocumentDetailsLazyQuery>;
export type DocumentDetailsQueryResult = Apollo.QueryResult<DocumentDetailsQuery, DocumentDetailsQueryVariables>;
export const AmendFastDocument = gql`
    mutation AmendFast($input: AmendFastInput!, $isMember: Boolean!, $identityId: ID!) {
  amendFast(input: $input) {
    fast {
      ...FullFastDetails
    }
  }
}
    ${FullFastDetailsFragmentDoc}
${FullFastDetailsAsAnyoneFragmentDoc}
${FullFastDetailsAsMemberFragmentDoc}
${ProposalCommonListFragmentDoc}
${ProposalFragmentDoc}`;
export type AmendFastMutationFn = Apollo.MutationFunction<AmendFastMutation, AmendFastMutationVariables>;

/**
 * __useAmendFastMutation__
 *
 * To run a mutation, you first call `useAmendFastMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAmendFastMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [amendFastMutation, { data, loading, error }] = useAmendFastMutation({
 *   variables: {
 *      input: // value for 'input'
 *      isMember: // value for 'isMember'
 *      identityId: // value for 'identityId'
 *   },
 * });
 */
export function useAmendFastMutation(baseOptions?: Apollo.MutationHookOptions<AmendFastMutation, AmendFastMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AmendFastMutation, AmendFastMutationVariables>(AmendFastDocument, options);
      }
export type AmendFastMutationHookResult = ReturnType<typeof useAmendFastMutation>;
export type AmendFastMutationResult = Apollo.MutationResult<AmendFastMutation>;
export type AmendFastMutationOptions = Apollo.BaseMutationOptions<AmendFastMutation, AmendFastMutationVariables>;
export const FastDetailsDocument = gql`
    query FastDetails($symbol: String!, $isMember: Boolean!, $identityId: ID!) {
  fasts(first: 1, symbol: $symbol) {
    edges {
      node {
        ...FullFastDetails
      }
    }
  }
}
    ${FullFastDetailsFragmentDoc}
${FullFastDetailsAsAnyoneFragmentDoc}
${FullFastDetailsAsMemberFragmentDoc}
${ProposalCommonListFragmentDoc}
${ProposalFragmentDoc}`;

/**
 * __useFastDetailsQuery__
 *
 * To run a query within a React component, call `useFastDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFastDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFastDetailsQuery({
 *   variables: {
 *      symbol: // value for 'symbol'
 *      isMember: // value for 'isMember'
 *      identityId: // value for 'identityId'
 *   },
 * });
 */
export function useFastDetailsQuery(baseOptions: Apollo.QueryHookOptions<FastDetailsQuery, FastDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FastDetailsQuery, FastDetailsQueryVariables>(FastDetailsDocument, options);
      }
export function useFastDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FastDetailsQuery, FastDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FastDetailsQuery, FastDetailsQueryVariables>(FastDetailsDocument, options);
        }
export type FastDetailsQueryHookResult = ReturnType<typeof useFastDetailsQuery>;
export type FastDetailsLazyQueryHookResult = ReturnType<typeof useFastDetailsLazyQuery>;
export type FastDetailsQueryResult = Apollo.QueryResult<FastDetailsQuery, FastDetailsQueryVariables>;
export const ResolveAddressesDocument = gql`
    query ResolveAddresses($ethAddresses: [EthAddress!]!) {
  lookupIdentities(ethAddresses: $ethAddresses) {
    ...ResolvedIdentity
  }
}
    ${ResolvedIdentityFragmentDoc}`;

/**
 * __useResolveAddressesQuery__
 *
 * To run a query within a React component, call `useResolveAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useResolveAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResolveAddressesQuery({
 *   variables: {
 *      ethAddresses: // value for 'ethAddresses'
 *   },
 * });
 */
export function useResolveAddressesQuery(baseOptions: Apollo.QueryHookOptions<ResolveAddressesQuery, ResolveAddressesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResolveAddressesQuery, ResolveAddressesQueryVariables>(ResolveAddressesDocument, options);
      }
export function useResolveAddressesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResolveAddressesQuery, ResolveAddressesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResolveAddressesQuery, ResolveAddressesQueryVariables>(ResolveAddressesDocument, options);
        }
export type ResolveAddressesQueryHookResult = ReturnType<typeof useResolveAddressesQuery>;
export type ResolveAddressesLazyQueryHookResult = ReturnType<typeof useResolveAddressesLazyQuery>;
export type ResolveAddressesQueryResult = Apollo.QueryResult<ResolveAddressesQuery, ResolveAddressesQueryVariables>;
export const MyFastsDocument = gql`
    query MyFasts {
  me {
    memberships(first: 100) {
      edges {
        node {
          id
          ethAddress
        }
      }
    }
  }
}
    `;

/**
 * __useMyFastsQuery__
 *
 * To run a query within a React component, call `useMyFastsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyFastsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyFastsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyFastsQuery(baseOptions?: Apollo.QueryHookOptions<MyFastsQuery, MyFastsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyFastsQuery, MyFastsQueryVariables>(MyFastsDocument, options);
      }
export function useMyFastsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyFastsQuery, MyFastsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyFastsQuery, MyFastsQueryVariables>(MyFastsDocument, options);
        }
export type MyFastsQueryHookResult = ReturnType<typeof useMyFastsQuery>;
export type MyFastsLazyQueryHookResult = ReturnType<typeof useMyFastsLazyQuery>;
export type MyFastsQueryResult = Apollo.QueryResult<MyFastsQuery, MyFastsQueryVariables>;
export const CloseProposalDocument = gql`
    mutation CloseProposal($input: CloseProposalInput!) {
  closeProposal(input: $input) {
    proposal {
      ...ShortProposal
    }
  }
}
    ${ShortProposalFragmentDoc}`;
export type CloseProposalMutationFn = Apollo.MutationFunction<CloseProposalMutation, CloseProposalMutationVariables>;

/**
 * __useCloseProposalMutation__
 *
 * To run a mutation, you first call `useCloseProposalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseProposalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeProposalMutation, { data, loading, error }] = useCloseProposalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCloseProposalMutation(baseOptions?: Apollo.MutationHookOptions<CloseProposalMutation, CloseProposalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloseProposalMutation, CloseProposalMutationVariables>(CloseProposalDocument, options);
      }
export type CloseProposalMutationHookResult = ReturnType<typeof useCloseProposalMutation>;
export type CloseProposalMutationResult = Apollo.MutationResult<CloseProposalMutation>;
export type CloseProposalMutationOptions = Apollo.BaseMutationOptions<CloseProposalMutation, CloseProposalMutationVariables>;
export const CreateProposalRequestDocument = gql`
    mutation CreateProposalRequest($input: CreateProposalInput!) {
  createProposal(input: $input) {
    proposal {
      ...ShortProposal
    }
  }
}
    ${ShortProposalFragmentDoc}`;
export type CreateProposalRequestMutationFn = Apollo.MutationFunction<CreateProposalRequestMutation, CreateProposalRequestMutationVariables>;

/**
 * __useCreateProposalRequestMutation__
 *
 * To run a mutation, you first call `useCreateProposalRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProposalRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProposalRequestMutation, { data, loading, error }] = useCreateProposalRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProposalRequestMutation(baseOptions?: Apollo.MutationHookOptions<CreateProposalRequestMutation, CreateProposalRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProposalRequestMutation, CreateProposalRequestMutationVariables>(CreateProposalRequestDocument, options);
      }
export type CreateProposalRequestMutationHookResult = ReturnType<typeof useCreateProposalRequestMutation>;
export type CreateProposalRequestMutationResult = Apollo.MutationResult<CreateProposalRequestMutation>;
export type CreateProposalRequestMutationOptions = Apollo.BaseMutationOptions<CreateProposalRequestMutation, CreateProposalRequestMutationVariables>;
export const ProposalDocument = gql`
    query Proposal($proposalId: ID!, $identityId: ID!, $hasVoted: Boolean = false) {
  proposal: node(id: $proposalId) {
    ...ProposalWithVotes
  }
}
    ${ProposalWithVotesFragmentDoc}
${ProposalFragmentDoc}
${ProposalVotesFragmentDoc}
${ProposalVoteFragmentDoc}`;

/**
 * __useProposalQuery__
 *
 * To run a query within a React component, call `useProposalQuery` and pass it any options that fit your needs.
 * When your component renders, `useProposalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProposalQuery({
 *   variables: {
 *      proposalId: // value for 'proposalId'
 *      identityId: // value for 'identityId'
 *      hasVoted: // value for 'hasVoted'
 *   },
 * });
 */
export function useProposalQuery(baseOptions: Apollo.QueryHookOptions<ProposalQuery, ProposalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProposalQuery, ProposalQueryVariables>(ProposalDocument, options);
      }
export function useProposalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProposalQuery, ProposalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProposalQuery, ProposalQueryVariables>(ProposalDocument, options);
        }
export type ProposalQueryHookResult = ReturnType<typeof useProposalQuery>;
export type ProposalLazyQueryHookResult = ReturnType<typeof useProposalLazyQuery>;
export type ProposalQueryResult = Apollo.QueryResult<ProposalQuery, ProposalQueryVariables>;
export const ProposalsDocument = gql`
    query Proposals($identityId: ID!) {
  proposals(first: 100) {
    edges {
      node {
        ...Proposal
      }
    }
  }
}
    ${ProposalFragmentDoc}`;

/**
 * __useProposalsQuery__
 *
 * To run a query within a React component, call `useProposalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProposalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProposalsQuery({
 *   variables: {
 *      identityId: // value for 'identityId'
 *   },
 * });
 */
export function useProposalsQuery(baseOptions: Apollo.QueryHookOptions<ProposalsQuery, ProposalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProposalsQuery, ProposalsQueryVariables>(ProposalsDocument, options);
      }
export function useProposalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProposalsQuery, ProposalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProposalsQuery, ProposalsQueryVariables>(ProposalsDocument, options);
        }
export type ProposalsQueryHookResult = ReturnType<typeof useProposalsQuery>;
export type ProposalsLazyQueryHookResult = ReturnType<typeof useProposalsLazyQuery>;
export type ProposalsQueryResult = Apollo.QueryResult<ProposalsQuery, ProposalsQueryVariables>;
export const VoteDocument = gql`
    mutation Vote($input: VoteInput!) {
  vote(input: $input) {
    vote {
      ...Vote
    }
  }
}
    ${VoteFragmentDoc}`;
export type VoteMutationFn = Apollo.MutationFunction<VoteMutation, VoteMutationVariables>;

/**
 * __useVoteMutation__
 *
 * To run a mutation, you first call `useVoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voteMutation, { data, loading, error }] = useVoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVoteMutation(baseOptions?: Apollo.MutationHookOptions<VoteMutation, VoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VoteMutation, VoteMutationVariables>(VoteDocument, options);
      }
export type VoteMutationHookResult = ReturnType<typeof useVoteMutation>;
export type VoteMutationResult = Apollo.MutationResult<VoteMutation>;
export type VoteMutationOptions = Apollo.BaseMutationOptions<VoteMutation, VoteMutationVariables>;
export const AuthenticateDocument = gql`
    mutation Authenticate($input: AuthenticateInput!) {
  authenticate(input: $input) {
    session {
      ...SessionSession
    }
  }
}
    ${SessionSessionFragmentDoc}
${SessionIdentityFragmentDoc}
${MembershipRequestIdentityFragmentDoc}
${FastIdentityFragmentDoc}`;
export type AuthenticateMutationFn = Apollo.MutationFunction<AuthenticateMutation, AuthenticateMutationVariables>;

/**
 * __useAuthenticateMutation__
 *
 * To run a mutation, you first call `useAuthenticateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateMutation, { data, loading, error }] = useAuthenticateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthenticateMutation(baseOptions?: Apollo.MutationHookOptions<AuthenticateMutation, AuthenticateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthenticateMutation, AuthenticateMutationVariables>(AuthenticateDocument, options);
      }
export type AuthenticateMutationHookResult = ReturnType<typeof useAuthenticateMutation>;
export type AuthenticateMutationResult = Apollo.MutationResult<AuthenticateMutation>;
export type AuthenticateMutationOptions = Apollo.BaseMutationOptions<AuthenticateMutation, AuthenticateMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ...SessionIdentity
  }
}
    ${SessionIdentityFragmentDoc}
${MembershipRequestIdentityFragmentDoc}
${FastIdentityFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const SettingsDocument = gql`
    query Settings {
  settings
}
    `;

/**
 * __useSettingsQuery__
 *
 * To run a query within a React component, call `useSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSettingsQuery(baseOptions?: Apollo.QueryHookOptions<SettingsQuery, SettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SettingsQuery, SettingsQueryVariables>(SettingsDocument, options);
      }
export function useSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SettingsQuery, SettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SettingsQuery, SettingsQueryVariables>(SettingsDocument, options);
        }
export type SettingsQueryHookResult = ReturnType<typeof useSettingsQuery>;
export type SettingsLazyQueryHookResult = ReturnType<typeof useSettingsLazyQuery>;
export type SettingsQueryResult = Apollo.QueryResult<SettingsQuery, SettingsQueryVariables>;