import { Link, useLocation } from "react-router-dom";
import { TabDescriptor } from "../../hooks/useNavigatorTabs";
import { useFastSymbol } from "../../providers/FastSymbolProvider";
import { makePath } from "../../routing";

interface Props {
  readonly tabs: ReadonlyArray<TabDescriptor>;
}

export const SecondFastTabNavigator = ({ tabs }: Props) => {
  const { pathname } = useLocation();
  const { symbol } = useFastSymbol();

  return (
    <nav className="SecondFastTabNavigator">
      {tabs.map((tab, index) => {
        const route = makePath(tab.path, { symbol });
        return (
          <Link key={index} className={`FastTabsNavigator-Link${route === pathname ? " active" : ""}`} to={route}>
            {tab.title}
          </Link>
        );
      })}
    </nav>
  );
};
