import { OnboardingOnboardFragment } from "../../../__generated__/graphql";
import { OnboardForm } from "../../../JsonSchemas/OnboardForm.schema.v1";

export const enum Step {
  ClaimOnboard,
  YourDetails,
  ConnectWallet,
  Documentation,
  KYCAML,
  Confirmation,
}

export interface StepProps {
  readonly onboard: OnboardingOnboardFragment;
  readonly refreshOnboard: (onboard?: OnboardingOnboardFragment) => void;
}

export const initialFormData: OnboardForm = {
  id: "OnboardForm",
  firstName: "",
  lastName: "",
};
