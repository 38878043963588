import { writeContract } from "@wagmi/core";
import { Button, Modal, Spin } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { crowdfundABI } from "../../../../__generated__/contracts";
import { useErrorHelpers } from "../../../../hooks/useErrorHelpers";
import { PropsWithModalState } from "../../../../hooks/useModalState";
import { useFastSymbol } from "../../../../providers/FastSymbolProvider";
import { Paths } from "../../../../routing";
import { EthAddress } from "../../../../types/ethers";
import { CompleteDrawdown } from "../types";

interface Props {
  readonly drawdownAddress: EthAddress;
  readonly refValue: string;
  readonly type: CompleteDrawdown;
}

export const CompleteDrawdownModal = ({
  drawdownAddress,
  refValue,
  state: { isVisible, close },
  type,
}: PropsWithModalState<Props>) => {
  const [isLoading, setIsLoading] = useState(false);
  const { trackTransaction } = useErrorHelpers();
  const { symbol } = useFastSymbol();
  const navigate = useNavigate();

  const handleAction = () => {
    const completionMessage = type.isClose ? "is now closed successfully" : "was aborted";
    const tx = writeContract({
      abi: crowdfundABI,
      address: drawdownAddress,
      functionName: "terminate",
      args: [type.isClose],
    });
    void trackTransaction(tx, "Investment Drawdown", `Investment Drawdown ${completionMessage}`, setIsLoading).then(
      () => navigate(Paths.Fasts.One.ManageFunds.drawdowns.replace(":symbol", symbol)),
    );
  };

  return (
    <Modal
      className="InvestmentDrawdowns-Modal"
      closable={false}
      footer={null}
      maskClosable={false}
      open={isVisible}
      onCancel={close}>
      <h2>{type.header}</h2>
      <Spin spinning={isLoading} size="large">
        <p>Reference: {refValue}</p>
        <p>{type.description}</p>
        <div className="ButtonPairWrapper">
          <Button onClick={close}>Cancel</Button>
          <Button onClick={handleAction}>{type.buttonLabel}</Button>
        </div>
      </Spin>
    </Modal>
  );
};
