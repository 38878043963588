import { JSONSchema7 } from "json-schema";

export interface SchemaType extends JSONSchema7 {
  "$id": "FastForm",
  "$schema": "http://json-schema.org/draft-07/schema#",
  "$ref": "#/definitions/FastForm",
  "definitions": {
    "FastForm": {
      "type": "object",
      "properties": {
        "id": {
          "type": "string",
          "const": "FastForm"
        },
        "logoId": {
          "type": "string"
        },
        "investmentThesis": {
          "type": "string",
          "maxLength": 500,
          "pattern": "\\S+"
        },
        "supportProvided": {
          "type": "array",
          "items": {
            "$ref": "#/definitions/Tag"
          },
          "minItems": 1
        },
        "sectors": {
          "type": "array",
          "items": {
            "$ref": "#/definitions/Tag"
          },
          "minItems": 1
        },
        "stage": {
          "type": "array",
          "items": {
            "$ref": "#/definitions/Stage"
          },
          "minItems": 1
        },
        "currency": {
          "$ref": "#/definitions/Currency"
        },
        "minimumTicketSize": {
          "type": "number",
          "minimum": 0
        },
        "targetPortfolioSize": {
          "type": "number",
          "minimum": 0
        },
        "unrealisedTokenValue": {
          "type": "number",
          "minimum": 0
        },
        "visibility": {
          "$ref": "#/definitions/Visibility"
        },
        "links": {
          "type": "array",
          "items": {
            "$ref": "#/definitions/Link"
          },
          "maxItems": 3
        },
        "documents": {
          "type": "array",
          "items": {
            "$ref": "#/definitions/Document"
          },
          "minItems": 1,
          "maxItems": 3
        },
        "teamMembers": {
          "type": "array",
          "items": {
            "$ref": "#/definitions/TeamMember"
          },
          "maxItems": 6
        }
      },
      "required": [
        "id",
        "investmentThesis",
        "supportProvided",
        "sectors",
        "stage",
        "currency",
        "minimumTicketSize",
        "targetPortfolioSize",
        "unrealisedTokenValue",
        "visibility",
        "links",
        "documents",
        "teamMembers"
      ]
    },
    "Tag": {
      "type": "string",
      "minLength": 1,
      "maxLength": 12
    },
    "Stage": {
      "type": "string",
      "enum": [
        "Pre-seed",
        "Seed",
        "Growth",
        "Scale",
        "Large Enterprises"
      ]
    },
    "Currency": {
      "type": "string",
      "enum": [
        "$",
        "£",
        "€"
      ]
    },
    "Visibility": {
      "type": "string",
      "enum": [
        "PRIVATE",
        "PUBLIC",
        "COMMUNITY"
      ]
    },
    "Link": {
      "type": "object",
      "properties": {
        "url": {
          "type": "string",
          "format": "uri"
        },
        "title": {
          "$ref": "#/definitions/Title"
        },
        "visibility": {
          "$ref": "#/definitions/Visibility"
        }
      },
      "required": [
        "url",
        "title",
        "visibility"
      ]
    },
    "Title": {
      "type": "string",
      "minLength": 1,
      "maxLength": 32
    },
    "Document": {
      "type": "object",
      "properties": {
        "documentId": {
          "type": "string"
        },
        "title": {
          "$ref": "#/definitions/Title"
        },
        "visibility": {
          "$ref": "#/definitions/Visibility"
        }
      },
      "required": [
        "documentId",
        "title",
        "visibility"
      ]
    },
    "TeamMember": {
      "type": "object",
      "properties": {
        "imageId": {
          "type": "string"
        },
        "fullName": {
          "$ref": "#/definitions/Title"
        },
        "linkedinProfileUrl": {
          "$ref": "#/definitions/LinkedInURL"
        }
      },
      "required": [
        "imageId",
        "fullName",
        "linkedinProfileUrl"
      ]
    },
    "LinkedInURL": {
      "type": "string",
      "pattern": "^https:\\/\\/([0-9a-z]+.)*linkedin.com\\/[\\/0-9a-z-]+$"
    }
  }
};
export const Schema: SchemaType = {
  "$id": "FastForm",
  "$schema": "http://json-schema.org/draft-07/schema#",
  "$ref": "#/definitions/FastForm",
  "definitions": {
    "FastForm": {
      "type": "object",
      "properties": {
        "id": {
          "type": "string",
          "const": "FastForm"
        },
        "logoId": {
          "type": "string"
        },
        "investmentThesis": {
          "type": "string",
          "maxLength": 500,
          "pattern": "\\S+"
        },
        "supportProvided": {
          "type": "array",
          "items": {
            "$ref": "#/definitions/Tag"
          },
          "minItems": 1
        },
        "sectors": {
          "type": "array",
          "items": {
            "$ref": "#/definitions/Tag"
          },
          "minItems": 1
        },
        "stage": {
          "type": "array",
          "items": {
            "$ref": "#/definitions/Stage"
          },
          "minItems": 1
        },
        "currency": {
          "$ref": "#/definitions/Currency"
        },
        "minimumTicketSize": {
          "type": "number",
          "minimum": 0
        },
        "targetPortfolioSize": {
          "type": "number",
          "minimum": 0
        },
        "unrealisedTokenValue": {
          "type": "number",
          "minimum": 0
        },
        "visibility": {
          "$ref": "#/definitions/Visibility"
        },
        "links": {
          "type": "array",
          "items": {
            "$ref": "#/definitions/Link"
          },
          "maxItems": 3
        },
        "documents": {
          "type": "array",
          "items": {
            "$ref": "#/definitions/Document"
          },
          "minItems": 1,
          "maxItems": 3
        },
        "teamMembers": {
          "type": "array",
          "items": {
            "$ref": "#/definitions/TeamMember"
          },
          "maxItems": 6
        }
      },
      "required": [
        "id",
        "investmentThesis",
        "supportProvided",
        "sectors",
        "stage",
        "currency",
        "minimumTicketSize",
        "targetPortfolioSize",
        "unrealisedTokenValue",
        "visibility",
        "links",
        "documents",
        "teamMembers"
      ]
    },
    "Tag": {
      "type": "string",
      "minLength": 1,
      "maxLength": 12
    },
    "Stage": {
      "type": "string",
      "enum": [
        "Pre-seed",
        "Seed",
        "Growth",
        "Scale",
        "Large Enterprises"
      ]
    },
    "Currency": {
      "type": "string",
      "enum": [
        "$",
        "£",
        "€"
      ]
    },
    "Visibility": {
      "type": "string",
      "enum": [
        "PRIVATE",
        "PUBLIC",
        "COMMUNITY"
      ]
    },
    "Link": {
      "type": "object",
      "properties": {
        "url": {
          "type": "string",
          "format": "uri"
        },
        "title": {
          "$ref": "#/definitions/Title"
        },
        "visibility": {
          "$ref": "#/definitions/Visibility"
        }
      },
      "required": [
        "url",
        "title",
        "visibility"
      ]
    },
    "Title": {
      "type": "string",
      "minLength": 1,
      "maxLength": 32
    },
    "Document": {
      "type": "object",
      "properties": {
        "documentId": {
          "type": "string"
        },
        "title": {
          "$ref": "#/definitions/Title"
        },
        "visibility": {
          "$ref": "#/definitions/Visibility"
        }
      },
      "required": [
        "documentId",
        "title",
        "visibility"
      ]
    },
    "TeamMember": {
      "type": "object",
      "properties": {
        "imageId": {
          "type": "string"
        },
        "fullName": {
          "$ref": "#/definitions/Title"
        },
        "linkedinProfileUrl": {
          "$ref": "#/definitions/LinkedInURL"
        }
      },
      "required": [
        "imageId",
        "fullName",
        "linkedinProfileUrl"
      ]
    },
    "LinkedInURL": {
      "type": "string",
      "pattern": "^https:\\/\\/([0-9a-z]+.)*linkedin.com\\/[\\/0-9a-z-]+$"
    }
  }
};
