import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { StatusLabel } from "../../../components/uiElements/StatusLabel";
import { stringToShortIsoDate } from "../../../helpers/utils";
import { useFastSymbol } from "../../../providers/FastSymbolProvider";
import { Proposal } from "../../../providers/ProposalProvider/types";
import { Paths, makePath } from "../../../routing";

interface Props {
  readonly data: Omit<Proposal, "choices" | "description">;
}

export const Card = ({ data }: Props) => {
  const { symbol } = useFastSymbol();
  const navigate = useNavigate();

  const handleOpenProposal = () => {
    navigate(makePath(Paths.Fasts.One.Proposals.one, { symbol, proposal: data.id }));
  };

  return (
    <div
      className={`Proposals-Card ${data.state === "CLOSED" ? "Closed" : ""}`}
      tabIndex={0}
      onClick={handleOpenProposal}>
      <div className="Card-Top">
        <StatusLabel isClosed={data.state === "CLOSED"} status={data.state} />
        {(data.hasVoted || data.state === "CLOSED") && data.tally && (
          <div className="Card-Tally">
            {data.tally.map((tally) => (
              <div key={tally.choice} className={`Card-Tally-Item Card-Tally-${tally.choice}`}>
                {tally.count}
              </div>
            ))}
          </div>
        )}
      </div>
      <h3>{data.title}</h3>
      <p>{`Published on ${stringToShortIsoDate(data.openedAt)} by ${data.proposer.fullName || ""}`}</p>
      {data.hasVoted ? <h4>Voted</h4> : data.state !== "CLOSED" && <Button>Vote</Button>}
    </div>
  );
};
