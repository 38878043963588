import { Button, Form, Input } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCreateProposalRequestMutation } from "../../../__generated__/graphql";
import { Breadcrumbs } from "../../../components/Breadcrumbs";
import { RequiredFieldRule } from "../../../helpers/utils";
import { useErrorHelpers } from "../../../hooks/useErrorHelpers";
import { useFastSymbol } from "../../../providers/FastSymbolProvider";
import { Paths, makePath } from "../../../routing";
import { SimpleToast } from "../../../types/toasts";
import { useFastDetails } from "../Top";

interface FormData {
  readonly description: string;
  readonly title: string;
}

export const New = () => {
  const {
    fastDetails: { id },
    updateFastDetails,
  } = useFastDetails();
  const { symbol } = useFastSymbol();
  const [createProposal] = useCreateProposalRequestMutation();
  const { handleError } = useErrorHelpers();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const onPublish = (formData: FormData) => {
    setIsLoading(true);

    createProposal({
      variables: {
        input: {
          //TODO: Get rid of static choices and implement opting of choices on the UI.
          choices: ["YES", "NO", "ABSTAIN"],
          votableId: id,
          description: formData.description,
          title: formData.title,
        },
      },
    })
      .then((res) => {
        if (!res.data?.createProposal) throw new Error("Invalid response from server.");
        const proposalId = res.data.createProposal.proposal.id;
        SimpleToast.success("The Proposal was successfully created.");
        return updateFastDetails().then(() =>
          navigate(makePath(Paths.Fasts.One.Proposals.one, { symbol, proposal: proposalId })),
        );
      })
      .catch((err) => handleError("An error occured while creating the proposal", err))
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <div className="Content-Header">
        <Breadcrumbs />
        <h1>Create Investment proposal</h1>
      </div>

      <section className="Content-Body Proposal-New">
        <Form autoComplete="off" disabled={isLoading} layout="vertical" requiredMark onFinish={onPublish}>
          <Form.Item label="Title" name="title" rules={[...RequiredFieldRule]}>
            <Input placeholder="Type a reference title" />
          </Form.Item>
          <Form.Item label="Description" name="description" rules={[...RequiredFieldRule]}>
            <Input.TextArea maxLength={300} rows={5} placeholder="Type your message" showCount />
          </Form.Item>
          <Button htmlType="submit">Publish</Button>
        </Form>
      </section>
    </>
  );
};
