interface ButtonProps {
  readonly className?: string;
  readonly onClick: () => void;
  readonly name: string;
  readonly icon?: JSX.Element;
}

type LinkProps = Omit<ButtonProps, "onClick"> & { readonly href: string };

export const HeaderButton = ({ onClick, name, icon }: ButtonProps) => (
  <button className="Button-Header" onClick={onClick}>
    <span>{name}</span>
    {icon}
  </button>
);

export const HeaderLink = ({ className, href, name, icon }: LinkProps) => (
  <a className={className} href={href} target="_blank">
    <span>{name}</span>
    {icon}
  </a>
);
