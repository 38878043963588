import { ApolloQueryResult } from "@apollo/client";
import { Button, Modal, Spin } from "antd";
import { useCallback, useState } from "react";
import { ChangeStatusModalType } from "./types";
import {
  MembershipRequestsQuery,
  useAcceptMemebershipRequestMutation,
  useRejectMembershipRequestMutation,
} from "../../__generated__/graphql";
import { useErrorHelpers } from "../../hooks/useErrorHelpers";
import { PropsWithModalState } from "../../hooks/useModalState";
import { SimpleToast } from "../../types/toasts";

interface Props {
  readonly id: string;
  readonly type: ChangeStatusModalType;
  readonly updateMembershipRequests: () => Promise<ApolloQueryResult<MembershipRequestsQuery>>;
}

export const ChangeApplicationStatusModal = ({
  id,
  state: { isVisible, close },
  type,
  updateMembershipRequests,
}: PropsWithModalState<Props>) => {
  const [isLoading, setIsLoading] = useState(false);
  const { handleError } = useErrorHelpers();
  const [acceptRequest] = useAcceptMemebershipRequestMutation();
  const [rejectRequest] = useRejectMembershipRequestMutation();

  const onAccept = useCallback(() => {
    acceptRequest({ variables: { input: { membershipRequestId: id } } })
      .then((res) => {
        if (!res.data?.acceptMembershipRequest) throw new Error("Invalid response from server.");
        SimpleToast.success("The Application was successfully accepted.");
        return updateMembershipRequests();
      })
      .then(close)
      .catch((err) => handleError("An error occured while accepting the application", err))
      .finally(() => setIsLoading(false));
  }, [acceptRequest, close, handleError, id, updateMembershipRequests]);

  const onReject = useCallback(() => {
    rejectRequest({ variables: { input: { membershipRequestId: id } } })
      .then((res) => {
        if (!res.data?.rejectMembershipRequest) throw new Error("Invalid response from server.");
        SimpleToast.success("The Application was successfully rejected.");
        return updateMembershipRequests();
      })
      .then(close)
      .catch((err) => handleError("An error occured while rejecting the application", err))
      .finally(() => setIsLoading(false));
  }, [close, handleError, id, rejectRequest, updateMembershipRequests]);

  const handleAction = useCallback(() => {
    setIsLoading(true);
    type === "Accept" ? onAccept() : onReject();
  }, [onAccept, onReject, type]);

  return (
    <Modal
      className="ModalWrapper"
      closable={false}
      footer={null}
      maskClosable={false}
      open={isVisible}
      onCancel={close}>
      <h2>
        You are about to
        <br />
        <span className={type === "Accept" ? "GreenText" : "RedText"}>{type}</span> this application
      </h2>
      <Spin spinning={isLoading} size="large">
        <p className="GreyText">You cannot undo this action</p>
        <div className="ButtonPairWrapper">
          <Button onClick={close}>Cancel</Button>
          <Button onClick={handleAction}>Yes, {type}</Button>
        </div>
      </Spin>
    </Modal>
  );
};
