import { Image } from "antd";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { ActionColumn } from "./ActionColumn";
import { FeeColumn } from "./FeeColumn";
import { Spinner } from "../../../../assets";
import { ResolvableAddress } from "../../../../components/ResolvableAddress";
import { useChain } from "../../../../providers/ChainClient";
import { Info, Phase, formatPhase } from "../../../../providers/DistributionProvider/types";
import { useTopContracts } from "../../../../providers/TopContractsProvider";

interface Props {
  readonly info: Info;
  readonly setIsLoading: (isLoading: boolean) => void;
}

export const Overview = ({ info, setIsLoading }: Props) => {
  const { chain } = useChain();
  const { isIssuerMember } = useTopContracts();
  const { address, available, creationBlock, fee, params, phase, erc20Info } = info;

  const explorer = chain.blockExplorers.default.url;

  const formattedPhase = useMemo(() => {
    if (phase === Phase.BeneficiariesSetup && available > 0n)
      return (
        <div className="ProgressText">
          <p>Accounting</p>
          <Image alt="" src={Spinner} preview={false} />
        </div>
      );
    return formatPhase(phase);
  }, [available, phase]);

  return (
    <div className="Distributions-Overview">
      <div className="FirstColumn">
        <div className="InfoColumn">
          <div
            className={`Distributions-Phase ${
              phase === Phase.BeneficiariesSetup ? "" : `Distributions-Phase-${formatPhase(phase)}`
            }`}>
            {formattedPhase}
          </div>
          <p>
            Creation Block:&nbsp;
            <Link to={`${explorer}/block/${creationBlock.toString()}`} target="_blank">
              {creationBlock.toString()}
            </Link>
          </p>
          <p>
            Accountancy Block:&nbsp;
            <Link to={`${explorer}/block/${params.blockLatch.toString()}`} target="_blank">
              {params.blockLatch.toString()}
            </Link>
          </p>
          <p>
            <Link to={`${explorer}/address/${address}`} target="_blank">
              View on block explorer
            </Link>
          </p>
          <ResolvableAddress
            address={erc20Info.address}
            fullName={`${erc20Info.name} (${erc20Info.symbol})`}
            explorer={explorer}
            withCopyButton={false}
          />
          <p>
            Total: {erc20Info.formatter.format(params.total, 2)} {erc20Info.symbol}
          </p>
          <p>
            Fee:&nbsp;
            {phase > 1 ? (
              `${erc20Info.formatter.format(fee)} ${erc20Info.symbol}`
            ) : (
              <span>Needs to be defined by issuer</span>
            )}
          </p>
        </div>

        <ActionColumn
          address={address}
          available={available}
          distributor={params.distributor}
          explorer={explorer}
          phase={phase}
          setIsLoading={setIsLoading}
        />
      </div>

      {isIssuerMember && info.phase < Phase.BeneficiariesSetup && (
        <FeeColumn
          address={address}
          available={available}
          erc20Info={erc20Info}
          total={params.total}
          setIsLoading={setIsLoading}
        />
      )}
    </div>
  );
};
