import { Button } from "antd";
import { useState } from "react";
import { Loader } from "../../../components/uiElements/Loader";
import { useDocumentDetails } from "../../../hooks/useDocumentDetails";
import { useUrlDownloader } from "../../../hooks/useUrlDownloader";
import * as Common from "../../../JsonSchemas/common.schema.v1";

interface Props {
  readonly document: Common.Document;
}

export const DocumentDownloadButton = ({ document }: Props) => {
  const { documentId, title } = document;
  const [isLoading, setIsLoading] = useState(false);
  const details = useDocumentDetails(documentId, setIsLoading);
  const download = useUrlDownloader(details && details.downloadUrl, setIsLoading);

  if (isLoading) return <Loader />;
  return download ? (
    <Button onClick={download} loading={isLoading} disabled={isLoading}>
      {title}
      <i className="icon-arrow-down-tail" />
    </Button>
  ) : null;
};
