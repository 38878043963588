export const Paths = {
  invite: "/invite",
  onboarding: "/onboarding",
  root: "/",
  fasts: "/fasts",
  Fasts: {
    new: "/fasts/new",
    membershipRequests: "/fasts/membership-requests",
    newMember: "/fasts/new-member",
    one: "/fasts/:symbol",
    One: {
      about: "/fasts/:symbol/about",
      About: {
        overview: "/fasts/:symbol/about/overview",
        edit: "/fasts/:symbol/about/edit",
      },
      transfers: "/fasts/:symbol/transfers",
      Transfers: {
        networkTransfers: "/fasts/:symbol/transfers/network-transfers",
        tokensManagement: "/fasts/:symbol/transfers/tokens-management",
        myTransfers: "/fasts/:symbol/transfers/my-transfers",
      },
      community: "/fasts/:symbol/community",
      Community: {
        members: "/fasts/:symbol/community/members",
        coordinators: "/fasts/:symbol/community/coordinators",
        applications: "/fasts/:symbol/community/applications",
      },
      manageFunds: "/fasts/:symbol/manage-funds",
      ManageFunds: {
        distributions: "/fasts/:symbol/manage-funds/distributions",
        Distributions: {
          new: "/fasts/:symbol/manage-funds/distributions/new",
          one: "/fasts/:symbol/manage-funds/distributions/:distribution",
        },
        drawdowns: "/fasts/:symbol/manage-funds/drawdowns",
        Drawdowns: {
          new: "/fasts/:symbol/manage-funds/drawdowns/new",
          one: "/fasts/:symbol/manage-funds/drawdowns/:drawdown",
        },
      },
      proposals: "/fasts/:symbol/proposals",
      Proposals: {
        new: "/fasts/:symbol/proposals/new",
        one: "/fasts/:symbol/proposals/:proposal",
      },
      onboarding: "/fasts/:symbol/onboarding",
    },
  },
};

// This function is used to replace the path parameters with the actual values.
export const makePath = (path: string, params: Record<string, string>) => {
  return Object.keys(params).reduce((acc, key) => acc.replace(`:${key}`, params[key]), path);
};
