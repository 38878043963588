import { Button } from "antd";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "./Card";
import { useFastSymbol } from "../../../providers/FastSymbolProvider";
import { Proposal } from "../../../providers/ProposalProvider/types";
import { Paths } from "../../../routing";
import { useFastDetails } from "../Top";

export const List = () => {
  const { symbol } = useFastSymbol();
  const navigate = useNavigate();
  const { fastDetails } = useFastDetails();

  const proposals = useMemo(() => {
    if (!fastDetails.proposals?.edges || fastDetails.proposals.edges.length === 0) return [];

    return fastDetails.proposals.edges.reduce<ReadonlyArray<Omit<Proposal, "choices" | "description">>>(
      (acc, proposal) => {
        const node = proposal?.node;

        if (!node) return acc;

        return [
          ...acc,
          {
            hasVoted: node.hasVoted,
            id: node.id,
            openedAt: node.openedAt,
            proposer: node.proposer,
            state: node.state,
            tally: node.tally,
            title: node.title,
          },
        ];
      },
      [],
    );
  }, [fastDetails]);

  return (
    <section className="FastTab Proposals-List">
      <div className="TitleBlockWithButton">
        <h2>View, vote and create Proposals</h2>
        <Button onClick={() => navigate(Paths.Fasts.One.Proposals.new.replace(":symbol", symbol))}>Create</Button>
      </div>
      {proposals.length === 0 ? (
        <div className="Proposals-Empty">
          <p>There are no proposals at the moment.</p>
          <p>You can create the first one by clicking on the "Create" button</p>
        </div>
      ) : (
        <div className="Proposals-Cards">
          {proposals.map((card) => (
            <Card key={card.id} data={card} />
          ))}
        </div>
      )}
    </section>
  );
};
