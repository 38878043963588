import { useEffect, useState } from "react";
import { useChain } from "../providers/ChainClient";
import { FastDetails, MembershipDetails } from "../providers/FastContractContext/types";
import { EthAddress } from "../types/ethers";

export const enum Status {
  Loading,
  Error,
  Done,
}

interface Loading {
  readonly status: Status.Loading;
}
interface Error {
  readonly status: Status.Error;
}
interface Done {
  readonly status: Status.Done;
  readonly details: MembershipDetails;
}
type State = Loading | Error | Done;

export const useFastMembership = (fastDetails: FastDetails | undefined, signerAddress: EthAddress | undefined) => {
  const { walletClient } = useChain();
  const contract = fastDetails?.contract;
  const [state, setState] = useState<State>({ status: Status.Loading });

  useEffect(() => {
    if (!contract || !signerAddress) return;
    setState({ status: Status.Loading });

    contract.read
      .detailedPrivileges([signerAddress])
      .then((details) => setState({ status: Status.Done, details }))
      .catch((e) => {
        console.error(e);
        setState({ status: Status.Error });
      });
  }, [contract, signerAddress, walletClient]);

  // TODO: Re-enable once the node doesn't blow up?...
  // useFastTransferEvent({
  //   address,
  //   listener: (log) => {
  //     if (!address || !contract || !formatter || !signerAddress) return;
  //     const eventData = log.at(0);
  //     if (!eventData) return;
  //     // onTransferChange(eventData.blockNumber);
  //     // The transfer involves me...
  //     if (eventData.args.from == signerAddress || eventData.args.to == signerAddress) {
  //       // Re-query balance.
  //       void contract.read.balanceOf([signerAddress]).then((balance) => {
  //         // Because the balance query defers execution, our state might have changed!
  //         setMembership((s) => s && { ...s, balance });
  //       });
  //     }
  //   },
  // });

  return state;
};
