import { writeContract } from "@wagmi/core";
import { Button, Col, Form, Input, Row, Space } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { zeroAddress } from "viem";
import { fastABI } from "../../../__generated__/contracts";
import { FastVisibility } from "../../../__generated__/graphql";
import { AddressResolverInput } from "../../../components/AddressResolverInput/AddressResolverInput";
import { DecimalStringRule, RequiredFieldRule } from "../../../helpers/utils";
import { paginateDetailedMembers } from "../../../helpers/wagmiActions";
import { BigNumberFormatter } from "../../../hooks/useBigNumberFormatter";
import { useErrorHelpers } from "../../../hooks/useErrorHelpers";
import { EthAddress } from "../../../types/ethers";
import { useFastDetails } from "../Top";

interface Props {
  readonly address: EthAddress;
  readonly symbol: string;
  readonly reserveBalance: bigint;
  readonly formatter: BigNumberFormatter;
  readonly memberCount: bigint;
}

interface ReserveTransferForm {
  readonly recipient: EthAddress;
  readonly amount: `${number}`;
  readonly reference: string;
}

export const ReserveTransfer = ({ address, symbol, reserveBalance, formatter, memberCount }: Props) => {
  const {
    fastDetails: { visibility },
  } = useFastDetails();
  const { trackTransaction } = useErrorHelpers();
  const [form] = useForm<ReserveTransferForm>();
  const [beneficiariesAddresses, setBeneficiariesAddresses] = useState<ReadonlyArray<EthAddress>>();
  const [isLoading, setIsLoading] = useState(false);

  // If this FAST is private, we should get valid user addresses for this FAST for a list of recipients.
  useEffect(() => {
    if (visibility !== FastVisibility.Private) return;

    void paginateDetailedMembers(address, memberCount, setBeneficiariesAddresses);
  }, [address, memberCount, visibility]);

  const onFinish = ({ recipient, amount, reference }: ReserveTransferForm) => {
    const tx = writeContract({
      abi: fastABI,
      address,
      functionName: "transferFromWithRef",
      args: [zeroAddress, recipient, formatter.parse(amount), reference],
    }).finally(() => form.resetFields());
    void trackTransaction(tx, "Allocation from Reserve", "Allocation successful.", setIsLoading);
  };

  return (
    <section>
      <h2>Transfer from Reserve</h2>
      <p className="Bold">
        Balance: {formatter.format(reserveBalance)} {symbol}
      </p>
      <Form
        form={form}
        layout="vertical"
        requiredMark
        onFinish={onFinish}
        disabled={isLoading}
        className="MyWallet-Transfer">
        <Row>
          <Col xl={16}>
            <Form.Item
              className="AddressResolverWithLabel"
              name="recipient"
              label="Recipient"
              rules={[...RequiredFieldRule]}>
              <AddressResolverInput ethAddresses={beneficiariesAddresses} id="recipient" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={16}>
            <Form.Item name="reference" label="Reference" rules={[...RequiredFieldRule]}>
              <Input type="string" placeholder="Reserve transfer reference..." />
            </Form.Item>
          </Col>
          <Col xl={7} offset={1}>
            <Form.Item
              name="amount"
              label="Amount"
              required={true}
              rules={[...RequiredFieldRule, ...DecimalStringRule]}>
              <Input type="number" placeholder="Amount.." />
            </Form.Item>
          </Col>
        </Row>
        <Space>
          <Button htmlType="submit">Send Transfer</Button>
        </Space>
      </Form>
    </section>
  );
};
