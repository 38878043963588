import { PropsWithChildren } from "react";
import { useSettingsQuery } from "../../__generated__/graphql";
import { Loader } from "../../components/uiElements/Loader";
import { JsonValue } from "../../graphql/types/JsonScalar";
import { Pages } from "../../pages/index";
import { createGenericContext } from "../utils";

interface ServerSettings {
  readonly chain: {
    readonly chain_id: string;
  };
  readonly common: {
    readonly api_url: string;
    readonly frontend_url: string;
  };
  readonly core: {
    readonly confirm_eth_address_template: string;
    readonly session_ttl: number;
    readonly sign_in_signature_template: string;
    readonly signature_max_drift: number;
    readonly signature_realm: string;
  };
}

interface State {
  readonly chain: {
    readonly chainId: string;
  };
  readonly common: {
    readonly apiUrl: string;
    readonly frontendUrl: string;
  };
  readonly core: {
    readonly confirm_eth_address_template: string;
    readonly sessionTtl: number;
    readonly signInSignatureTemplate: string;
    readonly signatureMaxDrift: number;
    readonly signatureRealm: string;
  };
}

const [useSettings, Provider] = createGenericContext<State>("SettingsContext");
export { useSettings };

// This provider is in charge of preloading environment settings from the backend.
export const SettingsProvider = ({ children }: PropsWithChildren) => {
  const settingsQuery = useSettingsQuery();

  if (settingsQuery.loading) return <Loader label="Loading Settings..." withLogo />;
  else if (settingsQuery.error || !settingsQuery.data)
    return <Pages.Errors.Error reason="Failed to retrieve application settings." />;

  return <Provider value={serverSettingsToSettings(settingsQuery.data.settings)}>{children}</Provider>;
};

const serverSettingsToSettings = (serverSettings: JsonValue): State => {
  const s = serverSettings as unknown as ServerSettings;
  return {
    chain: {
      chainId: s.chain.chain_id,
    },
    common: {
      apiUrl: s.common.api_url,
      frontendUrl: s.common.frontend_url,
    },
    core: {
      confirm_eth_address_template: s.core.confirm_eth_address_template,
      sessionTtl: s.core.session_ttl,
      signatureMaxDrift: s.core.signature_max_drift,
      signatureRealm: s.core.signature_realm,
      signInSignatureTemplate: s.core.sign_in_signature_template,
    },
  };
};
