import { FieldProps } from "@rjsf/utils";
import { Image, Input, Tooltip } from "antd";
import { JSONSchema7 } from "json-schema";
import { useCallback, useMemo, useState } from "react";
import { useDocumentDetails } from "../hooks/useDocumentDetails";
import { useErrorHelpers } from "../hooks/useErrorHelpers";
import useFileUpload from "../pages/fast/Edit/useFileUpload";

// Useful to get an uploader component preset to a given content type.
export const withAccepts =
  <T, S extends JSONSchema7>(accepts: string) =>
  (props: FieldProps<T, S>) =>
    <DocumentUploader<T, S> {...props} accepts={accepts} />;

// Our upload component is receiving stuff from RJSF, and has an additional
// content-type `accept` restriction.
interface Props<T, S extends JSONSchema7> extends FieldProps<T, S> {
  readonly accepts: string;
}

// A custom widget allowing for viewing an image and uploading a new one.
export const DocumentUploader = <T, S extends JSONSchema7>(props: Props<T, S>) => {
  // onChange: a callback passed by RJSF to let us update the form data.
  // formData: the current value of the field - expect a GID or undefined.
  const { onChange, formData, accepts, disabled } = props;
  const { handleError } = useErrorHelpers();
  const uploader = useFileUpload();
  // The `document` is the document that is currently set in the form.
  const document = useDocumentDetails(formData as string);
  // Whether the uploader is currently uploading a file.
  const [isLoading, setIsLoading] = useState(false);

  // This function first creates a document on the server side,
  // then uploads the file to the signed URL,
  // then updates the form value.
  const upload = useCallback(
    ({ target: { files } }: React.ChangeEvent<HTMLInputElement>) => {
      if (!files || files?.length == 0) return;
      const file = files[0];
      if (!file) return;
      // Start the upload.
      uploader(file, setIsLoading)
        // If the upload was successful, we can keep track of the new document ID.
        .then((res) => void onChange(res.id as T))
        .catch((err) => handleError("Document Upload", err));
    },
    [onChange, uploader, handleError],
  );

  // Render a preview based on the file type.
  const preview = useMemo(() => {
    if (!document?.downloadUrl) return <>No Document</>;
    return document.contentType.startsWith("image/") ? (
      <Image src={document.downloadUrl} className="FastAbout-Logo" />
    ) : (
      <a href={document.downloadUrl}>Download</a>
    );
  }, [document?.downloadUrl, document?.contentType]);

  return (
    <Tooltip title={formData as string}>
      {preview}
      <Input
        id={props.idSchema.$id}
        type="file"
        accept={accepts}
        onChange={upload}
        disabled={disabled || isLoading}
        style={{ display: "none" }}
      />
      <label htmlFor={props.idSchema.$id}>Change</label>
    </Tooltip>
  );
};
