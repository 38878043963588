import * as FastDetailsSchema from "../../JsonSchemas/FastForm.schema.v1";
import * as OnboardDetailsSchema from "../../JsonSchemas/OnboardForm.schema.v1";

// JSON can be expressed as such.
type JSON = string | number | boolean | { [key: string]: JSON } | ReadonlyArray<JSON>;

// On our schema, JsonValue can also be forms, undefined or null.
export type JsonValue = FastDetailsSchema.FastForm | OnboardDetailsSchema.OnboardForm | JSON | undefined | null;

interface DiscriminatedRecord {
  readonly id: string;
  readonly [key: string]: JSON;
}

// A helper function that determines if an unknown value `v` (likely JsonValue)
// is in fact a dictionary with at least an `id` key whose value is a string.
const isDiscriminatedData = (v: JsonValue): v is DiscriminatedRecord =>
  v !== null && v !== undefined && typeof v === "object" && "id" in v && typeof v.id === "string";

// Returns true and discriminates `v` to be a FastDetails data struct.
export const isFastDetails = (v: JsonValue): v is FastDetailsSchema.FastForm =>
  isDiscriminatedData(v) && v.id === "FastForm";

export const isOnboardingDetails = (v: JsonValue): v is OnboardDetailsSchema.OnboardForm =>
  isDiscriminatedData(v) && v.id === "OnboardForm";
