import { WriteContractResult, waitForTransaction, writeContract } from "@wagmi/core";
import { Collapse, Steps } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { erc20ABI } from "wagmi";
import { DoneModal } from "./DoneModal";
import { fastABI } from "../../../../__generated__/contracts";
import { assertUnreachable } from "../../../../helpers/utils";
import { useErrorHelpers } from "../../../../hooks/useErrorHelpers";
import { useModalState } from "../../../../hooks/useModalState";
import { ERC20Info } from "../../../../providers/ERC20Fetcher";
import { useFastContext } from "../../../../providers/FastContractContext/FastContractContext";

enum ProgressSteps {
  Creating,
  TransactionCreated,
  TransactionMined,
  DistributionCreated,
  DistributionMined,
}

const titleForStep = (step: ProgressSteps) => {
  switch (step) {
    case ProgressSteps.Creating:
      return "Creating";
    case ProgressSteps.TransactionCreated:
      return "Transaction Created";
    case ProgressSteps.TransactionMined:
      return "Transaction Mined";
    case ProgressSteps.DistributionCreated:
      return "Distribution Created";
    case ProgressSteps.DistributionMined:
      return "Distribution Mined";
    default:
      assertUnreachable(step);
  }
};

const Items = [
  { title: titleForStep(ProgressSteps.Creating) },
  { title: titleForStep(ProgressSteps.TransactionCreated) },
  { title: titleForStep(ProgressSteps.TransactionMined) },
  { title: titleForStep(ProgressSteps.DistributionCreated) },
  { title: titleForStep(ProgressSteps.DistributionMined) },
];

interface Props {
  readonly blockNumber?: bigint;
  readonly erc20Info?: ERC20Info;
  readonly log: string;
  readonly refValue: string;
  readonly totalValue: `${number}`;
  readonly setIsLoading: (isLoading: boolean) => void;
  readonly setIsSubmitted: (isSubmitted: boolean) => void;
  readonly setLog: (cb: (prev: string) => string) => void;
}

export const ProgressBlock = ({
  blockNumber,
  erc20Info,
  log,
  refValue,
  totalValue,
  setIsLoading,
  setIsSubmitted,
  setLog,
}: Props) => {
  const {
    details: { address },
  } = useFastContext();
  const doneModalState = useModalState(false);
  const { handleError } = useErrorHelpers();
  const [approvalTx, setApprovalTx] = useState<WriteContractResult>();
  const [approvalRx, setApprovalRx] = useState<unknown>();
  const [distributionTx, setDistributionTx] = useState<WriteContractResult>();
  const [distributionRx, setDistributionRx] = useState<unknown>();
  const [progressStep, setProgressStep] = useState<ProgressSteps>(ProgressSteps.Creating);
  const [detailsCollapsed, setDetailsCollapsed] = useState(true);
  // This needs to prevent re-render in 18 version of React in StrictMode (Reusable State) https://github.com/reactwg/react-18/discussions/18.
  const refLaunched = useRef(false);

  const appendLog = useCallback((message: string) => setLog((prev) => prev + message + "\n"), [setLog]);

  const toggleCollapsing = useCallback(() => setDetailsCollapsed((prev) => !prev), []);

  // Launch the creating of the distribution.
  useEffect(() => {
    if (!blockNumber || !erc20Info) return;
    const total = erc20Info.formatter.parse(totalValue);

    const logError = (err: unknown) => {
      setIsSubmitted(false);
      setIsLoading(false);
      handleError("New Distribution Flow", err);
    };

    if (!refLaunched.current) {
      refLaunched.current = true;

      // No approval transaction was created. Let's create one.
      if (!approvalTx) {
        appendLog("Creating approval transaction...");
        writeContract({
          abi: erc20ABI,
          address: erc20Info.address,
          functionName: "approve",
          args: [address, total],
        })
          .then((tx) => {
            setProgressStep(ProgressSteps.TransactionCreated);
            appendLog(`Approval transaction created: ${tx.hash}.`);
            setApprovalTx(tx);
          })
          .catch(logError);
      }
    }
    // An approval transaction was created, but it hasn't been mined yet.
    else if (approvalTx && !approvalRx) {
      appendLog("Waiting for approval transaction to be mined...");
      waitForTransaction(approvalTx)
        .then((ar) => {
          setProgressStep(ProgressSteps.TransactionMined);
          appendLog(`Approval transaction mined: ${ar.transactionHash}`);
          setApprovalRx(ar);
        })
        .catch(logError);
    }
    // An approval transaction was created and mined, but the distribution transaction hasn't been created yet.
    else if (approvalRx && !distributionTx) {
      appendLog("Creating distribution transaction...");
      writeContract({
        abi: fastABI,
        address,
        functionName: "createDistribution",
        args: [erc20Info.address, total, blockNumber, refValue],
      })
        .then((tx) => {
          setProgressStep(ProgressSteps.DistributionCreated);
          appendLog(`Distribution transaction created: ${tx.hash}.`);
          setDistributionTx(tx);
        })
        .catch(logError);
    }
    // The distribution transaction was created, but it hasn't been mined yet.
    else if (distributionTx && !distributionRx) {
      appendLog("Waiting for distribution transaction to be mined...");
      waitForTransaction(distributionTx)
        .then((dr) => {
          setProgressStep(ProgressSteps.DistributionMined);
          appendLog(`Distribution transaction mined: ${dr.transactionHash}`);
          setDistributionRx(dr);
          doneModalState.open();
        })
        .catch(logError);
    }
  }, [
    address,
    appendLog,
    approvalTx,
    approvalRx,
    blockNumber,
    distributionTx,
    distributionRx,
    doneModalState,
    erc20Info,
    handleError,
    refValue,
    totalValue,
    setIsLoading,
    setIsSubmitted,
  ]);

  return (
    <div className="Progress">
      <Steps current={progressStep} direction="vertical" items={Items} progressDot size="small" />

      <Collapse ghost onChange={toggleCollapsing}>
        <Collapse.Panel header={detailsCollapsed ? "Show details" : "Hide details"} key="1">
          <pre>{log}</pre>
        </Collapse.Panel>
      </Collapse>

      <DoneModal state={doneModalState} />
    </div>
  );
};
