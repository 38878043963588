import shape1 from "../../assets/curve-background-shape1.svg";
import shape2 from "../../assets/curve-background-shape2.svg";
import { useScreenSize } from "../../hooks/useScreenSize";

export const CurvedBackground = () => {
  const { isMobile } = useScreenSize();

  if (isMobile) {
    return null;
  }

  return (
    <div className="CurvedBackground">
      <img className="Shape2" src={shape2} alt={"background-curve"} />;
      <img className="Shape1" src={shape1} alt={"background-curve"} />;
    </div>
  );
};
