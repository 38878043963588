import { useCreateDocumentMutation } from "../../../__generated__/graphql";

const useFileUpload = () => {
  const [createDocumentMutation] = useCreateDocumentMutation();

  return (file: File, setIsLoading: (isLoading: boolean) => void) => {
    setIsLoading && setIsLoading(true);
    return createDocumentMutation({ variables: { input: { contentType: file.type } } })
      .then(({ data }) => {
        if (!data?.createDocument) throw Error("Failed to create document entity.");
        const createdDocument = data.createDocument.document;
        const signedUrl = data.createDocument.signedUrl;

        return Promise.all([
          createdDocument,
          fetch(signedUrl, {
            method: "PUT",
            headers: { "Content-Type": file.type },
            body: file,
          }),
        ]);
      })
      .then(([createdDocument, { ok }]) => {
        if (!ok) throw Error("Failed to upload.");
        return createdDocument;
      })
      .finally(() => setIsLoading && setIsLoading(false));
  };
};

export default useFileUpload;
