import { JSONSchema7 } from "json-schema";

export interface SchemaType extends JSONSchema7 {
  "$id": "OnboardForm",
  "$schema": "http://json-schema.org/draft-07/schema#",
  "$ref": "#/definitions/OnboardForm",
  "definitions": {
    "OnboardForm": {
      "type": "object",
      "properties": {
        "id": {
          "type": "string",
          "const": "OnboardForm"
        },
        "firstName": {
          "$ref": "#/definitions/Name"
        },
        "lastName": {
          "$ref": "#/definitions/Name"
        },
        "linkedIn": {
          "$ref": "#/definitions/LinkedInURL"
        }
      },
      "required": [
        "id",
        "firstName",
        "lastName"
      ]
    },
    "Name": {
      "type": "string",
      "minLength": 1,
      "maxLength": 32
    },
    "LinkedInURL": {
      "type": "string",
      "pattern": "^https:\\/\\/([0-9a-z]+.)*linkedin.com\\/[\\/0-9a-z-]+$"
    }
  }
};
export const Schema: SchemaType = {
  "$id": "OnboardForm",
  "$schema": "http://json-schema.org/draft-07/schema#",
  "$ref": "#/definitions/OnboardForm",
  "definitions": {
    "OnboardForm": {
      "type": "object",
      "properties": {
        "id": {
          "type": "string",
          "const": "OnboardForm"
        },
        "firstName": {
          "$ref": "#/definitions/Name"
        },
        "lastName": {
          "$ref": "#/definitions/Name"
        },
        "linkedIn": {
          "$ref": "#/definitions/LinkedInURL"
        }
      },
      "required": [
        "id",
        "firstName",
        "lastName"
      ]
    },
    "Name": {
      "type": "string",
      "minLength": 1,
      "maxLength": 32
    },
    "LinkedInURL": {
      "type": "string",
      "pattern": "^https:\\/\\/([0-9a-z]+.)*linkedin.com\\/[\\/0-9a-z-]+$"
    }
  }
};
