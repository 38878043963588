import { readContract } from "@wagmi/core";
import { Spin } from "antd";
import { useEffect, useMemo, useState } from "react";
import { Beneficiaries } from "./View/Beneficiaries";
import { Overview } from "./View/Overview";
import { PersonBenefit } from "./View/PersonBenefit";
import { distributionABI } from "../../../__generated__/contracts";
import { useErrorHelpers } from "../../../hooks/useErrorHelpers";
import { useChain } from "../../../providers/ChainClient";
import { useDistribution } from "../../../providers/DistributionProvider/DistributionProvider";
import { Phase } from "../../../providers/DistributionProvider/types";
import { useFastContext } from "../../../providers/FastContractContext/FastContractContext";
import { useTopContracts } from "../../../providers/TopContractsProvider";

export const View = () => {
  const { info } = useDistribution();
  const { signerAddress } = useChain();
  const { isIssuerMember } = useTopContracts();
  const { membership } = useFastContext();
  const { handleError } = useErrorHelpers();
  const [isLoading, setIsLoading] = useState(false);
  const [currentPhase, setCurrentPhase] = useState(info.phase);
  const [isNeedBeneficiaryBlock, setIsNeedBeneficiaryBlock] = useState(false);

  // We have an asynchronous update on the phase of the Distribution.
  // And it must avoid situations when the spinner disappears,
  // but the updated data hasn't come yet.
  useEffect(() => {
    if (!isLoading) return;

    if (currentPhase !== info.phase) {
      setIsLoading(false);
      setCurrentPhase(info.phase);
    }
  }, [currentPhase, info.phase, isLoading]);

  // Just checking whether a block of beneficiaries is needed.
  // If the user isn't a Coordinator or an Issuer, we should check to see if is a Beneficiary.
  useEffect(() => {
    if (info.phase < Phase.BeneficiariesSetup || info.beneficiaryCount === 0n) return;
    // if the user is a Coordinator or an Issuer, we just enable the need to render beneficiaries table and come out of useEffect.
    // In this case, the contract "paginateBeneficiaries" is read in the "Beneficiaries" component.
    if (membership.isGovernor || isIssuerMember) return setIsNeedBeneficiaryBlock(true);

    readContract({
      abi: distributionABI,
      address: info.address,
      functionName: "paginateBeneficiaries",
      args: [BigInt(0), BigInt(Number(info.beneficiaryCount))],
    })
      .then(([addresses]) => {
        if (addresses.includes(signerAddress)) {
          setIsNeedBeneficiaryBlock(true);
        }
      })
      .catch((err) => handleError("An error occured while loading the beneficiaries", err));
  }, [
    info.address,
    info.beneficiaryCount,
    info.phase,
    isIssuerMember,
    handleError,
    membership.isGovernor,
    signerAddress,
  ]);

  const renderBeneficiaryBlock = useMemo(
    () =>
      membership.isGovernor || isIssuerMember ? (
        <Beneficiaries setIsLoading={setIsLoading} />
      ) : (
        <PersonBenefit beneficiary={signerAddress} setIsLoading={setIsLoading} />
      ),
    [isIssuerMember, membership.isGovernor, signerAddress],
  );

  return (
    <>
      <div className="Content-Header">
        <h1>{info.params.ref}</h1>
      </div>
      <section className="Content-Body">
        <Spin spinning={isLoading} size="large">
          <Overview info={info} setIsLoading={setIsLoading} />
          {isNeedBeneficiaryBlock && renderBeneficiaryBlock}
        </Spin>
      </section>
    </>
  );
};
