import { IconButtonProps } from "@rjsf/utils";
import { JSONSchema7 } from "json-schema";

export const RemoveButton = <T, S extends JSONSchema7>(props: IconButtonProps<T, S>) => (
  <button disabled={props.disabled} className="FastEdit-RemoveButton" onClick={props.onClick}>
    <i className="icon-trash" />
  </button>
);

export const AddButton = <T, S extends JSONSchema7>(props: IconButtonProps<T, S>) => (
  <button disabled={props.disabled} className="FastEdit-AddButton" onClick={props.onClick}>
    Add...
  </button>
);
