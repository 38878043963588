import { Breadcrumb } from "antd";
import { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { Paths } from "../routing";

enum BreadcrumbPath {
  About = "about",
  Distributions = "distributions",
  Drawdowns = "drawdowns",
  Edit = "edit",
  Fasts = "fasts",
  Guidance = "guidance",
  New = "new",
  ManageFunds = "manage-funds",
  Management = "management",
  Community = "community",
  Transfers = "transfers",
  Wallet = "wallet",
  Proposals = "proposals",
}

const getLabelForBreadcrumb = (path: string) => {
  switch (path) {
    case BreadcrumbPath.About:
      return "About";
    case BreadcrumbPath.Distributions:
      return "Distributions";
    case BreadcrumbPath.Drawdowns:
      return "Investment Drawdowns";
    case BreadcrumbPath.Edit:
      return "Edit";
    case BreadcrumbPath.Fasts:
      return "All FASTs";
    case BreadcrumbPath.Guidance:
      return "Guidance";
    case BreadcrumbPath.New:
      return "Create";
    case BreadcrumbPath.ManageFunds:
      return "Manage Funds";
    case BreadcrumbPath.Management:
      return "Management";
    case BreadcrumbPath.Community:
      return "Community";
    case BreadcrumbPath.Transfers:
      return "Transfers";
    case BreadcrumbPath.Wallet:
      return "Wallet";
    case BreadcrumbPath.Proposals:
      return "Proposals";
    default:
      return path;
  }
};

export const Breadcrumbs = () => {
  const { pathname } = useLocation();
  const pathnames = pathname.split("/").filter((p) => p !== "");

  const breadcrumbs = useMemo(
    () =>
      pathnames.map((path, index) => {
        if (index > 1) {
          const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
          const isLast = index === pathnames.length - 1;
          const breadcrumbPath = getLabelForBreadcrumb(path);

          return (
            <Breadcrumb.Item key={path}>
              {isLast ? <span>{breadcrumbPath}</span> : <Link to={routeTo}>{breadcrumbPath}</Link>}
            </Breadcrumb.Item>
          );
        }
      }),
    [pathnames],
  );

  return (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Link to={Paths.root}>
          <i className="icon-arrow-left-tail" />
          {getLabelForBreadcrumb(BreadcrumbPath.Fasts)}
        </Link>
      </Breadcrumb.Item>
      {breadcrumbs}
    </Breadcrumb>
  );
};
