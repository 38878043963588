import { getContract, readContracts } from "@wagmi/core";
import { PropsWithChildren, createContext, useContext, useEffect, useState } from "react";
import { useChain } from "./ChainClient";
import { createGenericContext } from "./utils";
import { issuerABI, marketplaceABI } from "../__generated__/contracts";
import { Loader } from "../components/uiElements/Loader";
import { useErrorHelpers } from "../hooks/useErrorHelpers";
import { Pages } from "../pages/index";
import { IssuerContract, MarketplaceContract } from "../types/contracts";

// Possible state statuses.
const enum Status {
  Unready,
  Ready,
}
// Reasons for a state to be unready.
const enum UnreadyReason {
  Loading,
  Error,
}

interface UnreadyState {
  readonly status: Status.Unready;
  readonly reason: UnreadyReason;
}
interface ReadyState {
  readonly status: Status.Ready;
  readonly marketplace: MarketplaceContract;
  readonly issuer: IssuerContract;
  readonly isMarketplaceMember: boolean;
  readonly isIssuerMember: boolean;
}
type State = UnreadyState | ReadyState;

const isReady = (state: State): state is ReadyState => state.status == Status.Ready;

const initialState: UnreadyState = { status: Status.Unready, reason: UnreadyReason.Loading };
const Context = createContext<State>(initialState);
Context.displayName = "TopContractsProvider";

// This provider is in charge of keeping a sane environment when it comes to top-level
// on-chain objects such as the Marketplace and Issuer contracts, as well as the current
// user's membership in these contracts.
export const TopContractsProvider = ({ children }: PropsWithChildren) => {
  const { signerAddress, chain, walletClient } = useChain();
  const { handleError } = useErrorHelpers();
  const [state, setState] = useState<State>(initialState);

  const { marketplaceAddress, issuerAddress } = chain;

  useEffect(() => {
    // Mark current state as loading.
    setState({ status: Status.Unready, reason: UnreadyReason.Loading });
    // Instantiate contracts.
    const issuer = getContract({ abi: issuerABI, address: issuerAddress, walletClient });
    const marketplace = getContract({ abi: marketplaceABI, address: marketplaceAddress, walletClient });

    readContracts({
      contracts: [
        { abi: issuerABI, address: issuerAddress, functionName: "isMember", args: [signerAddress] },
        { abi: marketplaceABI, address: marketplaceAddress, functionName: "isMember", args: [signerAddress] },
      ],
    })
      .then(([{ result: isIssuerMember }, { result: isMarketplaceMember }]) => {
        if (isIssuerMember == undefined || isMarketplaceMember == undefined)
          throw new Error("Empty Issuer or Marketplace result.");
        setState({
          status: Status.Ready,
          issuer,
          marketplace,
          isMarketplaceMember,
          isIssuerMember,
        });
      })
      .catch((err) => {
        handleError("Loading Top Contracts", err);
        setState({ status: Status.Unready, reason: UnreadyReason.Error });
        throw err;
      });
  }, [marketplaceAddress, issuerAddress, signerAddress, handleError, walletClient]);

  // If the state has resolved successfully, we can wrap the children in a safe context.
  if (isReady(state)) {
    const { status: _, ...safeState } = state;
    return (
      <Context.Provider value={state}>
        <SafeProvider value={safeState}>{children}</SafeProvider>
      </Context.Provider>
    );
  }

  // Our fallback is to simply render the fallback component passed as props.
  // We however wrap it in our provider, so they can diagnose and access the context.
  return (
    <Context.Provider value={state}>
      {state.reason == UnreadyReason.Loading ? (
        <Loader label="Loading Common Contracts..." withLogo />
      ) : (
        <Pages.Errors.Error reason="Failed to invoke top-level contracts." />
      )}
    </Context.Provider>
  );
};
// We export a hook that can be used to access our unsafe provider.
// This hook shouldn't be used unless the developer's intent is to
// access underlying optional values on the state.
export const useUnsafeContracts = () => useContext(Context);

// -------- //

type SafeState = Omit<ReadyState, "status">;
const [useTopContracts, SafeProvider] = createGenericContext<SafeState>("TopContractsSafeContext");
export { useTopContracts };
