import { MembershipRequestState, OnboardIdentityCheckStatus } from "../../../__generated__/graphql";
import { ResolvedIdentity } from "../../../providers/AddressResolver.tsx/AddressResolverProvider";
import { EthAddress } from "../../../types/ethers";

export interface MembershipRequest {
  readonly id: string;
  readonly acceptedAt?: string | null;
  readonly issuedAt: string;
  readonly rejectedAt?: string | null;
  readonly ethAddress: EthAddress | string;
  readonly fullName: Promise<ResolvedIdentity> | string;
  readonly linkedIn?: string | null;
  readonly fastSymbol: string;
  readonly onfidoStatus: OnboardIdentityCheckStatus;
  readonly status: MembershipRequestState;
}

export enum OnfidoStatusFeedback {
  APPROVED = "KYC/AML passed",
  DECLINED = "Manual Review",
  UNCHECKED = "KYC/AML pending",
}
