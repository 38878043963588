import { memo } from "react";

interface Props {
  readonly currencySymbol: string;
  readonly pledges: string;
}

export const ClosedStep = memo(({ currencySymbol, pledges }: Props) => (
  <div className="InvestmentDrawdowns-Step">
    <h4>Your current Investment</h4>
    <p>
      <strong>
        {pledges} {currencySymbol}
      </strong>
    </p>
  </div>
));
