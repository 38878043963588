import { Spin } from "antd";
import { Logo } from "./Logo";

interface Props {
  readonly label?: string;
  readonly whiteLayout?: boolean;
  readonly withLogo?: boolean;
}

export const Loader = ({ label, withLogo, whiteLayout }: Props) => (
  <div className={`Loader ${whiteLayout ? "WhiteLayout" : ""}`}>
    {withLogo && <Logo />}
    <Spin spinning={true} size="large" />
    {label && <h4 className="Loader-Label">{label}</h4>}
  </div>
);
