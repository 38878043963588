import { readContract, readContracts } from "@wagmi/core";
import { ColumnsType } from "antd/lib/table";
import BigNumber from "bignumber.js";
import { useCallback, useMemo } from "react";
import { formatUnits } from "viem";
import { crowdfundABI, fastABI } from "../../../../__generated__/contracts";
import { ResolvableAddress } from "../../../../components/ResolvableAddress";
import { CollapsingColumnTitle } from "../../../../components/tables/CollapsingColumnTitle";
import { PaginatedTable } from "../../../../components/tables/PaginatedTable";
import { useDisplayingDecimals } from "../../../../hooks/useDecimalChange";
import {
  ResolvedIdentity,
  useAddressesResolver,
} from "../../../../providers/AddressResolver.tsx/AddressResolverProvider";
import { useChain } from "../../../../providers/ChainClient";
import { ERC20Info } from "../../../../providers/ERC20Fetcher";
import { useFastContext } from "../../../../providers/FastContractContext/FastContractContext";
import { EthAddress } from "../../../../types/ethers";

interface PledgerDetails {
  readonly balance: BigNumber;
  readonly pledger: EthAddress;
  readonly pledgerFullName: Promise<ResolvedIdentity>;
  readonly pledges: bigint;
}

interface Props {
  readonly address: EthAddress;
  readonly collected: bigint;
  readonly erc20Info: ERC20Info;
  readonly pledgerCount: bigint;
}

export const TotalInvestments = ({ address, collected, erc20Info, pledgerCount }: Props) => {
  const { chain } = useChain();
  const {
    details: { address: fastAddress, decimals, symbol },
  } = useFastContext();
  const { resolveAddresses } = useAddressesResolver();
  const { numberOfDecimals, changeNumberOfDecimals } = useDisplayingDecimals(erc20Info.decimals);

  const explorer = chain.blockExplorers.default.url;

  const fetchTotal = useCallback(
    () => readContract({ abi: crowdfundABI, address, functionName: "pledgerCount" }).then(Number),
    [address],
  );

  const fetchItems = useCallback(
    (index: number, perPage: number) => {
      const common = { abi: crowdfundABI, address };

      return readContract({
        ...common,
        functionName: "paginatePledgers",
        args: [BigInt(index), BigInt(perPage)],
      })
        .then(([pledgersAddresses]) =>
          Promise.all(
            pledgersAddresses.map((pledgerAddress) =>
              Promise.all([
                pledgerAddress,
                readContracts({
                  contracts: [
                    { ...common, functionName: "pledges", args: [pledgerAddress] },
                    { abi: fastABI, address: fastAddress, functionName: "balanceOf", args: [pledgerAddress] },
                  ],
                }),
              ]),
            ),
          ),
        )
        .then((pledgers): ReadonlyArray<PledgerDetails> => {
          const addresses = pledgers.map((pledger) => pledger[0]);
          const resolving = resolveAddresses(addresses);

          return pledgers.reduce<ReadonlyArray<PledgerDetails>>(
            (acc, [pledger, [{ result: pledges }, { result: balance }]]) => {
              if (!pledges) return acc;

              return [
                ...acc,
                {
                  balance: BigNumber(formatUnits(balance || 0n, decimals)),
                  pledger,
                  pledgerFullName: resolving[pledger],
                  pledges,
                },
              ];
            },
            [],
          );
        });
    },
    [address, decimals, fastAddress, resolveAddresses],
  );

  const columns: ColumnsType<PledgerDetails> = useMemo(
    () => [
      {
        title: "Name",
        render: (_, { pledger, pledgerFullName }) => (
          <ResolvableAddress address={pledger} explorer={explorer} fullName={pledgerFullName} />
        ),
      },
      {
        title: (
          <CollapsingColumnTitle
            decimals={erc20Info.decimals}
            numberOfDecimals={numberOfDecimals}
            title={`Amount (${erc20Info.symbol})`}
            changeNumberOfDecimals={changeNumberOfDecimals}
          />
        ),
        render: (_, { pledges }) => {
          const formattedAmount = erc20Info.formatter.format(pledges, numberOfDecimals);

          return formattedAmount.includes("...") ? (
            <p title={erc20Info.formatter.format(pledges, erc20Info.decimals)}>{formattedAmount}</p>
          ) : (
            formattedAmount
          );
        },
      },
      {
        title: "Current FAST holding",
        render: (_, { balance }) => `${balance.decimalPlaces(2, BigNumber.ROUND_HALF_EVEN).toString(10)} ${symbol}`,
      },
      {
        title: "Share of round",
        render: (_, { pledges }) =>
          `${pledgerCount > 1 ? "~ " : ""}${BigNumber(formatUnits(pledges, decimals))
            .multipliedBy(100)
            .dividedBy(BigNumber(formatUnits(collected, decimals)))
            .decimalPlaces(2, BigNumber.ROUND_HALF_EVEN)
            .toString(10)}%`,
      },
    ],
    [
      changeNumberOfDecimals,
      collected,
      decimals,
      explorer,
      erc20Info.decimals,
      erc20Info.formatter,
      erc20Info.symbol,
      numberOfDecimals,
      pledgerCount,
      symbol,
    ],
  );

  return (
    <div className="InvestmentDrawdowns-TotalInvestments">
      <h2>Total investments</h2>
      <PaginatedTable columns={columns} fetchTotal={fetchTotal} fetchItems={fetchItems} />
    </div>
  );
};
