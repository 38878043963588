import classnames from "classnames";
import { useCallback, useEffect, useState } from "react";
import { InvestmentDrawdownNames } from "./types";
import { Overview } from "./View/Overview";
import { ClosedStep } from "./View/Steps/ClosedStep";
import { FundingStep } from "./View/Steps/FundingStep";
import { RequestedStep } from "./View/Steps/RequestedStep";
import { WithdrawalStep } from "./View/Steps/WithdrawalStep";
import { TotalInvestments } from "./View/TotalInvestments";
import { assertUnreachable } from "../../../helpers/utils";
import { useErrorHelpers } from "../../../hooks/useErrorHelpers";
import { useAddressesResolver } from "../../../providers/AddressResolver.tsx/AddressResolverProvider";
import { useChain } from "../../../providers/ChainClient";
import { useCrowdfund } from "../../../providers/CrowdfundProvider/CrowdfundProvider";
import { Phase } from "../../../providers/CrowdfundProvider/types";
import { useFastContext } from "../../../providers/FastContractContext/FastContractContext";
import { useTopContracts } from "../../../providers/TopContractsProvider";

export const View = () => {
  const info = useCrowdfund();
  const { isIssuerMember } = useTopContracts();
  const { membership } = useFastContext();
  const { chain, signerAddress } = useChain();
  const { resolveAddresses } = useAddressesResolver();
  const { handleError } = useErrorHelpers();
  const [isLoading, setIsLoading] = useState(false);
  const [pledges, setPledges] = useState("0");
  const [names, setNames] = useState<InvestmentDrawdownNames>(() => ({
    ownerFullName: info.params.owner,
    beneficiaryFullName: info.params.beneficiary,
  }));
  const explorer = chain.blockExplorers.default.url;

  // We don't have information about pledged amount and going to get it.
  useEffect(() => {
    // When we start pledging, we don't need to read the data from the contract.
    if (isLoading) return;

    if (info.phase > 0) {
      info.contract.read
        .pledges([signerAddress])
        .then((amount) => {
          if (amount === undefined) throw new Error("Empty result from contract.");
          setPledges(info.erc20Info.formatter.format(amount, 2));
        })
        .catch((err) => {
          handleError("Loading Investment Commitments Information", err);
        });
    }
  }, [info.contract.read, info.erc20Info.formatter, info.phase, isLoading, signerAddress, handleError]);

  // Let's resolve owner and beneficiary names, because resolveAddresses is Promise.
  useEffect(() => {
    const resolving = resolveAddresses([info.params.beneficiary, info.params.owner]);
    setNames({
      ownerFullName: resolving[info.params.owner],
      beneficiaryFullName: resolving[info.params.beneficiary],
    });
  }, [info.params.beneficiary, info.params.owner, resolveAddresses]);

  const renderStep = useCallback(() => {
    switch (info.phase) {
      case Phase.Setup:
        return isIssuerMember ? <RequestedStep explorer={explorer} info={info} names={names} /> : <></>;
      case Phase.Funding:
        return (
          <FundingStep
            drawdownAddress={info.address}
            erc20Info={info.erc20Info}
            isLoading={isLoading}
            pledges={pledges}
            setIsLoading={setIsLoading}
          />
        );
      case Phase.Success:
        return <ClosedStep currencySymbol={info.erc20Info.symbol} pledges={pledges} />;
      case Phase.Failure:
        return (
          <WithdrawalStep
            beneficiary={info.params.beneficiary}
            currencySymbol={info.erc20Info.symbol}
            drawdownAddress={info.address}
            pledges={pledges}
          />
        );
      default:
        assertUnreachable(info.phase);
    }
  }, [explorer, info, isIssuerMember, isLoading, names, pledges]);

  return (
    <>
      <div className="Content-Header">
        <h1>{info.params.ref}</h1>
      </div>
      <section className="Content-Body InvestmentDrawdowns-View">
        <div className={classnames("Content", info.phase === Phase.Setup ? "Setup" : "")}>
          <Overview
            drawdownAddress={info.address}
            explorer={explorer}
            isLoading={isLoading}
            names={names}
            params={info.params}
            phase={info.phase}
          />
          {renderStep()}
        </div>
        {(isIssuerMember || membership.isGovernor) && (
          <TotalInvestments
            address={info.address}
            collected={info.collected}
            erc20Info={info.erc20Info}
            pledgerCount={info.pledgerCount}
          />
        )}
      </section>
    </>
  );
};
