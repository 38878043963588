import { init } from "onfido-sdk-ui";
import { useEffect, useState } from "react";
import { AwaitingChainAddition } from "./AwaitingChainAddition";
import {
  useRequestOnboardIdentityCheckMutation,
  useWatchOnboardUpdateSubscription,
} from "../../../../__generated__/graphql";
import { useErrorHelpers } from "../../../../hooks/useErrorHelpers";
import { StepProps } from "../types";

const ONFIDO_CONTAINER_ID = "onfido-mount";

export const KYCVerification = ({ onboard, refreshOnboard }: StepProps) => {
  const { id: onboardId, claimToken } = onboard;
  const { handleError } = useErrorHelpers();
  const [intermediateStep, setIntermediateStep] = useState(false);
  const [performIdentityCheck] = useRequestOnboardIdentityCheckMutation();
  const { data: subscriptionData } = useWatchOnboardUpdateSubscription({ variables: { onboardId, claimToken } });

  useEffect(() => {
    if (intermediateStep) return;
    performIdentityCheck({ variables: { input: { claimToken, onboardId } } })
      .then((res) => {
        const token = res.data?.requestOnboardIdentityCheck?.token;
        const workflowRunId = res.data?.requestOnboardIdentityCheck?.workflowRunId;
        if (token && workflowRunId) {
          init({
            containerId: ONFIDO_CONTAINER_ID,
            token,
            workflowRunId,
            onError: (err) => handleError("KYC Verification Failed (Code 1)", err),
            onComplete: () => setIntermediateStep(true),
            customUI: {
              fontFamilyBody: "Lato, sans-serif",
              fontSizeBody: "14px",
              colorContentBody: "#1c1b37",
              colorContentButtonPrimaryText: "#1c1b37",
              colorBackgroundButtonPrimary: "#23b8e1",
              colorBackgroundButtonPrimaryHover: "#9dcddd",
              colorBackgroundButtonPrimaryActive: "#0080a3",
              colorBorderButtonPrimary: "#23b8e1",
              colorBorderButtonPrimaryHover: "#9dcddd",
              colorBorderButtonPrimaryActive: "#0080a3",
              colorContentButtonSecondaryText: "#0080a3",
              colorBackgroundButtonSecondary: "#ffffff",
              colorBackgroundButtonSecondaryHover: "#ffffff",
              colorBackgroundButtonSecondaryActive: "#1c1b37",
              colorBorderButtonSecondary: "#0080a3",
              colorBorderButtonSecondaryHover: "#1c1b37",
              colorBorderButtonSecondaryActive: "#1c1b37",
              colorContentLinkTextHover: "#0080a3",
            },
          });
        }
      })
      .catch((err) => handleError("KYC Verification Failed (Code 2)", err));
  }, [claimToken, handleError, intermediateStep, onboardId, performIdentityCheck]);

  useEffect(() => {
    if (subscriptionData) refreshOnboard(subscriptionData.onboardUpdated);
  }, [subscriptionData, refreshOnboard]);

  return intermediateStep ? (
    <AwaitingChainAddition />
  ) : (
    <div className="ClientVerification">
      <div id={ONFIDO_CONTAINER_ID}></div>
    </div>
  );
};
