import { WalletClient, getContract } from "@wagmi/core";
import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { Listener } from "./Listener";
import * as CrowdfundThings from "./types";
import { crowdfundABI } from "../../__generated__/contracts";
import { Loader } from "../../components/uiElements/Loader";
import { useErrorHelpers } from "../../hooks/useErrorHelpers";
import { Pages } from "../../pages/index";
import { EthAddress } from "../../types/ethers";
import { useChain } from "../ChainClient";
import { erc20Fetcher } from "../ERC20Fetcher";
import { createGenericContext } from "../utils";

const ERR_NOT_FOUND = "Not Found";

// Possible state statuses.
const enum Status {
  Unready,
  Ready,
}
// Reasons for a state to be unready.
const enum UnreadyReason {
  Loading,
  NotFound,
  Error,
}

interface UnreadyState {
  // Our state readiness flag - set to unready.
  readonly status: Status.Unready;
  // The reason why our state is flagged as unready.
  readonly reason: UnreadyReason;
}
export interface ReadyState {
  // Our state readiness flag - set to ready.
  readonly status: Status.Ready;
  // The crowdfund contract instance.
  readonly info: CrowdfundThings.Info;
}

type State = ReadyState | UnreadyState;

const initialState: State = { status: Status.Unready, reason: UnreadyReason.Loading };
const [useCrowdfund, Provider] = createGenericContext<CrowdfundThings.Info>("CrowdfundContext");
export { useCrowdfund };

export type PhaseChangedCallback = (phase: number) => void;

interface Props {
  readonly address: EthAddress;
}

// TODO: We probably want to add a bunch of listeners here...
export const CrowdfundProvider = ({ address, children }: PropsWithChildren<Props>) => {
  const { walletClient } = useChain();
  const { handleError } = useErrorHelpers();
  const [state, setState] = useState<State>(initialState);

  const populateCrowdfund = useCallback(() => {
    // Fetch our crowdfund details.
    crowdfundFetcher(address, walletClient)
      // On success, set our state.
      .then((info) => setState({ status: Status.Ready, info }))
      // In case of an error, mark our state as unready and assign the right kind of reason to it.
      .catch((err) => {
        handleError("Load Crowdfund Details", err);
        setState({
          status: Status.Unready,
          reason: err === ERR_NOT_FOUND ? UnreadyReason.NotFound : UnreadyReason.Error,
        });
      });
  }, [address, handleError, walletClient]);

  // Sets `fast` and `fastDetails`.
  useEffect(() => {
    setState({ status: Status.Unready, reason: UnreadyReason.Loading });
    populateCrowdfund();
  }, [populateCrowdfund]);

  const onPhaseChanged: PhaseChangedCallback = (phase) => {
    if (state.status != Status.Ready) return;
    console.debug(`Phase changed to ${phase}.`);
    populateCrowdfund();
  };

  const { status } = state;
  switch (status) {
    case Status.Unready:
      switch (state.reason) {
        case UnreadyReason.Loading:
          return <Loader label="Loading Crowdfund..." whiteLayout />;
        case UnreadyReason.NotFound:
          return <Pages.Errors.NotFound />;
        case UnreadyReason.Error:
          return <Pages.Errors.Error reason={`Failed to invoke Crowdfund ${address} contract.`} whiteLayout />;
      }
    // eslint-disable-next-line no-fallthrough
    case Status.Ready:
      return (
        <Provider value={state.info}>
          <Listener address={address} onPhaseChanged={onPhaseChanged}>
            {children}
          </Listener>
        </Provider>
      );
  }
};

export const crowdfundFetcher = (address: EthAddress, walletClient: WalletClient): Promise<CrowdfundThings.Info> => {
  const contract = getContract({ abi: crowdfundABI, address, walletClient });
  return contract.read
    .details()
    .then((details) => Promise.all([details, erc20Fetcher(details.params.token, walletClient)]))
    .then(([details, erc20Info]) => {
      const { VERSION: version, phase, ...rest } = details;
      return {
        ...rest,
        address,
        contract,
        version,
        phase: CrowdfundThings.enforceNumericPhase(phase),
        erc20Info,
      };
    });
};
