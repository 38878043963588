import { RequestMembershipButton } from "../../../../components/buttons/RequestMembershipButton";
import { PropsWithSymbolState } from "../../../../providers/FastSymbolProvider";

export const FastMembershipCard = ({ symbol }: PropsWithSymbolState) => {
  return (
    <div className="Card">
      <h4>Do you want to be a member?</h4>
      <p>
        Please request to be added to the {symbol} community if you think your profile is relevant to their community
        and we'll shortly revert with the next steps.
      </p>
      <RequestMembershipButton symbol={symbol} />
    </div>
  );
};
