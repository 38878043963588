import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";

// A helper type for components that use a modal state in their props.
export type PropsWithModalState<P = unknown> = P & { readonly state: ModalState };

// Our internal state.
export interface ModalState {
  readonly isVisible: boolean;
  readonly setIsVisible: Dispatch<SetStateAction<boolean>>;
  readonly open: () => void;
  readonly close: () => void;
}

export const useModalState = (initialVisibility?: boolean): ModalState => {
  const [isVisible, setIsVisible] = useState(!!initialVisibility);

  const open = useCallback(() => setIsVisible(true), []);
  const close = useCallback(() => setIsVisible(false), []);

  return useMemo(() => ({ isVisible, setIsVisible, open, close }), [close, open, isVisible]);
};
