import { PropsWithChildren } from "react";
import { useLocation } from "react-router-dom";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { AppearanceTransition } from "../../components/layout/AppearanceTransition";
import { SecondFastTabNavigator } from "../../components/layout/SecondFastTabNavigator";
import { SectionProps, useNavigatorTabs } from "../../hooks/useNavigatorTabs";

export const FastContentWrapper = ({ children, section }: PropsWithChildren<SectionProps>) => {
  const { pathname } = useLocation();
  const isDeepLevelNavigation = pathname.split("/").length > 5;
  const tabs = useNavigatorTabs(section);

  return (
    <AppearanceTransition>
      {isDeepLevelNavigation ? (
        <div className="ContentWrapper-Breadcrumbs">
          <Breadcrumbs />
        </div>
      ) : (
        <SecondFastTabNavigator tabs={tabs} />
      )}
      <div className={isDeepLevelNavigation ? "" : "Content"}>{children}</div>
    </AppearanceTransition>
  );
};
