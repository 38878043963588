import { Col, Row } from "antd";
import { Link, useLocation } from "react-router-dom";
import { Paths } from "../../routing";

interface TabDescriptor {
  readonly title: string;
  readonly path: string;
}

const tabs: ReadonlyArray<TabDescriptor> = [
  { title: "Deploy new fast", path: Paths.Fasts.new },
  // Hidden until this page is implemented.
  // { title: "Add members", path: Paths.Fasts.newMember },
  { title: "Applications", path: Paths.Fasts.membershipRequests },
];

export const IssuerTabsNavigator = () => {
  const { pathname } = useLocation();

  return (
    <div className="IssuerTabsNavigator">
      <Row gutter={24}>
        {tabs.map((tab, index) => (
          <Col key={index}>
            <Link className={`IssuerTabsNavigator-Link${tab.path === pathname ? " active" : ""}`} to={tab.path}>
              {tab.title}
            </Link>
          </Col>
        ))}
      </Row>
    </div>
  );
};
