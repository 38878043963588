import { readContract } from "@wagmi/core";
import { fastABI } from "../__generated__/contracts";
import { EthAddress } from "../types/ethers";

export const paginateDetailedMembers = (
  address: EthAddress,
  memberCount: bigint,
  setAddresses: (addresses: ReadonlyArray<EthAddress>) => void,
) =>
  readContract({
    abi: fastABI,
    address,
    functionName: "paginateDetailedMembers",
    args: [0n, memberCount],
  })
    .then(([members]) => {
      const membersAddresses = members.map((member) => member.addr);
      setAddresses(membersAddresses);
    })
    .catch((err) => console.error(err));
