import { ReactNode } from "react";
import { OpacityTransition } from "../../components/layout/OpacityTransition";
import { NetworkName } from "../../components/NetworkName";

interface Props {
  readonly symbol?: string;
  readonly reason?: ReactNode;
  readonly whiteLayout?: boolean;
}

export const Error = ({ symbol, reason, whiteLayout }: Props) => (
  <OpacityTransition className={`PageNotFound ${whiteLayout ? "WhiteLayout" : ""}`}>
    <NetworkName symbol={symbol || "FAST"} />
    <h1>
      ERROR
      <br />
      :(
    </h1>
    <h3>
      {reason || "We tried really hard, but the computer disagreed. You might want to refresh this page later."}
      <br />
      Try reloading the page in a few minutes.
    </h3>
    <br />
  </OpacityTransition>
);
