import { Image } from "antd";
import { PropsWithChildren, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { AppearanceTransition } from "./AppearanceTransition";
import { Section, useNavigatorTabs } from "../../hooks/useNavigatorTabs";
import { useFastDetails } from "../../pages/fast/Top";
import { useFastSymbol } from "../../providers/FastSymbolProvider";
import { makePath } from "../../routing";

export const FastTabsNavigator = ({ children }: PropsWithChildren) => {
  const { pathname } = useLocation();
  const { symbol } = useFastSymbol();
  const { fastDetails } = useFastDetails();
  const tabs = useNavigatorTabs(Section.Main);

  const getSectionName = useCallback((path: string) => path.split("/").at(3), []);

  return (
    <section className="FastTabsNavigator">
      <div className="MainNavigatorWrapper">
        <AppearanceTransition initial={{ x: -20, opacity: 0 }} animate={{ x: 0, opacity: 1 }}>
          <div className="MainNavigator">
            <div className="FastInfo">
              {fastDetails.logoUrl && <Image alt="Fast Logo" src={fastDetails.logoUrl} preview={false} />}
              <h2>{symbol}</h2>
            </div>
            <nav>
              {tabs.map((tab, index) => {
                const route = makePath(tab.path, { symbol });
                return (
                  <Link
                    key={index}
                    className={`FastTabsNavigator-Link${
                      getSectionName(route) === getSectionName(pathname) ? " active" : ""
                    }`}
                    to={route}>
                    {tab.title}
                  </Link>
                );
              })}
            </nav>
          </div>
        </AppearanceTransition>
      </div>
      <div className="ContentWrapper">{children}</div>
    </section>
  );
};
