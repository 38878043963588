import { ApolloError } from "@apollo/client";
import { Button, Form, Input, Modal, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { useIssueOnboardMutation } from "../../__generated__/graphql";
import { RequiredEmailRule } from "../../helpers/utils";
import { useErrorHelpers } from "../../hooks/useErrorHelpers";
import { SimpleToast } from "../../types/toasts";

interface SingleValueFormType {
  readonly emailAddress: string;
}

export const InviteToPlatform = () => {
  const [form] = useForm();
  const { handleError } = useErrorHelpers();
  const [issueOnboard] = useIssueOnboardMutation();

  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const onCloseModal = () => {
    form.resetFields();
    setShowModal(false);
  };

  const onFinish = ({ emailAddress }: SingleValueFormType) => {
    setIsLoading(true);

    issueOnboard({ variables: { input: { emailAddress } } })
      .then((res) => {
        if (!res.data?.issueOnboard) throw new Error("Invalid response from server.");
        form.resetFields();
        onCloseModal();
        SimpleToast.success("Your invitation has been sent");
      })
      .catch((err: ApolloError) => handleError("An error occured while inviting to the platform", err))
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <Button onClick={() => setShowModal(true)}>Invite to Platform</Button>
      <Modal
        className="InviteToPlatform-Modal"
        centered
        footer={null}
        open={showModal}
        width={608}
        onCancel={onCloseModal}>
        <div>
          <h2>Invite to THE</h2>
          <h1>
            <span>TokenSphere</span> platform
          </h1>
        </div>

        <p>
          Only invite members who you think would be a valuable addition to the platform.
          <br />
          Your success is closely tied to the value added by other members in your communities.
        </p>

        <Spin spinning={isLoading} size="large">
          <Form autoComplete="off" form={form} layout="vertical" requiredMark onFinish={onFinish}>
            <Form.Item label="Invitee Email" name="emailAddress" rules={[...RequiredEmailRule]}>
              <Input placeholder="Type email" />
            </Form.Item>
            <Form.Item>
              <div className="ButtonPairWrapper">
                <Button onClick={onCloseModal}>Cancel</Button>
                <Button htmlType="submit">Send Invite</Button>
              </div>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};
