import { ResolvedIdentity } from "../../../providers/AddressResolver.tsx/AddressResolverProvider";
import { EthAddress } from "../../../types/ethers";

enum ButtonLabel {
  Accept = "Accept",
  Abort = "Abort",
  Close = "Close",
  Reject = "Reject",
}

export const AcceptInvestmentDrawdown = {
  header: "Accept INVESTMENT DRAWDOWN",
  essentialCondition: "Platform Fee: ",
  description: "All the FAST members will be notified to start investing upon accepting this request",
  buttonLabel: ButtonLabel.Accept,
  isAccept: true,
};

export const RejectInvestmentDrawdown = {
  header: "Reject INVESTMENT DRAWDOWN",
  essentialCondition: "ATTENTION: You cannot undo this action",
  description: "This Investment Drawdown request will be rejected and cannot take in any investments",
  buttonLabel: ButtonLabel.Reject,
  isAccept: false,
};

export const CloseInvestmentDrawdown = {
  header: "CLOSE INVESTMENT DRAWDOWN",
  description:
    "The investment drawdown will now be closed and the funds and the fee will be wired to the recipient. You can no longer accept new investments.",
  buttonLabel: ButtonLabel.Close,
  isClose: true,
};

export const AbortInvestmentDrawdown = {
  header: "Abort INVESTMENT DRAWDOWN",
  description:
    "You are about to abort the investment drawdown and refund funds to investors. All investors will be notified to collect their funds.",
  buttonLabel: ButtonLabel.Abort,
  isClose: false,
};

interface DrawdownModal {
  readonly header: string;
  readonly description: string;
  readonly buttonLabel: ButtonLabel;
}

export interface RequestDrawdown extends DrawdownModal {
  readonly essentialCondition: string;
  readonly isAccept: boolean;
}

export interface CompleteDrawdown extends DrawdownModal {
  readonly isClose: boolean;
}

export interface InvestmentDrawdownNames {
  readonly ownerFullName: Promise<ResolvedIdentity> | EthAddress;
  readonly beneficiaryFullName: Promise<ResolvedIdentity> | EthAddress;
}
