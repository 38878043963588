import { Steps } from "antd";
import { Step } from "./types";
import { assertUnreachable } from "../../../helpers/utils";

const titleForStep = (step: Step) => {
  switch (step) {
    case Step.Introduction:
      return "Introduction";
    case Step.EmailSent:
      return "Email Sent";
    default:
      assertUnreachable(step);
  }
};

const Items = [{ title: titleForStep(Step.Introduction) }, { title: titleForStep(Step.EmailSent) }];

interface Props {
  readonly step: number;
}

export const Stepper = ({ step }: Props) => (
  <Steps className="Steps" current={step} direction="vertical" items={Items} progressDot size="small" />
);
