import { WidgetProps } from "@rjsf/utils";
import { Select } from "antd";
import { JSONSchema7 } from "json-schema";

interface Props<T, S extends JSONSchema7> extends WidgetProps<T, S> {
  readonly value: ReadonlyArray<T>;
}

// TODO: We should explicitly type WidgetProps. TagsEditor should become a generic.
export const TagsEditor = <T, S extends JSONSchema7>({ value, onChange, disabled }: Props<T, S>) => (
  <Select
    disabled={disabled}
    className="FastEdit-MultipleSelect"
    mode="tags"
    maxTagCount="responsive"
    value={value}
    onChange={onChange}
  />
);
