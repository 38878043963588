import { useMemo } from "react";
import { formatUnits, parseUnits } from "viem";

export class BigNumberFormatter {
  private readonly decimals: number;

  constructor(decimals: number) {
    this.decimals = decimals;
  }

  // Given a bigint value, format it to a string with the correct number of decimals
  // If trunc is ommited, no trucation is done.
  // If trunc is specified, that number of decimals is kept after the dot.
  format(value: bigint, trunc?: number): string {
    const formatted = formatUnits(value, this.decimals);
    return trunc ? this.trunc(formatted, trunc) : formatted;
  }

  // Given a string value, parse it to a bigint with the correct number of decimals
  parse(value: `${number}`): bigint {
    return parseUnits(value, this.decimals);
  }

  // Truncates a given string with decimals to a given number of decimals.
  // If no truncation is needed, the original string is returned.
  // If truncation is performed, the string is truncated and "..." is suffixed to it.
  trunc(formatted: string, keep?: number): string {
    const idx = formatted.indexOf(".");
    if (idx > -1) {
      const truncated = formatted.slice(0, idx + (keep || 2) + 1);
      return formatted == truncated ? formatted : truncated + "...";
    } else {
      return formatted;
    }
  }
}

export const useBigNumberFormatter = (decimals: number) => useMemo(() => new BigNumberFormatter(decimals), [decimals]);
