import { SessionSessionFragment } from "../__generated__/graphql";
import { EthAddress } from "../types/ethers";

enum Keys {
  Sessions = "sessions",
  Theme = "theme",
}

// ------- General ------- //

// Clears **ALL** of Local Storage. Use with caution.
const clear = () => Object.values(Keys).forEach((k) => localStorage.removeItem(k));

// Gets one item.
const get = (key: Keys): null | string => localStorage.getItem(key);

// Sets one item.
const set = (key: Keys, value: string) => localStorage.setItem(key, value);

// Removes one item.
const remove = (key: Keys) => localStorage.removeItem(key);

// ------- Session Related ------- //

// Gets all sessions.
const getSessions = (chainId: number) => {
  const lsSessions = localStorage.getItem(`${Keys.Sessions}:${chainId}`) || "{}";
  return JSON.parse(lsSessions) as Record<EthAddress, SessionSessionFragment | undefined>;
};

// Gets a single session or undefined.
const getSession = (chainId: number, signerAddress: EthAddress): SessionSessionFragment | undefined => {
  const sessions = getSessions(chainId);
  return sessions[signerAddress];
};

// Stores session data under a given ethereum signer address.
const storeSession = (chainId: number, data: SessionSessionFragment) => {
  const sessions = { ...getSessions(chainId), [data.identity.ethAddress]: data };
  localStorage.setItem(`${Keys.Sessions}:${chainId}`, JSON.stringify(sessions));
};

// Clear one specific session.
const clearSession = (chainId: number, signerAddress: EthAddress) => {
  const { [signerAddress]: _clearedSession, ...rest } = getSessions(chainId);
  localStorage.setItem(`${Keys.Sessions}:${chainId}`, JSON.stringify(rest));
};

// Clears all recorded sessions.
export const clearAllSessions = (chainId: number) => {
  localStorage.removeItem(`${Keys.Sessions}:${chainId}`);
};

// ------- Export Everything under one LS namespace ------- //

export const LS = {
  Keys,
  clear,
  get,
  set,
  remove,
  sessions: {
    get: getSession,
    set: storeSession,
    clear: clearSession,
    clearAll: clearAllSessions,
  },
};
