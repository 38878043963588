import { fetchBalance } from "@wagmi/core";
import { useEffect, useState } from "react";
import { isEthAddress } from "./../types/ethers";
import { useChain } from "../providers/ChainClient";

interface TokenData {
  readonly formatted: string;
  readonly symbol: string;
}

// Hook for getting balances of MATIC and default currency.
export const useGetBalances = () => {
  const {
    chain: { defaultCurrency },
    signerAddress,
  } = useChain();
  const [balances, setBalances] = useState<ReadonlyArray<TokenData>>();

  // Check whether it has already fetched the balances. If not, fetch them.
  useEffect(() => {
    if (!isEthAddress(defaultCurrency) || balances) return;

    Promise.all([
      fetchBalance({
        address: signerAddress,
      }),
      fetchBalance({
        address: signerAddress,
        token: defaultCurrency,
      }),
    ])
      .then((res) => {
        const tokens = res.map((token) => ({ formatted: token.formatted, symbol: token.symbol }));
        setBalances(tokens);
      })
      .catch((err) => console.error("An error occurred while fetching the balances.", err));
  }, [balances, defaultCurrency, signerAddress]);

  return balances;
};
