import { writeContract } from "@wagmi/core";
import { Button } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useCallback, useMemo } from "react";
import { fastABI } from "../../../__generated__/contracts";
import { ResolvedIdentityFragment } from "../../../__generated__/graphql";
import { ResolvableAddress } from "../../../components/ResolvableAddress";
import { AddressColumn } from "../../../components/tables/AddressColumn";
import { PaginatedTable } from "../../../components/tables/PaginatedTable";
import { useErrorHelpers } from "../../../hooks/useErrorHelpers";
import { useAddressesResolver } from "../../../providers/AddressResolver.tsx/AddressResolverProvider";
import { useChain } from "../../../providers/ChainClient";
import { useFastContext } from "../../../providers/FastContractContext/FastContractContext";
import { EthAddress } from "../../../types/ethers";

interface MemberDetails {
  readonly address: EthAddress;
  readonly isGovernor: boolean;
  readonly balance: bigint;
  // Augmented at runtime.
  readonly ethBalance: bigint;
  readonly fullName?: Promise<ResolvedIdentityFragment | null>;
}

export const MembersTable = () => {
  const { chain } = useChain();
  const {
    details: { address: fastAddress, contract: fast },
    membership,
  } = useFastContext();
  const { trackTransaction } = useErrorHelpers();
  const { resolveAddresses } = useAddressesResolver();

  const explorer = chain.blockExplorers.default.url;

  const fetchTotal = useCallback(() => fast.read.memberCount().then(Number), [fast.read]);
  const fetchItems = useCallback(
    (index: number, pageSize: number) =>
      fast.read
        // Load member details from chain.
        .paginateDetailedMembers([BigInt(index), BigInt(pageSize)])
        // Transform chain member details into our known format.
        .then(([members]) => {
          type Acc = { members: ReadonlyArray<MemberDetails>; addresses: ReadonlyArray<EthAddress> };
          return members.reduce<Acc>(
            (acc, { addr, isGovernor, balance, ethBalance }): Acc => {
              const address = addr;
              return {
                members: [
                  ...acc.members,
                  {
                    address,
                    isGovernor,
                    balance,
                    ethBalance,
                  },
                ],
                addresses: [...acc.addresses, address],
              };
            },
            { members: [], addresses: [] },
          );
        })
        // We now are ready to collect addresses and resolve them in the background.
        .then(({ members, addresses }): ReadonlyArray<MemberDetails> => {
          // Start resolving the addresses.
          const resolving = resolveAddresses(addresses);
          // Augment the member details with the full name promise.
          return members.map((member) => ({ ...member, fullName: resolving[member.address] }));
        }),
    [fast, resolveAddresses],
  );

  const governanceCols: ColumnsType<MemberDetails> = useMemo(() => {
    const onRemoveMember = (address: EthAddress) => {
      const tx = writeContract({
        abi: fastABI,
        address: fastAddress,
        functionName: "removeMember",
        args: [address],
      });
      void trackTransaction(tx, "Member Removal", () => `Member ${address} has been removed`);
    };

    return [
      {
        title: "",
        render: (_, { address }) => (
          <Button className="Table-Remove-Button" onClick={() => onRemoveMember(address)}>
            <i className="icon-trash" />
          </Button>
        ),
      },
    ];
  }, [fastAddress, trackTransaction]);

  // Members Table Columns
  const columns: ColumnsType<MemberDetails> = useMemo(
    () => [
      {
        title: "Member",
        render: (_, { address, fullName }) => (
          <ResolvableAddress address={address} fullName={fullName} explorer={explorer} />
        ),
      },
      {
        title: "Address",
        render: (_, { address }) => <AddressColumn ethAddress={address} />,
      },
      ...(membership.isGovernor ? governanceCols : []),
    ],
    [explorer, governanceCols, membership.isGovernor],
  );

  return (
    <section className="FastTab Community">
      <h3>List of existing members</h3>
      <PaginatedTable fetchTotal={fetchTotal} fetchItems={fetchItems} columns={columns} />
    </section>
  );
};
