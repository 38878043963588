import { Button, Popover } from "antd";
import { useCallback } from "react";

interface Props {
  readonly classname?: string;
  readonly message: string;
}

export const CopyPopover = ({ classname, message }: Props) => {
  const onCopyToClipboard = useCallback(() => void navigator.clipboard.writeText(message), [message]);

  return (
    <Popover
      className={classname}
      autoAdjustOverflow
      placement="topLeft"
      trigger="click"
      onOpenChange={onCopyToClipboard}
      content={
        <div>
          {message}
          <span className="CopyPopover-Label">Copied!</span>
        </div>
      }>
      <Button className="CopyPopover-Button">
        <i className="icon-copy" />
      </Button>
    </Popover>
  );
};
