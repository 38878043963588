interface Props {
  readonly name: string;
  readonly symbol: string;
}

export const FastsDropdownOption = ({ name, symbol }: Props) => (
  <div className="FastsDropdown-Option">
    <strong>{symbol}</strong>
    <span>{name}</span>
  </div>
);
