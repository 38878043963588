import { useEffect, useState } from "react";

interface Props<T> {
  readonly promise: Promise<T>;
  readonly renderer?: (value: T) => React.ReactNode | string;
  readonly loading?: React.ReactNode;
}

export const PromiseDisplayer = <T,>({ promise, renderer, loading }: Props<T>) => {
  const [value, setValue] = useState<T>();

  // Whenever the promise changes, schedule a new update.
  useEffect(() => {
    promise.then(setValue).catch((err) => {
      console.error("Unable to resolve displayable promise.", err);
      return "!!!";
    });
  }, [promise]);

  return (
    <>{value !== null && value !== undefined ? (renderer ? renderer(value) : value.toString()) : loading || "..."}</>
  );
};
