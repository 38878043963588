import LogRocket from "logrocket";
import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { AuthenticateInput, useAuthenticateMutation, useMeLazyQuery } from "../../__generated__/graphql";
import { Loader } from "../../components/uiElements/Loader";
import { LS } from "../../helpers/LocalStorage";
import { useErrorHelpers } from "../../hooks/useErrorHelpers";
import { useAppContext } from "../APIClient";
import { useChain } from "../ChainClient";
import { createGenericContext } from "../utils";

interface ProviderState {
  readonly isLoading: boolean;
  // Consumers of this provider can call the `authenticate` function and know
  // if it was successful by observing the returned `Promise<boolean>`.
  readonly authenticate: (input: AuthenticateInput) => Promise<boolean>;
  // Logging out is also provided as a function to consumers of this provider.
  readonly logout: () => void;
}

const [useSession, Provider] = createGenericContext<ProviderState>("SessionContext");
export { useSession };

// TODO: Session storage is done without a chain ID. It means that bearer stored
// for a given wallet address will be rejected when the chain changes.
// Instead of storing sessions such as `sessions[ethAddress]` we should switch
// to something like `sessions${chainId}[ethAddress]`.
export const SessionProvider = ({ children }: PropsWithChildren) => {
  const { sessionData, setSessionData } = useAppContext();
  const { handleError } = useErrorHelpers();
  const { chain, signerAddress } = useChain();
  const { id: chainId } = chain;
  const [meQuery, { loading: meLoading }] = useMeLazyQuery();
  const [authenticateMutation] = useAuthenticateMutation();

  // LogRocket "last known" identify state.
  const [lastLgState, setLastLgState] = useState<string>();
  // Whether or not the component is loading.
  const [isLoading, setIsLoading] = useState(false);

  // LogRocket identity when session changes.
  useEffect(() => {
    if (!sessionData) return;
    // Construct the LogRocket identity data.
    const lgData = {
      name: (sessionData?.identity.fullName && sessionData.identity.fullName.trimStart().split(" ").at(0)) || "N/A",
    };
    // Transform it into a string - so that we don't send it more than once per session.
    const lgHash = `${sessionData.identity.id}:${JSON.stringify(lgData)}`;
    if (lastLgState === lgHash) return;
    // Set the last known state, identify.
    setLastLgState(lgHash);
    LogRocket.identify(sessionData.identity.id, lgData);
  }, [sessionData, lastLgState]);

  // Log out function provided to consumers. Note that the order of these operations is important:
  const logout = useCallback(() => {
    console.debug("Logging out...");
    // Delete all stored sessions.
    LS.sessions.clearAll(chainId);
    // Reset our session state.
    setSessionData(undefined);
  }, [setSessionData, chainId]);

  // Authentication function provided to consumers.
  const authenticate = useCallback(
    (input: AuthenticateInput) => {
      setIsLoading(true);

      return authenticateMutation({ variables: { input } })
        .then((res) => {
          if (!res.data?.authenticate) throw new Error("Empty authentication response.");
          const { session } = res.data.authenticate;
          // Store the session in local storage.
          LS.sessions.set(chainId, session);
          // Set the session data and credentials.
          setSessionData(session);
          return true;
        })
        .catch((err) => {
          handleError("Authentication error", err);
          setSessionData(undefined);
          return false;
        })
        .finally(() => setIsLoading(false));
    },
    [chainId, authenticateMutation, handleError, setSessionData],
  );

  // Whenever the signer address changes, we want to check whether there's a session matching it.
  // This effect does that.
  useEffect(() => {
    // Try to get session data from local storage.
    const lsSession = LS.sessions.get(chainId, signerAddress);

    // No session found in local storage. Clear the current session and credentials.
    if (!lsSession) {
      console.debug(`No session found in local storage matching (${signerAddress}) - authentication needed.`);
      setSessionData(undefined);
      return;
    }
    // A session was found in storage, but we have no session locally. Set the session data and the credentials.
    else if (!sessionData) {
      console.debug(`Setting session from local storage (${signerAddress}).`);
      setSessionData(lsSession);
      return;
    } else if (sessionData.bearerToken !== lsSession.bearerToken) {
      console.debug(`Session (${sessionData.identity.ethAddress}) set, doesn't match signer (${signerAddress}).`);
      setSessionData(lsSession);
      return;
    }
    // There could be a weird case where the session we have in local storage doesn't match the ethereum address.
    // This shouldn't happen, but if it does, we should clear the session.
    else if (sessionData.identity.ethAddress !== lsSession.identity.ethAddress) {
      console.warn(`Identity mismatch.`);
      LS.sessions.clear(chainId, signerAddress);
      setSessionData(undefined);
      return;
    }

    // The query below seems like redundant, so commented it out.
    // If this code turns out useful, uncomment this or wipe it off.
    // console.debug(`Existing session found for signer ${signerAddress} - checking validity...`);
    // meQuery()
    //   .then((res) => {
    //     const { data: resData, error } = res;
    //     if (!resData?.me) {
    //       // Only clear state if this is an unauthorized response as we don't want
    //       // to clear local storage if the error was a network problem or a bug in
    //       // our app.
    //       if (error?.message == "unauthorized") {
    //         console.info("Bearer token seems to be invalid.");
    //         LS.sessions.clear(chainId, signerAddress);
    //         setSessionData(undefined);
    //       }
    //       return;
    //     }

    //     // We are now guaranteed to have enough to build the session data.
    //     const identity = resData.me;
    //     // Store the refreshed session data.
    //     LS.sessions.set(chainId, { ...lsSession, identity });
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //     setSessionData(undefined);
    //     handleError("Session Fetch", err);
    //   });
  }, [chainId, signerAddress, meQuery, handleError, logout, sessionData, setSessionData]);

  return meLoading ? (
    <Loader label="Loading Session..." withLogo />
  ) : (
    <Provider value={{ isLoading, authenticate, logout }}>{children}</Provider>
  );
};
