import { writeContract } from "@wagmi/core";
import { Button, Col, Form, Input, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { fastABI } from "../../../__generated__/contracts";
import { DecimalStringRule, RequiredFieldRule } from "../../../helpers/utils";
import { BigNumberFormatter } from "../../../hooks/useBigNumberFormatter";
import { useErrorHelpers } from "../../../hooks/useErrorHelpers";
import { EthAddress } from "../../../types/ethers";

interface Props {
  readonly address: EthAddress;
  readonly formatter: BigNumberFormatter;
}

interface MintForm {
  readonly amount: `${number}`;
  readonly reference: string;
}

export const Minting = ({ address, formatter }: Props) => {
  const { trackTransaction } = useErrorHelpers();
  const [form] = useForm<MintForm>();
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = ({ amount, reference }: MintForm) => {
    const tx = writeContract({
      abi: fastABI,
      address,
      functionName: "mint",
      args: [formatter.parse(amount), reference],
    });
    void trackTransaction(tx, "Minting", "Minting successful.", setIsLoading);
  };

  return (
    <section>
      <h2>Minting</h2>
      <Form
        form={form}
        layout="vertical"
        requiredMark
        onFinish={onFinish}
        disabled={isLoading}
        className="FastManagement-FormItem">
        <Row align="bottom">
          <Col xl={7}>
            <Form.Item
              name="amount"
              label="Amount"
              required={true}
              rules={[...RequiredFieldRule, ...DecimalStringRule]}>
              <Input type="number" placeholder="Type an amount." />
            </Form.Item>
          </Col>
          <Col xl={7} offset={1}>
            <Form.Item name="reference" label="Reference" required={true}>
              <Input type="string" placeholder="Freeform..." />
            </Form.Item>
          </Col>
          <Col offset={1}>
            <Button htmlType="submit">Mint</Button>
          </Col>
        </Row>
      </Form>
    </section>
  );
};
