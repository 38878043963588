import { Divider } from "antd";
import { IssuerTabsNavigator } from "./IssuerTabsNavigator";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { OpacityTransition } from "../../components/layout/OpacityTransition";

export const IssuerHeader = () => (
  <OpacityTransition>
    <div className="IssuerHeader">
      <div className="IssuerHeader-Top">
        <Breadcrumbs />
        <IssuerTabsNavigator />
      </div>
      <Divider className="IssuerHeader-Divider" />
    </div>
  </OpacityTransition>
);
