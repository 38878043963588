import { WidgetProps } from "@rjsf/utils";
import { Select } from "antd";
import { JSONSchema7 } from "json-schema";

export const CustomSelect = <T, S extends JSONSchema7>({
  options,
  placeholder,
  value,
  onChange,
  disabled,
}: WidgetProps<T, S>) => (
  <Select
    disabled={disabled}
    className="FastEdit-Select"
    options={options.enumOptions}
    placeholder={placeholder}
    value={value as string}
    onChange={onChange}
  />
);
