import { WriteContractResult, waitForTransaction, writeContract } from "@wagmi/core";
import { Button, Form, Input, Popover, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { memo, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { erc20ABI } from "wagmi";
import { crowdfundABI } from "../../../../../__generated__/contracts";
import { RequiredFieldRule } from "../../../../../helpers/utils";
import { useErrorHelpers } from "../../../../../hooks/useErrorHelpers";
import { useChain } from "../../../../../providers/ChainClient";
import { ERC20Info } from "../../../../../providers/ERC20Fetcher";
import { useFastContext } from "../../../../../providers/FastContractContext/FastContractContext";
import { useFastSymbol } from "../../../../../providers/FastSymbolProvider";
import { EthAddress } from "../../../../../types/ethers";
import { SimpleToast } from "../../../../../types/toasts";

interface FormData {
  readonly amount: `${number}`;
}

interface Props {
  readonly drawdownAddress: EthAddress;
  readonly erc20Info: ERC20Info;
  readonly isLoading: boolean;
  readonly pledges: string;
  readonly setIsLoading: (isLoading: boolean) => void;
}

export const FundingStep = memo(({ drawdownAddress, erc20Info, isLoading, pledges, setIsLoading }: Props) => {
  const { signerAddress } = useChain();
  const [erc20Hint, setErc20Hint] = useState<string>();
  const [investmentAmount, setInvestmentAmount] = useState<`${number}`>();
  const [balance, setBalance] = useState<number>();
  const [approvalTx, setApprovalTx] = useState<WriteContractResult>();
  const [approvalRx, setApprovalRx] = useState<unknown>();
  const [pledgeTx, setPledgeTx] = useState<WriteContractResult>();
  const [pledgeRx, setPledgeRx] = useState<unknown>();
  const {
    membership,
    details: { address: fastAddress },
  } = useFastContext();
  const { symbol } = useFastSymbol();
  const navigate = useNavigate();
  const { handleError } = useErrorHelpers();
  const [form] = useForm<FormData>();

  // We don't have information about balance and going to get it.
  useEffect(() => {
    if (membership.isMember) {
      setBalance(undefined);
      setErc20Hint(undefined);

      erc20Info.contract.read
        .balanceOf([signerAddress])
        .then((bal) => {
          if (bal === undefined) throw new Error("Empty result from contract.");
          setBalance(+erc20Info.formatter.format(bal));
          setErc20Hint(`Balance ${erc20Info.symbol}: ${erc20Info.formatter.format(bal)}`);
        })
        .catch((err) => {
          handleError("Loading currency balance information", err);
        });
    }
  }, [erc20Info.contract.read, erc20Info.formatter, erc20Info.symbol, handleError, membership.isMember, signerAddress]);

  useEffect(() => {
    if (!isLoading || !investmentAmount) return;

    const onErrorOccured = (err: unknown) => {
      handleError("Investment Drawdown Error", err);
      setIsLoading(false);
    };

    // No approval transaction was created. Let's create one.
    if (!approvalTx) {
      SimpleToast.success("New Investment Requested, please confirm");
      writeContract({
        abi: erc20ABI,
        address: erc20Info.address,
        functionName: "approve",
        args: [drawdownAddress, erc20Info.formatter.parse(investmentAmount)],
      })
        .then((tx) => {
          SimpleToast.success(`Allowance Confirmed: ${tx.hash}.`);
          setApprovalTx(tx);
        })
        .catch(onErrorOccured);
    }
    // An approval transaction was created, but it hasn't been mined yet.
    else if (!approvalRx) {
      SimpleToast.success("Processing Allowance");
      waitForTransaction(approvalTx)
        .then((ar) => {
          SimpleToast.success(`Allowance Confirmed: ${ar.transactionHash}.`);
          setApprovalRx(ar);
        })
        .catch(onErrorOccured);
    }
    // An approval transaction was created and mined, but the pledge hasn't been created yet.
    else if (!pledgeTx) {
      SimpleToast.success("Processing Investment request");
      writeContract({
        abi: crowdfundABI,
        address: drawdownAddress,
        functionName: "pledge",
        args: [erc20Info.formatter.parse(investmentAmount)],
      })
        .then((tx) => {
          SimpleToast.success(`Investment Confirmed: ${tx.hash}.`);
          setPledgeTx(tx);
        })
        .catch(onErrorOccured);
    }
    // The pledge transaction was created, but it hasn't been mined yet.
    else if (!pledgeRx) {
      SimpleToast.success("Processing Investment request");
      waitForTransaction(pledgeTx)
        .then((dr) => {
          SimpleToast.success(`Investment Confirmed: ${dr.transactionHash}.`);
          setPledgeRx(dr);
          setIsLoading(false);
          form.resetFields();
        })
        .catch(onErrorOccured);
    }
  }, [
    approvalTx,
    approvalRx,
    drawdownAddress,
    erc20Info.address,
    erc20Info.formatter,
    fastAddress,
    form,
    handleError,
    investmentAmount,
    pledgeTx,
    pledgeRx,
    isLoading,
    navigate,
    setIsLoading,
    symbol,
  ]);

  useEffect(() => {
    // If we have fulfilled cycle of funding, clean all data about this cycle.
    if (pledgeRx) {
      setApprovalTx(undefined);
      setApprovalRx(undefined);
      setPledgeTx(undefined);
      setPledgeRx(undefined);
    }
  }, [pledgeRx]);

  const onFinish = useCallback(
    ({ amount }: FormData) => {
      if (isLoading) return;
      setIsLoading(true);
      setInvestmentAmount(amount);
    },
    [isLoading, setIsLoading],
  );

  return (
    <div className="InvestmentDrawdowns-Step">
      {isLoading ? (
        <div className="Processing">
          <Spin spinning={isLoading} size="large" />
          <h4>
            Please wait,
            <br />
            Do not leave this page
          </h4>
          <p>
            Some wallets may need further
            <br />
            actions before finalising the investment.
          </p>
        </div>
      ) : (
        <>
          <h4>Your Investment</h4>
          <div>Current Commitment</div>
          <p>
            <strong>
              {pledges} {erc20Info.symbol}
            </strong>
          </p>
          <Form autoComplete="off" form={form} layout="vertical" requiredMark onFinish={onFinish}>
            <Form.Item
              extra={erc20Hint}
              label="New Investment amount (Fee included)"
              name="amount"
              rules={[
                ...RequiredFieldRule,
                () => ({
                  validator(_, value) {
                    if (balance && value <= balance && value > 0) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("The amount must be less than the balance and more than 0"));
                  },
                }),
              ]}>
              <Input
                disabled={!balance || isLoading}
                type="number"
                placeholder="Add amount"
                suffix={erc20Info.symbol}
              />
            </Form.Item>
            <Button disabled={!balance || isLoading} htmlType="submit">
              {+pledges > 0 ? "Add to investment" : "Invest"}
              <i className="icon-sign" />
            </Button>
          </Form>
        </>
      )}
      {!balance && membership.isMember && (
        <div className="Reference">
          <div>
            <Popover
              arrowPointAtCenter
              autoAdjustOverflow
              overlayClassName="Funding-Popover"
              placement="topLeft"
              trigger="click"
              content={
                <p>
                  <span>You have the option to choose any public token on the polygon chain for your investments.</span>
                  <span>
                    We have chosen USDC as the default stable coin as it covers over 190 countries and is the closest to
                    making investments in fiat currencies such as USD/GBP/EUR.
                  </span>
                  <a href="https://www.coinbase.com/home" target="_blank">
                    Open Coinbase
                    <i className="icon-new-window" />
                  </a>
                </p>
              }>
              <i className="icon-info-circle" />
            </Popover>
            <strong>DON'T HAVE {erc20Info.symbol}?</strong>
          </div>
        </div>
      )}
    </div>
  );
});
