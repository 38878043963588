import { Button, Steps } from "antd";
import { useState } from "react";
import { Logo } from "./uiElements/Logo";
import { AuthenticationStepItems, AuthenticationSteps } from "./utils";
import { formatDateForSignature } from "../helpers/utils";
import { useErrorHelpers } from "../hooks/useErrorHelpers";
import { useAppContext } from "../providers/APIClient";
import { useChain } from "../providers/ChainClient";
import { useSession } from "../providers/SessionProvider/SessionProvider";
import { useSettings } from "../providers/SettingsProvider/SettingsProvider";

export const Login = () => {
  const {
    chain: { id: chainId },
    signerAddress,
    walletClient,
  } = useChain();
  const { sessionData } = useAppContext();
  const settings = useSettings();
  const { authenticate } = useSession();
  const { handleError } = useErrorHelpers();
  const [isSigning, setIsSigning] = useState(false);

  const onAuthClicked = () => {
    if (sessionData) return;
    setIsSigning(true);

    // Request to sign our digest.
    const transmittedAt = formatDateForSignature(new Date(Date.now()));
    const digest = settings.core.signInSignatureTemplate
      .replace("{{chain_id}}", `${chainId}`)
      .replace("{{iso8601_timestamp}}", transmittedAt)
      .replace("{{realm}}", settings.core.signatureRealm);

    walletClient
      .signMessage({ message: digest })
      .then((ethSignature) => authenticate({ chainId, ethAddress: signerAddress, ethSignature, transmittedAt }))
      .catch((err) => handleError("Authentication", err))
      .finally(() => setIsSigning(false));
  };

  return (
    <div className="Login">
      <Logo />
      <Steps
        className="Signin-Stepper"
        current={AuthenticationSteps.Logging}
        items={AuthenticationStepItems}
        progressDot
        size="small"
      />
      <div className="Login-Card">
        <Button disabled={isSigning} onClick={onAuthClicked}>
          Login
        </Button>
      </div>
    </div>
  );
};
