import { About } from "./About";
import { Crowdfunds } from "./Crowdfunds/index";
import { Distributions } from "./Distributions/index";
import { Edit } from "./Edit";
import { FastOnboarding } from "./FastOnboarding";
import { Management } from "./Management";
import { ApplicationsTable as CommunityApplications } from "./Members/ApplicationsTable";
import { GovernorsTable as CommunityGovernors } from "./Members/GovernorsTable";
import { MembersTable as CommunityMembers } from "./Members/MembersTable";
import { Proposals } from "./Proposals";
import { Top } from "./Top";
import { Transfers } from "./Transfers";
import { Wallet } from "./Wallet";

export const Pages = {
  Top,
  About,
  Edit,
  Wallet,
  CommunityApplications,
  CommunityGovernors,
  CommunityMembers,
  Transfers,
  Distributions,
  Crowdfunds,
  Management,
  FastOnboarding,
  Proposals,
};
