import { Image } from "antd";
import { useDocumentDetails } from "../../../hooks/useDocumentDetails";
import * as Common from "../../../JsonSchemas/common.schema.v1";

export const TeamMember = ({ imageId, fullName, linkedinProfileUrl }: Common.TeamMember) => {
  const details = useDocumentDetails(imageId);

  return (
    <div className="TeamMember">
      <Image src={details ? details.downloadUrl : "DEFAULT"} preview={false} />
      <p>{fullName}</p>
      <a href={linkedinProfileUrl} target="_blank">
        LinkedIn
      </a>
    </div>
  );
};
