import { useEffect, useState } from "react";
import { useErrorHelpers } from "./useErrorHelpers";
import { DocumentDetailsDocumentFragment, useDocumentDetailsLazyQuery } from "../__generated__/graphql";
import { DEFAULT_QUERY_OPTIONS } from "../graphql/defaults";

export const useDocumentDetails = (documentId: string | undefined, setIsLoading?: (loading: boolean) => void) => {
  const { handleError } = useErrorHelpers();
  const [documentDetailsQuery] = useDocumentDetailsLazyQuery(DEFAULT_QUERY_OPTIONS);
  const [document, setDocument] = useState<null | DocumentDetailsDocumentFragment>(null);

  useEffect(() => {
    if (!documentId) return undefined;
    setIsLoading && setIsLoading(true);
    documentDetailsQuery({ variables: { documentId } })
      .then((res) => {
        const node = res.data?.node;
        if (!node) throw Error("Empty response from server.");
        else if (node.__typename != "Document") throw Error("Unexpected response.");
        setDocument(node);
      })
      .catch((err) => handleError("Document Download", err))
      .finally(() => setIsLoading && setIsLoading(false));
  }, [documentDetailsQuery, documentId, setIsLoading, setDocument, handleError]);

  return document;
};
