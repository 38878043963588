import { polygon } from "@wagmi/core/chains";
import { Chain } from "wagmi";
import { Env, Environment } from "./utils";
import { BigNumberFormatter } from "../hooks/useBigNumberFormatter";
import { EthAddress } from "../types/ethers";

export const enum ConnectorNames {
  MetaMask = "MetaMask",
  Web3Auth = "Web3Auth",
}

export const enum TokenNames {
  Matic = "MATIC",
  USDC = "USDC",
}

export interface ExtendedChain extends Chain {
  // For local networks `genesisTimestamp` and `blockFrequency` don't mean much since a new block is
  // only generated on a transaction, thus we default to null values.
  readonly marketplaceAddress: EthAddress;
  readonly issuerAddress: EthAddress;
  readonly apiUrl: string;
  readonly apiWsUrl: string;
  readonly nativeCurrency: {
    readonly name: string;
    readonly symbol: string;
    readonly decimals: number;
    readonly formatter: BigNumberFormatter;
  };
  readonly blockExplorers: {
    readonly default: {
      readonly name: string;
      readonly url: string;
    };
  };
  readonly defaultCurrency: string;
}

const formatter = new BigNumberFormatter(18);

export const SupportedChain: ExtendedChain = (() => {
  switch (Env) {
    case Environment.Production:
      return {
        ...polygon,
        issuerAddress: "0x2F03add7AAbA219405ea7Dc94e4345D99D469FbA",
        marketplaceAddress: "0x1B0C84a86cC6730B583166C93e410ddd4F876328",
        nativeCurrency: { name: "Matic", symbol: "MATIC", decimals: 18, formatter },
        defaultCurrency: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
        blockExplorers: { default: { name: "PolygonScan", url: "https://polygonscan.com" } },
        apiUrl: "https://api.tokensphere.io/graphiql",
        apiWsUrl: "wss://api.tokensphere.io/ws/graphql",
      } as const;
    case Environment.Staging:
    case Environment.Development:
      // SEE: https://github.com/wevm/viem/blob/main/src/chains/definitions/polygonAmoy.ts
      return {
        id: 80_002,
        name: "Polygon Amoy",
        network: "amoy",
        rpcUrls: {
          // This seems to be used internally by Wagmi.
          default: {
            http: ["https://rpc-amoy.polygon.technology"],
          },
          // This seems to be used when adding the chain to Metamask.
          public: {
            http: ["https://rpc-amoy.polygon.technology"],
          },
        },
        marketplaceAddress: "0xC3A6CF54e39E49B7D5e927B96F8f5ba200853C41",
        issuerAddress: "0x385D8298eaCdba2002313fEeA062b1C3dd5da4aD",
        nativeCurrency: { name: "Matic", symbol: "MATIC", decimals: 18, formatter },
        defaultCurrency: "0x41e94eb019c0762f9bfcf9fb1e58725bfb0e7582",
        blockExplorers: { default: { name: "Ok Link", url: "https://www.oklink.com/amoy" } },
        apiUrl: "https://api.dev.tokensphere.io/graphiql",
        apiWsUrl: "wss://api.dev.tokensphere.io/ws/graphql",
        testnet: true,
      } as const;
  }
})();

export const AlchemyClientId = (): string => {
  switch (Env) {
    case Environment.Production:
      return "CgVJ8Cb8wRczY2iT3g64_TpWbNQg4g5v";
    case Environment.Staging:
      return "xVdmLFF4ubq0Uc2-awM1OgrxKHK16loG";
    case Environment.Development:
      return "FRX7_esJwXxUJ0vdBC67aiSQg27tzc-u";
  }
};

export const Web3AuthClientDetails = (): {
  readonly clientId: string;
  readonly net: "cyan";
} => {
  switch (Env) {
    case Environment.Production:
      return {
        clientId: "BHroVrmxGJHvvdaKC9yD-IfJYGIpCSo9b5VVwedeukQPs0z6BapmIMppQEj4KPb1DHNXZaxPJKTI3rEHXtTViqw",
        net: "cyan",
      };
    case Environment.Staging:
    case Environment.Development:
      return {
        clientId: "BHroVrmxGJHvvdaKC9yD-IfJYGIpCSo9b5VVwedeukQPs0z6BapmIMppQEj4KPb1DHNXZaxPJKTI3rEHXtTViqw",
        net: "cyan",
      };
  }
};
