// Web3Auth Libraries
import { CHAIN_NAMESPACES } from "@web3auth/base";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { Web3AuthNoModal } from "@web3auth/no-modal";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import type { OpenloginLoginParams } from "@web3auth/openlogin-adapter";
import { ExtendedChain, Web3AuthClientDetails } from "../../helpers/chain";

export const web3AuthOptionsBuilder = (
  chain: ExtendedChain,
  loginParams: OpenloginLoginParams = { loginProvider: "google" },
) => {
  // Create Web3Auth Instance
  const chainConfig = {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: "0x" + chain.id.toString(16),
    rpcTarget: chain.rpcUrls.default.http[0],
    displayName: chain.name,
    tickerName: chain.nativeCurrency.name,
    ticker: chain.nativeCurrency.symbol,
    blockExplorer: chain.blockExplorers.default.url[0],
  };

  const { clientId, net } = Web3AuthClientDetails();

  const web3AuthInstance = new Web3AuthNoModal({
    chainConfig,
    clientId,
    web3AuthNetwork: net,
  });

  web3AuthInstance.configureAdapter(
    new OpenloginAdapter({
      privateKeyProvider: new EthereumPrivateKeyProvider({ config: { chainConfig } }),
      adapterSettings: {
        network: net,
        uxMode: "redirect",
      },
    }),
  );

  return {
    chains: [chain],
    options: {
      web3AuthInstance,
      loginParams,
    },
  };
};
