import { BigNumber as BJS } from "bignumber.js";
import LogRocket from "logrocket";
import React from "react";
import { createRoot } from "react-dom/client";
import { App } from "./App";
import { Env, Environment, isProduction } from "./helpers/utils";
import reportWebVitals from "./reportWebVitals";
import "./styles/_index.less";

// Configure BigNumber.js so that it rounds down towards zero by default.
BJS.config({ DECIMAL_PLACES: 32, ROUNDING_MODE: BJS.ROUND_DOWN });

if (isProduction) LogRocket.init("leqztp/tokensphere-production");
else if (Env === Environment.Staging) LogRocket.init("leqztp/testing-logrocket");

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <>
    <React.StrictMode>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
      <link href="https://fonts.googleapis.com/css?family=Lato:400,700" rel="stylesheet" type="text/css" />
      <link href="https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;600&display=swap" rel="stylesheet" />
      <App />
    </React.StrictMode>
  </>,
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
