import { Row } from "antd";
import { FacebookShareButton, LinkedinShareButton, TelegramShareButton, TwitterShareButton } from "react-share";

interface Props {
  readonly shareUrl: string;
}

export const SocialButtons = ({ shareUrl }: Props) => {
  return (
    <Row>
      <LinkedinShareButton url={shareUrl}>
        <i className="icon-linkedin" />
      </LinkedinShareButton>
      <TwitterShareButton url={shareUrl}>
        <i className="icon-twitter-x" />
      </TwitterShareButton>
      <FacebookShareButton url={shareUrl}>
        <i className="icon-facebook" />
      </FacebookShareButton>
      <TelegramShareButton url={shareUrl}>
        <i className="icon-telegram" />
      </TelegramShareButton>
    </Row>
  );
};
