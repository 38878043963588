import { getContract, readContract } from "@wagmi/core";
import { Button } from "antd";
import { ColumnsType } from "antd/lib/table";
import classnames from "classnames";
import { useCallback, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { distributionABI, fastABI, useFastDistributionDeployedEvent } from "../../../__generated__/contracts";
import { ResolvableAddress } from "../../../components/ResolvableAddress";
import { PaginatedTable } from "../../../components/tables/PaginatedTable";
import { BigNumberFormatter } from "../../../hooks/useBigNumberFormatter";
import { useAddressesResolver } from "../../../providers/AddressResolver.tsx/AddressResolverProvider";
import { useChain } from "../../../providers/ChainClient";
import * as DistThings from "../../../providers/DistributionProvider/types";
import { erc20Fetcher } from "../../../providers/ERC20Fetcher";
import { useFastContext } from "../../../providers/FastContractContext/FastContractContext";
import { useFastSymbol } from "../../../providers/FastSymbolProvider";
import { useTopContracts } from "../../../providers/TopContractsProvider";
import { Paths, makePath } from "../../../routing";

export const List = () => {
  const { symbol } = useFastSymbol();
  const { chain, walletClient } = useChain();
  const {
    details: { address: fastAddress },
    membership,
  } = useFastContext();
  const { isIssuerMember } = useTopContracts();
  const { resolveAddresses } = useAddressesResolver();
  const navigate = useNavigate();

  const explorer = chain.blockExplorers.default.url;

  useFastDistributionDeployedEvent({
    address: fastAddress,
    listener: ([{ args: eventData }]) => {
      const { distribution: dist } = eventData;
      dist && console.debug(`We detected that a new distribution was deployed at ${dist}. What should we do?`);
    },
  });

  // Simply generates links compliant with `/fast/:symbol/distributions/:address`.
  const distributionLink = useCallback(
    (address: string) => makePath(Paths.Fasts.One.ManageFunds.Distributions.one, { symbol, distribution: address }),
    [symbol],
  );

  const fetchTotal = useCallback(
    () => readContract({ abi: fastABI, address: fastAddress, functionName: "distributionCount" }).then(Number),
    [fastAddress],
  );

  const fetchItems = useCallback(
    (index: number, perPage: number) =>
      // Fetch distribution addresses.
      readContract({
        abi: fastABI,
        address: fastAddress,
        functionName: "paginateDetailedDistributions",
        args: [BigInt(index), BigInt(perPage)],
      })
        .then(([distsDetails]) =>
          Promise.all(
            distsDetails.map((details) => Promise.all([details, erc20Fetcher(details.params.token, walletClient)])),
          ),
        )
        .then((stuff) =>
          stuff.map(([details, erc20Info]): DistThings.Info => {
            const { VERSION: version, addr: address, phase, ...rest } = details;
            return {
              ...rest,
              address,
              version,
              erc20Info,
              phase: DistThings.enforceNumericPhase(phase),
              contract: getContract({ abi: distributionABI, address, walletClient }),
            };
          }),
        )
        // We now are ready to collect addresses and resolve them in the background.
        .then((distributions): ReadonlyArray<DistThings.Info> => {
          // Collect all addresses.
          const addresses = distributions.map((d) => d.params.distributor);
          // Start resolving the addresses.
          const resolving = resolveAddresses(addresses);
          // Return an array of `DistributionThings.Info`.
          return distributions.map((dist) => ({
            ...dist,
            params: { ...dist.params, distributorFullName: resolving[dist.params.distributor] },
          }));
        }),
    [fastAddress, resolveAddresses, walletClient],
  );

  const columns: ColumnsType<DistThings.Info> = useMemo(
    () => [
      {
        title: "Block",
        render: (_, { creationBlock }) => (
          <Link to={`${explorer}/block/${creationBlock.toString()}`} title="View block on block explorer" target="_blank">
            {creationBlock.toString()}
          </Link>
        ),
      },
      {
        title: "Reference",
        render: (_, { address, params: { ref } }) => (
          <>
            {ref}
            <Link to={`${explorer}/address/${address}`} title="View on block explorer" target="_blank">
              <i className="icon-new-window"></i>
            </Link>
          </>
        ),
      },
      {
        title: "Amount",
        render: (_, { params: { total }, erc20Info: { decimals, symbol: currencySymbol } }) => (
          <>
            {new BigNumberFormatter(decimals).format(total, 2)} {currencySymbol}
          </>
        ),
      },
      {
        title: "Distributor",
        render: (_, { params: { distributor, distributorFullName } }) => (
          <ResolvableAddress address={distributor} fullName={distributorFullName} explorer={explorer} />
        ),
      },
      {
        title: "Status",
        render: (_, { phase }) => {
          const formattedPhase = DistThings.formatPhase(phase);
          return (
            <div className={classnames("Distributions-Phase", `Distributions-Phase-${formattedPhase}`)}>
              {formattedPhase}
              {phase === DistThings.Phase.Funding && isIssuerMember && <div>Needs approval</div>}
            </div>
          );
        },
      },
      {
        title: "",
        render: (_, { address, phase }) => {
          const isDisabled = phase === DistThings.Phase.Funding && !isIssuerMember;
          return (
            <Button
              disabled={isDisabled}
              className="Button-Secondary"
              onClick={() => navigate(distributionLink(address))}>
              Details
            </Button>
          );
        },
      },
    ],
    [distributionLink, explorer, isIssuerMember, navigate],
  );

  return (
    <section className="FastTab Distributions">
      <div className="TitleBlockWithButton">
        <h2>Distributions</h2>
        {membership.isGovernor && (
          <Button onClick={() => navigate(Paths.Fasts.One.ManageFunds.Distributions.new.replace(":symbol", symbol))}>
            Create Distribution
          </Button>
        )}
      </div>
      <PaginatedTable columns={columns} fetchItems={fetchItems} fetchTotal={fetchTotal} perPage={10} reverse />
    </section>
  );
};
