import { PropsWithChildren, createContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { createGenericContext } from "./utils";
import { Error } from "../pages/errors/Error";

export type PropsWithSymbolState<P = unknown> = P & { readonly symbol: string };

interface State {
  readonly symbol: string;
  readonly pathSymbol: string;
}

const [useFastSymbol, Provider] = createGenericContext<State>();
const Context = createContext<State>(null as unknown as State);
Context.displayName = "FastSymbolProvider";

// This provider should be used exclusively within routes that are known
// to have a FAST symbol.
// Otherwise, it will throw!
export const FastSymbolProvider = ({ children }: PropsWithChildren) => {
  const { symbol } = useParams();
  if (!symbol) throw "This should never have happened.";

  // As we don't want to re-render if the symbol changes to something we've already
  // seen in the past, we use memoization.
  return useMemo(() => {
    // We don't need state management or anything. We can just work the state
    // out based on the symbol given by `useParams`.
    const state: State = { pathSymbol: symbol, symbol: symbol.toUpperCase() };

    // Invalid symbol... Just render the invalid component.
    if (symbol.length < 3 || symbol.length > 5) {
      console.warn(`An invalid symbol was detected: ${symbol}.`);
      return <Error reason={`Invalid FAST symbol ${symbol}.`} />;
    }

    // Otherwise, if the symbol is valid, we can render the children
    // and other nested route items via Outlet.
    console.debug(`A valid symbol was found: ${symbol}.`);
    return <Provider value={state}>{children}</Provider>;
  }, [symbol, children]);
};

export { useFastSymbol };
