import { Spin } from "antd";

export const AwaitingChainAddition = () => (
  <div className="Confirmation">
    <div className="Confirmation-Process">
      <Spin size="large" />
      <h2>Your application has been accepted!</h2>
      <p>Please hold on - this will only take a couple of minutes.</p>
    </div>
  </div>
);
