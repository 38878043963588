import { Spin } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useCallback, useEffect, useMemo, useState } from "react";
import { StatusColumn } from "./StatusColumn";
import { MembershipRequestState, PageInfo, useMembershipRequestsQuery } from "../../../__generated__/graphql";
import { AddressColumn } from "../../../components/tables/AddressColumn";
import { CursorPaginatedTable } from "../../../components/tables/CursorPaginatedTable";
import { UserColumn } from "../../../components/tables/UserColumn";
import { stringToShortIsoDate } from "../../../helpers/utils";
import { ResolvedIdentity } from "../../../providers/AddressResolver.tsx/AddressResolverProvider";
import { useChain } from "../../../providers/ChainClient";
import { EthAddress } from "../../../types/ethers";
import { useFastDetails } from "../Top";

interface ApplicationDetails {
  readonly id: string;
  readonly ethAddress: EthAddress | string;
  readonly fullName: Promise<ResolvedIdentity> | string;
  readonly issuedAt: string;
  readonly linkedIn?: string | null;
  readonly status: MembershipRequestState;
}

export const ApplicationsTable = () => {
  const [applications, setApplications] = useState<ReadonlyArray<ApplicationDetails>>([]);
  const [pageInfo, setPageInfo] = useState<PageInfo>();
  const [pageSize, setPageSize] = useState(10);
  const { chain } = useChain();
  const {
    fastDetails: { id: fastId },
  } = useFastDetails();
  const { error, data, loading, refetch } = useMembershipRequestsQuery({
    variables: {
      first: pageSize,
      fastId,
      states: [MembershipRequestState.Accepted, MembershipRequestState.Amended, MembershipRequestState.Rejected],
    },
  });

  const explorer = chain.blockExplorers.default.url;

  useEffect(() => {
    if (!data) return;
    const membershipRequests = data.membershipRequests?.edges;
    const fetchedPageInfo = data?.membershipRequests?.pageInfo;

    if (!membershipRequests || !fetchedPageInfo) throw new Error("Unexpected server response");

    // Transform the necessary data from the response.
    const convertedApplications = membershipRequests.reduce<ReadonlyArray<ApplicationDetails>>((acc, request) => {
      const membershipRequest = request?.node;
      if (!membershipRequest) return acc;

      const { ethAddress, fullName, linkedIn } = membershipRequest.applicant
        ? {
            ethAddress: membershipRequest.applicant.ethAddress || "",
            fullName: membershipRequest.applicant.fullName || "",
            linkedIn: membershipRequest.applicant.linkedIn,
          }
        : { ethAddress: "", fullName: "", linkedIn: "" };

      return [
        ...acc,
        {
          id: membershipRequest.id,
          ethAddress,
          fullName,
          issuedAt: membershipRequest.issuedAt,
          linkedIn,
          status: membershipRequest.state,
        },
      ];
    }, []);
    setApplications(convertedApplications);
    setPageInfo(fetchedPageInfo);
  }, [data]);

  const updateMembershipRequests = useCallback(() => refetch({ first: pageSize }), [pageSize, refetch]);

  const columns: ColumnsType<ApplicationDetails> = useMemo(
    () => [
      {
        title: "Received on",
        render: (_, { issuedAt }) => stringToShortIsoDate(issuedAt),
      },
      {
        title: "User",
        render: (_, { ethAddress, fullName, linkedIn }) => (
          <UserColumn ethAddress={ethAddress} explorer={explorer} fullName={fullName} linkedIn={linkedIn} />
        ),
      },
      {
        title: "Address",
        render: (_, { ethAddress }) => <AddressColumn ethAddress={ethAddress} />,
      },
      {
        title: "",
        render: (_, { id, status }) => (
          <StatusColumn id={id} status={status} updateMembershipRequests={updateMembershipRequests} />
        ),
      },
    ],
    [explorer, updateMembershipRequests],
  );

  return (
    <section className="FastTab Community">
      <h3>All members requests</h3>
      <Spin spinning={loading} size="large">
        {pageInfo && (
          <CursorPaginatedTable
            columns={columns}
            data={applications}
            error={error}
            pageInfo={pageInfo}
            pageSize={pageSize}
            refetch={refetch}
            setPageSize={setPageSize}
          />
        )}
      </Spin>
    </section>
  );
};
