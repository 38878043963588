import { Button } from "antd";
import classnames from "classnames";
import { memo, useState } from "react";
import { Link } from "react-router-dom";
import { CompleteDrawdownModal } from "./CompleteDrawdownModal";
import { ResolvableAddress } from "../../../../components/ResolvableAddress";
import { useModalState } from "../../../../hooks/useModalState";
import { Params, Phase, formatPhase } from "../../../../providers/CrowdfundProvider/types";
import { useTopContracts } from "../../../../providers/TopContractsProvider";
import { EthAddress } from "../../../../types/ethers";
import { AbortInvestmentDrawdown, CloseInvestmentDrawdown, CompleteDrawdown, InvestmentDrawdownNames } from "../types";

interface OverviewProps {
  readonly drawdownAddress: EthAddress;
  readonly explorer: string;
  readonly isLoading: boolean;
  readonly names: InvestmentDrawdownNames;
  readonly params: Params;
  readonly phase: Phase;
}

export const Overview = memo(({ drawdownAddress, explorer, isLoading, names, params, phase }: OverviewProps) => {
  const { isIssuerMember } = useTopContracts();
  const completeDrawdownModalState = useModalState(false);
  const [modalType, setModalType] = useState(CloseInvestmentDrawdown);
  const { basisPointsFee, beneficiary, owner } = params;
  const formattedPhase = formatPhase(phase);

  const handleOpenModal = (type: CompleteDrawdown) => () => {
    setModalType(type);
    completeDrawdownModalState.open();
  };

  return (
    <div className="InvestmentDrawdowns-Overview">
      <div className={classnames("InvestmentDrawdowns-Phase", `InvestmentDrawdowns-Phase-${formattedPhase.status}`)}>
        {formattedPhase.status}
      </div>
      {phase === Phase.Failure && <p>The admin has aborted the investment. Please withdraw your pledged funds.</p>}
      {phase !== Phase.Setup && (
        <>
          <div>
            Requested by:&nbsp;
            <ResolvableAddress
              address={owner}
              fullName={names.ownerFullName}
              explorer={explorer}
              withCopyButton={false}
            />
          </div>
          <div>
            Recipient:&nbsp;
            <ResolvableAddress
              address={beneficiary}
              fullName={names.beneficiaryFullName}
              explorer={explorer}
              withCopyButton={false}
            />
          </div>
          <div>Fee: {basisPointsFee / 100}%</div>
          <div>
            <Link to={`${explorer}/address/${drawdownAddress}`} target="_blank">
              View on block explorer
            </Link>
          </div>
        </>
      )}
      {isIssuerMember && phase == Phase.Funding && (
        <div className="ButtonPairWrapper">
          <Button disabled={isLoading} onClick={handleOpenModal(CloseInvestmentDrawdown)}>
            Close
          </Button>
          <Button disabled={isLoading} onClick={handleOpenModal(AbortInvestmentDrawdown)}>
            Abort
          </Button>
          <CompleteDrawdownModal
            drawdownAddress={drawdownAddress}
            refValue={params.ref}
            state={completeDrawdownModalState}
            type={modalType}
          />
        </div>
      )}
    </div>
  );
});
