import { Link } from "react-router-dom";

interface Props {
  readonly hasFixedSupply: boolean;
  readonly memberCount: bigint;
  readonly name: string;
  readonly path: string;
  readonly readableTotalSupply: string;
  readonly symbol: string;
}

export const FastCard = ({ hasFixedSupply, memberCount, name, path, readableTotalSupply, symbol }: Props) => (
  <Link className="FastCard" to={path}>
    <div className="FastCard-Name">
      <h1>{symbol}</h1>
      <h4>{name}</h4>
    </div>
    <div>
      <p>Members: {memberCount.toString()}</p>
      <p>Total supply: {readableTotalSupply}</p>
      <p>
        {hasFixedSupply ? (
          "Fixed"
        ) : (
          <>
            Growing <i className="icon-coins" />
          </>
        )}
      </p>
    </div>
  </Link>
);
