import { CGEmail } from "../../../../helpers/utils";

export const WhyFast = () => (
  <div className="WhyFast">
    <h2>Why Fast?</h2>
    <p>
      FAST is the cornerstone to the development of a stakeholder economy; securitisation of assets within a community
      aligns interests and enables members to benefit when they contribute to the creation of value.
    </p>
    <a href="https://openvc.app/blog/vc-fund-fast" target="_blank">
      Learn more about FAST
    </a>
    <h3>It creates better incentive systems without disrupting the organisation</h3>

    <div className="WhyFast-Areas">
      <div className="WhyFast-Area">
        <h4>VCs</h4>
        <p>FAST</p>
        <ul>
          <li>
            <span>Grow</span> portfolio value
          </li>
          <li>
            <span>Reward</span> network and offer early liquidity
          </li>
        </ul>
      </div>

      <div className="WhyFast-Area">
        <h4>Real Estates</h4>
        <p>FAST</p>
        <ul>
          <li>
            <span>Fund</span> bigger projects without being listed
          </li>
          <li>
            <span>Reward</span> labour and align interests
          </li>
        </ul>
      </div>

      <div className="WhyFast-Area">
        <h4>Professional services</h4>
        <p>FAST</p>
        <ul>
          <li>
            <span>Unlock</span> services for equity
          </li>
          <li>
            <span>Bring</span> network value on the balance sheet
          </li>
        </ul>
      </div>
    </div>

    <p>
      Please <a href={`mailto:${CGEmail}`}>contact us</a> if you have any question.
    </p>
  </div>
);
