import { useMemo } from "react";
import { CGEmail } from "../../helpers/utils";
import { HeaderLink } from "../../pages/fast/FastHeader/HeaderButtons/common";
import { PropsWithSymbolState } from "../../providers/FastSymbolProvider";

export const RequestMembershipButton = ({ symbol }: PropsWithSymbolState) => {
  const subject = `Request to Join ${symbol}'s private Web3 network`;
  const body = `Hello,\rI'd like to join a particular FAST ${symbol} of the FAST community. Please let me know the next steps.\rThank you.`;

  const emailHref = useMemo(() => {
    const encodedSubject = encodeURI(subject);
    const encodedBody = encodeURI(body);
    return `mailto:${CGEmail}?subject=${encodedSubject}&body=${encodedBody}`;
  }, [subject, body]);

  return <HeaderLink name="Request Membership" className="Button" href={emailHref} />;
};
