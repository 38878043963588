import { ApolloQueryResult } from "@apollo/client";
import { Button, Popover } from "antd";
import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { MembershipRequestState, MembershipRequestsQuery } from "../../../__generated__/graphql";
import { ChangeApplicationStatusModal } from "../../../components/modals/ChangeApplicationStatusModal";
import { ChangeStatusModalType } from "../../../components/modals/types";
import { CGEmail } from "../../../helpers/utils";
import { useModalState } from "../../../hooks/useModalState";

interface Props {
  readonly id: string;
  readonly status: MembershipRequestState;
  readonly updateMembershipRequests: () => Promise<ApolloQueryResult<MembershipRequestsQuery>>;
}

export const StatusColumn = ({ id, status, updateMembershipRequests }: Props) => {
  const [modalType, setModalType] = useState<ChangeStatusModalType>("Accept");
  const changeApplicationStatusModalState = useModalState(false);

  const handleOpenModal = useCallback(
    (type: ChangeStatusModalType) => () => {
      setModalType(type);
      changeApplicationStatusModalState.open();
    },
    [changeApplicationStatusModalState],
  );

  const renderStatus = useCallback(() => {
    switch (status) {
      case MembershipRequestState.Amended:
        return (
          <div className="ButtonPairWrapper">
            <Button onClick={handleOpenModal("Reject")}>Reject</Button>
            <Button onClick={handleOpenModal("Accept")}>Accept</Button>
            <ChangeApplicationStatusModal
              id={id}
              state={changeApplicationStatusModalState}
              type={modalType}
              updateMembershipRequests={updateMembershipRequests}
            />
          </div>
        );
      case MembershipRequestState.Accepted:
        return <div className="GreenText">Accepted</div>;
      case MembershipRequestState.Rejected:
        return (
          <div className="StatusColumn-Rejected">
            <div className="RedText">Rejected</div>
            <Popover
              overlayClassName="StatusColumn-Overlay"
              autoAdjustOverflow
              placement="top"
              trigger="click"
              content={
                <div>
                  If you now want to accept this user, please email them to send you a new request
                  <Link to={`mailto:${CGEmail}`}> Send email</Link>
                </div>
              }>
              <Button>
                <i className="icon-info-circle" />
              </Button>
            </Popover>
          </div>
        );
    }
  }, [changeApplicationStatusModalState, handleOpenModal, id, modalType, status, updateMembershipRequests]);

  return <>{renderStatus()}</>;
};
