import { useCallback, useState } from "react";

export const useDisplayingDecimals = (decimals: number) => {
  const [numberOfDecimals, setNumberOfDecimals] = useState(2);

  const changeNumberOfDecimals = useCallback(() => {
    setNumberOfDecimals((prev) => (prev === 2 ? decimals : 2));
  }, [decimals]);

  return { numberOfDecimals, changeNumberOfDecimals };
};
