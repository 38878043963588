import { ApolloError } from "@apollo/client";
import { Button, Form, Image, Input, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import Modal from "antd/lib/modal/Modal";
import { useState } from "react";
import { useIssueOnboardMutation } from "../../../__generated__/graphql";
import { RequiredEmailRule } from "../../../helpers/utils";
import { useErrorHelpers } from "../../../hooks/useErrorHelpers";
import { PropsWithModalState } from "../../../hooks/useModalState";
import { useFastContext } from "../../../providers/FastContractContext/FastContractContext";
import { SimpleToast } from "../../../types/toasts";
import { useFastDetails } from "../Top";

interface SingleValueFormType {
  readonly emailAddress: string;
}

export const InviteToFastModal = ({ state }: PropsWithModalState) => {
  const { isVisible, close } = state;
  const { details: fastContractDetails } = useFastContext();
  const { name, symbol } = fastContractDetails;
  const { fastDetails } = useFastDetails();
  const [form] = useForm();
  const { handleError } = useErrorHelpers();
  const [issueOnboard] = useIssueOnboardMutation();
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = ({ emailAddress }: SingleValueFormType) => {
    setIsLoading(true);
    issueOnboard({ variables: { input: { emailAddress, fastId: fastDetails.id } } })
      .then((res) => {
        if (!res.data?.issueOnboard) throw new Error("Invalid response from server.");
        form.resetFields();
        close();
        SimpleToast.success("Your invitation has been sent");
      })
      .catch((err: ApolloError) => handleError(`An error occured while inviting to ${name}`, err))
      .finally(() => setIsLoading(false));
  };

  return (
    <Modal open={isVisible} centered onCancel={close} footer={null} width="fit-content">
      <div className="FastAbout-InviteModal">
        <div className="FastPlaque">
          {fastDetails.logoUrl && <Image alt="Fast Logo" src={fastDetails.logoUrl} preview={false} />}
          <div>
            <h2>
              Invite to <span className="RedText">{symbol}</span>
            </h2>
            <h2>
              From <span className="RedText">{name}</span>
            </h2>
          </div>
        </div>

        <p>
          Only invite members who you think would be a valuable addition to your network.
          <br />
          Your success is closely tied to the value added by other members in the community.
        </p>

        <Spin spinning={isLoading} size="large">
          <Form form={form} layout="vertical" requiredMark onFinish={onFinish}>
            <Form.Item label="Invitee Email" name="emailAddress" rules={[...RequiredEmailRule]}>
              <Input type="string" placeholder="Type an email..." />
            </Form.Item>

            <div className="ButtonPairWrapper">
              <Button onClick={close}>Cancel</Button>
              <Button htmlType="submit">Send invite</Button>
            </div>
          </Form>
          {/* Hidden for v0 */}
          {/* <Link to="#">Edit Invitation Template</Link> */}
        </Spin>
      </div>
    </Modal>
  );
};
