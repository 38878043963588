import { useErrorHelpers } from "./useErrorHelpers";

export const useUrlDownloader = (downloadUrl: string | null, setIsLoading?: (isLoading: boolean) => void) => {
  const { handleError } = useErrorHelpers();
  return downloadUrl
    ? () => {
        setIsLoading && setIsLoading(true);
        fetch(downloadUrl)
          .then((res) => res.blob())
          .then((blob) => {
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "";
            link.click();
          })
          .catch((err) => handleError("Document Upload", err))
          .finally(() => setIsLoading && setIsLoading(false));
      }
    : null;
};
