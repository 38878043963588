import { PropsWithChildren } from "react";
import { UserButtons } from "./FastHeader/HeaderButtons/UserButtons";
import { FastTabsNavigator } from "../../components/layout/FastTabNavigator";
import { useFastContext } from "../../providers/FastContractContext/FastContractContext";
import { useFastSymbol } from "../../providers/FastSymbolProvider";

export const FastPageWrapper = ({ children }: PropsWithChildren) => {
  const { symbol } = useFastSymbol();
  const { details, membership } = useFastContext();

  return (
    <>
      <div className="FastHeaderButtons">
        <UserButtons symbol={symbol} address={details.address} decimals={details.decimals} membership={membership} />
      </div>

      <article className="PageContent">
        <FastTabsNavigator>{children}</FastTabsNavigator>
      </article>
    </>
  );
};
