import { Button, Form, Input } from "antd";
import { WhyFast } from "./WhyFast";
import { useIssueOnboardMutation } from "../../../../__generated__/graphql";
import { CurveBackgroundMini } from "../../../../assets";
import { RequiredEmailRule } from "../../../../helpers/utils";

interface SingleValueFormType {
  readonly emailAddress: string;
}

interface Props {
  readonly onContinue: () => void;
}

export const Introduction = ({ onContinue }: Props) => {
  const [mutation] = useIssueOnboardMutation();

  const onFinish = ({ emailAddress }: SingleValueFormType) => {
    void mutation({ variables: { input: { emailAddress } } }).then(onContinue);
  };

  return (
    <>
      <div className="Introduction">
        <h4>Your application to Join</h4>
        {/* <div className="Introduction-Logo">
          <Image alt="Company Logo" src={Clipboard} preview={false} />
          <div>
            <h2>Consilience ventures</h2>
            <h2>CVDS</h2>
          </div>
        </div> */}
        <Form layout="vertical" requiredMark onFinish={onFinish}>
          <Form.Item label="Enter your Email" name="emailAddress" rules={[...RequiredEmailRule]}>
            <Input type="string" placeholder="Type an email..." />
          </Form.Item>
          <Button htmlType="submit">Continue</Button>
        </Form>
        <p>
          We advise you to use your primary email to not miss out on any important communications related to the FASTs
          you participate in.
        </p>
        <img className="CurveBackgroundMini" alt="background-curve" src={CurveBackgroundMini} />
      </div>
      <WhyFast />
    </>
  );
};
