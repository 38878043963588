import { CrowdfundContract } from "../../types/contracts";
import { EthAddress } from "../../types/ethers";
import { ResolvedIdentity } from "../AddressResolver.tsx/AddressResolverProvider";
import { ERC20Info } from "../ERC20Fetcher";

export enum Phase {
  Setup = 0,
  Funding = 1,
  Success = 2,
  Failure = 3,
}

enum Status {
  Aborted = "Aborted",
  Closed = "Closed",
  Open = "Open",
  Requested = "Requested",
}

enum ButtonLabel {
  Details = "Details",
  Invest = "Invest",
  Review = "Review",
}

type FormattedPhase = {
  buttonLabel: ButtonLabel;
  status: Status;
};

export const formatPhase = (phase: Phase | number): FormattedPhase => {
  switch (phase) {
    case Phase.Setup:
      return {
        buttonLabel: ButtonLabel.Review,
        status: Status.Requested,
      };
    case Phase.Funding:
      return {
        buttonLabel: ButtonLabel.Invest,
        status: Status.Open,
      };
    case Phase.Success:
      return {
        buttonLabel: ButtonLabel.Details,
        status: Status.Closed,
      };
    case Phase.Failure:
      return {
        buttonLabel: ButtonLabel.Details,
        status: Status.Aborted,
      };
    default:
      throw Error(`Unknown Crowdfund phase ${phase}.`);
  }
};

export const enforceNumericPhase = (phase: number): Phase => {
  if (phase < Phase.Setup || phase > Phase.Failure) throw new Error(`Invalid phase: ${phase}`);
  return phase;
};

export interface Params {
  readonly owner: EthAddress;
  readonly issuer: EthAddress;
  readonly fast: EthAddress;
  readonly beneficiary: EthAddress;
  readonly basisPointsFee: number;
  readonly token: EthAddress;
  readonly ref: string;
  // Augmented at runtime.
  readonly ownerFullName?: Promise<ResolvedIdentity>;
  readonly beneficiaryFullName?: Promise<ResolvedIdentity>;
}

// TODO: We probably want this type to be a union of the different
// phases, so that we only query data minimalistically.
export interface Info {
  readonly address: EthAddress;
  readonly contract: CrowdfundContract;
  readonly version: number;
  readonly params: Params;
  readonly erc20Info: ERC20Info;
  readonly phase: Phase;
  readonly creationBlock: bigint;
  readonly collected: bigint;
  readonly pledgerCount: bigint;
}
