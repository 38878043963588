import { WidgetProps } from "@rjsf/utils";
import { Select } from "antd";
import { JSONSchema7 } from "json-schema";

interface OptionType {
  readonly label: string;
  readonly value: string;
}

// This function simply maps selected values into an array of objects that the Select component can understand.
const stringsToOptionTypes = (values: ReadonlyArray<string>): OptionType[] =>
  values.map((value: string) => ({ label: value, value: value }));

// This function explicitly takes the list of possible values upfront and returns
// a component that can be used as a widget for the JSON Schema Form.
export const EnumMultiSelect =
  <T, S extends JSONSchema7>(values: ReadonlyArray<string>) =>
  ({ value, onChange, disabled }: WidgetProps<T, S>) => {
    return (
      <Select
        disabled={disabled}
        className="FastEdit-MultipleSelect"
        mode="multiple"
        options={stringsToOptionTypes(values)}
        value={stringsToOptionTypes(value as ReadonlyArray<string>)}
        onChange={onChange}
      />
    );
  };

// This component infers possible values based on schema enums.
export const MultiSelect = ({ value, schema, onChange }: WidgetProps) => {
  // Somehow the enum definition isn't correctly defined in the schema.
  if (!schema.items || schema.items == true || Array.isArray(schema.items)) {
    console.warn("MultiSelect widget didn't receive a valid enum schema definition.", schema);
    return null;
  }

  const enumSchema = schema.items.enum || [];
  return (
    <Select
      className="FastEdit-MultipleSelect"
      mode="multiple"
      options={stringsToOptionTypes(enumSchema as ReadonlyArray<string>)}
      value={stringsToOptionTypes(value as ReadonlyArray<string>)}
      onChange={onChange}
    />
  );
};
