import { writeContract } from "@wagmi/core";
import { Button, Col, Form, Input, Row, Spin } from "antd";
import { useForm, useWatch } from "antd/lib/form/Form";
import { useCallback, useEffect, useState } from "react";
import { DrawdownCreatedModal } from "./View/DrawdownCreatedModal";
import { fastABI } from "../../../__generated__/contracts";
import { AddressResolverInput } from "../../../components/AddressResolverInput/AddressResolverInput";
import { ChangeTokenModal } from "../../../components/modals/ChangeTokenModal";
import { RequiredFieldRule } from "../../../helpers/utils";
import { paginateDetailedMembers } from "../../../helpers/wagmiActions";
import { useErrorHelpers } from "../../../hooks/useErrorHelpers";
import { useModalState } from "../../../hooks/useModalState";
import { useChain } from "../../../providers/ChainClient";
import { erc20Fetcher } from "../../../providers/ERC20Fetcher";
import { useFastContext } from "../../../providers/FastContractContext/FastContractContext";
import { EthAddress, isEthAddress } from "../../../types/ethers";
import { SimpleToast } from "../../../types/toasts";

interface FormData {
  readonly ref: string;
  readonly beneficiary: EthAddress;
  readonly token: EthAddress;
}

export const New = () => {
  const { chain, walletClient } = useChain();
  const drawdownCreatedModalState = useModalState(false);
  const changeCurrencyModalState = useModalState(false);
  const [erc20Hint, setErc20Hint] = useState<string>();
  const [erc20Error, setErc20Error] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [beneficiariesAddresses, setBeneficiariesAddresses] = useState<ReadonlyArray<EthAddress>>([]);
  const [token, setToken] = useState<string>(chain.defaultCurrency);
  const [form] = useForm<FormData>();
  const refValue = useWatch("ref", form);
  const { handleError } = useErrorHelpers();
  const {
    details: { address: fastAddress, memberCount },
  } = useFastContext();

  useEffect(() => {
    setErc20Error(undefined);
    setErc20Hint(undefined);

    if (!isEthAddress(token)) return;
    // Fetch the ERC20 info.
    erc20Fetcher(token, walletClient)
      .then((info) => {
        if (info === undefined) throw new Error("Empty result from contract.");
        setErc20Hint(`Token symbol: ${info.symbol}`);
      })
      .catch((err) => {
        handleError("Loading Token Info", err);
        setErc20Error("Token address not found, please try again");
      });
  }, [token, handleError, walletClient]);

  // We should get valid user addresses for this FAST for a list of recipients.
  useEffect(
    () => void paginateDetailedMembers(fastAddress, memberCount, setBeneficiariesAddresses),
    [fastAddress, memberCount],
  );

  const onFinish = useCallback(
    (formData: FormData) => {
      if (isEthAddress(token)) {
        setIsLoading(true);
        writeContract({
          abi: fastABI,
          address: fastAddress,
          functionName: "createCrowdfund",
          args: [token, formData.beneficiary, formData.ref],
        })
          .then((tx) => {
            SimpleToast.success(`Investment Drawdown request successful: ${tx.hash}.`);
            drawdownCreatedModalState.open();
          })
          .catch((err) => {
            handleError("Investment Drawdown request unsuccessful", err);
          })
          .finally(() => setIsLoading(false));
      }
    },
    [drawdownCreatedModalState, fastAddress, handleError, token],
  );

  return (
    <>
      <div className="Content-Header">
        <h1>New Investment Drawdown</h1>
      </div>
      <section className="Content-Body InvestmentDrawdowns">
        <Spin spinning={isLoading} size="large">
          <Form className="InvestmentDrawdowns-New" form={form} layout="vertical" requiredMark onFinish={onFinish}>
            <Row>
              <Col span={12}>
                <Form.Item label="Reference / SAFT ID" name="ref" rules={[...RequiredFieldRule]}>
                  <Input autoComplete="off" type="string" showCount maxLength={30} placeholder="Reference Text" />
                </Form.Item>
                <Form.Item
                  className="AddressResolverWithLabel"
                  label="Select Recipient"
                  name="beneficiary"
                  rules={[...RequiredFieldRule]}>
                  <AddressResolverInput ethAddresses={beneficiariesAddresses} id="beneficiary" />
                </Form.Item>
                <div className="SetCurrency">
                  <label>Token contract address</label>
                  <Input disabled type="string" placeholder="Set Token" value={token} />
                  <Button className="SetCurrency-Button" onClick={changeCurrencyModalState.open}>
                    Change
                  </Button>
                  <p className="SetCurrency-Extra">{erc20Hint}</p>
                </div>
              </Col>
            </Row>

            <p>
              We recommend USDC as the default currency given its coverage of over 190 countries and ease of on and off
              ramp.
            </p>

            {/* Hide this for v0 version. */}
            {/* <Collapse ghost>
            <Collapse.Panel key="1" header="Learn more">
              <p>Blah blah blah</p>
            </Collapse.Panel>
          </Collapse> */}

            <Button disabled={isLoading} htmlType="submit">
              Create Draft
              <i className="icon-sign" />
            </Button>
          </Form>
        </Spin>
        <DrawdownCreatedModal refValue={refValue} state={drawdownCreatedModalState} />
        <ChangeTokenModal
          erc20Hint={erc20Hint}
          erc20Error={erc20Error}
          token={token}
          setToken={setToken}
          state={changeCurrencyModalState}
        />
      </section>
    </>
  );
};
