import { ApolloError } from "@apollo/client";
import { Button, Form, Input } from "antd";
import {
  OnboardingOnboardFragment,
  OnboardingSettingsFragment,
  useClaimOnboardMutation,
  useOnboardingSettingsLazyQuery,
} from "../../../../__generated__/graphql";
import { CGEmail, RequiredFieldRule } from "../../../../helpers/utils";
import { useErrorHelpers } from "../../../../hooks/useErrorHelpers";

interface Props {
  readonly onboardId: string;
  readonly refreshOnboard: (onboard?: OnboardingOnboardFragment) => void;
  readonly setSettings: (settings: OnboardingSettingsFragment) => void;
}

interface FormData {
  readonly claimToken: string;
}

export const ClaimOnboard = ({ onboardId, refreshOnboard, setSettings }: Props) => {
  const { handleError } = useErrorHelpers();
  const [claimOnboard] = useClaimOnboardMutation();
  const [settingsQuery] = useOnboardingSettingsLazyQuery();

  const onFinish = ({ claimToken }: FormData) => {
    let fetchedOnboard: OnboardingOnboardFragment | undefined;

    claimOnboard({ variables: { input: { onboardId, claimToken } } })
      .then((claimOnboardResponse) => {
        if (!claimOnboardResponse.data?.claimOnboard) throw new Error("Invalid response from server.");
        fetchedOnboard = claimOnboardResponse.data?.claimOnboard?.onboard;
        return settingsQuery({ variables: { claimToken, onboardId } });
      })
      // Request onboarding settings for the next steps.
      .then((settingsResponse) => {
        if (!settingsResponse.data?.onboardingSettings) throw new Error("Invalid response from server.");
        setSettings(settingsResponse.data?.onboardingSettings);
        refreshOnboard(fetchedOnboard);
      })
      .catch((err: ApolloError) =>
        handleError(err.graphQLErrors[0]?.path?.includes("claimOnboard") ? "Invalid token" : "Unknown error", err),
      );
  };

  return (
    <div className="EmailVerification Padding">
      <h2>Verify your email</h2>
      <p>We have sent a verification code to your email address.</p>

      <Form autoComplete="off" layout="vertical" requiredMark onFinish={onFinish}>
        <Form.Item label="Enter verification code" name="claimToken" rules={[...RequiredFieldRule]}>
          <Input type="string" placeholder="ex: 6174e841" />
        </Form.Item>
        <Button htmlType="submit">Submit</Button>
      </Form>

      <p>
        If your email address is incorrect, please <a href={`mailto:${CGEmail}`}>contact us.</a>
      </p>
    </div>
  );
};
