import classnames from "classnames";
import { DetailedHTMLProps, HTMLAttributes } from "react";

interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  readonly group?: string;
  readonly symbol?: string;
}

export const NetworkName = ({ group, symbol, ...divProps }: Props) => (
  <div {...divProps} className={classnames(divProps.className, "H2 Bold", "NetworkName")}>
    <div>{group || symbol || "FAST"}</div>
    <div className="Opaque">NETWORK</div>
  </div>
);
