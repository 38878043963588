import { Button, Modal } from "antd";
import { Link } from "react-router-dom";
import { PropsWithModalState } from "../../../../hooks/useModalState";
import { useChain } from "../../../../providers/ChainClient";
import { ERC20Info } from "../../../../providers/ERC20Fetcher";
import { useFastSymbol } from "../../../../providers/FastSymbolProvider";

interface Props {
  readonly blockNumber: bigint;
  readonly erc20Info: ERC20Info;
  readonly total: `${number}`;
  readonly setIsLoading: (isLoading: boolean) => void;
  readonly setIsSubmitted: (isSubmitted: boolean) => void;
  readonly setLog: (message: string) => void;
}

export const SubmitModal = ({
  blockNumber,
  erc20Info,
  state: { isVisible, close },
  total,
  setIsLoading,
  setIsSubmitted,
  setLog,
}: PropsWithModalState<Props>) => {
  const { chain } = useChain();
  const { symbol } = useFastSymbol();

  const explorer = chain.blockExplorers.default.url;

  const onSubmit = () => {
    setLog("");
    setIsLoading(true);
    setIsSubmitted(true);
    close();
  };

  return (
    <Modal className="Distributions-SubmitModal" footer={null} open={isVisible} width={720} onCancel={close}>
      <p>
        Locked onto&nbsp;
        <Link to={`${explorer}/block/${blockNumber}`} target="_blank">
          block #{blockNumber.toString()}
        </Link>
        . This means that the distribution will account for amounts as they would have been at this block. If you would
        like to have the distribution account for amounts as they are right now, please refresh the page.
      </p>
      <p>
        Everything seems to be in good order and it looks like you'll be distributing&nbsp;
        <b>
          {total} {erc20Info.symbol}
        </b>
        &nbsp;today.
      </p>
      <br />
      <p>
        To do so, you will have to perform <b>two distinct transactions</b>:
      </p>
      <ul>
        <li>
          The first transaction will give approval to the <b>{symbol}</b> FAST for it to move your&nbsp;
          <b>{erc20Info.symbol} tokens</b> on your behalf.
        </li>
        <li>
          The second transaction one will deploy a Smart Contract that will be in charge of distributing the tokens.
        </li>
      </ul>
      <p>
        Please keep in mind that these transactions will need time to complete, and you might have to have to wait for a
        few seconds between each.
      </p>
      <div className="ButtonPairWrapper">
        <Button onClick={close}>Cancel</Button>
        <Button onClick={onSubmit}>
          Sign Transactions
          <i className="icon-sign" />
        </Button>
      </div>
    </Modal>
  );
};
