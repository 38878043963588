export const TipModalContent = () => (
  <>
    <p>
      You can view your current token balance and a historical record of your transfers via the “My Wallet” tab. In
      order to make a transfer to another FAST member, you'll need to know their wallet address.
    </p>
    <br />
    <p>
      View the FAST member directory and member contribution board.You can see who else is in the network and a list of
      wallet addresses with their FAST holdings which is an indication of their contributions to the network either
      through investment of cash or time (advise /expertise).
    </p>
    <br />
    <p>Useful Resources:Useful Resources:</p>
    <ul>
      <a
        href="https://a16z.com/2021/12/16/come-for-the-tool-stay-for-the-exchange-bootstrapping-liquidity-in-the-private-markets/"
        target="_blank"
        rel="noreferrer">
        <li>Bootstrapping Liquidity in the Private Markets</li>
      </a>
      <a href="https://www.nfx.com/post/token-investing" target="_blank" rel="noreferrer">
        <li>The Future of Fundraising? What We Need to Fix About Token Investing</li>
      </a>
      <a
        href="https://kevinmonserrat.medium.com/uk-startups-can-pay-in-sweat-equity-and-earn-90k-in-r-d-tax-relief-30dceb63a7ff"
        target="_blank"
        rel="noreferrer">
        <li>UK Startups Can Pay In “Sweat Equity” and benefit from R&amp;D Tax Relief</li>
      </a>
      <a
        href="https://kevinmonserrat.medium.com/vcs-as-portfolio-curators-democratizing-access-to-private-markets-d4b8561155ae"
        target="_blank"
        rel="noreferrer">
        <li>VCs as Portfolio Curators: Democratizing Access to Private Markets</li>
      </a>
    </ul>
  </>
);
