import { writeContract } from "@wagmi/core";
import { Button, Modal, Spin } from "antd";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { crowdfundABI, fastABI } from "../../../../__generated__/contracts";
import { useErrorHelpers } from "../../../../hooks/useErrorHelpers";
import { PropsWithModalState } from "../../../../hooks/useModalState";
import { useFastContext } from "../../../../providers/FastContractContext/FastContractContext";
import { useFastSymbol } from "../../../../providers/FastSymbolProvider";
import { Paths } from "../../../../routing";
import { EthAddress } from "../../../../types/ethers";
import { RequestDrawdown } from "../types";

interface Props {
  readonly drawdownAddress: EthAddress;
  readonly feeValue: string;
  readonly refValue: string;
  readonly type: RequestDrawdown;
}

export const RequestDrawdownModal = ({
  drawdownAddress,
  feeValue,
  refValue,
  state: { isVisible, close },
  type,
}: PropsWithModalState<Props>) => {
  const [isLoading, setIsLoading] = useState(false);
  const { trackTransaction } = useErrorHelpers();
  const {
    details: { address: fastAddress },
  } = useFastContext();
  const { symbol } = useFastSymbol();
  const navigate = useNavigate();

  const onReject = useCallback(() => {
    const tx = writeContract({
      abi: fastABI,
      address: fastAddress,
      functionName: "removeCrowdfund",
      args: [drawdownAddress],
    });
    void trackTransaction(
      tx,
      "Requesting New Investment Drawdown",
      "Investment Drawdown request rejected",
      setIsLoading,
    ).then(() => navigate(Paths.Fasts.One.ManageFunds.drawdowns.replace(":symbol", symbol)));
  }, [fastAddress, drawdownAddress, navigate, symbol, trackTransaction]);

  const onAccept = useCallback(() => {
    const tx = writeContract({
      abi: crowdfundABI,
      address: drawdownAddress,
      functionName: "advanceToFunding",
      args: [+feeValue * 100],
    });
    void trackTransaction(
      tx,
      "Requesting New Investment Drawdown",
      "Investment Drawdown request accepted",
      setIsLoading,
    ).then(() => navigate(Paths.Fasts.One.ManageFunds.drawdowns.replace(":symbol", symbol)));
  }, [drawdownAddress, feeValue, navigate, symbol, trackTransaction]);

  const handleAction = () => {
    type.isAccept ? onAccept() : onReject();
  };

  return (
    <Modal
      className="InvestmentDrawdowns-Modal"
      closable={false}
      footer={null}
      maskClosable={false}
      open={isVisible}
      onCancel={close}>
      <h2>{type.header}</h2>
      <Spin spinning={isLoading} size="large">
        <p>Reference: {refValue}</p>
        <p>
          <strong>{`${type.essentialCondition} ${feeValue ? `${feeValue}%` : ""}`}</strong>
        </p>
        <p>{type.description}</p>
        <div className="ButtonPairWrapper">
          <Button onClick={close}>Cancel</Button>
          <Button onClick={handleAction}>{type.buttonLabel}</Button>
        </div>
      </Spin>
    </Modal>
  );
};
