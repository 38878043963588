import { useCallback, useMemo, useState } from "react";
import { Stepper } from "./Application/Stepper";
import { ClaimOnboard } from "./Application/Steps/ClaimOnboard";
import { Confirmation } from "./Application/Steps/Confirmation";
import { Details } from "./Application/Steps/Details";
import { Documentation } from "./Application/Steps/Documentation";
import { KYCVerification } from "./Application/Steps/KYCVerification";
import { SelectWalletAddress } from "./Application/Steps/SelectWalletAddress";
import { Step } from "./Application/types";
import { InfoBlock } from "./InfoBlock";
import { ConfirmationCorrectKYC, ConfirmationManuallyReviewKYC } from "./types";
import {
  OnboardStates,
  OnboardingOnboardFragment,
  OnboardingSettingsFragment,
  OnboardingStep,
} from "../../__generated__/graphql";
import { AppearanceTransition } from "../../components/layout/AppearanceTransition";
import { isOnboardingDetails } from "../../graphql/types/JsonScalar";
import { isEthAddress } from "../../types/ethers";

interface Props {
  readonly onboardId: string;
}

export const Application = ({ onboardId }: Props) => {
  const [onboard, setOnboard] = useState<OnboardingOnboardFragment>();
  const [settings, setSettings] = useState<OnboardingSettingsFragment>();

  const fastsInfo = useMemo(() => {
    if (!onboard?.membershipRequests) return;

    return onboard.membershipRequests.map((membershipRequest) => membershipRequest.fast);
  }, [onboard?.membershipRequests]);

  const stepAndContent = useCallback(() => {
    // No onboard - we need to claim it.
    if (!onboard) {
      return {
        step: Step.ClaimOnboard,
        content: <ClaimOnboard onboardId={onboardId} refreshOnboard={setOnboard} setSettings={setSettings} />,
      };
    }
    // We have an onboard...
    else if (settings && isOnboardingDetails(onboard.form.data)) {
      const formData = onboard.form.data;
      // Form doesn't have first or last name.
      if (onboard.onboardingStep === OnboardingStep.FillDetails)
        return { step: Step.YourDetails, content: <Details onboard={onboard} refreshOnboard={setOnboard} /> };
      // No eth address.
      else if (onboard.onboardingStep === OnboardingStep.ConfirmEthAddress)
        return {
          step: Step.ConnectWallet,
          content: (
            <SelectWalletAddress
              fullName={`${formData.firstName} ${formData.lastName}`}
              onboard={onboard}
              refreshOnboard={setOnboard}
              settings={settings}
            />
          ),
        };
      // Onboard documents don't sign.
      else if (
        onboard.onboardingStep === OnboardingStep.SignDocuments &&
        onboard.ethAddress &&
        isEthAddress(onboard.ethAddress)
      )
        return {
          step: Step.Documentation,
          content: (
            <Documentation
              ethAddress={onboard.ethAddress}
              fullName={`${formData.firstName} ${formData.lastName}`}
              onboard={onboard}
              refreshOnboard={setOnboard}
              settings={settings}
            />
          ),
        };
      // Onboard seems incomplete - form is filled, but KYC wasn't done yet.
      else if (onboard.onboardingStep === OnboardingStep.PerformKyc)
        return {
          step: Step.KYCAML,
          content: <KYCVerification onboard={onboard} refreshOnboard={setOnboard} />,
        };
      // KYC was declined.
      else if (onboard.onboardingStep === OnboardingStep.ReviewKyc) {
        return {
          step: Step.Confirmation,
          content: <Confirmation type={ConfirmationManuallyReviewKYC} />,
        };
      }
      // KYC was approved or we've finished Onboarding.
      else if (
        onboard.onboardingStep === OnboardingStep.WaitForChain ||
        onboard.onboardingStep === OnboardingStep.Finish
      )
        return {
          step: Step.Confirmation,
          content: <Confirmation type={ConfirmationCorrectKYC} />,
        };
      else if (onboard.state === OnboardStates.Closed) {
        // TODO: We should redirect to another page? Or say "Congrats"?
      }
    }
    // For any other state, we should say that this is an impossible state.
    return { step: 0, content: <>Invalid state.</> };
  }, [onboard, onboardId, settings]);

  const { step, content } = stepAndContent();
  return (
    <>
      <AppearanceTransition initial={{ x: -20, opacity: 0 }} animate={{ x: 0, opacity: 1 }}>
        <Stepper step={step} />
      </AppearanceTransition>

      <AppearanceTransition className="Onboarding-Form">{content}</AppearanceTransition>
      <div className="Onboarding-Info">{fastsInfo && <InfoBlock fastsInfo={fastsInfo} />}</div>
    </>
  );
};
