import { useState } from "react";
import { Stepper } from "./Issuance/Stepper";
import { EmailSent } from "./Issuance/Steps/EmailSent";
import { Introduction } from "./Issuance/Steps/Introduction";
import { Step } from "./Issuance/types";
import { AppearanceTransition } from "../../components/layout/AppearanceTransition";
import { assertUnreachable } from "../../helpers/utils";

export const Issuance = () => {
  const [step, setStep] = useState(Step.Introduction);

  const renderStep = () => {
    switch (step) {
      case Step.Introduction:
        return <Introduction onContinue={() => setStep(Step.EmailSent)} />;
      case Step.EmailSent:
        return <EmailSent />;
      default:
        assertUnreachable(step);
    }
  };

  return (
    <>
      <AppearanceTransition initial={{ x: -20, opacity: 0 }} animate={{ x: 0, opacity: 1 }}>
        <Stepper step={step} />
      </AppearanceTransition>
      <AppearanceTransition className="Onboarding-Form">{renderStep()}</AppearanceTransition>
    </>
  );
};
