import { Button, Layout } from "antd";
import { useNavigate } from "react-router-dom";
import { TipModalContent } from "./Home/TipModalContent";
import { FastCard } from "../components/FastCard";
import { OpacityTransition } from "../components/layout/OpacityTransition";
import { TipModal } from "../components/modals/TipModal";
import { BigNumberFormatter } from "../hooks/useBigNumberFormatter";
import { useAppContext } from "../providers/APIClient";
import { useFastsList } from "../providers/FastsListProvider";
import { useTopContracts } from "../providers/TopContractsProvider";
import { Paths } from "../routing";

export const Home = () => {
  const detailedFastsList = useFastsList();
  const { isIssuerMember } = useTopContracts();
  const navigate = useNavigate();

  const { sessionData } = useAppContext();
  if (!sessionData) throw new Error("Impossible state: page requires session data.");

  return (
    <OpacityTransition>
      <Layout className="FastsLayout">
        <div className="FastsLayout-Body">
          {isIssuerMember ? (
            <>
              <h1>Welcome ISSUER</h1>
              <div className="FastsLayout-Body-ActionButtons">
                <Button onClick={() => navigate(Paths.Fasts.new)}>Deploy New FAST</Button>
                <Button onClick={() => navigate(Paths.Fasts.membershipRequests)}>Manage applications</Button>
              </div>
            </>
          ) : (
            <h1>Raise, Earn, Invest</h1>
          )}
          {isIssuerMember ? (
            <p>These are all the FAST on our network. Click one to access its individual administrative page.</p>
          ) : (
            <p>
              Here are the FASTs you are part of.
              <TipModal content={<TipModalContent />} title="Make peer-to-peer transfers" />
            </p>
          )}

          <div className="FastsLayout-Body-Fasts">
            {detailedFastsList.map(({ symbol, name, decimals, memberCount, hasFixedSupply, totalSupply }) => {
              const readableTotalSupply = new BigNumberFormatter(decimals).format(totalSupply, 2);
              return (
                <FastCard
                  key={symbol}
                  hasFixedSupply={hasFixedSupply}
                  memberCount={memberCount}
                  name={name}
                  path={Paths.Fasts.One.About.overview.replace(":symbol", symbol)}
                  readableTotalSupply={readableTotalSupply}
                  symbol={symbol}
                />
              );
            })}
          </div>
        </div>
      </Layout>
    </OpacityTransition>
  );
};
