import { ApolloError } from "@apollo/client";
import { Button, Checkbox, Image, Layout, Spin } from "antd";
import type { CheckboxValueType } from "antd/es/checkbox/Group";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  MembershipRequestSettingsFragment,
  useMembershipRequestSettingsLazyQuery,
  useSignMembershipRequestDocumentsMutation,
} from "../../__generated__/graphql";
import { TokenSphereWhite } from "../../assets";
import { AppearanceTransition } from "../../components/layout/AppearanceTransition";
import { OpacityTransition } from "../../components/layout/OpacityTransition";
import { LS } from "../../helpers/LocalStorage";
import { CGEmail, formatDateForSignature, formatDocumentsToSigningTemplate } from "../../helpers/utils";
import { useErrorHelpers } from "../../hooks/useErrorHelpers";
import { useAppContext } from "../../providers/APIClient";
import { useChain } from "../../providers/ChainClient";
import { EthAddress, shortenAddress } from "../../types/ethers";
import { OnboardDocument } from "../Onboarding/Application/Steps/OnboardDocument";

export const FastOnboarding = () => {
  const { symbol } = useParams();
  const [settings, setSettings] = useState<MembershipRequestSettingsFragment>();
  const [submittable, setSubmittable] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { sessionData } = useAppContext();
  const {
    chain: { id: chainId },
    walletClient,
  } = useChain();
  const { handleError } = useErrorHelpers();
  const [membershipRequestSettingsQuery] = useMembershipRequestSettingsLazyQuery();
  const [signMembershipDocuments] = useSignMembershipRequestDocumentsMutation();

  const membershipRequestData = useMemo(() => {
    if (!sessionData?.identity.membershipRequests) return;

    return sessionData.identity.membershipRequests.find(
      (membershipRequest) => membershipRequest.fast.symbol === symbol,
    );
  }, [sessionData, symbol]);

  const [isSigned, setIsSigned] = useState(!!membershipRequestData?.documentsSignedAt);

  useEffect(() => {
    if (settings || !membershipRequestData) return;

    membershipRequestSettingsQuery({ variables: { membershipRequestId: membershipRequestData.id } })
      .then((res) => {
        if (!res.data?.membershipRequestSettings) throw new Error("Invalid response from server.");
        setSettings(res.data.membershipRequestSettings);
        setIsLoading(false);
      })
      .catch((err: ApolloError) => handleError("An error occured while requesting settings", err));
  }, [handleError, membershipRequestData, membershipRequestSettingsQuery, settings]);

  const onChange = useCallback(
    (checkedValues: CheckboxValueType[]) => setSubmittable(checkedValues.length === settings?.requiredDocuments.length),
    [settings],
  );

  const onSignDocuments = useCallback(() => {
    if (!sessionData?.identity.fullName || !membershipRequestData || !settings) return;

    const transmittedAt = formatDateForSignature(new Date(Date.now()));
    const digest = settings.documentsSigningTemplate
      .replace("{{full_name}}", `${sessionData.identity.fullName}`)
      .replace("{{{documents}}}", formatDocumentsToSigningTemplate(settings.requiredDocuments))
      .replace("{{iso8601_timestamp}}", transmittedAt);

    walletClient
      .signMessage({ message: digest })
      .then((ethSignature) => {
        const documentIds = settings.requiredDocuments.map(({ id }) => id);
        return signMembershipDocuments({
          variables: {
            input: { documentIds, ethSignature, membershipRequestId: membershipRequestData?.id, transmittedAt },
          },
        });
      })
      .then((res) => {
        if (!res.data?.signMembershipRequestDocuments) throw new Error("Invalid response from server.");
        const updatedMembershipRequest = res.data.signMembershipRequestDocuments.membershipRequest;

        // It needs to update membershipRequests session data in Local storage. Let's do it.
        const updatedMembershipRequests = sessionData.identity.membershipRequests.map((request) => {
          return request.id === updatedMembershipRequest.id
            ? { ...request, documentsSignedAt: updatedMembershipRequest.documentsSignedAt }
            : request;
        });
        const updatedSessionData = {
          ...sessionData,
          identity: {
            ...sessionData.identity,
            membershipRequests: updatedMembershipRequests,
          },
        };
        LS.sessions.set(chainId, { ...updatedSessionData });

        setIsSigned(true);
      })
      .catch((err) => handleError("An error occured while signing the required document", err));
  }, [chainId, handleError, membershipRequestData, sessionData, settings, signMembershipDocuments, walletClient]);

  return (
    <OpacityTransition>
      <Layout className="Onboarding">
        <h2 className="Onboarding-Title">
          <Image alt="TokenSpheree" src={TokenSphereWhite} preview={false} />
          <span className="Opaque">Platform</span>
        </h2>
        <AppearanceTransition className="Onboarding-Form FastOnboarding-Form">
          <div className="Documentation SpecificFastDocumentation Padding">
            <h4>
              Hi <span className="RedText">{sessionData?.identity.fullName}</span>
              <br />
              You are applying to
            </h4>
            <div className="FastInfo">
              {membershipRequestData?.fast.logoUrl && (
                <Image alt="Fast Logo" src={membershipRequestData?.fast.logoUrl} preview={false} />
              )}
              <div>
                <h2>{membershipRequestData?.fast.name}</h2>
                <h2>{membershipRequestData?.fast.symbol}</h2>
              </div>
            </div>

            {isLoading ? (
              <Spin spinning={isLoading} size="large" />
            ) : (
              <div className="SpecificFastDocumentation-Form">
                <div className="SpecificFastDocumentation-Divider" />
                {isSigned ? (
                  <div className="SpecificFastDocumentation-IsSigned">
                    <p>Thank you for signing the documents and making a membership request.</p>
                    <p>
                      The {membershipRequestData?.fast.name} coordinator has now received your request and you will soon
                      receive an update.
                    </p>
                  </div>
                ) : (
                  <>
                    <p>Please read and sign the below documents for membership:</p>
                    <Checkbox.Group onChange={onChange}>
                      {settings?.requiredDocuments.map((document) => (
                        <OnboardDocument key={document.id} name={document.name} url={document.downloadUrl} />
                      ))}
                    </Checkbox.Group>
                    <p>
                      I <strong>{sessionData?.identity.fullName}</strong>, read, understood and agree to the terms and
                      conditions set out in the above documents
                    </p>
                    <Button disabled={!submittable} onClick={onSignDocuments}>
                      {submittable ? (
                        <>
                          Sign with
                          <p>{shortenAddress(sessionData?.identity.ethAddress as EthAddress)}</p>
                          <i className="icon-sign" />
                        </>
                      ) : (
                        "Sign"
                      )}
                    </Button>
                    <p>
                      Please <a href={`mailto:${CGEmail}`}>contact us</a> if you have any questions.
                    </p>
                  </>
                )}
              </div>
            )}
          </div>
        </AppearanceTransition>
      </Layout>
    </OpacityTransition>
  );
};
