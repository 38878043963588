import { Button, Image, Popover } from "antd";
import { Outlet } from "react-router-dom";
import { useAccount, useDisconnect } from "wagmi";
import { WalletBalances } from "./WalletBalances";
import { UserIcon } from "../../assets";
import { useAppContext } from "../../providers/APIClient";
import { useChain } from "../../providers/ChainClient";
import { useUnsafeChain } from "../../providers/ChainClient/InnerProvider";
import { useSession } from "../../providers/SessionProvider/SessionProvider";
import { OpacityTransition } from "../layout/OpacityTransition";
import { logoFor } from "../utils";

// This component won't be able to use `useChain` as it relies on the principle that
// the user might not be connected to the correct chain yet.
export const WalletBadge = () => {
  const { chain } = useUnsafeChain();
  const { connector } = useAccount();
  const { signerAddress: address } = useChain();
  const { disconnect } = useDisconnect();
  const { logout } = useSession();
  const { sessionData } = useAppContext();

  const onLogoutAndDisconnect = () => {
    logout();
    disconnect();
  };

  const chainStatus = chain ? "Correct" : "Wrong";
  const chainStatusMessage =
    chain?.name && connector
      ? `Connected to the correct network (${chain.name}) via ${connector.name}`
      : "Connected to the wrong network";

  return (
    <>
      <OpacityTransition transition={{ delay: 0.35 }}>
        <Popover
          destroyTooltipOnHide
          overlayClassName="WalletBadge-Popover"
          showArrow={false}
          autoAdjustOverflow
          placement="bottomRight"
          trigger="click"
          content={
            <div className="WalletBadge-Content">
              <div className="WalletBadge-Row">
                <Button className="DisconnectButton" onClick={onLogoutAndDisconnect}>
                  Logout & Disconnect
                </Button>
                {sessionData?.identity.fullName && <h4>{sessionData?.identity.fullName}</h4>}
              </div>

              {connector && (
                <div className="WalletBadge-Row">
                  <div>
                    <Image alt={`${connector.name}-icon`} preview={false} src={logoFor(connector.name)} />
                    <p>{chainStatusMessage}</p>
                  </div>
                  <Button>Switch</Button>
                </div>
              )}

              <div>
                <Image alt="person" src={UserIcon} preview={false} />
                <div>{address}</div>
              </div>
              <div className="Divider" />

              <WalletBalances />
            </div>
          }>
          <Button className="WalletBadge">
            <Image alt="person" src={UserIcon} preview={false} />
            <span className={`WalletBadge-Status ${chainStatus}`} />
            <i className="icon-arrow-down" />
          </Button>
        </Popover>
      </OpacityTransition>
      <Outlet />
    </>
  );
};
