import { CurrentHoldersTable } from "./New/CurrentHoldersTable";
import { DeployDistribution } from "./New/DeployDistribution";

export const New = () => (
  <>
    <div className="Content-Header">
      <h1>Create Distribution</h1>
    </div>
    <section className="Content-Body">
      <DeployDistribution />
      <CurrentHoldersTable />
    </section>
  </>
);
