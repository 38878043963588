import { PropsWithChildren } from "react";
import {
  useDistributionAdvanceEvent,
  useDistributionBeneficiaryAddedEvent,
  useDistributionBeneficiaryRemovedEvent,
  useDistributionWithdrawalEvent,
} from "../../__generated__/contracts";
import { EthAddress } from "../../types/ethers";

interface Props {
  readonly address: EthAddress;
  readonly populateDistribution: () => void;
  readonly setIsReceivedWithdrawal: (received: boolean) => void;
}

// This component is a top-level listener for Distribution events changes.
// We don't want to have this logic in the Distribution contract provider, as it contains
// business-specific logic and is in charge of informing the parent context of
// changes happening on-chain in the Distribution contracts.
export const Listener = ({
  address,
  children,
  populateDistribution,
  setIsReceivedWithdrawal,
}: PropsWithChildren<Props>) => {
  // TODO: Re-enable once the node doesn't blow up?..
  useDistributionBeneficiaryAddedEvent({
    address,
    listener: (log) => {
      const eventData = log.at(0);
      // We need to request distributions here, because we monitor 2 fields - beneficiaryCount and available.
      eventData?.args.beneficiary && populateDistribution();
    },
  });

  useDistributionBeneficiaryRemovedEvent({
    address,
    listener: (log) => {
      const eventData = log.at(0);
      // We need to request distributions here, because we monitor 2 fields - beneficiaryCount and available.
      eventData?.args.beneficiary && populateDistribution();
    },
  });

  useDistributionAdvanceEvent({
    address,
    listener: (log) => {
      const eventData = log.at(0);
      eventData?.args.phase && populateDistribution();
    },
  });

  useDistributionWithdrawalEvent({
    address,
    listener: (log) => {
      const eventData = log.at(0);
      // We need a new data to update beneficiaries table.
      eventData?.args.amount && eventData.args.beneficiary && eventData.args.caller && setIsReceivedWithdrawal(true);
    },
  });

  return <>{children}</>;
};
