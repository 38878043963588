import { readContracts, writeContract } from "@wagmi/core";
import { Button } from "antd";
import { useEffect, useState } from "react";
import { Status } from "./Status";
import { distributionABI } from "../../../../__generated__/contracts";
import { useErrorHelpers } from "../../../../hooks/useErrorHelpers";
import { useDistribution } from "../../../../providers/DistributionProvider/DistributionProvider";
import { Phase } from "../../../../providers/DistributionProvider/types";
import { EthAddress } from "../../../../types/ethers";
import { SimpleToast } from "../../../../types/toasts";

interface BeneficiaryDetails {
  readonly owing: bigint;
  readonly withdrawn: boolean;
}

interface Props {
  readonly beneficiary: EthAddress;
  readonly setIsLoading: (isLoading: boolean) => void;
}

export const PersonBenefit = ({ beneficiary, setIsLoading }: Props) => {
  const {
    info: { address, erc20Info, phase },
    isReceivedWithdrawal,
  } = useDistribution();
  const { handleError } = useErrorHelpers();
  const [beneficiaryDetails, setBeneficiaryDetails] = useState<BeneficiaryDetails>();

  useEffect(() => {
    const common = { abi: distributionABI, address };

    readContracts({
      contracts: [
        { ...common, functionName: "owings", args: [beneficiary] },
        { ...common, functionName: "withdrawn", args: [beneficiary] },
      ],
    })
      .then(([{ result: owing }, { result: withdrawn }]) => {
        if (!owing || withdrawn === undefined) throw new Error("Empty results from contract.");
        setBeneficiaryDetails({ owing, withdrawn });
      })
      .catch((err) => handleError("An error occurred while loading information about the distribution.", err));
  }, [address, beneficiary, handleError, isReceivedWithdrawal]);

  // When we get an asynchronous update of receiving withdrawal, we disable a spinner.
  useEffect(() => {
    isReceivedWithdrawal && setIsLoading(false);
  }, [isReceivedWithdrawal, setIsLoading]);

  const onWithdraw = () => {
    setIsLoading(true);
    SimpleToast.success("Distribution Withdrawal.");

    writeContract({
      abi: distributionABI,
      address,
      functionName: "withdraw",
      args: [beneficiary],
    })
      .then(() => {
        SimpleToast.success("Distribution was withdrawn.");
      })
      .catch((err) => handleError("An error occurred while withdrawing the distribution.", err));
  };

  return (
    <div className="Distributions-PersonBenefit">
      <h2>My benefit</h2>

      {beneficiaryDetails && (
        <>
          <div className="PersonBenefit-Cards">
            <div className="PersonBenefit-Card">
              <h3>
                {erc20Info.formatter.format(beneficiaryDetails.owing, erc20Info.decimals)} {erc20Info.symbol}
              </h3>
              <span>Amount</span>
            </div>

            <div className="PersonBenefit-Card">
              {phase >= Phase.Withdrawal ? (
                <h3 className="StatusTitle">
                  <Status phase={phase} withdrawn={beneficiaryDetails.withdrawn} />
                </h3>
              ) : (
                <h3>Not sent yet</h3>
              )}
              <span>Status</span>
            </div>
          </div>
          {phase === Phase.Withdrawal && !beneficiaryDetails.withdrawn && (
            <Button onClick={onWithdraw}>Withdraw</Button>
          )}
        </>
      )}
    </div>
  );
};
