import type { OpenloginLoginParams } from "@web3auth/openlogin-adapter";
import { Web3AuthConnector } from "@web3auth/web3auth-wagmi-connector";
import { Button, Form, Image, Input } from "antd";
import { useCallback } from "react";
import { useConnect } from "wagmi";
import { GoogleColor, LinkedInColor, MetaMask } from "../assets";
import { ConnectorNames, SupportedChain } from "../helpers/chain";
import { RequiredEmailRule } from "../helpers/utils";
import { web3AuthOptionsBuilder } from "../providers/ChainClient/web3AuthOptionsBuilder";

interface SingleValueFormType {
  readonly emailAddress: string;
}

export const Connectors = () => {
  const { connect, connectors } = useConnect();

  const connectWithWeb3Auth = useCallback(
    (options?: OpenloginLoginParams) =>
      connect({ connector: new Web3AuthConnector(web3AuthOptionsBuilder(SupportedChain, options)) }),
    [connect],
  );

  const loginWithGoogle = useCallback(() => connectWithWeb3Auth(), [connectWithWeb3Auth]);

  const loginWithLinkedIn = useCallback(
    () => connectWithWeb3Auth({ loginProvider: "linkedin" }),
    [connectWithWeb3Auth],
  );

  const loginWithEmail = useCallback(
    ({ emailAddress }: SingleValueFormType) =>
      connectWithWeb3Auth({ loginProvider: "email_passwordless", login_hint: emailAddress }),
    [connectWithWeb3Auth],
  );

  const loginWithMetaMask = useCallback(() => {
    // We use this way of connecting because when we try to use the connect method with MetaMaskConnector,
    // MetaMaskConnector doesn't save data about the connection in localStorage.
    connectors.map((connector) => {
      if (connector.name === ConnectorNames.MetaMask) {
        connect({ connector });
      }
    });
  }, [connect, connectors]);

  return (
    <div className="Connectors">
      <div>
        <h3>Sign in</h3>
        <p>Your TokenSphere wallet with one click</p>
      </div>
      <div className="Buttons">
        <p>Continue with</p>
        <Button onClick={loginWithGoogle}>
          <Image preview={false} src={GoogleColor} />
        </Button>
        <Button onClick={loginWithLinkedIn}>
          <Image preview={false} src={LinkedInColor} />
        </Button>
      </div>

      <Form layout="vertical" requiredMark onFinish={loginWithEmail}>
        <Form.Item label="Email" name="emailAddress" rules={[...RequiredEmailRule]}>
          <Input type="string" placeholder="E.g. hello@example.com" />
        </Form.Item>
        <Button htmlType="submit">Continue with Email</Button>
      </Form>

      <div className="wallet">
        <p>External wallet</p>
        <Button onClick={loginWithMetaMask}>
          <Image preview={false} src={MetaMask} />
          Connect with MetaMask
        </Button>
      </div>
    </div>
  );
};
