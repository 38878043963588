import { Grid } from "antd";
import { Breakpoint } from "antd/lib/_util/responsiveObserve";

export const useScreenSize = () => {
  const screens = Grid.useBreakpoint();
  const currentScreen = Object.entries(screens)
    .filter((screen) => !!screen[1])
    .at(-1);

  if (!currentScreen) return { screenSize: "xl" as Breakpoint };

  const isMobile = currentScreen[0] === "xs";
  return { screenSize: currentScreen[0] as Breakpoint, isMobile };
};
