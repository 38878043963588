import { readContract, writeContract } from "@wagmi/core";
import { Button, Spin } from "antd";
import { memo, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { crowdfundABI } from "../../../../../__generated__/contracts";
import { useErrorHelpers } from "../../../../../hooks/useErrorHelpers";
import { useChain } from "../../../../../providers/ChainClient";
import { useFastSymbol } from "../../../../../providers/FastSymbolProvider";
import { Paths } from "../../../../../routing";
import { EthAddress } from "../../../../../types/ethers";

interface Props {
  readonly beneficiary: EthAddress;
  readonly currencySymbol: string;
  readonly drawdownAddress: EthAddress;
  readonly pledges: string;
}

export const WithdrawalStep = memo(({ beneficiary, drawdownAddress, currencySymbol, pledges }: Props) => {
  const { signerAddress } = useChain();
  const [isClaimbacked, setIsClaimbacked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { handleError, trackTransaction } = useErrorHelpers();
  const { symbol } = useFastSymbol();
  const navigate = useNavigate();

  // We want to know if the user has refunded.
  useEffect(() => {
    readContract({
      abi: crowdfundABI,
      address: drawdownAddress,
      functionName: "refunded",
      args: [signerAddress],
    })
      .then((isRefunded) => {
        if (isRefunded === undefined) throw new Error("Empty result from contract.");
        setIsClaimbacked(isRefunded);
      })
      .catch((err) => {
        handleError("Error Loading Refund Information", err);
      });
  }, [drawdownAddress, handleError, signerAddress]);

  const onClaimback = useCallback(() => {
    const tx = writeContract({
      abi: crowdfundABI,
      address: drawdownAddress,
      functionName: "refund",
      args: [beneficiary],
    });
    void trackTransaction(tx, "Investment Drawdown", "Investment Refund Successful", setIsLoading).then(() =>
      navigate(Paths.Fasts.One.ManageFunds.drawdowns.replace(":symbol", symbol)),
    );
  }, [beneficiary, drawdownAddress, navigate, setIsLoading, symbol, trackTransaction]);

  return (
    <div className="InvestmentDrawdowns-Step">
      <h4>Your pledged amount</h4>
      <Spin spinning={isLoading} size="large">
        <p>
          <strong>
            {pledges} {currencySymbol}
          </strong>
        </p>
        <Button disabled={isClaimbacked || +pledges === 0} onClick={onClaimback}>
          Claimback
          <i className="icon-sign" />
        </Button>
      </Spin>
    </div>
  );
});
