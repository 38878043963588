import { writeContract } from "@wagmi/core";
import { Button, Col, Form, Input, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { fastABI } from "../../../__generated__/contracts";
import { FastVisibility } from "../../../__generated__/graphql";
import { AddressResolverInput } from "../../../components/AddressResolverInput/AddressResolverInput";
import { DecimalStringRule, RequiredFieldRule } from "../../../helpers/utils";
import { paginateDetailedMembers } from "../../../helpers/wagmiActions";
import { useBigNumberFormatter } from "../../../hooks/useBigNumberFormatter";
import { useErrorHelpers } from "../../../hooks/useErrorHelpers";
import { FastDetails, MembershipDetails } from "../../../providers/FastContractContext/types";
import { EthAddress } from "../../../types/ethers";
import { useFastDetails } from "../Top";

interface TransferForm {
  readonly recipient: EthAddress;
  readonly amount: `${number}`;
  readonly reference: string;
}

interface Props {
  readonly fastAddress: EthAddress;
  readonly details: FastDetails;
  readonly membership: MembershipDetails;
}

export const Transfer = ({ fastAddress, details: { decimals, memberCount, symbol }, membership }: Props) => {
  const {
    fastDetails: { visibility },
  } = useFastDetails();
  const { trackTransaction } = useErrorHelpers();
  const formatter = useBigNumberFormatter(decimals);
  const [form] = useForm<TransferForm>();
  const [beneficiariesAddresses, setBeneficiariesAddresses] = useState<ReadonlyArray<EthAddress>>();
  const [isLoading, setIsLoading] = useState(false);

  // If this FAST is private, we should get valid user addresses for this FAST for a list of recipients.
  useEffect(() => {
    if (visibility !== FastVisibility.Private) return;

    void paginateDetailedMembers(fastAddress, memberCount, setBeneficiariesAddresses);
  }, [fastAddress, memberCount, visibility]);

  const onFinish = (data: TransferForm) => {
    const tx = writeContract({
      abi: fastABI,
      address: fastAddress,
      functionName: "transferWithRef",
      args: [data.recipient, formatter.parse(data.amount), data.reference],
    }).finally(() => form.resetFields());
    void trackTransaction(tx, "Transfer", "Transfer was successful.", setIsLoading);
  };
  return (
    <section>
      <h2>Transfer</h2>
      <p className="Bold">
        Current balance:
        <span>
          {formatter.format(membership.balance)} {symbol}
        </span>
      </p>
      <Form className="Wallet-Form" form={form} layout="vertical" requiredMark onFinish={onFinish} disabled={isLoading}>
        <Col xl={16}>
          <Form.Item
            className="AddressResolverWithLabel"
            name="recipient"
            label="Recipient"
            rules={[...RequiredFieldRule]}>
            <AddressResolverInput ethAddresses={beneficiariesAddresses} id="recipient" />
          </Form.Item>
        </Col>
        <Row>
          <Col xl={16}>
            <Form.Item name="reference" label="Reference" rules={[...RequiredFieldRule]}>
              <Input type="text" showCount maxLength={23} placeholder="Enter a reference..." />
            </Form.Item>
          </Col>
          <Col xl={7} offset={1}>
            <Form.Item name="amount" label="Amount" rules={[...RequiredFieldRule, ...DecimalStringRule]}>
              <Input type="string" placeholder="Enter a number..." />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button htmlType="submit">Send Transfer</Button>
        </Form.Item>
      </Form>
    </section>
  );
};
