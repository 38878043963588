import { useCallback, useLayoutEffect, useRef } from "react";

// This hook helps in maintaining consistent event handling across component renders.
// Even when the function used as an event handler changes, the function is memoized.
export function useEvent<T extends (...args: unknown[]) => unknown>(fn: T) {
  const fnRef = useRef(fn);

  useLayoutEffect(() => {
    fnRef.current = fn;
  }, [fn]);

  const eventCb = useCallback(
    (...args: unknown[]) => {
      return fnRef.current(...args);
    },
    [fnRef],
  );

  return eventCb;
}
