import { useMemo } from "react";
import { isProduction } from "../helpers/utils";
import { useFastContext } from "../providers/FastContractContext/FastContractContext";
import { useTopContracts } from "../providers/TopContractsProvider";
import { Paths } from "../routing";

export const enum Section {
  Main,
  About,
  Transfers,
  Community,
  ManageFunds,
  Proposals,
}

export interface SectionProps {
  readonly section: Section;
}

export interface TabDescriptor {
  readonly title: string;
  readonly path: string;
  readonly available: boolean;
}

export const useNavigatorTabs = (section: Section) => {
  const { isIssuerMember } = useTopContracts();
  const { membership } = useFastContext();
  const isMember = membership.isMember;
  const isGovernor = membership.isGovernor;

  const tabs: ReadonlyArray<TabDescriptor> = useMemo(() => {
    const isMemberOrGovernor = isMember || isGovernor;
    const isGovernorOrIssuerMember = isGovernor || isIssuerMember;
    const isMemberOrGovernorOrIssuerMember = isMemberOrGovernor || isIssuerMember;

    return [
      {
        section: Section.Main,
        title: "About",
        path: Paths.Fasts.One.About.overview,
        available: isMemberOrGovernorOrIssuerMember,
      },
      {
        title: "Overview",
        section: Section.About,
        path: Paths.Fasts.One.About.overview,
        available: isMemberOrGovernorOrIssuerMember,
      },
      { title: "Edit", section: Section.About, path: Paths.Fasts.One.About.edit, available: isGovernor },
      {
        section: Section.Main,
        title: "Transfers",
        path: isMemberOrGovernor ? Paths.Fasts.One.Transfers.myTransfers : Paths.Fasts.One.Transfers.networkTransfers,
        available: isMemberOrGovernorOrIssuerMember,
      },
      {
        title: "My Transfers",
        section: Section.Transfers,
        path: Paths.Fasts.One.Transfers.myTransfers,
        available: isMemberOrGovernor,
      },
      {
        title: "Network Transfers",
        section: Section.Transfers,
        path: Paths.Fasts.One.Transfers.networkTransfers,
        available: isGovernorOrIssuerMember,
      },
      {
        title: "Tokens Management",
        section: Section.Transfers,
        path: Paths.Fasts.One.Transfers.tokensManagement,
        available: isIssuerMember,
      },
      {
        section: Section.Main,
        title: "Community",
        path: Paths.Fasts.One.Community.members,
        available: true,
      },
      { title: "Members", section: Section.Community, path: Paths.Fasts.One.Community.members, available: true },
      {
        title: "Coordinators",
        section: Section.Community,
        path: Paths.Fasts.One.Community.coordinators,
        available: isGovernorOrIssuerMember,
      },
      {
        title: "Applications",
        section: Section.Community,
        path: Paths.Fasts.One.Community.applications,
        available: isGovernor,
      },
      {
        section: Section.Main,
        title: "Proposals",
        path: Paths.Fasts.One.proposals,
        available: true,
      },
      {
        section: Section.Main,
        title: "Manage Funds",
        path: Paths.Fasts.One.ManageFunds.drawdowns,
        available: true,
      },
      {
        // Disable the distributions tab in production.
        title: "Distributions",
        section: Section.ManageFunds,
        path: Paths.Fasts.One.ManageFunds.distributions,
        available: !isProduction,
      },
      {
        title: "INVESTMENT DRAWDOWNS",
        section: Section.ManageFunds,
        path: Paths.Fasts.One.ManageFunds.drawdowns,
        available: true,
      },
    ].filter((tab) => tab.section === section && tab.available);
  }, [isIssuerMember, isMember, isGovernor, section]);

  return tabs;
};
