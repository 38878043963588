import { Button, Form, Input } from "antd";
import { useForm, useWatch } from "antd/lib/form/Form";
import { useCallback, useEffect, useMemo, useState } from "react";
import { readContracts, useBlockNumber } from "wagmi";
import { ProgressBlock } from "./ProgressBlock";
import { SubmitModal } from "./SubmitModal";
import { fastABI } from "../../../../__generated__/contracts";
import { ChangeTokenModal } from "../../../../components/modals/ChangeTokenModal";
import { RequiredFieldRule } from "../../../../helpers/utils";
import { useErrorHelpers } from "../../../../hooks/useErrorHelpers";
import { useModalState } from "../../../../hooks/useModalState";
import { useChain } from "../../../../providers/ChainClient";
import { ERC20Info, erc20Fetcher } from "../../../../providers/ERC20Fetcher";
import { useFastContext } from "../../../../providers/FastContractContext/FastContractContext";
import { isEthAddress } from "../../../../types/ethers";

interface FormData {
  readonly ref: string;
  readonly total: `${number}`;
}

export const DeployDistribution = () => {
  const { chain, signerAddress, walletClient } = useChain();
  const {
    details: { address: fastAddress },
  } = useFastContext();
  const { data: latestBlockNumber } = useBlockNumber();
  const { handleError } = useErrorHelpers();
  const changeCurrencyModalState = useModalState(false);
  const submitModalState = useModalState(false);
  const [form] = useForm<FormData>();
  const totalValue = useWatch("total", form);
  const refValue = useWatch("ref", form);

  const [token, setToken] = useState<string>(chain.defaultCurrency);
  const [blockNumber, setBlockNumber] = useState(latestBlockNumber);
  const [erc20Balance, setErc20Balance] = useState<string>();
  const [erc20Allowance, setErc20Allowance] = useState<string>();
  const [erc20Error, setErc20Error] = useState<string>();
  const [erc20Info, setErc20Info] = useState<ERC20Info>();
  const [balance, setBalance] = useState<number>();
  const [isFormValidate, setIsFormValidated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [log, setLog] = useState("");

  // Only update the block number if it's not set yet.
  useEffect(() => {
    if (blockNumber) return;
    setBlockNumber(latestBlockNumber);
  }, [blockNumber, latestBlockNumber]);

  // Find out the balance and allowance of the User and update it if the token contract address has changed.
  useEffect(() => {
    setErc20Error(undefined);
    setErc20Info(undefined);
    setBalance(undefined);
    setErc20Balance(undefined);

    if (!isEthAddress(token)) return;

    erc20Fetcher(token, walletClient)
      .then((info) => {
        const common = { abi: fastABI, address: info.address, walletClient };
        return Promise.all([
          info,
          readContracts({
            contracts: [
              { ...common, functionName: "balanceOf", args: [signerAddress] },
              { ...common, functionName: "allowance", args: [signerAddress, fastAddress] },
            ],
          }),
        ]);
      })
      .then(([info, [{ result: bal }, { result: allowance }]]) => {
        if (bal === undefined || allowance === undefined) throw new Error("Empty result from contract.");
        setErc20Info(info);
        setBalance(+info.formatter.format(bal));
        setErc20Allowance(`Allowance: ${info.formatter.format(allowance)} ${info.symbol}`);
        setErc20Balance(`Balance: ${info.formatter.format(bal)} ${info.symbol}`);
      })
      .catch((err) => {
        handleError("Loading token contract information", err);
        setErc20Error("Failed to fetch token contract information. Is the token contract address correct?");
      });
  }, [token, fastAddress, signerAddress, handleError, walletClient]);

  // Validation of the Form.
  useEffect(() => {
    if (!form.isFieldsTouched(true)) return;

    form.validateFields().then(
      () => setIsFormValidated(true),
      () => setIsFormValidated(false),
    );
  }, [form, refValue, totalValue]);

  // This needs because without it has an error in console.
  // https://ant.design/components/input#why-input-lose-focus-when-change-prefixsuffixshowcount
  const symbolSuffix = useMemo(() => (erc20Info ? erc20Info.symbol : <span />), [erc20Info]);

  const onOpenSubmitModal = useCallback(() => {
    if (!isFormValidate || !erc20Info || !blockNumber) return;
    submitModalState.open();
  }, [isFormValidate, erc20Info, blockNumber, submitModalState]);

  return (
    <div className="Distributions-Deploy">
      <Form
        autoComplete="off"
        disabled={isLoading}
        form={form}
        layout="vertical"
        requiredMark
        onFinish={onOpenSubmitModal}>
        <Form.Item label="Reference/Title" name="ref" rules={[...RequiredFieldRule]}>
          <Input maxLength={30} type="string" placeholder="Type a reference title" showCount />
        </Form.Item>

        <div>
          <Form.Item
            label="Distribution amount"
            name="total"
            rules={[
              () => ({
                required: true,
                validator(_, value) {
                  if (balance && value <= balance && value > 0) return Promise.resolve();
                  return Promise.reject(new Error("The amount must be less than the balance and more than 0"));
                },
              }),
            ]}>
            <Input placeholder="Add amount" suffix={symbolSuffix} type="number" />
          </Form.Item>
          <div className="TokenInfo">
            <p>{erc20Allowance}</p>
            <p>{erc20Balance}</p>
            <p>
              To change the distribution token please&nbsp;
              <Button className="SetToken-Button" disabled={isLoading} onClick={changeCurrencyModalState.open}>
                click here
              </Button>
            </p>

            <ChangeTokenModal
              erc20Hint={erc20Balance}
              erc20Error={erc20Error}
              title="Distribution token"
              token={token}
              state={changeCurrencyModalState}
              setToken={setToken}
            />
          </div>
        </div>

        {!isLoading && (
          <>
            <Button htmlType="submit">Create</Button>
            {erc20Info && !!blockNumber && (
              <SubmitModal
                blockNumber={blockNumber}
                erc20Info={erc20Info}
                total={totalValue}
                state={submitModalState}
                setIsLoading={setIsLoading}
                setIsSubmitted={setIsSubmitted}
                setLog={setLog}
              />
            )}
          </>
        )}
      </Form>
      {isSubmitted && (
        <ProgressBlock
          blockNumber={blockNumber}
          erc20Info={erc20Info}
          log={log}
          refValue={refValue}
          totalValue={totalValue}
          setIsLoading={setIsLoading}
          setIsSubmitted={setIsSubmitted}
          setLog={setLog}
        />
      )}
    </div>
  );
};
