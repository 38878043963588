import { Table } from "antd";
import { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import { useEffect, useState } from "react";
import { EmptyTable } from "./EmptyTable";
import { Nullable } from "../../helpers/utils";
import { useErrorHelpers } from "../../hooks/useErrorHelpers";

export type FetchTotalFun = () => Promise<number>;
export type FetchItemsFun<T> = (at: number, perPage: number, total: number) => Promise<ReadonlyArray<T>>;

interface Props<T extends object> {
  readonly columns: ColumnsType<T>;
  readonly fetchTotal: FetchTotalFun;
  readonly fetchItems: FetchItemsFun<T>;
  readonly perPage?: number;
  readonly reverse?: boolean;
}

export const PaginatedTable = <T extends object>({
  columns,
  fetchTotal,
  fetchItems,
  perPage = 10,
  reverse,
}: Props<T>) => {
  const { handleError } = useErrorHelpers();
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(perPage);
  const [items, setItems] = useState<ReadonlyArray<T>>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Nullable<string>>(null);
  const paginationProps: TablePaginationConfig = {
    position: ["topRight"],
    pageSize,
    total,
    onChange: setPage,
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "30"],
    showQuickJumper: true,
    onShowSizeChange(_, size) {
      setPageSize(size);
    },
  };

  // This effect will run once when the component displays.
  useEffect(() => {
    // Reset state, start loading.
    setError(null);
    setIsLoading(true);
    // Let the consumer give us the total.
    fetchTotal()
      // We got a total.
      .then(setTotal)
      // Something went wrong while fetching the total.
      .catch((err) => {
        handleError("Loading Total", err);
        setError("An error has occurred while fetching the total number of items.");
      })
      // Regardless, stop showing the loading indicator.
      .finally(() => setIsLoading(false));
  }, [fetchTotal, handleError]);

  // This effect will run every time the total number of items change, or when the page number
  // or number of items per page change.
  useEffect(() => {
    // No items to render.
    if (total == 0) return;
    // Reset state, start loading.
    setError(null);
    setIsLoading(true);
    // Let the consumer fetch items.
    const cursor = pageSize * (page - 1);
    const start = reverse ? Math.max(0, total - cursor - pageSize) : cursor;
    const perPageAtMost = Math.min(pageSize, total - (page - 1) * pageSize);
    fetchItems(start, perPageAtMost, total)
      // We got results. Remap them so that they all have a key.
      .then((res) => {
        const itemsWithKey = res.map((item, index) => ({ ...item, key: index }));
        setItems(reverse ? itemsWithKey.reverse() : itemsWithKey);
      })
      // Something bad happened during fetching of the items by the consumer.
      .catch((err) => {
        handleError("Loading Items", err);
        setError("An error has occurred while loading the items.");
      })
      // Regardless, set loading to false.
      .finally(() => setIsLoading(false));
  }, [fetchItems, total, page, pageSize, reverse, handleError]);

  if (error) {
    return <div className="Table-Error">{error}</div>;
  } else {
    return total == 0 ? (
      <EmptyTable text="No items found." />
    ) : (
      <Table
        className="Table"
        dataSource={items}
        columns={columns}
        loading={isLoading}
        pagination={paginationProps}
        rowClassName="Table-Row"
      />
    );
  }
};
