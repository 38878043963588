import { Image } from "antd";
import { useGetBalances } from "../../hooks/useGetBalances";
import { logoFor } from "../utils";

export const WalletBalances = () => {
  const balances = useGetBalances();

  return (
    <div className="WalletBalances">
      {balances &&
        balances.map((balance) => (
          <div key={balance.symbol} className="WalletBalances-Balance">
            <Image alt={`${balance.symbol}-icon`} preview={false} src={logoFor(balance.symbol)} />
            <span>{balance.formatted}</span>
            <span>{balance.symbol}</span>
          </div>
        ))}
    </div>
  );
};
