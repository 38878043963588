import { Layout } from "antd";
import { PropsWithChildren } from "react";
import { IssuerHeader } from "./IssuerHeader";
import { OpacityTransition } from "../../components/layout/OpacityTransition";

export const IssuerDashboard = ({ children }: PropsWithChildren) => (
  <OpacityTransition className="IssuerDashboard">
    <Layout className="FastsLayout">
      <section className="PageContent">
        <IssuerHeader />
        {children}
      </section>
    </Layout>
  </OpacityTransition>
);
