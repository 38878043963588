import { Image } from "antd";
import { CheckMarkIcon } from "../../../../assets";
import { ConfirmationType } from "../../types";

interface Props {
  readonly type: ConfirmationType;
}

export const Confirmation = ({ type }: Props) => (
  <div className="Confirmation">
    {type.label && <Image alt="Check mark" src={CheckMarkIcon} preview={false} />}
    <h2>{type.title}</h2>
    <p className="Bold">What Happens Next:</p>
    <p>{type.text}</p>
    {!type.label && <p>This process should take no longer than 7–10 days.</p>}
    <p className="Bold">Learn more about FAST and Tokenisation</p>
    <ul>
      <li>
        <a href="https://openvc.app/blog/vc-fund-fast" target="_blank">
          What is a FAST ? Why FAST ?
        </a>
      </li>
      <li>
        <a href="https://www.nfx.com/post/token-investing" target="_blank">
          The Future of Fundraising? What We Need to Fix About Token Investing
        </a>
      </li>
      <li>
        <a
          href="https://www.linkedin.com/pulse/how-build-million-dollar-portfolio-savings-consiliencegroup"
          target="_blank">
          Learn about building a portfolio without Cash
        </a>
      </li>
    </ul>
  </div>
);
