import { Image } from "antd";
import { WarningOrangeIcon } from "../../../../assets";

export const EmailSent = () => (
  <div className="ExistingEmail">
    <Image alt="Warning logo" src={WarningOrangeIcon} preview={false} />
    <h2>Please check your inbox!</h2>
    <p>
      We sent you an email containing instructions on how to continue with your application. If you don't see the email,
      please check your spam folder.
    </p>
    <p>
      Please keep this email safe in your inbox, as it is the only way for you to get back to your application later on.
    </p>
  </div>
);
