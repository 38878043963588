import { PropsWithChildren } from "react";
import { FastDetails, MembershipDetails } from "./types";
import { Loader } from "../../components/uiElements/Loader";
import { Status as FastStatus, useFast } from "../../hooks/useFast";
import { Status, useFastMembership } from "../../hooks/useFastMembership";
import { Pages } from "../../pages";
import { useChain } from "../ChainClient";
import { useFastSymbol } from "../FastSymbolProvider";
import { createGenericContext } from "../utils";

interface State {
  readonly details: FastDetails;
  readonly membership: MembershipDetails;
}

const [useFastContext, Provider] = createGenericContext<State>("FastContractContext");
export { useFastContext };

export const FastContractContext = ({ children }: PropsWithChildren) => {
  const { signerAddress } = useChain();
  const { symbol } = useFastSymbol();

  const fast = useFast(symbol);
  const membership = useFastMembership(fast.status == FastStatus.Done ? fast.details : undefined, signerAddress);

  // We're loading the FAST details...
  if (fast.status == FastStatus.Loading) return <Loader label="Loading FAST Contract..." />;
  // FAST not found, or there's an error...
  else if (fast.status == FastStatus.Error) return <Pages.Errors.Error reason={fast.reason} />;
  // Loading membership details...
  else if (membership.status == Status.Loading) return <Loader label="Loading FAST Membership..." />;
  // Membership details error.
  else if (membership.status == Status.Error)
    return <Pages.Errors.Error reason={`Failed to load FAST ${symbol} Membership details.`} />;

  // All is good!
  return <Provider value={{ details: fast.details, membership: membership.details }}>{children}</Provider>;
};
