import { Space } from "antd";
import { History } from "./Management/History";
import { Minting } from "./Management/Minting";
import { ReserveTransfer } from "./Management/ReserveTransfer";
import { Settings } from "./Management/Settings";
import { useFastContext } from "../../providers/FastContractContext/FastContractContext";

export const Management = () => {
  const fastDetails = useFastContext();
  const { details } = fastDetails;
  const { address, symbol, hasFixedSupply, reserveBalance, contract, formatter, memberCount } = details;

  return (
    <section className="FastTab">
      <Space className="FastManagement" direction="vertical" size={56}>
        {hasFixedSupply || <Minting address={address} formatter={formatter} />}
        <ReserveTransfer
          address={address}
          symbol={symbol}
          reserveBalance={reserveBalance}
          formatter={formatter}
          memberCount={memberCount}
        />
        <Settings address={address} fastDetails={details} />
        <History contract={contract} formatter={formatter} symbol={symbol} />
      </Space>
    </section>
  );
};
