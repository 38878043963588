import { AutoComplete } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useResolveAddressLazyQuery, useResolveIdentityLazyQuery } from "../../__generated__/graphql";
import { useDebounce } from "../../hooks/useDebounce";
import { EthAddress, isEthAddress } from "../../types/ethers";

interface Option {
  readonly value: string;
  readonly label?: string;
}

interface Props {
  readonly ethAddresses?: ReadonlyArray<EthAddress>;
  readonly id?: string;
  readonly initialValue?: EthAddress;
  readonly label?: string;
  readonly placeholder?: string;
  readonly onChange?: (value?: EthAddress) => void;
}

export const AddressResolverInput = ({ ethAddresses, id, initialValue, label, placeholder, onChange }: Props) => {
  const [riQuery] = useResolveIdentityLazyQuery();
  const [raQuery] = useResolveAddressLazyQuery();
  // The options as returned by the backend. Initially an empty list.
  const [options, setOptions] = useState<Option[]>([]);
  // The currently selected value, or undefined if none.
  const [value, setValue] = useState<Option>();
  // Whether or not we've already ran the initial value resolution.
  const [initialValueResolved, setInitialValueResolved] = useState(false);
  // The component starts with an empty string as the search term.
  const [fullName, setFullName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleRiQuery = useCallback(
    (address: EthAddress) => {
      // If we have a bunch of specific addresses, we should check whether this address fits.
      if (ethAddresses && !ethAddresses.includes(address)) {
        setErrorMessage(`This address doesn't belong to a member`);
        return;
      }

      riQuery({ variables: { ethAddress: address } })
        .then((res) => {
          const identity = res.data?.lookupIdentities?.at(0);
          if (!identity) throw new Error("Invalid server response.");
          setValue({ value: address, label: identity.fullName || "UNKNOWN" });
          onChange && onChange(address);
        })
        .catch((err) => {
          console.error(err);
          setValue({ value: address });
        });
    },
    [ethAddresses, riQuery, onChange],
  );

  // We're debouncing query for 300 ms.
  const debouncedRaQuery = useDebounce(() => {
    // It makes sense to require more identities if we are looking for specific addresses.
    const numberOfIdentities = ethAddresses ? ethAddresses.length : 10;

    raQuery({ variables: { fullName, numberOfIdentities } })
      .then((res) => {
        const identities = res.data?.identities?.edges;
        if (!identities) return;
        // As some identities might be null, iterate over them and filter out the null ones, while building a
        // compatible AutoComplete `{value: identity.ethAddress, label: identity.fullName}` array. Use a reducer for this.
        const newOptions = identities.reduce<Option[]>((acc, identity) => {
          if (!identity?.node?.ethAddress || !identity.node.fullName) return acc;

          // If we have a bunch of specific addresses, we should check whether this address fits.
          if (
            ethAddresses &&
            isEthAddress(identity.node.ethAddress) &&
            !ethAddresses.includes(identity.node.ethAddress)
          )
            return acc;

          return [...acc, { value: identity.node.ethAddress, label: identity.node.fullName || "UNKNOWN" }];
        }, []);
        setOptions(newOptions);
      })
      .catch(console.error);
  }, 300);

  // If there's an initial value, and we havn't resolved it, do it now.
  useEffect(() => {
    // Already resolved...
    if (initialValueResolved) return;
    // No initial value passed. Nothing to resolve.
    if (!initialValue) return;

    handleRiQuery(initialValue);
    setFullName(initialValue);
  }, [initialValueResolved, initialValue, handleRiQuery]);

  // If the user starts to type something after first render, allow it.
  useEffect(() => {
    if (!initialValueResolved && fullName.length > 0) {
      setInitialValueResolved(true);
    }
  }, [fullName, initialValueResolved]);

  useEffect(() => {
    if (!initialValueResolved) return;
    setValue({ value: fullName });
    setErrorMessage("");

    // We require at least 3 characters before hitting the backend.
    if (fullName.length < 3) {
      setOptions([]);
      // It's necessary for Antd Form validation.
      onChange && onChange();
      return;
    }

    // If the user has typed or pasted EthAddress, let's receive this address,
    // otherwise try to receive name.
    if (isEthAddress(fullName)) {
      handleRiQuery(fullName);
    } else {
      // Clean parent field data so that the user can't send previous data.
      onChange && onChange();
      debouncedRaQuery();
    }
  }, [debouncedRaQuery, initialValueResolved, fullName, handleRiQuery, onChange]);

  // For some reason, there's a Typescript typing issue here.
  // The first parameter is not an `Option` at runtime, it's a `string`.
  const onSelect = useCallback((_dummy: Option, newValue: Option) => setFullName(newValue.value), []);

  return (
    <div className="AddressResolverInput">
      {label && <label>{label}</label>}
      <AutoComplete
        allowClear
        id={id || label}
        notFoundContent="No results found"
        options={options}
        placeholder={placeholder}
        value={value}
        onSelect={onSelect}
        onSearch={setFullName}
      />
      {value?.label && <span className="AddressResolverInput-Name">{value.label}</span>}
      {errorMessage && <span className="ErrorMessage">{errorMessage}</span>}
    </div>
  );
};
